import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router';
import { ROUTE } from 'router';
import { LocalStorage } from 'shared';
import { ErrorNotification } from 'sharedComponents';
import { clearErrorUpdateToken, getUser, logoutAction, useAppDispatch, useAppSelector } from 'store';

import { IUser } from 'store/types';

interface IProps {
  redirectPage?: string;
  user: IUser;
}

export const ProtectedUnAuth = ({ redirectPage = ROUTE.HOME, user }: IProps) => {
  const dispatch = useAppDispatch();
  const { errorUpdateToken } = useAppSelector(getUser);

  // если в LocalStorage.LogoutMessage записалась ошибка, вызывается logoutAction() для выхода из аккаунта, записи ошибки в slice, очистки localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const message = localStorage.getItem(LocalStorage.LogoutMessage);
      if (message) dispatch(logoutAction());
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [dispatch]);

  const clearMessage = () => {
    dispatch(clearErrorUpdateToken());
  };

  return (
    <>
      <Outlet />
      {user.isAuth && <Navigate to={redirectPage} replace />}
      {errorUpdateToken && <ErrorNotification message={errorUpdateToken} clearMessage={clearMessage} />}
    </>
  );
};
