import { useState } from 'react';
import {
  clearPlatformsForDeletingFromViewed,
  deleteAllPlatformsFromHistoryAction,
  deleteSelectedPlatformsFromHistoryAction,
  getCountOfPlatformsForDeletingFromViewed,
  getIsDeletingfromViewed,
  getIsSelectMode,
  getPlatformsForDeletingFromViewed,
  toggleSelectMode,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { Modal } from 'sharedComponents';
import { ProfileHistoryTabs } from 'modules/Profile/ProfileLayout/ProfileHistoryLayout/config';

interface CleanHistoryProps {
  target: ProfileHistoryTabs;
}

export const CleanHistoryPlatforms = ({ target }: CleanHistoryProps) => {
  const [isShowSelectButtons, setIsShowSelectButtons] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSelectMode = useAppSelector(getIsSelectMode);
  const selectedPlatforms = useAppSelector(getPlatformsForDeletingFromViewed);
  const countOfSelectedPlatforms = useAppSelector(getCountOfPlatformsForDeletingFromViewed);
  const dispatch = useAppDispatch();
  const isDeleting = useAppSelector(getIsDeletingfromViewed);
  const selectedCount = target === ProfileHistoryTabs.platforms ? countOfSelectedPlatforms : 0;
  const isDisabled = !selectedCount || isDeleting;

  const handleToggleSelectButtons = () => {
    setIsShowSelectButtons((prev) => !prev);
  };

  const handleToggleSelectMode = () => {
    if (target === ProfileHistoryTabs.platforms) {
      dispatch(toggleSelectMode());
    }
  };

  const handleCancel = () => {
    if (target === ProfileHistoryTabs.platforms) {
      dispatch(clearPlatformsForDeletingFromViewed());
    }
    handleToggleSelectButtons();
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleModalClick = () => {
    if (isSelectMode) {
      dispatch(deleteSelectedPlatformsFromHistoryAction(selectedPlatforms));
    } else {
      dispatch(deleteAllPlatformsFromHistoryAction());
    }
    setIsModalOpen(false);
  };

  return (
    <>
      {!isShowSelectButtons && (
        <button type="button" className="buttonWithoutBorder" onClick={handleToggleSelectButtons}>
          Очистить историю
        </button>
      )}
      {isShowSelectButtons && !isSelectMode && (
        <div>
          <button type="button" className="button buttonSecondary" onClick={handleToggleSelectMode}>
            Выбрать
          </button>
          <button type="button" className="buttonWithoutBorder buttonRemove" onClick={handleOpenModal}>
            Удалить все
          </button>
        </div>
      )}
      {isSelectMode && (
        <div>
          <button type="button" className="button buttonSecondary" onClick={handleCancel}>
            Отменить
          </button>
          <button
            type="button"
            className="buttonWithoutBorder buttonRemove"
            disabled={isDisabled}
            onClick={handleOpenModal}
          >
            Удалить <span>({selectedCount})</span>
          </button>
        </div>
      )}
      {isModalOpen && (
        <Modal setIsActiveModal={setIsModalOpen} handleClick={handleModalClick}>
          <div>
            <p>Вы уверены, что хотите удалить платформy(-ы) из истории просмотра?</p>
            <p>Данное действие невозможно будет отменить</p>
          </div>
        </Modal>
      )}
    </>
  );
};
