import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteUsers } from 'modules/Admin/api';
import { RootState, setUsersAction } from 'store';

export const deleteUsersAction = createAsyncThunk<void, void, { state: RootState }>(
  'usersPage/deleteUsersAction',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const ids = state.usersPage.usersChecked;
    await deleteUsers(ids);
    dispatch(setUsersAction());
  },
);
