import { OrderCard } from 'components/OrderCard/OrderCard';
import { robotConstructor, robotEntire } from 'assets';
import { useState } from 'react';

import styles from './styles.module.scss';

export const OrderCards = () => {
  const [isFormReady, setIsFormReady] = useState(false);
  const [isFormRevision, setIsFormRevision] = useState(false);

  const handleFormReady = () => {
    setIsFormReady(!isFormReady);
  };

  const handleFormRevision = () => {
    setIsFormRevision(!isFormRevision);
  };

  return (
    <div className={styles.orderCardsWrapper}>
      <OrderCard
        title="Заказать готовое решение"
        description="Чат-бот в Telegram для языковой школы. Заменяет LMS и помогает выстроить бесшовный автоматизированный путь студента в мессенджере"
        btnTitle="Заказать"
        picturePath={robotEntire}
        titleForm="на готовое решение"
        onBtnClick={handleFormReady}
        isFormReady={isFormReady}
      />

      <OrderCard
        picturePath={robotConstructor}
        title="Решение с доработкой"
        description="Персональное решение под ваши задачи. Наш менеджер свяжется с вами по телефону и рассчитает цену после уточнения всех деталей"
        btnTitle="Отправить заявку"
        titleForm="на доработку готового решения"
        onBtnClick={handleFormRevision}
        isFormRevision={isFormRevision}
      />
    </div>
  );
};
