import { createAsyncThunk } from '@reduxjs/toolkit';
import { updatePlatformsFiltersCategory } from 'modules/Admin/api';

import { setAdminPlatformsCategoriesFiltersAction } from './setAdminPlatformsCategoriesFiltersAction';

export interface IEditCategoriesForPlatforms {
  id: string | null;
  categoryName: string;
}

export const editCategoriesForPlatformsAction = createAsyncThunk<void, IEditCategoriesForPlatforms>(
  'adminPlatformsFiltersCategory/editCategoriesForPlatforms',
  async ({ id, categoryName }, { dispatch }) => {
    if (id) {
      await updatePlatformsFiltersCategory({ filterGroupName: categoryName }, id);
    }

    dispatch(setAdminPlatformsCategoriesFiltersAction());
  },
);
