import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentFilterForPlatform } from 'api';
import { getFiltersForPlatformsAdmin } from 'modules/Admin/api/getFiltersForPlatformsAdmin';

import { AdminPlatformFilter, AdminPlatformsFiltersResponse } from 'store/types';

export const setAdminPlatformsFiltersAction = createAsyncThunk<AdminPlatformsFiltersResponse[]>(
  'adminPlatformsFilters/setAdminPlatformsFiltersAction',
  getFiltersForPlatformsAdmin,
);

export const setCurrentPlatformFilterAction = createAsyncThunk<AdminPlatformFilter, string>(
  'adminPlatformsFilters/setPlatformFilterAction',
  (filterId) => getCurrentFilterForPlatform(filterId),
);
