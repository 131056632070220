import { SolutionsFiltersList } from 'modules/Admin/components';
import { AdminSolutionRoutes, ROUTE } from 'modules/Admin/router/routes';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SuccessNotification } from 'sharedComponents';
import { clearMessages, getSolutionsFilterSuccessSelector, useAppDispatch, useAppSelector } from 'store';

import styles from './styles.module.scss';

export const SolutionsFiltersDirectoryPage = () => {
  const successMessage = useAppSelector(getSolutionsFilterSuccessSelector);
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(clearMessages());
    },
    [dispatch],
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.buttonWrapper}>
          <Link
            to={`${ROUTE.ADMIN}/${ROUTE.SOLUTIONS}/${AdminSolutionRoutes.workWithFilter}/${AdminSolutionRoutes.create}`}
            className="button buttonSecondary"
          >
            Добавить фильтр
          </Link>
        </div>
        <SolutionsFiltersList />
      </div>
      {successMessage && <SuccessNotification message={successMessage} />}
    </>
  );
};
