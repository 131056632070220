import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Link as AnchorLink } from 'react-scroll';

import { ROUTE } from 'router';
import { logoIcon } from 'assets';

import { LocalStorage, navs } from 'shared';
import { LANGUAGES } from 'helpers';

import styles from './index.module.scss';

import { footerRoutes, socials } from './config/routes';

export const Footer = () => {
  const defaultLang = localStorage.getItem(LocalStorage.Language);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLang || 'ru');

  useEffect(() => {
    const handleStorageChange = () => {
      const lang = localStorage.getItem(LocalStorage.Language);
      if (lang) setSelectedLanguage(lang);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = event.target.value;
    localStorage.setItem(LocalStorage.Language, lang);
    window.dispatchEvent(new Event('storage'));
  };

  const navigate = useNavigate();

  const handleLinkClick = (to: string) => {
    window.scrollTo(0, 0);
    navigate(to);
  };
  return (
    <footer className={styles.footer}>
      <div className="containerWrapper">
        <div className={styles.wrapperLogo}>
          <Link to={ROUTE.HOME}>
            <img src={logoIcon} className={styles.logo} alt="Townsend logo" />
          </Link>
        </div>
        <div className={styles.wrapperUp}>
          <ul className={styles.wrapper}>
            {navs.map(({ to, text }) => (
              <li key={text} onClick={() => handleLinkClick(to)}>
                <Link to={to} className={styles.links}>
                  {text}
                </Link>
              </li>
            ))}
          </ul>

          <div className={styles.right}>
            <ul className={styles.wrapper}>
              {footerRoutes.map(({ to, text }) => (
                <li key={text}>
                  <AnchorLink to={to} className={styles.links} offset={-140}>
                    {text}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.wrapperDown}>
          <div className={styles.dropdown}>
            <select className={styles.lang} value={selectedLanguage} onChange={handleLanguageChange}>
              <option value="ru">{LANGUAGES.ru}</option>
              <option value="en">{LANGUAGES.en}</option>
            </select>
          </div>

          <ul className={styles.social_block__container}>
            {socials.map(({ img, to }) => {
              const Img = img;
              return (
                <li key={to}>
                  <a href={to}>
                    <Img />
                  </a>
                </li>
              );
            })}
          </ul>
          <div className={styles.copyright}>© 2023 Townsend </div>
        </div>
      </div>
    </footer>
  );
};
