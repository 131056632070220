import { getRole, logoutWithoutErrorAction, setIsModalProfileOpen, useAppDispatch, useAppSelector } from 'store';
import { ROLES } from 'shared';
import { currentRoutesNavAdmin } from 'helpers';

import styles from './styles.module.scss';

import { MenuNavItem } from '../MenuNavItem/MenuNavItem';
import { ExitIcon } from '../../assets';

export const MenuNavAdmin = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(getRole);

  const list = currentRoutesNavAdmin[role as ROLES];

  const handleLogout = () => {
    dispatch(logoutWithoutErrorAction());
    dispatch(setIsModalProfileOpen(false));
  };

  return (
    <nav className={styles.nav}>
      <ul className={styles.linksWrapper}>
        {list.map((item) => (
          <MenuNavItem item={item} key={item.id} />
        ))}
      </ul>
      <button className={styles.exit} type="button" onClick={handleLogout}>
        <ExitIcon />
        <span>Выйти</span>
      </button>
    </nav>
  );
};
