import { useState } from 'react';
import { DescriptionIcon } from 'assets';

import { PopUpDescription } from 'modules';

import styles from './styles.module.scss';

import { ToggleButton } from '../ToggleButton/ToggleButton';
import { CheckItem } from '../CheckItem/CheckItem';

import type { IFilters as FiltersType, IFilters } from 'store/types';

const VISIBLE_ITEMS = 4; // количество видимых элементов в группе

interface FiltersGroupProps {
  activeFilters: string[];
  filtersData: FiltersType;
  objFilter?: IFilters;
  onClickAny: (event: any) => void;
  showTitle?: boolean;
  showFullList?: boolean;
}

export const Filters = ({
  activeFilters,
  filtersData,
  objFilter,
  onClickAny,
  showTitle = true,
  showFullList = false,
}: FiltersGroupProps) => {
  const { descriptionLong, filterName } = filtersData;
  const filterItemsCount = filtersData.filterItemsForPlatforms.length;

  const [isOpen, setIsOpen] = useState(showFullList);
  const toggleOpen = () => setIsOpen((prevState) => !prevState);

  const getHasActiveRadio = () => {
    const arrFilterItems = objFilter?.filterItemsForPlatforms.map((item) => item.id) || [];
    const hasActive = arrFilterItems.some((item) => activeFilters.includes(item));
    return hasActive;
  };

  return (
    <div className={styles.filterGroup}>
      {showTitle && (
        <h6 className={styles.filterName}>
          {filterName}
          {descriptionLong && (
            <div className={styles.iconContainer}>
              <DescriptionIcon className={styles.icon} />
              <div className={styles.popUpBox}>
                <PopUpDescription name={filterName} text={descriptionLong} />
              </div>
            </div>
          )}
        </h6>
      )}
      <ul className={styles.filterOptions}>
        {filtersData.filterItemsForPlatforms.map(
          (option, index) =>
            (isOpen || index + 1 <= VISIBLE_ITEMS) && (
              <CheckItem
                filterName={filtersData.id}
                type={filtersData.inputType as 'checkbox' | 'radio'}
                filter={option}
                index={index}
                key={option.id}
                activeFilters={activeFilters}
                hasActiveRadio={getHasActiveRadio()}
                onClickAny={onClickAny}
              />
            ),
        )}
      </ul>
      {!showFullList && filterItemsCount > VISIBLE_ITEMS && (
        <ToggleButton moreItem={filterItemsCount - VISIBLE_ITEMS} isOpen={isOpen} onClick={toggleOpen} />
      )}
    </div>
  );
};
