import { BasketIcon, PencilIcon } from 'modules/Admin/assets';
import { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';

import cn from 'classnames';
import { solutionImageScheme } from 'modules/Admin/data/solutionImageScheme';

import { ISolutionImage } from 'modules/Admin/api';
import { useParams } from 'react-router';

import { useAppDispatch, updateSolutionImageAction } from 'store';

import styles from './styles.module.scss';

import buttonStyles from '../../../../styles/button.style.module.scss';

import { TextInput } from '..';

interface ImageLineProps {
  description: string;
  handleDelete: () => void;
  file: {
    id: string;
    url: string;
  } | null;
}

interface ImageOptions {
  imageDescription: string;
  name: string;
}

export const SolutionImageLine: FC<ImageLineProps> = ({ description, handleDelete, file }) => {
  const saveButtonStyle = cn(buttonStyles.invertButton, styles.saveBtn);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { id: currentSolutionId } = useParams<{ id: string }>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<ImageOptions>({
    mode: 'onChange',
    defaultValues: {
      imageDescription: description,
    },
    resolver: yupResolver(solutionImageScheme),
  });

  const isDisabled = !isDirty || !isValid;

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const onSubmit = (data: ImageOptions) => {
    const UpdateData: ISolutionImage = {
      solutionId: currentSolutionId!,
      fileId: file?.id || '',
      description: data.imageDescription,
    };
    dispatch(updateSolutionImageAction(UpdateData));
    setIsEdit(!isEdit);
  };

  const handleReset = () => {
    reset();
    setIsEdit(!isEdit);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.imageLine}>
        <div className={styles.imageLineData}>
          <img src={file?.url} alt="Preview" />
          <div className={styles.imageInputs}>
            {isEdit ? (
              <TextInput
                name="imageDescription"
                id="imageDescription"
                register={register}
                error={errors.imageDescription}
                defaultValue={description}
                value={description}
              />
            ) : (
              <p>{description}</p>
            )}
          </div>
        </div>
        <div className={styles.editButtons}>
          <button type="button" onClick={handleEdit}>
            <PencilIcon />
          </button>
          <button type="button" onClick={handleDelete}>
            <BasketIcon />
          </button>
        </div>
      </div>
      {isEdit && (
        <div className={styles.buttonsBlock}>
          <button className="button buttonWithoutBorder" type="button" onClick={handleReset}>
            Отмена
          </button>
          <button className={saveButtonStyle} type="submit" disabled={isDisabled}>
            Сохранить изменения
          </button>
        </div>
      )}
    </form>
  );
};
