import { RootState } from 'store/types/types';

export const getPlatformsFilterCategoriesSelector = (state: RootState) =>
  state.adminPlatformsFiltersCategory.categoriesForFiltersPlatforms;

export const getCategoriesPlatformIsDeleteModalOpen = (state: RootState) =>
  state.adminPlatformsFiltersCategory.isCategoriesPlatformIsDeleteModalOpen;

export const getEditingCategoryMode = (state: RootState) => state.adminPlatformsFiltersCategory.editingMode;

export const getActiveCategory = (state: RootState) => state.adminPlatformsFiltersCategory.activeCategory;

export const getActiveCategoryId = (state: RootState) => state.adminPlatformsFiltersCategory.activeCategoryId;

export const getIsSaveCategoryModalOpen = (state: RootState) => state.adminPlatformsFiltersCategory.isSaveModalOpen;

export const getPlatformCategoryErrorMessage = (state: RootState) => state.adminPlatformsFiltersCategory.errorMessage;

export const getPlatformCategorySuccessMessage = (state: RootState) =>
  state.adminPlatformsFiltersCategory.successMessage;
