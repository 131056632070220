import { PlatfromCard } from 'store';
import { BasketIcon, PencilIcon } from 'modules/Admin/assets';
import { Dispatch, SetStateAction, useState } from 'react';
import { CustomLogo } from 'components/CustomLogo/CustomLogo';
import { Link } from 'react-router-dom';
import { ROUTE, TabsRoutes } from 'modules/Admin/router/routes';
import cn from 'classnames';

import styles from './styles.module.scss';

import { SwitchBtn } from '../SwitchBtn/SwitchBtn';

interface PlatformLineProps {
  platform: PlatfromCard;
  handleToggle: (isActive: boolean, platformId: string) => Promise<void>;
  setActiveId: Dispatch<SetStateAction<string>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  isAdmin: boolean;
}

export const PlatformLine = ({
  platform,
  handleToggle,
  setActiveId,
  setIsModalOpen,
  setErrorMessage,
  isAdmin,
}: PlatformLineProps) => {
  const { file, name, isActive, numberOfSolution, id } = platform;
  const [imageLoadError, setImageLoadError] = useState(false);
  const handleImageLoadError = () => setImageLoadError(true);

  const handleSwitch = (isToggled: boolean) => handleToggle(isToggled, id);

  const activityStyle = cn(styles.activity, {
    [styles.isActive]: isActive,
  });

  return (
    <>
      <div className={styles.imageWrapper}>
        <span className={styles.image}>
          {!file?.url || imageLoadError ? (
            <CustomLogo title={name} />
          ) : (
            <img src={file?.url} onError={handleImageLoadError} alt="Platform icon" />
          )}
        </span>
      </div>
      <span className={styles.platformName}>{name}</span>
      <span className={styles.solutions}>{numberOfSolution}</span>
      {isAdmin ? (
        <SwitchBtn
          isToggled={isActive}
          setIsToggled={handleSwitch}
          id={id}
          unActiveText="Неактивна"
          activeText="Активна"
        />
      ) : (
        <span className={activityStyle}>{isActive ? 'Активна' : 'Неактивна'}</span>
      )}
      <Link to={`${ROUTE.ADMIN}/${ROUTE.PLATFORMS}/${id}/${TabsRoutes.mainOptions}`}>
        <PencilIcon />
      </Link>
      {isAdmin && (
        <button
          type="button"
          onClick={() => {
            setActiveId(id);
            setErrorMessage('');
            setIsModalOpen(true);
          }}
        >
          <BasketIcon />
        </button>
      )}
    </>
  );
};
