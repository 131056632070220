import { useCallback, useEffect } from 'react';
import { Input, Modal } from 'sharedComponents';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { filtersCategoryScheme } from 'modules/Admin/data/filtersCategoryScheme';
import {
  editCategoriesForPlatformsAction,
  getActiveCategory,
  getActiveCategoryId,
  getIsSaveCategoryModalOpen,
  setAdminPlatformsCategoriesFiltersAction,
  setEditingCategoryMode,
  setSaveModalOpen,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { createCategoriesForPlatformsAction } from 'store/actions/createCategoriesForPlatformsAction';

import styles from './styles.module.scss';

interface EditModeProps {
  isEditMode?: boolean;
}

// eslint-disable-next-line react/prop-types
export const AdminCategoriesEdit: React.FC<EditModeProps> = ({ isEditMode }) => {
  const dispatch = useAppDispatch();
  const activeId = useAppSelector(getActiveCategoryId);
  const activeCategory = useAppSelector(getActiveCategory);
  const isSaveModalOpen = useAppSelector(getIsSaveCategoryModalOpen);

  const handleResetMode = useCallback(() => dispatch(setEditingCategoryMode(null)), [dispatch]);

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<{ categoryName: string }>({
    mode: 'onChange',
    resolver: yupResolver(filtersCategoryScheme),
  });

  const handleSaveModalOpen = (isOpen: boolean) => dispatch(setSaveModalOpen(isOpen));

  const handleCancel = () => {
    handleResetMode();
  };

  const handleSaveCategory = () => {
    const { categoryName } = getValues();
    if (categoryName) {
      if (isEditMode) {
        dispatch(editCategoriesForPlatformsAction({ id: activeId, categoryName }));
      } else {
        dispatch(createCategoriesForPlatformsAction({ categoryName })).then(() =>
          dispatch(setAdminPlatformsCategoriesFiltersAction()),
        );
      }

      dispatch(setEditingCategoryMode(null));
      handleSaveModalOpen(false);
    }
  };

  const handleSubmitBtn = () => {
    if (!isEditMode) handleSaveCategory();
  };

  const isDisabled = !isDirty || !isValid;

  const categoryNameByDefault = activeCategory?.filterGroupName;

  useEffect(() => {
    if (categoryNameByDefault) {
      setValue('categoryName', categoryNameByDefault);
    }
  }, [dispatch, categoryNameByDefault, setValue, reset, handleResetMode]);

  return (
    <>
      <form className={styles.editingWrapper} onSubmit={handleSubmit(() => handleSaveModalOpen(true))}>
        <Input
          id="categoryName"
          label="Введите наименование категории фильтров"
          register={register}
          error={errors.categoryName?.message}
        />
        <div className={styles.buttonWrapper}>
          <button className="button buttonWithoutBorder" type="button" onClick={handleCancel}>
            Отмена
          </button>
          <button type="submit" className="button buttonSecondary" onClick={handleSubmitBtn} disabled={isDisabled}>
            Сохранить
          </button>
        </div>
      </form>
      {isSaveModalOpen && isEditMode && (
        <Modal handleClick={handleSaveCategory} setIsActiveModal={handleSaveModalOpen} submitButtonText="Сохранить">
          <p>Вы уверены, что хотите сохранить изменения?</p>
        </Modal>
      )}
    </>
  );
};
