import { GetSolutionsResponse } from 'store';
import { SortType } from 'modules/Admin/config';

import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export interface UserData {
  amount: string;
  count: number;
  type: `${SortType}`;
  idFilterItems: string[];
  priceMin: string;
  priceMax: string;
}

interface RequestData {
  idFilterItems: string[];
  priceMin: number;
  priceMax?: number;
}

export const getSolutionCards = ({
  amount,
  count,
  type,
  idFilterItems,
  priceMin,
  priceMax,
}: UserData): Promise<GetSolutionsResponse> => {
  const params = {
    pageNumber: amount,
    numberOfSolutionsPerPage: count,
    type,
  };

  const requestData: RequestData = {
    idFilterItems,
    priceMin: priceMin && !priceMin.includes('-') ? +priceMin : 0,
  };

  if (priceMax && !priceMax.includes('-') && +priceMax > 0) {
    requestData.priceMax = +priceMax;
  }

  return authAxiosInstance
    .post(
      endpoints.getFilteredSolutions,

      requestData,
      {
        params,
      },
    )
    .then(({ data }) => data);
};
