export const formatDateToDateTimeStart = (date: string) => {
  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}T00:00:00.000Z`;
};

export const formatDateToDateTimeEnd = (date: string) => {
  const [day, month, year] = date.split('.');
  return `${year}-${month}-${day}T23:59:59.999Z`;
};

export const formatDateTimeToDate = (dateTime: string) => {
  const parts = dateTime.split(/[-T:.Z]/);
  return `${parts[2]}.${parts[1]}.${parts[0]}`;
};

export const formatDateToShort = (dateStr: string) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};
