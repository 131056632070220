import { SolutionCard } from 'store';
import { BasketIcon, PencilIcon } from 'modules/Admin/assets';
import { Dispatch, SetStateAction, useState } from 'react';
import { CustomLogo } from 'components/CustomLogo/CustomLogo';
import cn from 'classnames';

import { Link } from 'react-router-dom';
import { ROUTE, TabsRoutes } from 'modules/Admin/router/routes';

import styles from './styles.module.scss';

import { SwitchBtn } from '../SwitchBtn/SwitchBtn';

interface SolutionLineProps {
  solution: SolutionCard;
  handleToggle: (isActive: boolean, solutionId: string) => void;
  setDeletingSolutionId: (id: string) => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  isAdmin: boolean;
}

export const SolutionLine = ({
  solution,
  handleToggle,
  setDeletingSolutionId,
  setIsModalOpen,
  isAdmin,
}: SolutionLineProps) => {
  const {
    name,
    isActive,
    id,
    platform: { file, name: platformName },
  } = solution;
  const [imageLoadError, setImageLoadError] = useState(false);
  const handleImageLoadError = () => setImageLoadError(true);

  const handleSwitch = (isToggled: boolean) => handleToggle(isToggled, id);

  const activityStyle = cn(styles.activity, {
    [styles.isActive]: isActive,
  });

  return (
    <>
      <p className={styles.platformName}>{name}</p>
      <div className={styles.imageWrapper}>
        <span className={styles.image}>
          {!file?.url || imageLoadError ? (
            <CustomLogo title={platformName} />
          ) : (
            <img src={file?.url} onError={handleImageLoadError} alt="Platform icon" />
          )}
        </span>
        <p className={styles.platformName}>{platformName}</p>
      </div>
      {isAdmin ? (
        <SwitchBtn
          isToggled={isActive}
          setIsToggled={handleSwitch}
          id={id}
          unActiveText="Неактивна"
          activeText="Активна"
        />
      ) : (
        <span className={activityStyle}>{isActive ? 'Активна' : 'Неактивна'}</span>
      )}
      <Link to={`${ROUTE.ADMIN}/${ROUTE.SOLUTIONS}/${id}/${TabsRoutes.mainOptions}`}>
        <PencilIcon />
      </Link>
      {isAdmin && (
        <button
          type="button"
          onClick={() => {
            setDeletingSolutionId(id);
            setIsModalOpen(true);
          }}
        >
          <BasketIcon />
        </button>
      )}
    </>
  );
};
