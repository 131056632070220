import axios from 'axios';
import { LocalStorage } from 'shared';

import { endpoints } from './endpoints';
import { refreshTokenWithoutError } from './refreshToken';

export const logout = async () => {
  try {
    const accessToken = localStorage.getItem(LocalStorage.AccessToken);
    const language = localStorage.getItem(LocalStorage.Language) || 'ru';
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Language': language };
    await axios.get(endpoints.logout, { withCredentials: true, headers });
  } finally {
    localStorage.removeItem(LocalStorage.AccessToken);
  }
};

export const logoutWithoutError = async () => {
  const accessToken = localStorage.getItem(LocalStorage.AccessToken);
  try {
    const language = localStorage.getItem(LocalStorage.Language) || 'ru';
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Language': language };
    await axios.get(endpoints.logout, { withCredentials: true, headers });
    localStorage.removeItem(LocalStorage.AccessToken);
  } catch (error) {
    if (accessToken) {
      try {
        // если accessToken устарел, обновляем его и отправляем запрос повторно
        await refreshTokenWithoutError(accessToken);
        await logoutWithoutError();
      } catch (errorUpdateRefresh: any) {
        throw new Error(errorUpdateRefresh);
      }
    }
  } finally {
    localStorage.removeItem(LocalStorage.AccessToken);
  }
};
