import { createSlice } from '@reduxjs/toolkit';
import { updateEmailSendNewEmailAction, updateEmailSendOldEmailAction } from 'store/actions';

interface UpdateEmailState {
  showModalOldEmail: boolean;
  showModalNewEmail: boolean;
  newEmail: string;
  isLoading: boolean;
  errorMessage: string;
  successMessage: string;
}

const initialState: UpdateEmailState = {
  showModalOldEmail: false,
  showModalNewEmail: false,
  newEmail: '',
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};

const setLoading = (state: UpdateEmailState) => {
  state.isLoading = true;
  state.newEmail = '';
  state.showModalOldEmail = false;
  state.showModalNewEmail = false;
  state.errorMessage = '';
  state.successMessage = '';
};

const updateEmailSlice = createSlice({
  name: 'updateEmail',
  initialState,
  reducers: {
    clearUpdateEmailMessages: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
    closeUpdateEmailModal: (state) => {
      state.showModalOldEmail = false;
      state.showModalNewEmail = false;
    },
    setUpdateEmailError: (state, { payload }) => {
      state.errorMessage = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateEmailSendOldEmailAction.pending, setLoading)
      .addCase(updateEmailSendOldEmailAction.fulfilled, (state) => {
        state.isLoading = false;
        state.showModalOldEmail = true;
      })
      .addCase(updateEmailSendOldEmailAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка отправки ссылки на текущий email';
      })
      .addCase(updateEmailSendNewEmailAction.pending, setLoading)
      .addCase(updateEmailSendNewEmailAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.showModalNewEmail = true;
        state.newEmail = payload;
      })
      .addCase(updateEmailSendNewEmailAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка отправки ссылки на новый email';
      });
  },
});

export const {
  reducer: updateEmailReducer,
  actions: { clearUpdateEmailMessages, closeUpdateEmailModal, setUpdateEmailError },
} = updateEmailSlice;
