import { RootState } from 'store/types/types';

export const getSolutionState = (state: RootState) => state.solutionOption.solution;

export const getSolutionOptionIsLoading = (state: RootState) => state.solutionOption.isLoading;

export const getSolutionOptionIsRedirect = (state: RootState) => state.solutionOption.isRedirect;

export const getSolutionOptionIsNeedUpdateInit = (state: RootState) => state.solutionOption.isNeedUpdateInit;

export const getSolutionOptionErrorMessage = (state: RootState) => state.solutionOption.errorMessage;

export const getSolutionOptionSuccessMessage = (state: RootState) => state.solutionOption.successMessage;
