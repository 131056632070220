import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { updateSolutionVideoData } from 'modules/Admin/api/updateSolutionVideoData';
import { SolutionVideoType } from 'store/types/solutionVideo';

export const updateSolutionVideoDataAction = createAsyncThunk<Promise<any>, SolutionVideoType, { rejectValue: string }>(
  'video/UpdateSolutionVideo',
  async (videoData, { rejectWithValue }) => {
    try {
      return await updateSolutionVideoData(videoData);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      return rejectWithValue(error.response?.data?.message || 'Ошибка обновления данных');
    }
  },
);
