import { Tabs } from 'modules/Admin/components';
import { Outlet } from 'react-router';
import { getRole, useAppSelector } from 'store';

import { solutionTabListConfig } from './config';
import styles from './styles.module.scss';

export const SolutionsLayout = () => {
  const role = useAppSelector(getRole);

  const tabList = role ? solutionTabListConfig[role] : [];

  return (
    <>
      <div className={styles.tabsWrapper}>
        <Tabs tabs={tabList || []} />
      </div>
      <Outlet />
    </>
  );
};
