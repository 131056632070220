import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRole } from 'store';
import { AxiosError } from 'axios';
import { getRoles } from 'modules/Admin/api';

export const setRolesAction = createAsyncThunk<IRole[], void, { rejectValue: string }>(
  'roles/setRolesAction',
  async (_, { rejectWithValue }) => {
    try {
      const roles = await getRoles();
      return roles;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
