import { authAxiosInstance } from 'modules/Auth/api';
import { SolutionFilter, UpdateSolutionFilter } from 'store';

import { endpoints } from './endpoints';

export const updateSolutionsFilter = (filter: SolutionFilter): Promise<unknown> => {
  const { filterName, descriptionLong, id, filterItemsForSolutions: options, inputType } = filter;

  const filterItemsForSolutions = options.map(({ filterItemName }) => filterItemName);

  const updatedFilter: UpdateSolutionFilter = {
    inputType,
    filterName,
    ...(descriptionLong && { descriptionLong }),
    filterItemsForSolutions,
  };

  return authAxiosInstance.put(`${endpoints.filtersForSolutions}/${id}`, updatedFilter).then((data) => data.data);
};
