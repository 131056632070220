import { ROUTE } from 'router';

export enum ProfileHistoryTabs {
  platforms = 'Платформы',
  solutions = 'Решения',
}

export interface HistoryTabsList {
  id: number;
  tabName: ProfileHistoryTabs;
  tabLink: ROUTE;
}

export const profileHistoryTabsList: HistoryTabsList[] = [
  {
    id: 1,
    tabName: ProfileHistoryTabs.platforms,
    tabLink: ROUTE.PROFILE_HISTORY_PLATFORMS,
  },
  {
    id: 2,
    tabName: ProfileHistoryTabs.solutions,
    tabLink: ROUTE.PROFILE_HISTORY_SOLUTIONS,
  },
];
