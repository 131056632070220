import { createAsyncThunk } from '@reduxjs/toolkit';
import { changePassword, updateUserData } from 'modules';
import { RootState, signInAction } from 'store';

interface INewPassword {
  newPassword?: string;
}

export const updateUserDateAction = createAsyncThunk<void, INewPassword, { state: RootState }>(
  'user/updateUserDateAction',
  async (objPassword, { dispatch, getState }) => {
    const state = getState();
    const { objUpdate } = state.user;
    if (Object.keys(objUpdate).length !== 0) await updateUserData(objUpdate);
    if (Object.keys(objPassword).length !== 0) await changePassword(objPassword);
    await dispatch(signInAction());
  },
);
