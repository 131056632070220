import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSolutionCards } from 'api';
import { GetSolutionsResponse, RootState } from 'store/types';

interface ISetSolutionsAction {
  amount: string;
}

export const setSolutionsAction = createAsyncThunk<GetSolutionsResponse, ISetSolutionsAction, { state: RootState }>(
  'solutions/setSolutionsAction',
  async ({ amount }, { getState }) => {
    const state = getState();
    const { sortSolutionDirection } = state.solutions;
    const { numberOfSolutionsPerPage } = state.solutions.solutions;
    const { activeFilters, prices } = state.solutionsPageFilters;

    const obj = {
      amount,
      count: numberOfSolutionsPerPage,
      type: sortSolutionDirection,
      idFilterItems: activeFilters,
      priceMin: prices.priceMin,
      priceMax: prices.priceMax,
    };
    const response = await getSolutionCards(obj);
    return response;
  },
);
