import { authAxiosInstance } from 'modules/Auth/api';
import { SolutionFilter, UpdateSolutionFilter } from 'store';

import { endpoints } from './endpoints';

export const createSolutionsFilter = (filter: SolutionFilter): Promise<void> => {
  const { filterName, descriptionLong, filterItemsForSolutions: options, inputType } = filter;

  const filterItemsForSolutions = options.map(({ filterItemName }) => filterItemName);

  const newFilter: UpdateSolutionFilter = {
    inputType,
    filterName,
    filterItemsForSolutions,
    ...(descriptionLong && { descriptionLong }),
  };

  return authAxiosInstance.post(`${endpoints.filtersForSolutions}`, newFilter).then((data) => data.data);
};
