import { ErrorNotification, Loading, Modal, SuccessNotification } from 'sharedComponents';
import { BasketIcon, PencilIcon } from 'modules/Admin/assets';
import { useForm } from 'react-hook-form';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';

import { createSolutionVideoScheme } from 'modules/Admin/data/createVideoScheme';

import { TextInput } from 'modules/Admin/components';

import cn from 'classnames';

import {
  setSolutionVideoLink,
  setSolutionVideoDesc,
  editSolutionVideoLink,
  editSolutionVideoDesc,
  useAppDispatch,
  useAppSelector,
  updateSolutionVideoDataAction,
  createSolutionVideoDataAction,
  setSolutionVideoDataByIdAction,
  getSolutionVideoState,
  getSolutionVideoLinkAddedState,
  getSolutionVideoDescAddedState,
  getSolutionVideoErrorMessage,
  getSolutionVideoSuccessMessage,
  getIsSolutionAddedState,
  getLoadingVideoState,
  resetAddedState,
  deleteSolutionVideoAction,
} from 'store';

import styles from './styles.module.scss';

import buttonStyles from '../../../../styles/button.style.module.scss';

import videoLinkIcon from '../../assets/icons/videoLink.svg';

interface VideoOptions {
  id: string;
  url: string;
  description: string;
}

export const SettingSolutionVideoPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { id: currentSolutionId } = useParams<{ id: string }>();
  const videoData = useAppSelector(getSolutionVideoState);
  const isSolutionAdded = useAppSelector(getIsSolutionAddedState);
  const isLinkAdded = useAppSelector(getSolutionVideoLinkAddedState);
  const isDescAdded = useAppSelector(getSolutionVideoDescAddedState);
  const errorMessage = useAppSelector(getSolutionVideoErrorMessage);
  const successMessage = useAppSelector(getSolutionVideoSuccessMessage);

  const isLoading = useAppSelector(getLoadingVideoState);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<VideoOptions>({
    mode: 'onChange',
    defaultValues: {
      id: videoData.id,
      url: videoData.url,
      description: videoData.description,
    },
    resolver: yupResolver(createSolutionVideoScheme),
  });

  const saveButtonStyle = cn(buttonStyles.invertButton, styles.saveBtn);
  const videoInputStyle = cn(styles.solutionVideo, styles.solutionVideoEdit);
  const saveButtonisDisabled = !isDirty;

  useEffect(() => {
    if (currentSolutionId) {
      dispatch(setSolutionVideoDataByIdAction(currentSolutionId));
      dispatch(resetAddedState());
    }
  }, [currentSolutionId, dispatch]);

  useEffect(() => {
    if (videoData) {
      setValue('url', videoData.url);
      setValue('description', videoData.description);
    }
    if (!videoData.url || !videoData.description) {
      dispatch(editSolutionVideoLink());
      dispatch(editSolutionVideoDesc());
    }
  }, [videoData, setValue, dispatch]);

  const onSubmit = (data: VideoOptions) => {
    if (isSolutionAdded && currentSolutionId) {
      const newData = {
        ...data,
        id: currentSolutionId,
      };
      dispatch(setSolutionVideoLink(newData.url));
      dispatch(setSolutionVideoDesc(newData.description));
      dispatch(updateSolutionVideoDataAction(newData));
    }
    if (!isSolutionAdded && currentSolutionId) {
      const newData = {
        ...data,
        id: currentSolutionId,
      };
      dispatch(setSolutionVideoLink(newData.url));
      dispatch(setSolutionVideoDesc(newData.description));
      dispatch(createSolutionVideoDataAction(newData));
    }
  };

  const handleLinkEdit = () => {
    dispatch(editSolutionVideoLink());
  };

  const handleDescEdit = () => {
    dispatch(editSolutionVideoDesc());
  };

  const handleReset = () => {
    setValue('url', videoData.url);
    setValue('description', videoData.description);

    if (Object.values(videoData).some((item) => Object.keys(item).length) && isSolutionAdded) {
      dispatch(setSolutionVideoLink(videoData.url));
      dispatch(setSolutionVideoDesc(videoData.description));
      return;
    }
    reset();
  };

  const handleDeleteButton = () => {
    setIsModalOpen(true);
  };

  const handleDeleteSolution = async () => {
    await dispatch(deleteSolutionVideoAction(currentSolutionId!));
    setIsModalOpen(false);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={isLinkAdded ? videoInputStyle : styles.solutionVideo}>
          <TextInput
            name="url"
            id="videoURL"
            register={register}
            label="Ссылка на видеофайл или анимацию"
            error={errors.url}
            inputIcon={videoLinkIcon}
            isDisabled={isLinkAdded}
            value={videoData.url}
          />
          {isLinkAdded && (
            <div className={styles.editButtons}>
              <button type="button" onClick={handleLinkEdit}>
                <PencilIcon />
              </button>
              <button type="button" onClick={handleDeleteButton}>
                <BasketIcon />
              </button>
            </div>
          )}
        </div>
        <div className={isDescAdded ? videoInputStyle : styles.solutionVideo}>
          <TextInput
            name="description"
            id="videoDescription"
            register={register}
            label="Краткое описание видеофайла"
            placeholder="Инструкция по установке"
            error={errors.description}
            isDisabled={isDescAdded}
            value={videoData.description}
          />
          {isDescAdded && (
            <div className={styles.editButtons}>
              <button type="button" onClick={handleDescEdit}>
                <PencilIcon />
              </button>
              <button type="button" onClick={handleDeleteButton}>
                <BasketIcon />
              </button>
            </div>
          )}
        </div>
        {(!isLinkAdded || !isDescAdded) && (
          <div className={styles.buttonsBlock}>
            <button className="button buttonWithoutBorder" type="button" onClick={handleReset}>
              Отмена
            </button>
            <button className={saveButtonStyle} type="submit" disabled={saveButtonisDisabled}>
              Сохранить
            </button>
          </div>
        )}
      </form>
      {isModalOpen && (
        <Modal setIsActiveModal={setIsModalOpen} handleClick={handleDeleteSolution}>
          <p>Вы уверены, что хотите удалить видео?</p>
        </Modal>
      )}
      {errorMessage && <ErrorNotification message={errorMessage} />}
      {successMessage && <SuccessNotification message={successMessage} />}
    </>
  );
};
