import { TabsRoutes } from 'modules/Admin/router/routes';
import { PlatformsTabsList, PlatformsTabs } from 'modules/Admin/types';
import { ROLES } from 'shared';

const adminPlatformsTabsList: PlatformsTabsList[] = [
  {
    id: 1,
    tabName: PlatformsTabs.workWithPlatforms,
    tabLink: TabsRoutes.workWithPlatforms,
  },
  {
    id: 2,
    tabName: PlatformsTabs.filtersDirectory,
    tabLink: TabsRoutes.filtersDirectory,
  },
  {
    id: 3,
    tabName: PlatformsTabs.filterСategories,
    tabLink: TabsRoutes.filterСategories,
  },
];

const moderatorPlatformsTabsList: PlatformsTabsList[] = [
  {
    id: 1,
    tabName: PlatformsTabs.workWithPlatforms,
    tabLink: TabsRoutes.workWithPlatforms,
  },
];

export const tabListConfig: { [key in ROLES]?: PlatformsTabsList[] } = {
  [ROLES.SUPERADMIN]: adminPlatformsTabsList,
  [ROLES.ADMIN]: adminPlatformsTabsList,
  [ROLES.MODERATOR]: moderatorPlatformsTabsList,
};
