import { configureStore } from '@reduxjs/toolkit';

import {
  platfromsPageFiltersReducer,
  platformReducer,
  platformsReducer,
  userReducer,
  modalProfileReducer,
  adminPlatformReducer,
  adminPlatformsReducer,
  adminPlatformsFiltersReducer,
  adminSolutionsReducer,
  activeLinkReducer,
  solutionsFiltersReducer,
  adminPlatformsFilterReducer,
  solutionsReducer,
  solutionsFilterReducer,
  solutionReducer,
  adminPlatformsFiltersCategoryReducer,
  solutionOptionReducer,
  solutionVideoReducer,
  solutionEventsReducer,
  usersPageReducer,
  rolesReducer,
  solutionTasksReducer,
  solutionReviewsReducer,
  solutionImageReducer,
  solutionOrderFormReducer,
  solutionsPageFiltersReducer,
  platformReviewsReducer,
  confirmEmailReducer,
  updateEmailReducer,
} from './features';

export const store = configureStore({
  reducer: {
    user: userReducer,
    platforms: platformsReducer,
    platform: platformReducer,
    sortPlatforms: platformsReducer,
    platfromsPageFilters: platfromsPageFiltersReducer,
    modalProfile: modalProfileReducer,
    activeLink: activeLinkReducer,
    adminPlatform: adminPlatformReducer,
    adminPlatforms: adminPlatformsReducer,
    solutions: solutionsReducer,
    solution: solutionReducer,
    solutionOption: solutionOptionReducer,
    sortSolutions: platformsReducer,
    solutionsFilters: solutionsFiltersReducer,
    solutionsPageFilters: solutionsPageFiltersReducer,
    adminPlatformsFilters: adminPlatformsFiltersReducer,
    adminSolutions: adminSolutionsReducer,
    adminPlatformsFilter: adminPlatformsFilterReducer,
    solutionsFilter: solutionsFilterReducer,
    adminPlatformsFiltersCategory: adminPlatformsFiltersCategoryReducer,
    solutionEvenets: solutionEventsReducer,
    solutionVideo: solutionVideoReducer,
    solutionTasks: solutionTasksReducer,
    usersPage: usersPageReducer,
    roles: rolesReducer,
    solutionsReview: solutionReviewsReducer,
    solutionImage: solutionImageReducer,
    solutionOrderForm: solutionOrderFormReducer,
    platformReviews: platformReviewsReducer,
    confirmEmail: confirmEmailReducer,
    updateEmail: updateEmailReducer,
  },
});
