export enum ROLES {
  USER = 'User',
  ADMIN = 'Admin',
  MODERATOR = 'Moderator',
  SUPERADMIN = 'SuperAdmin',
}

export const rolesRuEng: { [key: string]: string } = {
  Админ: 'Admin',
  Пользователь: 'User',
  Модератор: 'Moderator',
  Суперадмин: 'SuperAdmin',
};
