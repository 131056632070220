import axios from 'axios';
import { endpoints } from 'modules/Auth/api/endpoints';
import { LocalStorage } from 'shared';

interface IConfirmEmailResponse {
  message: string;
}

export const confirmEmail = (token: string): Promise<IConfirmEmailResponse> => {
  const language = localStorage.getItem(LocalStorage.Language) || 'ru';
  const headers = { Authorization: `Bearer ${token}`, 'Content-Language': language };
  return axios.post(endpoints.confirmEmail, {}, { withCredentials: true, headers }).then(({ data }) => data);
};
