import { MouseEvent, ReactElement } from 'react';

import cn from 'classnames';

import styles from './styles.module.scss';

export enum SliderArrowsDirection {
  left = 'left',
  right = 'right',
}

interface ISliderArrow {
  direction: SliderArrowsDirection;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  centeredBtns?: boolean;
}
const SliderArrow = ({ onClick, direction, centeredBtns = false }: ISliderArrow): ReactElement => {
  const leftArrow = cn(styles.prevArrow, {
    [styles.centeredLeftArrow]: centeredBtns,
  });
  const rightArrow = cn(styles.nextArrow, {
    [styles.centeredRightArrow]: centeredBtns,
  });
  const className = direction === SliderArrowsDirection.left ? leftArrow : rightArrow;
  return <div className={className} onClick={onClick} />;
};

export { SliderArrow };
