import axios from 'axios';
import { endpoints } from 'modules/Auth/api/endpoints';
import { LocalStorage } from 'shared';
import { UpdateEmail } from 'store';

const getHeaders = (token?: string) => {
  const language = localStorage.getItem(LocalStorage.Language) || 'ru';
  const accessToken = token || localStorage.getItem(LocalStorage.AccessToken);
  const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Language': language };
  return { withCredentials: true, headers };
};

export const updateEmailSendOldEmail = (): Promise<void> =>
  axios.post(endpoints.updateEmailSendOldEmail, {}, getHeaders());

export const updateEmailSendNewEmail = (body: UpdateEmail, token: string): Promise<void> =>
  axios.post(endpoints.updateEmailSendNewEmail, body, getHeaders(token));
