import axios from 'axios';
import { LocalStorage } from 'shared';

import { endpoints } from './endpoints';

import { ResetPasswordRequestType } from '../types/resetPasswordType';

export const resetPassword = (querryParams: ResetPasswordRequestType): Promise<void> => {
  const language = localStorage.getItem(LocalStorage.Language) || 'ru';
  const headers = { headers: { 'Content-Language': language } };
  return axios.post(endpoints.resetPassword, querryParams, headers);
};
