import { RootState } from 'store/types/types';

export const getSolutionsPageSolutionsFiltersSelector = (state: RootState) =>
  state.solutionsPageFilters.solutionsFilters;

export const getSolutionsPageActiveFiltersSelector = (state: RootState) => state.solutionsPageFilters.activeFilters;

export const getSolutionsPagePrices = (state: RootState) => state.solutionsPageFilters.prices;

export const getSolutionsPageIsLoading = (state: RootState) => state.solutionsPageFilters.isLoading;

export const getSolutionsPageErrorMessage = (state: RootState) => state.solutionsPageFilters.errorMessage;
