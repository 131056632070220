import { RootState } from 'store/types/types';

export const getSolutions = (state: RootState) => state.solutions.solutions.rows;

export const getTotalSolutionsCount = (state: RootState) => state.solutions.solutions.countRows;

export const getSolutionsTotalPages = (state: RootState) => state.solutions.solutions.numberOfPages;

export const getSolutionsLoadingState = (state: RootState) => state.solutions.loadingState;

export const getIsSolutionsLoaderVisible = (state: RootState) => state.solutions.isLoaderVisible;

export const getFavoriteSolutions = (state: RootState) => state.solutions.favoriteSolutions;

export const getViewedSolutions = (state: RootState) => state.solutions.viewedSolutions;

export const getSolutionsLoadingMessage = (state: RootState) => state.solutions.loadingMessage;

export const getSolution = (state: RootState) => state.solution.solutionDetails;

export const getError = (state: RootState) => state.solution.error;

export const getSolutionIsLoading = (state: RootState) => state.solution.isLoading;

export const getSortSolutionDirection = (state: RootState) => state.solutions.sortSolutionDirection;

export const getSolutionsPricesLimit = (state: RootState) => state.solutions.pricesLimit;

export const getSolutionsForDeletingFromViewed = (state: RootState) => state.solutions.solutionsForDeletingFromViewed;
export const getCountOfSolutionsForDeletingFromViewed = (state: RootState) =>
  state.solutions.solutionsForDeletingFromViewed.length;

export const getIsSelectModeSolutions = (state: RootState) => state.solutions.isSelectMode;

export const getIsDeletingSolutionsfromViewed = (state: RootState) => state.solutions.isDeletingFromViewed;

export const getDeleteFromViewedSuccessMessage = (state: RootState) => state.solutions.deleteFromHistorySuccess;

export const getDeleteFromViewedErrorMessage = (state: RootState) => state.solutions.deleteFromHistoryError;
