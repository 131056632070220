import {
  docEmailRequiredValidation,
  docMessageOrReviewOrDescriptionRequiredValidation,
  docNameAndSurnameRequiredValidation,
  docPhoneRequiredValidation,
} from 'shared';
import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: docNameAndSurnameRequiredValidation,
  email: docEmailRequiredValidation,
  phone: docPhoneRequiredValidation,
  message: docMessageOrReviewOrDescriptionRequiredValidation,
});
