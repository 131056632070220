import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export interface ISolutionImage {
  solutionId: string;
  fileId: string;
  description: string;
}

export interface IUpdateSolutionImage {
  description: string;
}

export const updateSolutionImage = (imageData: ISolutionImage): Promise<void> => {
  const { solutionId, fileId, description } = imageData;
  const UpdateImageData: IUpdateSolutionImage = {
    description,
  };
  return authAxiosInstance.patch(`${endpoints.solutions}/${solutionId}/${endpoints.photo}/${fileId}`, UpdateImageData);
};
