import { docSearchUsersPageValidation, docDateValidation } from 'shared';
import * as Yup from 'yup';

export const searchUsersPageScheme = Yup.object().shape({
  text: docSearchUsersPageValidation,
});

export const usersPageDatesScheme = Yup.object().shape({
  dateStart: docDateValidation,
  dateEnd: docDateValidation,
});
