import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { ErrorNotification } from 'sharedComponents';

import { useForm } from 'react-hook-form';
import {
  getUser,
  useAppSelector,
  IUserObjUpdate,
  useAppDispatch,
  setObjUpdate,
  setUpdateIsValid,
  getUpdateEmail,
  updateEmailSendOldEmailAction,
  clearUpdateEmailMessages,
  closeUpdateEmailModal,
} from 'store';
import { WarningIcon } from 'modules/Auth/assets';

import { ModalEmail } from '../ModalEmail/ModalEmail';
import { userDataScheme } from '../../data';
import styles from '../../styles/styles.module.scss';
import { ChangeNameType } from '../../types';
import { Input } from '../../UI';

export const ChangeNameForm = () => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, email, telegram, phone, objUpdate } = useAppSelector(getUser);
  const { errorMessage, showModalOldEmail } = useAppSelector(getUpdateEmail);

  const {
    register,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm<ChangeNameType>({
    mode: 'onChange',
    resolver: yupResolver(userDataScheme),
  });

  const formFirstName = watch('firstName');
  const formLastName = watch('lastName');
  const formPhone = watch('phone');
  const formTelegram = watch('telegram');

  useEffect(() => {
    const obj: IUserObjUpdate = {};
    if (formFirstName && formFirstName !== firstName) obj.firstName = formFirstName;
    if (formLastName && formLastName !== lastName) obj.lastName = formLastName;
    if (formPhone && formPhone !== phone) obj.phone = formPhone;
    if (formTelegram && formTelegram !== telegram) obj.telegram = formTelegram;
    dispatch(setObjUpdate(obj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFirstName, formLastName, formPhone, formTelegram]);

  useEffect(() => {
    const hasChanges = Object.values(objUpdate).some((value) => value !== null);
    dispatch(setUpdateIsValid(isValid && hasChanges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues()]);

  useEffect(() => {
    document.body.style.overflow = showModalOldEmail ? 'hidden' : 'auto';
  }, [showModalOldEmail]);

  const handleChangeEmail = () => {
    dispatch(updateEmailSendOldEmailAction());
  };

  const closeModal = () => {
    dispatch(closeUpdateEmailModal());
  };

  const clearMessages = () => {
    dispatch(clearUpdateEmailMessages());
  };

  return (
    <div>
      {showModalOldEmail && <ModalEmail textStart="Ссылка для изменения email" closeModal={closeModal} email={email} />}
      <form className={styles.form}>
        <div className={styles.inputGroupWrapper}>
          <Input
            register={register}
            id="firstName"
            label="Имя"
            error={errors.firstName?.message}
            defaultValue={firstName || ''}
            placeholder={firstName || ''}
          />
          <Input
            register={register}
            id="lastName"
            label="Фамилия"
            error={errors.lastName?.message}
            defaultValue={lastName || ''}
            placeholder={lastName || ''}
          />
          <div className={styles.wrap}>
            <div className={styles.text}>Номер телефона</div>
            <InputMask
              mask="+7 999 999 9999"
              maskChar="_"
              {...register('phone')}
              id="phone"
              className={styles.inputMask}
              defaultValue={phone || ''}
              placeholder={phone || '+ 7 ( _ _ ) _ _ _ _'}
            />
            {errors.phone?.message && (
              <span className={styles.warningText}>
                <WarningIcon /> {errors.phone?.message}
              </span>
            )}
          </div>
          <Input
            register={register}
            id="telegram"
            label="Telegram"
            error={errors.telegram?.message}
            defaultValue={telegram || ''}
            placeholder={telegram || '@username'}
          />
          <div className={styles.emailGroup}>
            <Input register={register} id="email" label="Email" defaultValue={email || ''} disabled />
            <div className={styles.span} onClick={handleChangeEmail}>
              Изменить
            </div>
          </div>
        </div>
      </form>
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
    </div>
  );
};
