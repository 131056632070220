import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

interface IChangeSolutionEvent {
  solutionId: string;
  eventId: string;
  body: {
    text: string;
  };
}

export const changeSolutionEvent = ({ solutionId, eventId, body }: IChangeSolutionEvent): Promise<void> =>
  authAxiosInstance.patch(`${endpoints.solutions}/${solutionId}/event/${eventId}`, body).then(({ data }) => data);
