import React, { useCallback, useEffect, useState } from 'react';

import {
  setSolutionEventsAction,
  useAppDispatch,
  useAppSelector,
  getSolutionEvents,
  getSolutionEventsIsLoading,
  getSolutionEventsErrorMessage,
  getSolutionEventsSuccessMessage,
  createSolutionEventAction,
  deleteSolutionEventAction,
  changeSolutionEventAction,
  clearEventsMessages,
} from 'store';

import { useParams } from 'react-router';

import { ErrorNotification, Loading, Modal, SuccessNotification } from 'sharedComponents';
import { useForm } from 'react-hook-form';
import { EventsOptions } from 'modules/Admin/types';

import { yupResolver } from '@hookform/resolvers/yup';

import { addEventSheme } from 'modules/Admin/data/createEventSheme';

import { SolutionEventLine, TextInput } from 'modules/Admin/components';

import cn from 'classnames';

import styles from './styles.module.scss';

import buttonStyle from '../../../../styles/button.style.module.scss';

export const SolutionEventsOptionPage = () => {
  const { id: solutionId } = useParams();
  const dispatch = useAppDispatch();
  const events = useAppSelector(getSolutionEvents);
  const isLoading = useAppSelector(getSolutionEventsIsLoading);
  const errorMessage = useAppSelector(getSolutionEventsErrorMessage);
  const successMessage = useAppSelector(getSolutionEventsSuccessMessage);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [eventIdDelete, setEventIdDelete] = useState<string>('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<EventsOptions>({
    mode: 'onChange',
    defaultValues: { event: '' },
    resolver: yupResolver(addEventSheme),
  });

  const saveButtonStyle = cn(buttonStyle.invertButton, styles.addEventButton);
  const isDisabledBtnSave = isLoading || !isValid || !isDirty;

  useEffect(() => {
    if (solutionId) {
      dispatch(setSolutionEventsAction(solutionId));
    }
  }, [dispatch, solutionId]);

  const clearMessages = useCallback(() => {
    dispatch(clearEventsMessages());
  }, [dispatch]);

  const onSubmit = (obj: EventsOptions) => {
    if (solutionId) {
      const text = obj.event;
      dispatch(createSolutionEventAction({ solutionId, text }));
      reset();
    }
  };

  const openModalDelete = (eventId: string) => {
    setEventIdDelete(eventId);
    setIsVisibleModal(true);
  };

  const handleClickDelete = () => {
    if (solutionId) {
      dispatch(deleteSolutionEventAction({ solutionId, eventId: eventIdDelete }));
      setIsVisibleModal(false);
    }
  };

  const handleClickChange = (eventId: string, text: string) => {
    if (solutionId) {
      const data = { text };
      dispatch(changeSolutionEventAction({ solutionId, eventId, data }));
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.inputContainer}>
          <TextInput
            name="event"
            id="solutionNewEvent"
            register={register}
            label="Новое мероприятие"
            error={errors?.event}
          />
        </div>
        <button className={saveButtonStyle} type="submit" disabled={isDisabledBtnSave}>
          Добавить мероприятие
        </button>
        {isVisibleModal && (
          <Modal setIsActiveModal={(prev) => setIsVisibleModal(prev)} handleClick={handleClickDelete}>
            <p>Удалить мероприятие?</p>
          </Modal>
        )}
        {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
        {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
      </form>
      <div className={styles.events}>
        {events.map((event, i) => (
          <SolutionEventLine
            number={i + 1}
            text={event.text}
            id={event.id}
            key={event.id}
            handleClickDelete={openModalDelete}
            handleClickChange={handleClickChange}
          />
        ))}
      </div>
    </>
  );
};
