import { Outlet } from 'react-router';
import { getUser, useAppSelector } from 'store';

import styles from './styles.module.scss';

import { AdminHeader, MenuNavAdmin } from '../../components';

export const AdminLayout = () => {
  const { firstName } = useAppSelector(getUser);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} ${styles.layout}`}>
        <AdminHeader />
        <MenuNavAdmin />
        <div className={styles.main}>
          <p className={styles.userRole}>{firstName || 'Admin'}</p>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
