import { logoIcon } from 'assets';
import { Link } from 'react-router-dom';
import { ROUTE } from 'router';

import styles from './styles.module.scss';

export const AdminHeader = () => (
  <header className={styles.header}>
    <Link to={ROUTE.HOME}>
      <img src={logoIcon} className={styles.logo} alt="Townsend logo" />
    </Link>
  </header>
);
