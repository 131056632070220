import styles from './styles.module.scss';

export interface SolutionEventItemProps {
  eventNumber: number;
  eventText: string;
}

const SolutionEventItem = ({ eventText, eventNumber }: SolutionEventItemProps): JSX.Element => (
  <li className={styles.eventWrapper}>
    <span className={styles.eventTextNumber}>{eventNumber}.</span>
    <span className={styles.eventText}>{eventText}</span>
  </li>
);

export { SolutionEventItem };
