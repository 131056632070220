import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { confirmEmail } from 'modules/Admin/api';

export const confirmEmailAction = createAsyncThunk<string, string, { rejectValue: string }>(
  'confirmEmail/confirmEmailAction',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await confirmEmail(token);
      return response.message;
    } catch (e) {
      const error = e as AxiosError;
      const { response } = error.request;
      const { message } = JSON.parse(response);
      return rejectWithValue(message);
    }
  },
);
