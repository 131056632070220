import { createAsyncThunk } from '@reduxjs/toolkit';

import { clearDeleteFromHistoryMessagesSolutions, clearSolutionsForDeletingFromViewed } from 'store/features';
import { deleteAllViewedSolutions, deleteSelectedViewedSolution } from 'modules/Admin/api';

import { setViewedSolutionsAction } from './setViewedSolutionsAction';

export const deleteAllSolutionsFromHistoryAction = createAsyncThunk<Promise<void>>(
  'adminSolutions/deleteAllSolutionsFromHistoryAction',
  async (_, { dispatch }) => {
    await deleteAllViewedSolutions();
    dispatch(setViewedSolutionsAction());
  },
);

export const deleteSelectedSolutionsFromHistoryAction = createAsyncThunk<
  PromiseSettledResult<void>[],
  string[],
  { rejectValue: string }
>(
  'adminSolutions/deleteSelectedSolutionsFromHistoryAction',
  async (selectedSolutions, { dispatch, rejectWithValue }) => {
    const requests = selectedSolutions.map((id) => deleteSelectedViewedSolution(id));
    const responses = await Promise.allSettled(requests);
    const errors = responses.filter((response) => response.status === 'rejected');
    dispatch(clearDeleteFromHistoryMessagesSolutions());

    if (errors.length > 0) {
      return rejectWithValue('Удаление не выполнено');
    }
    await dispatch(setViewedSolutionsAction());
    dispatch(clearSolutionsForDeletingFromViewed());
    return responses;
  },
);
