import { useState } from 'react';
import {
  clearSolutionsForDeletingFromViewed,
  getCountOfSolutionsForDeletingFromViewed,
  getIsDeletingSolutionsfromViewed,
  getIsSelectModeSolutions,
  getSolutionsForDeletingFromViewed,
  toggleSelectModeSolutions,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { Modal } from 'sharedComponents';
import { ProfileHistoryTabs } from 'modules/Profile/ProfileLayout/ProfileHistoryLayout/config';
import {
  deleteAllSolutionsFromHistoryAction,
  deleteSelectedSolutionsFromHistoryAction,
} from 'store/actions/deleteSolutionsFromHistoryAction';

interface CleanHistoryProps {
  target: ProfileHistoryTabs;
}

export const CleanHistorySolutions = ({ target }: CleanHistoryProps) => {
  const [isShowSelectButtons, setIsShowSelectButtons] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSelectMode = useAppSelector(getIsSelectModeSolutions);
  const selectedSolutions = useAppSelector(getSolutionsForDeletingFromViewed);
  const dispatch = useAppDispatch();
  const isDeleting = useAppSelector(getIsDeletingSolutionsfromViewed);

  const counterOfSelectedSolutions = useAppSelector(getCountOfSolutionsForDeletingFromViewed);
  const selectedCountSolutions = target === ProfileHistoryTabs.solutions ? counterOfSelectedSolutions : 0;
  const isDisabled = !selectedCountSolutions || isDeleting;

  const handleToggleSelectButtons = () => {
    setIsShowSelectButtons((prev) => !prev);
  };

  const handleToggleSelectMode = () => {
    if (target === ProfileHistoryTabs.solutions) {
      dispatch(toggleSelectModeSolutions());
    }
  };

  const handleCancel = () => {
    if (target === ProfileHistoryTabs.solutions) {
      dispatch(clearSolutionsForDeletingFromViewed());
    }
    handleToggleSelectButtons();
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleModalClick = () => {
    if (isSelectMode) {
      dispatch(deleteSelectedSolutionsFromHistoryAction(selectedSolutions));
    } else {
      dispatch(deleteAllSolutionsFromHistoryAction());
    }
    setIsModalOpen(false);
  };

  return (
    <>
      {!isShowSelectButtons && (
        <button type="button" className="buttonWithoutBorder" onClick={handleToggleSelectButtons}>
          Очистить историю
        </button>
      )}
      {isShowSelectButtons && !isSelectMode && (
        <div>
          <button type="button" className="button buttonSecondary" onClick={handleToggleSelectMode}>
            Выбрать
          </button>
          <button type="button" className="buttonWithoutBorder buttonRemove" onClick={handleOpenModal}>
            Удалить все
          </button>
        </div>
      )}
      {isSelectMode && (
        <div>
          <button type="button" className="button buttonSecondary" onClick={handleCancel}>
            Отменить
          </button>
          <button
            type="button"
            className="buttonWithoutBorder buttonRemove"
            disabled={isDisabled}
            onClick={handleOpenModal}
          >
            Удалить <span>({selectedCountSolutions})</span>
          </button>
        </div>
      )}
      {isModalOpen && (
        <Modal setIsActiveModal={setIsModalOpen} handleClick={handleModalClick}>
          <div>
            <p>Вы уверены, что хотите удалить решение(-я) из истории просмотра?</p>
            <p>Данное действие невозможно будет отменить</p>
          </div>
        </Modal>
      )}
    </>
  );
};
