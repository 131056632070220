import { createSlice } from '@reduxjs/toolkit';
import { setSolutionsFiltersAction } from 'store/actions';

import type { SolutionFilter } from '../types';

interface ISolutionsPageFilters {
  activeFilters: string[];
  solutionsFilters: SolutionFilter[];
  prices: {
    priceMin: string;
    priceMax: string;
  };
  isLoading: boolean;
  errorMessage: string;
}

const initialState: ISolutionsPageFilters = {
  activeFilters: [],
  solutionsFilters: [],
  prices: {
    priceMin: '',
    priceMax: '',
  },
  isLoading: false,
  errorMessage: '',
};

const solutionsPageFiltersSlice = createSlice({
  name: 'solutionsPageFilters',
  initialState,
  reducers: {
    changeSolutionsPageFilters: (state, { payload }) => {
      state.activeFilters = payload;
    },
    setSolutionsPagePrices: (state, { payload }) => {
      state.prices = payload;
    },
    clearSolutionsPageFiltersAndPrices: (state) => {
      state.activeFilters = [];
      state.prices = {
        priceMin: '',
        priceMax: '',
      };
    },
    clearSolutionsPageErrorMessage: (state) => {
      state.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSolutionsFiltersAction.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(setSolutionsFiltersAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.solutionsFilters = payload || [];
      })
      .addCase(setSolutionsFiltersAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка получения фильтров';
      });
  },
});

export const {
  reducer: solutionsPageFiltersReducer,
  actions: {
    changeSolutionsPageFilters,
    setSolutionsPagePrices,
    clearSolutionsPageFiltersAndPrices,
    clearSolutionsPageErrorMessage,
  },
} = solutionsPageFiltersSlice;
