import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFavoriteSolutions } from 'api/getFavoriteSolutions';
import { SolutionFavoriteCard } from 'store';
import { AxiosError } from 'axios';

export const setSolutionsFavouritesAction = createAsyncThunk<SolutionFavoriteCard[], void>(
  'solutions/setSolutionsFavourites',
  async (_, { rejectWithValue }) => {
    try {
      return await getFavoriteSolutions();
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
