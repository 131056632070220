import { setSolutionsLoadingMessage, useAppDispatch, useAppSelector } from 'store';

import { SolutionsList } from 'components';

import { setSolutionsFavouritesAction } from 'store/actions/setFavoriteSolutionsAction';

import { useEffect } from 'react';

import { ErrorNotification, Loading } from 'sharedComponents';

import styles from './styles.module.scss';

import {
  getFavoriteSolutions,
  getIsSolutionsLoaderVisible,
  getSolutionsLoadingMessage,
} from '../../../../store/selectors/solutionsSelector';

export const ProfileFavoriteSolutionsPage = () => {
  const dispatch = useAppDispatch();

  const favoritesSolutions = useAppSelector(getFavoriteSolutions);
  const favoritesSolutionsUpdate = favoritesSolutions
    ? favoritesSolutions.map((solution) => ({
        ...solution,
        isFavorites: true,
      }))
    : [];
  const isLoading = useAppSelector(getIsSolutionsLoaderVisible);
  const errorMessage = useAppSelector(getSolutionsLoadingMessage);

  useEffect(() => {
    dispatch(setSolutionsFavouritesAction());
  }, [dispatch]);

  const reloadCardList = () => {
    dispatch(setSolutionsFavouritesAction());
  };

  const clearErrorMessage = () => dispatch(setSolutionsLoadingMessage(''));

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      {favoritesSolutionsUpdate.length ? (
        <SolutionsList solutions={favoritesSolutionsUpdate} reloadCardList={reloadCardList} />
      ) : (
        <div className={styles.wrapper}>Пусто</div>
      )}
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearErrorMessage} />}
    </div>
  );
};
