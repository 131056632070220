import { yupResolver } from '@hookform/resolvers/yup';
import { addTaskSheme } from 'modules/Admin/data/createTaskSheme';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { TextInput } from 'modules/Admin/components';
import {
  cleanErrorMessage,
  cleanSuccessMessage,
  createSolutionTasksAction,
  getSolutionErrorMessage,
  getSolutionOptionIsLoading,
  getSolutionSuccessMessage,
  getSolutionTasks,
  setSolutionTasksAction,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { SolutionTaskLine } from 'modules/Admin/components/index';
import { useParams } from 'react-router';
import { useCallback, useEffect } from 'react';
import { ErrorNotification, SuccessNotification } from 'sharedComponents';

import styles from './styles.module.scss';

import buttonStyle from '../../../../styles/button.style.module.scss';

export const SettingSolutionTasksPage = () => {
  const { id: solutionId } = useParams();
  const dispatch = useAppDispatch();
  const items = useAppSelector(getSolutionTasks);
  const errorMessage = useAppSelector(getSolutionErrorMessage);
  const successMessage = useAppSelector(getSolutionSuccessMessage);
  const isLoading = useAppSelector(getSolutionOptionIsLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<{ newTask: string }>({
    mode: 'onChange',
    resolver: yupResolver(addTaskSheme),
    defaultValues: { newTask: '' },
  });

  const itemButtonStyle = cn(buttonStyle.invertButton, styles.sharedButtonStyle);

  const isDisabled = isLoading || !isValid || !isDirty;

  const onSubmit = async (item: { newTask: string }) => {
    if (solutionId) {
      const text = item.newTask;
      const data = { text };
      dispatch(createSolutionTasksAction({ solutionId, data }));
      reset();
    }
  };

  useEffect(() => {
    if (solutionId) {
      dispatch(setSolutionTasksAction(solutionId));
    }
  }, [solutionId, dispatch]);

  const clearMessages = useCallback(() => {
    dispatch(cleanErrorMessage());
    dispatch(cleanSuccessMessage());
  }, [dispatch]);

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.newItem}>
          <TextInput id="task" name="newTask" label="Новая задача" register={register} error={errors.newTask} />
        </div>
        <button className={itemButtonStyle} type="submit" disabled={isDisabled}>
          Добавить задачу
        </button>
        {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
        {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
      </form>
      <div className={styles.list}>
        {items.map(({ id, text }, index) => (
          <SolutionTaskLine key={id} index={index} text={text} id={id} solutionId={solutionId as string} />
        ))}
      </div>
    </>
  );
};
