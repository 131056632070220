import { Collapse } from 'sharedComponents/Collapse/Collapse';
import { CollapseArrowDown, CollapseArrowUp, cancelBtnIcon } from 'assets';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Characteristic, PlatformFilterItem } from 'store';
import { useParams } from 'react-router';

import { getActivePlatformFilters } from 'modules/Admin/api/getAllPosibleFilters';

import { FieldValues, FormProvider, SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';

import { updatePlatformFilters } from 'modules/Admin/api';

import cn from 'classnames';

import { FiltersIdStatus } from 'modules/Admin/types';

import { createActiveFilters } from 'modules/Admin/utils/helpers';

import { ErrorNotification, Loading, Modal, SuccessNotification } from 'sharedComponents';

import styles from './styles.module.scss';

import buttonsStyles from '../../../../styles/button.style.module.scss';
import { FilterGroup } from '../FIlterGroup/FilterGroup';

interface GetPlatformFiltersProps {
  platformId: string;
  setAllFilters: Dispatch<SetStateAction<Characteristic[]>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  methods: UseFormReturn<FieldValues>;
}

const createFilterFields = async (currentPlatformId: string): Promise<[Characteristic[], FiltersIdStatus]> => {
  const platformFilters = await getActivePlatformFilters(currentPlatformId);
  const defaultFiltersValue = createActiveFilters(platformFilters);
  return [platformFilters, defaultFiltersValue];
};

const getPlatformFilters = ({
  platformId,
  setAllFilters,
  setErrorMessage,
  setIsLoading,
  methods,
}: GetPlatformFiltersProps) => {
  setIsLoading(true);
  createFilterFields(platformId)
    .then((filters) => {
      setAllFilters(filters[0]);
      methods.reset(filters[1]);
    })
    .catch((e: Error) => {
      setErrorMessage(e.message);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const PlatformFiltersOptions = () => {
  const { platformId } = useParams();

  const [allFilters, setAllFilters] = useState<Characteristic[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [activeFiltersRequest, setActiveFiltersRequest] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const methods = useForm();

  useEffect(() => {
    if (platformId) {
      getPlatformFilters({ platformId, setAllFilters, setErrorMessage, setIsLoading, methods });
    }
  }, [platformId, methods]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const activeFilters = Object.entries(data)
      .filter((filter) => filter[1])
      .map((curFilter) => curFilter[0]);
    setActiveFiltersRequest(activeFilters);
    setIsVisibleModal(true);
  };

  const handleClickSend = () => {
    if (platformId) {
      updatePlatformFilters({ idFilterItems: activeFiltersRequest }, platformId)
        .then(() => {
          setSuccessMessage('Фильтры успешно сохранены');
          getPlatformFilters({ platformId, setAllFilters, setErrorMessage, setIsLoading, methods });
        })
        .catch((e: Error) => {
          setErrorMessage(e.message);
        })
        .finally(() => {
          setIsVisibleModal(false);
        });
    }
  };

  const clearMessages = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleReset = () => {
    methods.reset();
    Object.keys(methods.getValues()).forEach((key) => {
      methods.setValue(key, false, { shouldDirty: true });
    });
  };

  const onChangeRadio = (arr: PlatformFilterItem[], active?: string) => {
    arr.forEach((item) => {
      if (item.id !== active) methods.setValue(item.id, false, { shouldDirty: true });
      else methods.setValue(item.id, active, { shouldDirty: true });
    });
  };

  const { isDirty } = methods.formState;
  const submitBtnStyle = cn(buttonsStyles.invertButton, styles.submitBtn);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <p className={styles.title}>Отметьте фильтры, которые необходимо отобразить у платформы.</p>
      <FormProvider {...methods}>
        <form className={styles.filtersWrapper} onSubmit={methods.handleSubmit(onSubmit)}>
          {allFilters.map((filter) => (
            <Collapse
              title={filter.filterGroupName}
              openIcon={CollapseArrowDown}
              closeIcon={CollapseArrowUp}
              key={filter.id}
            >
              <FilterGroup
                filtersForPlatforms={filter.filtersForPlatforms}
                activeFilters={methods.getValues()}
                onChangeRadio={onChangeRadio}
              />
            </Collapse>
          ))}
          <div className={styles.controlButtons}>
            <button className={styles.resetFiltersBtn} type="button" onClick={handleReset}>
              Сбросить фильтры
              <img className={styles.cancelBtnIcon} src={cancelBtnIcon} alt={`Изображение ${cancelBtnIcon}`} />
            </button>
            <button className={submitBtnStyle} disabled={!isDirty} type="submit">
              Сохранить
            </button>
          </div>
        </form>
      </FormProvider>
      {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
      {isVisibleModal && (
        <Modal
          setIsActiveModal={(prev) => setIsVisibleModal(prev)}
          submitButtonText="Сохранить"
          handleClick={handleClickSend}
        >
          <p>Сохранить фильтры платформы?</p>
        </Modal>
      )}
    </>
  );
};
