import axios from 'axios';
import { LocalStorage } from 'shared';

import { endpoints } from './endpoints';

import { SendEmailRequestType } from '../types/sendEmailType';

export const sendEmail = (querryParams: SendEmailRequestType): Promise<void> => {
  const language = localStorage.getItem(LocalStorage.Language) || 'ru';
  const headers = { headers: { 'Content-Language': language } };
  return axios.post(endpoints.sendEmail, querryParams, headers);
};
