import { CrossIcon } from 'modules/Admin/assets';
import { deleteParametrOption, ParametrFilterItem, useAppDispatch } from 'store';

import styles from './styles.module.scss';

interface IOptionFilterItem {
  filterItemName: ParametrFilterItem['filterItemName'];
  id: ParametrFilterItem['id'];
  changeOptions: () => void;
}

export const FilterOptionItem = ({ filterItemName, id, changeOptions }: IOptionFilterItem) => {
  const dispatch = useAppDispatch();
  const handleDelete = () => {
    dispatch(deleteParametrOption(id));
    changeOptions();
  };

  return (
    <div className={styles.wrapper}>
      {filterItemName}
      <button type="button" onClick={handleDelete}>
        <CrossIcon />
      </button>
    </div>
  );
};
