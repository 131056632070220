import { close } from 'assets';
import cn from 'classnames';

import { TargetPortal } from 'shared';
import { Portal } from 'components/Portal/Portal';

import { useEffect, useState } from 'react';

import styles from './styles.module.scss';

interface ModalProps {
  message?: string;
  displayTime?: number;
  clearMessage?: () => void;
  className?: string;
}

export const ErrorNotification = ({
  message = 'Действие не может быть выполнено',
  displayTime = 5000,
  clearMessage,
  className,
}: ModalProps) => {
  const [isVisible, setIsVisible] = useState(true);

  const closeModal = () => setIsVisible(false);
  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      if (clearMessage) clearMessage();
      setIsVisible(false);
    }, displayTime);

    return () => {
      clearTimeout(timer);
    };
  }, [displayTime, clearMessage]);

  if (!isVisible) {
    return null;
  }

  return (
    <Portal target={TargetPortal.MODAL}>
      <div className={cn(styles.notification, className)}>
        <div className={styles.wrap}>
          <div className={styles.circle}>!</div>
          <div className={styles.wrapper}>
            <div className={styles.title}>Ошибка</div>
            <div className={styles.text}>{message}</div>
          </div>
        </div>

        <button type="button" onClick={closeModal}>
          <img src={close} alt="close" />
        </button>
      </div>
    </Portal>
  );
};
