import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISolutionImage, updateSolutionImage } from 'modules/Admin/api/updateSolutionImage';
import { setSolutionImageDataByIdAction } from 'store';

export const updateSolutionImageAction = createAsyncThunk<void, ISolutionImage>(
  'solutionImage/updateSolutionImageAction',
  async (imageData, { dispatch }) => {
    await updateSolutionImage(imageData);
    dispatch(setSolutionImageDataByIdAction(imageData.solutionId!));
  },
);
