import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export interface ICreatePlatformReview {
  platformId: string;
  date: string;
  text: string;
  author: string;
}

export interface IPatchPlatformReview {
  reviewId: string;
  body: {
    date: string;
    text: string;
    author: string;
  };
}

export const postPlatformReview = (body: ICreatePlatformReview): Promise<string> =>
  authAxiosInstance.post(endpoints.platformReview, body);

export const getPlatformReview = (platformId: string) =>
  authAxiosInstance
    .get(endpoints.platformReview, {
      params: {
        id: platformId,
      },
    })
    .then(({ data }) => data);

export const deletePlatformReview = (reviewId: string) =>
  authAxiosInstance.delete(`${endpoints.platformReview}/${reviewId}`);

export const patchPlatformReview = ({ reviewId, body }: IPatchPlatformReview) =>
  authAxiosInstance.patch(`${endpoints.platformReview}/${reviewId}`, body);
