import { SolutionTask } from 'store';

import { SolutionTaskItem } from './SolutionTaskListItem';

import styles from './styles.module.scss';

export interface SolutionTasksListProps {
  tasks: SolutionTask[];
}

const SolutionTasksList = ({ tasks }: SolutionTasksListProps) => {
  if (tasks.length === 0) return <div className={styles.emptyList}>Список задач пуст</div>;
  return (
    <ul className={styles.tasksList}>
      {tasks.map(({ text, id }) => (
        <SolutionTaskItem taskText={text} key={id} />
      ))}
    </ul>
  );
};

export { SolutionTasksList };
