import React from 'react';
import { close, emailIcon } from 'assets';
import { Portal } from 'components';
import { TargetPortal } from 'shared';

import styles from '../../styles/styles.module.scss';

interface IModalEmail {
  textStart: string;
  email: string | null;
  closeModal: () => void;
}

export const ModalEmail = ({ textStart, email, closeModal }: IModalEmail) => {
  const handleCloseModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    closeModal();
    document.body.style.overflow = 'auto';
  };

  return (
    <Portal target={TargetPortal.MODAL}>
      <div className={styles.modal} onClick={handleCloseModal}>
        <div className={styles.modalWrapper} onClick={(e) => e.stopPropagation()}>
          <div className={styles.message}>
            <button type="button" onClick={handleCloseModal}>
              <img className={styles.picture} src={close} alt="close" />
            </button>
            <div>
              <img className={styles.icon} src={emailIcon} alt="email" />
            </div>
            <p className={styles.subtitle}>
              {textStart} была отправлена на <span>{email || 'почту'}</span> и будет действовать 15 минут
            </p>
          </div>
        </div>
      </div>
    </Portal>
  );
};
