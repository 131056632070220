const formatPrice = (price: number): string => {
  const strPrice = String(price);

  const regex = /\B(?=(\d{3})+(?!\d))/g;

  const formattedIntegerPart = strPrice.replace(regex, ' ');

  return formattedIntegerPart;
};

export default formatPrice;
