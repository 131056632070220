import { docMessageOrReviewOrDescriptionRequiredValidation } from 'shared';
import * as Yup from 'yup';

const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'];
export const MAX_SOLUTION_FILE_SIZE_MB = 10;
export const MAX_SOLUTION_FILE_SIZE_BYTES = MAX_SOLUTION_FILE_SIZE_MB * 1024 * 1024;

export const createSolutionImageScheme = Yup.object().shape({
  description: docMessageOrReviewOrDescriptionRequiredValidation,
  files: Yup.mixed()
    .required('Обязательное для заполнения поле')
    .test('fileSize', function checkFileSize(value) {
      if (!value) return true;
      return value.size <= MAX_SOLUTION_FILE_SIZE_BYTES;
    })
    .test('fileFormat', function checkFile(value) {
      if (!value) return true;
      return supportedFormats.includes(value.type);
    }),
});
