import { AdminPlatformsFilter, clearNotifications, success, useAppDispatch, useAppSelector } from 'store';
import { BasketIcon, PencilIcon } from 'modules/Admin/assets';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import { ROUTE, TabsRoutes } from 'modules/Admin/router/routes';

import styles from './styles.module.scss';

interface FilterLineProps {
  filterGroupName: string;
  filter: AdminPlatformsFilter;
  setActiveId: Dispatch<SetStateAction<string>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const FilterLine = ({ filterGroupName, filter, setActiveId, setIsModalOpen }: FilterLineProps): JSX.Element => {
  const { filterName, id } = filter;
  const dispatch = useAppDispatch();
  const getIsSuccess = useAppSelector(success);

  const isShowNotification = () => {
    if (getIsSuccess) dispatch(clearNotifications());
  };

  return (
    <>
      <span className={styles.filterName}>{filterName}</span>
      <span className={styles.filterName}>{filterGroupName}</span>
      <Link
        onClick={isShowNotification}
        to={`${ROUTE.ADMIN}/${ROUTE.PLATFORMS}/${id}/${TabsRoutes.mainOptionsFilterLink}`}
      >
        <PencilIcon />
      </Link>
      <button
        type="button"
        onClick={() => {
          setActiveId(id);
          setIsModalOpen(true);
        }}
      >
        <BasketIcon />
      </button>
    </>
  );
};
