import { SolutionCardItem } from 'components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  clearDeleteFromHistoryMessagesSolutions,
  clearSolutionsForDeletingFromViewed,
  getDeleteFromViewedErrorMessage,
  getDeleteFromViewedSuccessMessage,
  getIsSelectModeSolutions,
  getIsSolutionsLoaderVisible,
  getSolutionsForDeletingFromViewed,
  getSolutionsLoadingMessage,
  getUser,
  getViewedSolutions,
  setViewedSolutionsAction,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { addSolutionInFavorites, deleteSolutionFromFavorites } from 'api';
import { isIncluded } from 'helpers/isInclude';

import { ErrorNotification, Loading, LoadingMessage, SuccessNotification } from 'sharedComponents';
import { errorHandler } from 'helpers';

import styles from '../ProfileHistoryPlatformsPage/styles.module.scss';

export const ProfileHistorySolutionsPage = () => {
  const { id: userId } = useAppSelector(getUser);
  const viewedSolutions = useAppSelector(getViewedSolutions);
  const dispatch = useAppDispatch();
  const isSelectMode = useAppSelector(getIsSelectModeSolutions);
  const selectedCards = useAppSelector(getSolutionsForDeletingFromViewed);
  const loadingMessage = useAppSelector(getSolutionsLoadingMessage);
  const isLoaderVisible = useAppSelector(getIsSolutionsLoaderVisible);
  const deletingSuccessMessage = useAppSelector(getDeleteFromViewedSuccessMessage);
  const deletingErorrMessage = useAppSelector(getDeleteFromViewedErrorMessage);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const reloadCardList = useCallback(() => {
    dispatch(setViewedSolutionsAction());
  }, [dispatch]);

  const clearMessages = useCallback(() => {
    dispatch(clearDeleteFromHistoryMessagesSolutions());
  }, [dispatch]);

  const toggleFavorite = useCallback(
    (isFavorite: boolean, cardId: string) => {
      try {
        if (userId) {
          if (isFavorite) {
            deleteSolutionFromFavorites(cardId).then(() => reloadCardList());
          } else {
            addSolutionInFavorites(cardId).then(() => reloadCardList());
          }
        }
      } catch (e: any) {
        const message = errorHandler(e, 'Ошибка загрузки истории просмотра платформ');
        setErrorMessage(message);
      }
    },
    [reloadCardList, userId],
  );

  const SolutionsList = useMemo(
    () => (
      <>
        {viewedSolutions.map((item) => {
          const { id: solutionId } = item;
          const { description, name, priceMin, id, platform, isFavorites } = item;
          const isSelected = isIncluded(selectedCards, solutionId);

          return (
            <SolutionCardItem
              key={solutionId}
              name={name}
              description={description}
              id={id}
              priceMin={priceMin}
              platformName={platform.name}
              isFavorite={isFavorites}
              platformLogoLink={platform.file?.url || ''}
              toggleFavorite={toggleFavorite}
              isSelected={isSelected}
              isSelectMode={isSelectMode}
            />
          );
        })}
      </>
    ),
    [viewedSolutions, selectedCards, isSelectMode, toggleFavorite],
  );

  useEffect(() => {
    reloadCardList();
    return () => {
      dispatch(clearSolutionsForDeletingFromViewed());
      clearMessages();
    };
  }, [reloadCardList, dispatch, clearMessages]);

  return (
    <div className={styles.wrapper}>
      {deletingSuccessMessage && <SuccessNotification message={deletingSuccessMessage} clearMessage={clearMessages} />}
      {deletingErorrMessage && <ErrorNotification message={deletingErorrMessage} clearMessage={clearMessages} />}
      {errorMessage && <ErrorNotification message={errorMessage} />}
      {isLoaderVisible && viewedSolutions.length === 0 && <Loading delay={300} />}
      {loadingMessage && <LoadingMessage message={loadingMessage} />}
      {SolutionsList}
    </div>
  );
};
