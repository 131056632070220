import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';
import { ChangePlatformsFilter } from './createPlatformsFilter';

export interface UpdateCurrentFilterPlatform extends Omit<ChangePlatformsFilter, 'filterGroupId'> {
  filterGroupId: string;
}

export const updateCurrentFilterPlatform = (newFilter: UpdateCurrentFilterPlatform, id: string): Promise<void> =>
  authAxiosInstance.put(`${endpoints.filtersForPlatforms}/${id}`, newFilter).then((data) => data.data);
