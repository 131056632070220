import { FieldError } from 'react-hook-form';
import { LegacyRef, forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { Warning } from 'sharedComponents';

import styles from './styles.module.scss';
import { setFile } from './helpers';

export interface IFileInputProps {
  id: string;
  label?: string;
  error?: FieldError;
  value?: File | null;
  onChange: (file: File | null) => void;
  imageLink: string;
  isSolutionImage?: boolean;
  isSolution?: boolean;
}

export const FileInput = forwardRef((props: IFileInputProps, ref: LegacyRef<HTMLInputElement> | undefined) => {
  const { id, label, error, value, onChange, imageLink, isSolutionImage, isSolution = false } = props;
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [fileTypeError, setFileTypeError] = useState<boolean>(false);
  const acceptFormats = isSolution ? '.jpeg, .jpg, .png, .gif, .webp' : '.jpeg, .jpg, .png';

  useEffect(() => {
    if (value) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(value);
    } else {
      setPreviewUrl(imageLink);
    }
  }, [value, imageLink]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) setFile(fileList, onChange, setFileTypeError, isSolution);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    if (fileList) setFile(fileList, onChange, setFileTypeError);
  };

  const customInputStyle = cn(
    styles.fileInputCustom,
    {
      [styles.inputError]: error || fileTypeError,
    },
    isSolutionImage && styles.solutionImage,
  );
  const solutionImageDesc = cn(styles.imageInputDesc, styles.solutionImageDesc);

  return (
    <div className={styles.fileInputWrapper} onDragOver={handleDragOver} onDrop={handleDrop}>
      <label htmlFor={id} className={styles.label}>
        {label && <span>{label}</span>}
        <input
          id={id}
          className={styles.fileInputDefault}
          type="file"
          accept={acceptFormats}
          onChange={handleFileChange}
          ref={ref}
        />
        <div className={customInputStyle}>
          {previewUrl && <img className={styles.previewImg} src={previewUrl} alt="Preview" />}
        </div>
      </label>
      {fileTypeError
        ? (isSolution && <Warning message="Пожалуйста, загрузите jpeg, gif, webp или png формат до 10 Мб." />) || (
            <Warning message="Пожалуйста, загрузите jpeg или png формат до 500 Kб." />
          )
        : (isSolution && (
            <div className={solutionImageDesc}>Поддерживаются файлы формата jpeg, gif, webp или png до 10 Мб</div>
          )) || <div className={styles.imageInputDesc}>Поддерживаются файлы формата jpeg или png до 500 Kб</div>}
    </div>
  );
});
