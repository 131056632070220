import { RootState } from 'store/types/types';

export const filtersSelector = (state: RootState) => state.platfromsPageFilters;

export const getPlatfromsPageAllFilters = (state: RootState) => state.platfromsPageFilters.allFilters;

export const getPlatfromsPageTopPriorityFilters = (state: RootState) => state.platfromsPageFilters.topPriorityFilters;

export const getPlatfromsPageActiveFilters = (state: RootState) => state.platfromsPageFilters.activeFilters;

export const getPlatfromsPagePrices = (state: RootState) => state.platfromsPageFilters.prices;
