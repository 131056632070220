import axios from 'axios';
import { LocalStorage } from 'shared';

import { endpoints } from './endpoints';

export const refreshToken = async (accessToken: string): Promise<void> => {
  try {
    const language = localStorage.getItem(LocalStorage.Language) || 'ru';
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Language': language };
    const response = await axios.get(endpoints.refreshToken, { withCredentials: true, headers });
    localStorage.setItem(LocalStorage.AccessToken, response.data.value);
  } catch (e: any) {
    const { message } = e.response.data;
    // запись ошибки в localStorage для дальнейшего вызова logoutAction()
    localStorage.setItem(LocalStorage.LogoutMessage, message);
    window.dispatchEvent(new Event('storage'));
    throw new Error(message);
  }
};

export const refreshTokenWithoutError = async (accessToken: string): Promise<void> => {
  try {
    const language = localStorage.getItem(LocalStorage.Language) || 'ru';
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Language': language };
    const response = await axios.get(endpoints.refreshToken, { withCredentials: true, headers });
    localStorage.setItem(LocalStorage.AccessToken, response.data.value);
  } catch (e: any) {
    throw new Error(e.response.data.message);
  }
};
