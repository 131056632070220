import * as Yup from 'yup';

import {
  DOC_DATE_PATTERN,
  DOC_EMAIL_PATTERN,
  DOC_FULL_DATE_PATTERN,
  DOC_MESSAGE_OR_REVIEW_OR_DESCRIPTION_PATTERN,
  DOC_NAME_AND_SURNAME_PATTERN,
  DOC_NAME_OR_PARAMETR_OR_CATEGORY_OF_FILTER,
  DOC_NAME_OR_SEARCH_PLATFORM_OR_SOLUTION_PATTERN,
  DOC_NAME_OR_SURNAME_PATTERN,
  DOC_NAME_TASK_OR_EVENT_PATTERN,
  DOC_PHONE_EMPTY_PATTERN,
  DOC_PHONE_PATTERN,
  DOC_SEARCH_USERS_PAGE_PATTERN,
  DOC_TELEGRAM_PATTERN,
  DOC_URL_PATTERN,
} from './documentRegularExpressions';

// поле, обязательное для заполнение (обязательное)
export const docFieldRequiredValidation = Yup.string().required('Обязательное для заполнения поле');

// поле object, обязательное для заполнение (обязательное)
export const docFieldObjectRequiredValidation = Yup.object().nullable().required('Обязательное для заполнения поле');

// имя / фамилия (обязательное)
export const docNameOrSurnameRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_NAME_OR_SURNAME_PATTERN, 'Некорректное имя')
  .min(2, 'Число символов должно быть не менее 2')
  .max(25, 'Число символов должно быть не более 25');

// имя / фамилия (необязательное)
export const docNameOrSurnameValidation = Yup.string()
  .matches(DOC_NAME_OR_SURNAME_PATTERN, 'Некорректное имя')
  .min(2, 'Число символов должно быть не менее 2')
  .max(25, 'Число символов должно быть не более 25');

// имя / имя и фамилия (обязательное)
export const docNameAndSurnameRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_NAME_AND_SURNAME_PATTERN, 'Некорректная запись')
  .min(2, 'Число символов должно быть не менее 2')
  .max(50, 'Число символов должно быть не более 50');

// почта (обязательное)
export const docEmailRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_EMAIL_PATTERN, 'Некорректный email')
  .matches(/^(?!\.)(?!.*\.\.)/, 'Некорректный email'); // Строка не начинается с точки и не содержит две точки подряд

// почта (необязательное)
export const docEmailValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_EMAIL_PATTERN, 'Некорректный email')
  .matches(/^(?!\.)(?!.*\.\.)/, 'Некорректный email'); // Строка не начинается с точки и не содержит две точки подряд

// ссылка (обязательное)
export const docURLRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_URL_PATTERN, 'Некорректная ссылка');

// ссылка (необязательное)
export const docURLValidation = Yup.string().matches(DOC_URL_PATTERN, 'Некорректная ссылка');

// номер телефона (обязательное)
export const docPhoneRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_PHONE_PATTERN, 'Некорректный номер телефона');

// номер телефона (необязательное)
export const docPhoneValidation = Yup.string().matches(DOC_PHONE_EMPTY_PATTERN, 'Некорректный номер телефона');

// telegram (необязательное)
export const docTelegramValidation = Yup.string().matches(DOC_TELEGRAM_PATTERN, 'Некорректный telegram');

// пароль (обязательное)
export const docPasswordRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(/^\S*$/, 'Пароль не может содержать символы пробела')
  .matches(/^[\x00-\x7F]*$/, 'Пароль не может содержать буквы кириллицы')
  .min(5, 'Число символов должно быть не менее 5')
  .matches(/[A-Z]/, 'Пароль должен содержать заглавную букву')
  .matches(/[a-z]/, 'Пароль должен содержать прописную букву')
  .matches(/\d/, 'Пароль должен содержать цифру')
  .matches(/[^a-zA-Z\d]/, 'Пароль должен содержать специальный символ');

// стоимость (обязательное)
export const docPriceRequiredValidation = Yup.number().positive('Число должно быть положительным');

// дата (необязательное)
export const docDateValidation = Yup.string().matches(DOC_DATE_PATTERN, 'Ошибка');

// дата со временем (необязательное)
export const docFullDateValidation = Yup.string().matches(DOC_FULL_DATE_PATTERN, 'Ошибка');

// дата (обязательное)
export const docDateRequiredValidation = Yup.date().required('Дата обязательна для заполнения');

// название платформы или решения (обязательное)
export const docNamePlatformOrSolutionRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_NAME_OR_SEARCH_PLATFORM_OR_SOLUTION_PATTERN, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(50, 'Число символов должно быть не более 50');

// поиск по платформам или решениям (необязательное)
export const docSearchPlatformOrSolutionValidation = Yup.string()
  .matches(DOC_NAME_OR_SEARCH_PLATFORM_OR_SOLUTION_PATTERN, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(50, 'Число символов должно быть не более 50');

// сообщение, отзыв, описание (обязательное)
export const docMessageOrReviewOrDescriptionRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_MESSAGE_OR_REVIEW_OR_DESCRIPTION_PATTERN, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(255, 'Число символов должно быть не более 255');

// сообщение, отзыв, описание (необязательное)
export const docMessageOrReviewOrDescriptionValidation = Yup.string()
  .matches(DOC_MESSAGE_OR_REVIEW_OR_DESCRIPTION_PATTERN, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(255, 'Число символов должно быть не более 255');

// название задачи или мероприятия (обязательное)
export const docNameTaskOrEventRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_NAME_TASK_OR_EVENT_PATTERN, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(255, 'Число символов должно быть не более 255');

// название задачи или мероприятия (необязательное)
export const docNameTaskOrEventValidation = Yup.string()
  .matches(DOC_NAME_TASK_OR_EVENT_PATTERN, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(255, 'Число символов должно быть не более 255');

// поиск на странице пользователей (необязательное)
export const docSearchUsersPageValidation = Yup.string()
  .matches(DOC_SEARCH_USERS_PAGE_PATTERN, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(25, 'Число символов должно быть не более 25');

// название, параметр или категория фильтра (обязательное)
export const docNameOrParametrOrCategoryOfFilterRequiredValidation = Yup.string()
  .required('Обязательное для заполнения поле')
  .matches(DOC_NAME_OR_PARAMETR_OR_CATEGORY_OF_FILTER, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(50, 'Число символов должно быть не более 50');

// название, параметр или категория фильтра (необязательное)
export const docNameOrParametrOrCategoryOfFilterValidation = Yup.string()
  .matches(DOC_NAME_OR_PARAMETR_OR_CATEGORY_OF_FILTER, 'Введен недопустимый символ')
  .matches(/^(?:[^ ]|$)/, 'Первым символом не может быть пробел')
  .max(50, 'Число символов должно быть не более 50');

// файл в формате JPEG или PNG (необязательное)
export const docFileValidation = Yup.mixed().test(
  'file',
  'Неверный формат файла. Пожалуйста загрузите JPEG или PNG формат.',
  function checkFile(value) {
    if (!value) return true;
    const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    return supportedFormats.includes(value.type);
  },
);
