import { Checkbox, Radio } from 'components';
import { FilterItem } from 'store';

import { INPUTTYPE } from 'mocks';

interface CheckItemProps {
  filter: FilterItem;
  index: number;
  type: 'checkbox' | 'radio';
  filterName: string;
  activeFilters: string[];
  hasActiveRadio: boolean;
  onClickAny: (event: any) => void;
}

export const CheckItem = ({
  filter,
  type,
  filterName,
  index,
  activeFilters,
  hasActiveRadio,
  onClickAny,
}: CheckItemProps) => {
  const isChecked = activeFilters.includes(filter.id);

  return (
    <>
      {type === INPUTTYPE.CHECKBOX && (
        <Checkbox isChecked={isChecked} text={filter.filterItemName} id={filter.id} name={filterName} />
      )}
      {type === INPUTTYPE.RADIO && (
        <>
          {index === 0 && (
            <Radio
              text="Любое"
              value=""
              id={`default_${filterName}`}
              checked={!hasActiveRadio}
              name={filterName}
              onClick={onClickAny}
            />
          )}
          <Radio checked={isChecked} text={filter.filterItemName} value={filter.id} id={filter.id} name={filterName} />
        </>
      )}
    </>
  );
};
