import { authAxiosInstance } from 'modules/Auth/api';
import { SolutionVideoType, UpdateSolutionVideoType } from 'store/types/solutionVideo';

import { endpoints } from './endpoints';

export const createSolutionVideo = (videoData: SolutionVideoType): Promise<void> => {
  const { id, url, description } = videoData;
  const newVideoData: UpdateSolutionVideoType = {
    url,
    description,
  };
  return authAxiosInstance
    .post(`${endpoints.solutions}/${id}/${endpoints.video}`, newVideoData)
    .then((data) => data.data);
};
