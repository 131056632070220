import { createAsyncThunk } from '@reduxjs/toolkit';
import { blockUser } from 'modules/Admin/api';
import { RootState, setUsersAction } from 'store';

export const blockUserAction = createAsyncThunk<void, string, { state: RootState }>(
  'usersPage/blockUserAction',
  async (id: string, { dispatch }) => {
    await blockUser(id);
    dispatch(setUsersAction());
  },
);
