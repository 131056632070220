import { CleanHistoryPlatforms, CleanHistorySolutions } from 'modules/Profile/components';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getViewedPlatforms, getViewedSolutions, useAppSelector } from 'store';
import { Tabs } from 'modules/Admin/components';

import styles from './styles.module.scss';
import { ProfileHistoryTabs, profileHistoryTabsList } from './config';

export const ProfileHistoryLayout = () => {
  const location = useLocation();
  const [target, setTarget] = useState<number>();
  const viewedPlatforms = useAppSelector(getViewedPlatforms);
  const viewedSolutions = useAppSelector(getViewedSolutions);

  useEffect(() => {
    const path = location.pathname;
    const page = path.substring(path.lastIndexOf('/') + 1);
    if (page === 'platforms') {
      setTarget(1);
    } else {
      setTarget(2);
    }
  }, [location.pathname]);

  const activeTabName = profileHistoryTabsList.find(({ id }) => id === target)?.tabName as ProfileHistoryTabs;

  const handleTabClick = (selectedId: number) => {
    setTarget(selectedId);
  };
  const isShowPlatforms = activeTabName === ProfileHistoryTabs.platforms ? !!viewedPlatforms.length : false;
  const isShowSolutions = activeTabName === ProfileHistoryTabs.solutions ? !!viewedSolutions.length : false;

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <nav className={styles.tabsWrapper}>
          <Tabs tabs={profileHistoryTabsList} setActiveTab={handleTabClick} />
        </nav>
        {activeTabName === ProfileHistoryTabs.platforms && isShowPlatforms && (
          <CleanHistoryPlatforms target={activeTabName} />
        )}
        {activeTabName === ProfileHistoryTabs.solutions && isShowSolutions && (
          <CleanHistorySolutions target={activeTabName} />
        )}
      </div>
      <Outlet />
    </div>
  );
};
