import React, { FC } from 'react';
import {
  getUsersPageFiltration,
  setFiltrationOrderBy,
  clearFiltrationOrderBy,
  setUsersAction,
  useAppDispatch,
  useAppSelector,
  setActiveFilter,
  getUsersPageActiveFilter,
  clearFilterOptionsChecked,
  setPageNumber,
  clearArrayUsersChecked,
} from 'store';

import cn from 'classnames';

import { SortArrowDownIcon, SortArrowUpIcon, SortArrowsIcon } from 'modules/Admin/assets';

import { changeSortType } from 'modules/Admin/utils/helpers';

import styles from './styles.module.scss';
import { columnRuEng } from './config';

interface IUsersPageTableHeaderColumn {
  title: string;
  customClass: string;
}

export const UsersPageTableHeaderColumn: FC<IUsersPageTableHeaderColumn> = ({ title, customClass }) => {
  const dispatch = useAppDispatch();
  const { orderByItems } = useAppSelector(getUsersPageFiltration);
  const activeFilter = useAppSelector(getUsersPageActiveFilter);
  const titleEng = columnRuEng[title];

  const showSortIcon = () => {
    if (orderByItems.length && titleEng === orderByItems[0].column) {
      switch (orderByItems[0].orderBy) {
        case 'ASC':
          <SortArrowUpIcon />;
          return <SortArrowUpIcon />;
        case 'DESC':
          return <SortArrowDownIcon />;
        default:
          return <SortArrowsIcon />;
      }
    }
    return <SortArrowsIcon />;
  };

  const handleClickSort = () => {
    const sortType = orderByItems.length ? changeSortType(titleEng, orderByItems[0]) : 'ASC';
    const obj = {
      column: titleEng,
      orderBy: sortType,
    };
    // обновляем или очищаем сортировку в Filtration
    const func = sortType === '' ? clearFiltrationOrderBy() : setFiltrationOrderBy(obj);
    dispatch(func);

    // закрыть фильтры перед отправкой запроса
    dispatch(clearFilterOptionsChecked(activeFilter));
    dispatch(setActiveFilter(''));
    dispatch(setPageNumber(1));
    dispatch(clearArrayUsersChecked());
    dispatch(setUsersAction());
  };

  const tableHeaderColumnStyle = cn(styles.usersPageTableHeaderColumn, styles[customClass]);

  return (
    <div className={tableHeaderColumnStyle} onClick={handleClickSort}>
      <p className={styles.title}>{title}</p>
      {showSortIcon()}
    </div>
  );
};
