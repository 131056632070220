import { SolutionEvent } from 'store';

import { SolutionEventItem } from './SolutionEventItem';

import styles from './styles.module.scss';

export interface SolutionTasksListProps {
  events: SolutionEvent[];
}

const SolutionEventsList = ({ events }: SolutionTasksListProps) => {
  if (events.length === 0) return <div className={styles.emptyList}>Список мероприятий пуст</div>;
  return (
    <ol className={styles.eventsList}>
      {events.map(({ text, id }, idx) => (
        <SolutionEventItem eventText={text} key={id} eventNumber={idx + 1} />
      ))}
    </ol>
  );
};

export { SolutionEventsList };
