import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeUserRole } from 'modules/Admin/api';
import { IChangeUserRole, RootState, setUsersAction } from 'store';

export const changeUserRoleAction = createAsyncThunk<void, IChangeUserRole, { state: RootState }>(
  'usersPage/changeUserRoleAction',
  async (obj, { dispatch }) => {
    await changeUserRole(obj);
    dispatch(setUsersAction());
  },
);
