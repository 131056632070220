import axios, { AxiosResponse } from 'axios';
import { LocalStorage } from 'shared';

import { endpoints } from './endpoints';

export interface UserRegistrationType {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export const userRegistration = async (querryParams: UserRegistrationType): Promise<AxiosResponse> => {
  const params = {
    email: querryParams.email.trim(),
    firstName: querryParams.firstName.trim(),
    lastName: querryParams.lastName.trim(),
    password: querryParams.password.trim(),
  };
  const language = localStorage.getItem(LocalStorage.Language) || 'ru';
  const headers = { headers: { 'Content-Language': language } };
  return axios.post(endpoints.signUp, params, headers);
};
