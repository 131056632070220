import {
  deleteCategoriesForPlatformsAction,
  getActiveCategoryId,
  getCategoriesPlatformIsDeleteModalOpen,
  getPlatformsFilterCategoriesSelector,
  getSolutionsFiltersMessageSelector,
  isShowSolutionsFiltersLoaderSelector,
  setActiveCategory,
  setAdminPlatformsCategoriesFiltersAction,
  setIsDeleteCategoryModalOpen,
  setEditingCategoryMode,
  useAppDispatch,
  useAppSelector,
  clearActiveCategory,
  clearErrorMessageCategoryPlatform,
  clearSuccessMessageCategoryPlatform,
  getPlatformCategorySuccessMessage,
  getPlatformCategoryErrorMessage,
} from 'store';

import { Checkbox, ErrorNotification, Loading, LoadingMessage, Modal, SuccessNotification } from 'sharedComponents';

import { EditingModes } from 'shared/config/modes';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import { AdminCategories } from '../AdminCategories/AdminCategories';

export const AdminCategoriesList = () => {
  const activeId = useAppSelector(getActiveCategoryId);
  const loadingMessage = useAppSelector(getSolutionsFiltersMessageSelector);
  const isLoaderVisible = useAppSelector(isShowSolutionsFiltersLoaderSelector);
  const dispatch = useAppDispatch();
  const filterCategories = useAppSelector(getPlatformsFilterCategoriesSelector);
  const isCategoriesPlatformIsDeleteModalOpen = useAppSelector(getCategoriesPlatformIsDeleteModalOpen);
  const [isCategoryDeleted, setIsCategoryDeleted] = useState(false);
  const [isDeleteAllFilters, setIsDeleteAllFilters] = useState(false);
  const successMessage = useAppSelector(getPlatformCategorySuccessMessage);
  const errorMessage = useAppSelector(getPlatformCategoryErrorMessage);

  const setActiveId = useCallback((id: string) => dispatch(setActiveCategory(id)), [dispatch]);

  const handleDeleteModalOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setIsDeleteCategoryModalOpen(isOpen));
      dispatch(clearActiveCategory());
    },
    [dispatch],
  );

  const clearMessages = useCallback(() => {
    dispatch(clearErrorMessageCategoryPlatform());
    dispatch(clearSuccessMessageCategoryPlatform());
  }, [dispatch]);

  const hangleSetEdit = useCallback(
    (mode: EditingModes | null) => {
      dispatch(setEditingCategoryMode(mode));
    },
    [dispatch],
  );

  const handleEditClick = useCallback(
    (id: string) => {
      window.scrollTo(0, 0);
      clearMessages();
      setActiveId(id);
      hangleSetEdit(EditingModes.EDIT);
    },
    [hangleSetEdit, setActiveId, clearMessages],
  );

  const handleDeleteClick = useCallback(
    (id: string) => {
      setActiveId(id);
      dispatch(setEditingCategoryMode(null));
      dispatch(setIsDeleteCategoryModalOpen(true));
    },
    [dispatch, setActiveId],
  );

  const handleCategoryDelete = async () => {
    if (activeId) {
      try {
        const objFilter = {
          id: activeId,
          deleteFilters: isDeleteAllFilters,
        };
        await dispatch(deleteCategoriesForPlatformsAction(objFilter));
        setIsCategoryDeleted(true);
      } catch (error) {
        setIsCategoryDeleted(false);
      }
      dispatch(clearActiveCategory());
      dispatch(setIsDeleteCategoryModalOpen(false));
    }
  };

  useEffect(() => {
    dispatch(setAdminPlatformsCategoriesFiltersAction());
    clearMessages();
  }, [dispatch, clearMessages]);

  const categories = useMemo(
    () =>
      filterCategories.map((filterGroupName) => (
        <AdminCategories
          key={filterGroupName.id}
          category={filterGroupName}
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleEditClick}
        />
      )),
    [filterCategories, handleDeleteClick, handleEditClick],
  );

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.title}>Наименование</p>
        {categories}
      </div>

      {isLoaderVisible && <Loading delay={300} />}
      {loadingMessage && <LoadingMessage message={loadingMessage} />}
      {isCategoryDeleted && successMessage && (
        <SuccessNotification message={successMessage} clearMessage={clearMessages} />
      )}
      {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
      {isCategoriesPlatformIsDeleteModalOpen && (
        <Modal
          setIsActiveModal={handleDeleteModalOpen}
          handleClick={handleCategoryDelete}
          submitButtonText="Да, удалить"
        >
          <div className={styles.deleteModal}>
            <div className={styles.deleteModalTitle}> Вы действительно хотите удалить Категорию фильтров?</div>
            <p className={styles.deleteModalSubtitle}>
              При удалении Категории, все ее фильтры будут перемещены в категорию &quot;Иные параметры&quot;.
              <span> Для удаления Категории вместе с фильтрами, нажмите на поле ниже:</span>
            </p>
            <div className={styles.deleteModalCheckbox}>
              <Checkbox
                id="deleteFilters"
                text="Удалить фильтры данной Категории"
                defaultChecked={isDeleteAllFilters}
                onChange={() => setIsDeleteAllFilters(!isDeleteAllFilters)}
              />
            </div>
          </div>
        </Modal>
      )}
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
    </>
  );
};
