import React from 'react';

import styles from './styles.module.scss';

export interface IPopUp {
  name: string;
  text: string;
}

export const PopUpDescription = ({ name, text }: IPopUp) => (
  <div className={styles.popUp}>
    <h6 className={styles.filterName}> {name}</h6>
    <p className={styles.popUpText}>{text}</p>
  </div>
);

export default PopUpDescription;
