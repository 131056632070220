import { BasketIcon, PencilIcon } from 'modules/Admin/assets';
import cn from 'classnames';
import { Modal } from 'sharedComponents';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { changeTaskSheme } from 'modules/Admin/data/createTaskSheme';
import { changeSolutionTasksAction, deleteSolutionTasksAction, useAppDispatch } from 'store';

import styles from '../../pages/SettingSolutionTasksPage/styles.module.scss';

import { TextInput } from '../TextInput/TextInput';

import buttonStyle from '../../../../styles/button.style.module.scss';

interface TaskLineProps {
  text: string;
  id: string;
  index: number;
  solutionId: string;
}
export const SolutionTaskLine: FC<TaskLineProps> = ({ index, text, id, solutionId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const dispatch = useAppDispatch();

  const {
    setValue,
    trigger,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: { task: text },
    resolver: yupResolver(changeTaskSheme),
  });

  const isDisabled = !isValid || !isDirty;
  const toggleEditing = () => {
    setIsEditOpen(!isEditOpen);
    setValue('task', text);
    trigger('task');
  };

  const handleClickPencil = () => {
    setIsEditOpen(!isEditOpen);
    reset({ task: text });
  };

  const handleClickBasketIcon = () => {
    setIsModalOpen(true);
  };

  const handleClickDeleteItem = () => {
    dispatch(deleteSolutionTasksAction({ solutionId: String(solutionId), taskId: String(id), data: [{ text: '' }] }));
  };

  const onSubmit = (item: { task: string }) => {
    const taskId = id;
    const taskText = item.task;
    const data = { text: taskText };
    dispatch(changeSolutionTasksAction({ solutionId, taskId, data }));
    setIsEditOpen(!isEditOpen);
  };

  const itemButtonStyle = cn(buttonStyle.invertButton, styles.sharedButtonStyle);
  return (
    <form className={styles.item} key={id} id={id} onSubmit={handleSubmit(onSubmit)}>
      <p className={styles.text}>Задача {index + 1}</p>
      <div className={styles.line}>
        <div className={styles.field}>
          <TextInput
            id={id}
            name="task"
            register={register}
            isDisabled={!isEditOpen}
            error={errors.task}
            defaultValue={text}
          />
        </div>
        <div className={styles.iconsGroup}>
          <PencilIcon onClick={handleClickPencil} />
          <button type="button" onClick={handleClickBasketIcon}>
            <BasketIcon />
          </button>
          {isModalOpen && (
            <Modal handleClick={() => handleClickDeleteItem()} setIsActiveModal={setIsModalOpen}>
              <p>Вы уверены, что хотите удалить задачу?</p>
            </Modal>
          )}
        </div>
      </div>
      {isEditOpen && (
        <div className={styles.buttonsGroup}>
          <button type="button" className="button buttonWithoutBorder" onClick={toggleEditing}>
            Отмена
          </button>
          <button className={itemButtonStyle} type="submit" disabled={isDisabled}>
            Сохранить изменения
          </button>
        </div>
      )}
    </form>
  );
};
