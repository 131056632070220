import { createSlice } from '@reduxjs/toolkit';
import { setRolesAction } from 'store/actions';
import { RolesState } from 'store/types/roles';

const initialState: RolesState = {
  roles: [],
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};

const setLoading = (state: RolesState) => {
  state.isLoading = true;
  state.errorMessage = '';
  state.successMessage = '';
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setRolesAction.pending, setLoading)
      .addCase(setRolesAction.fulfilled, (state, { payload }) => {
        if (payload) {
          state.roles = payload;
        }
        state.isLoading = false;
      })
      .addCase(setRolesAction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload || 'Ошибка при получении ролей';
      });
  },
});

export const { reducer: rolesReducer } = rolesSlice;
