import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export interface ICreateSolutionTask {
  solutionId: string;
  body: {
    text: string;
  };
}
interface IChangeSolutionTask {
  solutionId: string;
  taskId: string;
  body: {
    text: string;
  };
}

export const postSolutionTask = ({ solutionId, body }: ICreateSolutionTask): Promise<string> =>
  authAxiosInstance.post(`${endpoints.solutions}/${solutionId}/${endpoints.task}`, body);

export const getSolutionTask = (solutionId: string) =>
  authAxiosInstance.get(`${endpoints.solutions}/${solutionId}/${endpoints.task}`).then(({ data }) => data);

export const deleteSolutionTask = (solutionId: string, taskId: string): Promise<void> =>
  authAxiosInstance.delete(`${endpoints.solutions}/${solutionId}/${endpoints.task}/${taskId}`);

export const patchSolutionTask = ({ solutionId, taskId, body }: IChangeSolutionTask): Promise<void> =>
  authAxiosInstance.patch(`${endpoints.solutions}/${solutionId}/${endpoints.task}/${taskId}`, body);
