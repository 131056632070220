import axios, { AxiosError } from 'axios';

import { baseURL, LocalStorage } from 'shared';

import { refreshToken } from './refreshToken';

export const authAxiosInstance = axios.create({ baseURL });

authAxiosInstance.interceptors.request.use((requestConfigArgs) => {
  const requestConfig = requestConfigArgs;
  const accessToken = localStorage.getItem(LocalStorage.AccessToken);
  const language = localStorage.getItem(LocalStorage.Language) || 'ru';
  requestConfig.headers = requestConfig.headers || {};

  if (accessToken) {
    requestConfig.headers.Authorization = `Bearer ${accessToken}`;
  }
  requestConfig.headers['Content-Language'] = language;
  return requestConfig;
});

const ignoreError = ['jwt expired', 'Неверное имя пользователя или пароль'];
interface IData {
  message: string;
}

authAxiosInstance.interceptors.response.use(undefined, async (error: AxiosError) => {
  if (error.response) {
    const { status, data, config } = error.response;
    const accessToken = localStorage.getItem(LocalStorage.AccessToken);
    if (status === 401 && (data as IData).message === 'jwt expired' && accessToken) {
      try {
        await refreshToken(accessToken);
        return await authAxiosInstance(config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    if (status === 401 && !ignoreError.includes((data as IData).message)) {
      // запись ошибки в localStorage для дальнейшего вызова logoutAction()
      localStorage.setItem(LocalStorage.LogoutMessage, 'Ошибка обновления accessToken. Произошёл выход из аккаунта');
      window.dispatchEvent(new Event('storage'));
    }
    return Promise.reject(error.response);
  }
  if (error.request) {
    return Promise.reject(error.request);
  }
  return Promise.reject(error);
});
