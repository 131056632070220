import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export interface IPlatformsFiltersCategory {
  filterGroupName: string;
}

export const createPlatformsFiltersCategory = (category: IPlatformsFiltersCategory): Promise<void> =>
  authAxiosInstance.post(endpoints.categoriesFiltersForPlatforms, category);
