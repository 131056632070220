import {
  AppDispatch,
  SolutionFilter,
  changeActiveFilters,
  changeSolutionsPageFilters,
  changePlatformsPageFilters,
  IFilters,
} from 'store';

interface IUpdateActiveFiltersCheckbox {
  activeFilters: string[];
  filterId: string;
  page: string;
  dispatch: AppDispatch;
}

interface IUpdateActiveFiltersRadio {
  objFilter: SolutionFilter | IFilters;
  activeFilters: string[];
  filterId: string;
  page: string;
  dispatch: AppDispatch;
}

export const getActiveFiltersId = (formState: Record<string, string | boolean>): Array<string> => {
  const activeFilterItems = Object.entries(formState).filter(([, value]) => !!value);
  return activeFilterItems.map(([id]) => id);
};

export const getResetFormState = (state: Record<string, string | boolean>): Record<string, boolean> => {
  const resetFormArr = Object.keys(state).map((inputId) =>
    inputId.includes('price') ? [inputId, ''] : [inputId, false],
  );
  return Object.fromEntries(resetFormArr);
};

// --------------------------------------

const getFuncForUpdateActiveFilters = (page: string, arr: string[]) => {
  const obj: { [key in string]: () => any } = {
    solutionsPage: () => changeSolutionsPageFilters(arr),
    platformsPage: () => changePlatformsPageFilters(arr),
    solutionSettings: () => changeActiveFilters(arr),
  };
  return obj[page]();
};

export const updateActiveFiltersCheckbox = ({
  activeFilters,
  filterId,
  page,
  dispatch,
}: IUpdateActiveFiltersCheckbox) => {
  const newArr = !activeFilters.includes(filterId)
    ? [...activeFilters, filterId]
    : activeFilters.filter((item) => item !== filterId);
  const func = getFuncForUpdateActiveFilters(page, newArr);
  dispatch(func);
};

export const updateActiveFiltersRadio = ({
  objFilter,
  activeFilters,
  filterId,
  page,
  dispatch,
}: IUpdateActiveFiltersRadio) => {
  // id всех фильтров в этой категории
  let arrFilterItems: string[];
  if ('filterItemsForSolutions' in objFilter) {
    arrFilterItems = objFilter?.filterItemsForSolutions.map((item) => item.id);
  } else {
    arrFilterItems = objFilter?.filterItemsForPlatforms.map((item) => item.id);
  }
  // удаляем id ранее активной радио-кнопки
  const updatedOldFilterItem = activeFilters.filter((item) => !arrFilterItems.includes(item));

  // если выбрано не 'Любое', добавляем фильтр в массив активных
  if (!filterId.includes('default')) updatedOldFilterItem.push(filterId);
  // обновляем массив активных
  const func = getFuncForUpdateActiveFilters(page, updatedOldFilterItem);
  dispatch(func);
};
