import { Tabs } from 'modules/Admin/components';
import { Outlet } from 'react-router';
import { getRole, useAppSelector } from 'store';

import styles from './styles.module.scss';
import { tabListConfig } from './config';

export const PlatformsLayout = () => {
  const role = useAppSelector(getRole);

  const tabList = role ? tabListConfig[role] : [];

  return (
    <>
      <div className={styles.tabsWrapper}>
        <Tabs tabs={tabList || []} />
      </div>
      <Outlet />
    </>
  );
};
