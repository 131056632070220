import { docEmailRequiredValidation, docNameOrSurnameRequiredValidation, docPasswordRequiredValidation } from 'shared';

import * as Yup from 'yup';

export const schema = Yup.object().shape({
  email: docEmailRequiredValidation,
  firstName: docNameOrSurnameRequiredValidation,
  lastName: docNameOrSurnameRequiredValidation,
  password: docPasswordRequiredValidation,
  passwordConfirm: docPasswordRequiredValidation.test(
    'passwords-match',
    'Пароли должны совпадать',
    // eslint-disable-next-line func-names
    function (value: string | undefined) {
      return value === this.parent.password;
    },
  ),
});
