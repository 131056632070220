import { ActionCreatorWithPayload, unwrapResult } from '@reduxjs/toolkit';
import { getMainOptionsAdminAction } from 'store/actions/platformMainOptions';
import {
  MainOptionsCompare,
  MainOptionsFormFiels,
  MainOptionsRequest,
  MainOptionsResponse,
  isAxiosResponseError,
} from 'modules/Admin/types/platformsTypes';
import { Dispatch } from 'react';
import { AppDispatch, setIsLoading } from 'store';
import { isAxiosError } from 'axios';

export const convertToCreatePlatformDTO = (
  formData: MainOptionsFormFiels,
  isActive: boolean = true,
): MainOptionsRequest => {
  const { urlWebsite, priceMin, priceMax, fileAsFile = null, file, ...restFields } = formData;
  return {
    file: fileAsFile,
    isActive,
    urlWebsite,
    priceMin,
    priceMax,
    ...restFields,
  };
};

const initialMainInfo = {
  name: '',
  description: '',
  urlWebsite: '',
  priceMin: 0,
  priceMax: 0,
  fileAsFile: null,
};

export const initFormValues =
  (
    dispatch: AppDispatch,
    setPlatformStatus: ActionCreatorWithPayload<boolean>,
    setImageLink: Dispatch<React.SetStateAction<string>>,
    platformId?: string,
  ) =>
  async (): Promise<MainOptionsFormFiels> => {
    try {
      if (platformId) {
        dispatch(setIsLoading(true));
        const optionsResponse = await dispatch(getMainOptionsAdminAction(platformId));
        const mainOptions = unwrapResult(optionsResponse);
        if (Object.hasOwnProperty.call(mainOptions, 'numberOfSolution')) {
          Reflect.deleteProperty(mainOptions, 'numberOfSolution');
        }
        const { isActive, id, file, ...options } = mainOptions;
        dispatch(setPlatformStatus(isActive));
        if (typeof file?.url === 'string') {
          const imageLink = file?.url;
          setImageLink(imageLink);
        }
        const convertedOptions: MainOptionsFormFiels = {
          ...options,
          fileAsFile: null,
        };
        return convertedOptions;
      }
      return initialMainInfo;
    } catch {
      return initialMainInfo;
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getErrorMessage = (err: unknown) => {
  let message = 'Что-то пошло не так...';
  if (isAxiosResponseError(err)) {
    const { error, message: errorMessage } = err.data;
    message = `Ошибка: ${error || ''}, описание: ${errorMessage || ''}`;
  } else if (isAxiosError(err)) {
    const { message: errorMessage } = err;
    message = `Ошибка: ${errorMessage || ''}`;
  }
  return message;
};

export const getObjEditPlatform = (platformDTO: MainOptionsRequest, oldData: MainOptionsResponse) => {
  const objEditPlatform: Partial<MainOptionsCompare> = {};
  if (platformDTO.isActive !== oldData.isActive) objEditPlatform.isActive = platformDTO.isActive;
  if (platformDTO.name !== oldData.name) objEditPlatform.name = platformDTO.name;
  if (platformDTO.urlWebsite !== oldData.urlWebsite) objEditPlatform.urlWebsite = platformDTO.urlWebsite;
  if (platformDTO.description !== oldData.description) objEditPlatform.description = platformDTO.description;
  if (platformDTO.priceMin !== oldData.priceMin) objEditPlatform.priceMin = platformDTO.priceMin;
  if (platformDTO.priceMax !== oldData.priceMax) objEditPlatform.priceMax = platformDTO.priceMax;
  if (platformDTO.file) objEditPlatform.file = platformDTO.file;
  return objEditPlatform;
};
