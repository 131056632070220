import { RootState } from 'store';

export const getImage = (state: RootState) => state.solutionImage;

export const getImageLoadingState = (state: RootState) => state.solutionImage.isSolutionImageLoading;

export const getSolutionImageSuccessMessage = (state: RootState) => state.solutionImage.solutionImageSuccessMessage;

export const getSolutionImageErrorMessage = (state: RootState) => state.solutionImage.solutionImageErrorMessage;

export const getImageId = (state: RootState) => state.solutionImage.id;

export const getImageName = (state: RootState) => state.solutionImage.name;

export const getSolutionImageLines = (state: RootState) => state.solutionImage.solutionImageLines;

export const getSolutionImageDeleteId = (state: RootState) => state.solutionImage.solutionImageDeleteId;

export const getSolutionImageDeleteName = (state: RootState) => state.solutionImage.solutionImageDeleteName;
