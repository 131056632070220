import { createAsyncThunk } from '@reduxjs/toolkit';
import { createPlatformsFiltersCategory } from 'modules/Admin/api';

import { AxiosError } from 'axios';

export interface ICreateCategoriesForPlatforms {
  categoryName: string;
}

export const createCategoriesForPlatformsAction = createAsyncThunk<
  void,
  ICreateCategoriesForPlatforms,
  { rejectValue: string }
>('adminPlatformsFiltersCategory/createCategoriesForPlatforms', async ({ categoryName }, { rejectWithValue }) => {
  try {
    return await createPlatformsFiltersCategory({ filterGroupName: categoryName });
  } catch (e) {
    const error = e as AxiosError;
    const { response } = error.request;
    const { message } = JSON.parse(response);
    return rejectWithValue(message);
  }
});
