import React, { useEffect, useState } from 'react';
import { getUsersPageResponse, setPageNumber, setUsersAction, useAppDispatch, useAppSelector } from 'store';

import cn from 'classnames';

import { getAmountUsersShown, getArrPaginationPageNumbers } from 'modules/Admin/utils/helpers';

import { arrow_backIcon, arrow_forwardIcon } from 'assets';

import styles from './styles.module.scss';

export const UsersPagePagination = () => {
  const dispatch = useAppDispatch();
  const { countRows, pageNumber, numberOfPages, numberOfUsersPerPage } = useAppSelector(getUsersPageResponse);
  const objUsersPages = { countRows, pageNumber, numberOfPages, numberOfUsersPerPage };

  const [btnBackIsDisabled, setBtnBackIsDisabled] = useState(false);
  const [btnForwardIsDisabled, setBtnForwardIsDisabled] = useState(false);
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    // установить disabled для кнопок-стрелок
    if (pageNumber === 1) setBtnBackIsDisabled(true);
    else setBtnBackIsDisabled(false);
    if (pageNumber === numberOfPages) setBtnForwardIsDisabled(true);
    else setBtnForwardIsDisabled(false);

    setPages(getArrPaginationPageNumbers(pageNumber, numberOfPages));
  }, [countRows, pageNumber, numberOfPages, numberOfUsersPerPage]);

  const handleClickBtnBack = () => {
    dispatch(setPageNumber(pageNumber - 1));
    dispatch(setUsersAction());
  };
  const handleClickBtnForward = () => {
    dispatch(setPageNumber(pageNumber + 1));
    dispatch(setUsersAction());
  };
  const handleClickItem = (value: number) => {
    dispatch(setPageNumber(value));
    dispatch(setUsersAction());
  };

  const setStyle = (value: number) =>
    cn(styles.item, {
      [styles.active]: pageNumber === value,
    });

  return (
    <div className={styles.usersPagePagination}>
      <div className={styles.pagination}>
        <button type="button" onClick={handleClickBtnBack} disabled={btnBackIsDisabled}>
          <img src={arrow_backIcon} alt="back" />
        </button>
        <div className={styles.pages}>
          {pages.map((value) => (
            <div className={setStyle(value)} onClick={() => handleClickItem(value)} key={value}>
              {value}
            </div>
          ))}
        </div>
        <button type="button" onClick={handleClickBtnForward} disabled={btnForwardIsDisabled}>
          <img src={arrow_forwardIcon} alt="forward" />
        </button>
      </div>
      <p className={styles.amount}>{getAmountUsersShown(objUsersPages)}</p>
    </div>
  );
};
