import { createSlice } from '@reduxjs/toolkit';
import { createSolutionOptionAction, setSolutionOptionAction, updateSolutionOptionAction } from 'store/actions';
import { ISolutionOptionState } from 'store/types';

const initialState: ISolutionOptionState = {
  solution: {
    id: '',
    name: '',
    description: '',
    isActive: false,
    priceMin: 0,
    priceMax: 0,
    eventPlan: '',
    platformId: '',
    platformName: '',
    platform: {
      id: '',
      name: '',
    },
  },
  isLoading: false,
  isRedirect: false,
  isNeedUpdateInit: false,
  successMessage: '',
  errorMessage: '',
};

const setLoading = (state: ISolutionOptionState) => {
  state.isLoading = true;
  state.errorMessage = '';
  state.successMessage = '';
};

const solutionOptionSlice = createSlice({
  name: 'solutionOption',
  initialState,
  reducers: {
    clearSolutionOption: (state) => {
      state.solution = initialState.solution;
      state.isNeedUpdateInit = true;
    },
    setSolutionOptionIsRedirect: (state, { payload }) => {
      state.isRedirect = payload;
    },
    setSolutionOptionIsNeedUpdate: (state, { payload }) => {
      state.isNeedUpdateInit = payload;
    },
    setSolutionOptionErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    clearSolutionOptionMessages: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSolutionOptionAction.pending, setLoading)
      .addCase(setSolutionOptionAction.fulfilled, (state, { payload }) => {
        state.solution = payload;
        state.solution.platformName = payload.platform.name;
        state.isNeedUpdateInit = true;
        state.isLoading = false;
      })
      .addCase(setSolutionOptionAction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload || 'Ошибка получения решения';
      })

      .addCase(createSolutionOptionAction.pending, setLoading)
      .addCase(createSolutionOptionAction.fulfilled, (state, { payload }) => {
        state.solution.id = payload;
        state.isRedirect = true;
        state.isLoading = false;
        state.successMessage = 'Решение успешно создано';
      })
      .addCase(createSolutionOptionAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка создания решения';
      })

      .addCase(updateSolutionOptionAction.pending, setLoading)
      .addCase(updateSolutionOptionAction.fulfilled, (state) => {
        state.isLoading = false;
        state.successMessage = 'Решение успешно изменено';
      })
      .addCase(updateSolutionOptionAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка редактирования решения';
      });
  },
});

export const {
  reducer: solutionOptionReducer,
  actions: {
    clearSolutionOption,
    setSolutionOptionErrorMessage,
    setSolutionOptionIsRedirect,
    setSolutionOptionIsNeedUpdate,
    clearSolutionOptionMessages,
  },
} = solutionOptionSlice;
