import React, { FC, useEffect, useState } from 'react';
import {
  clearFiltrationDates,
  clearFilterOptionsChecked,
  getUsersPageOptionsChecked,
  setActiveFilter,
  setUsersAction,
  useAppDispatch,
  useAppSelector,
  getUsersPageFiltration,
  getUsersPageActiveFilter,
} from 'store';

import { UsersPageFilterDates, UsersPageFilterOptions } from 'modules/Admin/components';
import { ArrowDownIcon, close } from 'assets';
import { formatDateTimeToDate } from 'helpers';
import { UsersFiltration } from 'modules/Admin/config';
import cn from 'classnames';

import styles from './styles.module.scss';

interface IUsersPageFilter {
  title: string;
  options: string[];
}

export const UsersPageFilter: FC<IUsersPageFilter> = ({ title, options }) => {
  const dispatch = useAppDispatch();
  const activeFilter = useAppSelector(getUsersPageActiveFilter);
  const optionsChecked = useAppSelector(getUsersPageOptionsChecked);
  const { createdAt: dates } = useAppSelector(getUsersPageFiltration);

  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const [showChecked, setShowChecked] = useState('');

  useEffect(() => {
    if (activeFilter === title) setOptionsOpen(true);
    else {
      setOptionsOpen(false);

      // если выбраны опции фильтрации, отобразить их
      let checkedStr = '';
      if (title === UsersFiltration.DATE && (dates.min || dates.max)) {
        const dateStart = dates.min ? formatDateTimeToDate(dates.min) : '...';
        const dateEnd = dates.max ? formatDateTimeToDate(dates.max) : '...';
        checkedStr = dateStart !== dateEnd ? `${dateStart} - ${dateEnd}` : `${dateStart}`;
      } else {
        const obj = optionsChecked.find((item) => item.filter === title);
        if (obj) checkedStr = obj.options.join(', ');
      }
      setShowChecked(checkedStr);
    }
  }, [activeFilter, optionsChecked, dates, title]);

  const handleClickFilterTitle = (event: React.MouseEvent<HTMLDivElement>) => {
    // event нужен, чтобы в случае если открыты опции другого фильтра и происходит клик по кнопке этого фильтра, он считывался именно этой кнопкой, а не просто закрылось окно с прошлыми опциями
    event.stopPropagation();
    const filterTitle = activeFilter !== title ? title : '';
    dispatch(setActiveFilter(filterTitle));
  };

  const handleClickClear = () => {
    if (title === UsersFiltration.DATE) dispatch(clearFiltrationDates());
    else dispatch(clearFilterOptionsChecked(title));
    dispatch(setUsersAction());
  };

  const filterButtonStyle = cn(styles.filterButton, { [styles.filterOptionsOpen]: isOptionsOpen });

  return (
    <div className={styles.usersPageFilter}>
      {showChecked ? (
        <div className={styles.filterButton} onClick={handleClickClear}>
          <p className={styles.title}>{title}</p>
          <p className={styles.optionsChecked}>{showChecked}</p>
          <img className={styles.icon} src={close} alt="cross" />
        </div>
      ) : (
        <>
          <div className={filterButtonStyle} onClick={(event) => handleClickFilterTitle(event)}>
            <p className={styles.title}>{title}</p>
            <ArrowDownIcon className={styles.icon} />
          </div>
          {isOptionsOpen && (
            <div className={styles.options}>
              {title === UsersFiltration.DATE ? (
                <UsersPageFilterDates />
              ) : (
                <UsersPageFilterOptions title={title} options={options} />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
