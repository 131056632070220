import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreatePlatformFields,
  CreatePlatformFieldsName,
  MainOptionsFielsdId,
  MainOptionsRequest,
  MainOptionsFormFiels,
} from 'modules/Admin/types/platformsTypes';

import { createPlatform } from 'modules/Admin/api/createPlatform';

import {
  useAppDispatch,
  useAppSelector,
  setAdminPlatformErrorMessage,
  setAdminPlatformSuccessMessage,
  setIsPlafromActive,
  setIsLoading,
  toggleActivePlatform,
  clearAdminPlatformMessages,
  getRole,
} from 'store';
import { ROLES } from 'shared';

import { getIsLoading, getIsPlatformActive } from 'store/selectors/adminPlatformSelector';

import { createPlatformScheme } from 'modules/Admin/data';

import { useNavigate, useParams } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';

import { TabsRoutes } from 'modules/Admin/router/routes';

import cn from 'classnames';

import { ChangeEvent, useEffect, useState } from 'react';

import { editPlatformMainOptions, getMainOptionsAdminAction } from 'store/actions/platformMainOptions';

import { Loading, Modal, TextArea } from 'sharedComponents';

import { getErrorMessage, convertToCreatePlatformDTO, initFormValues, getObjEditPlatform } from './helpers';

import styles from './styles.module.scss';

import { FileInput } from '../FileInput/FileInput';

import { TextInput } from '../TextInput/TextInput';
import { NumberInput } from '../NumberInput/NumberInput';

import { ROUTE } from '../../router/routes';

import buttonStyle from '../../../../styles/button.style.module.scss';
import { SwitchBtn } from '../SwitchBtn/SwitchBtn';

export const PlatformMainOptions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [imageLink, setImageLink] = useState<string>('');
  const { platformId: currentPlatformId } = useParams();
  const initialValue = initFormValues(dispatch, setIsPlafromActive, setImageLink, currentPlatformId);
  const isLoading = useAppSelector(getIsLoading);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [objEditPlatform, setObjEditPlatform] = useState({});

  const userRole = useAppSelector(getRole);
  const isAdmin = userRole === ROLES.SUPERADMIN || userRole === ROLES.ADMIN;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    trigger,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<MainOptionsFormFiels>({
    mode: 'onChange',
    defaultValues: initialValue,
    resolver: yupResolver(createPlatformScheme),
  });

  const isActivePlatform = useAppSelector(getIsPlatformActive);
  const [defaultIsActive, setDefaultIsActive] = useState<boolean | undefined>();

  const [fileUpdated, setFileUpdated] = useState(false);
  const fileAsFile = watch('fileAsFile');

  useEffect(() => {
    if (fileAsFile) setFileUpdated(true);
  }, [fileAsFile]);

  const onSubmit: SubmitHandler<MainOptionsFormFiels> = async (data) => {
    if (data.priceMax && data.priceMin && data.priceMax < data.priceMin) {
      dispatch(setAdminPlatformErrorMessage('Значение второй цены должно быть больше или равно первой'));
      return;
    }

    dispatch(clearAdminPlatformMessages());
    try {
      const platformDTO: MainOptionsRequest = convertToCreatePlatformDTO(data, isActivePlatform);
      if (!currentPlatformId) {
        const { id: platformId } = await createPlatform(platformDTO);
        dispatch(setAdminPlatformSuccessMessage('Платформа успешно сохранена.'));
        const editPlatformOptionsPath = `${ROUTE.ADMIN}/${ROUTE.PLATFORMS}/${platformId}/${TabsRoutes.filtersOptions}`;
        navigate(editPlatformOptionsPath);
      } else {
        platformDTO.isActive = isActivePlatform;
        const optionsResponse = await dispatch(getMainOptionsAdminAction(currentPlatformId));
        const oldData = unwrapResult(optionsResponse);
        const objDifference = getObjEditPlatform(platformDTO, oldData);
        setObjEditPlatform(objDifference);
        setIsVisibleModal(true);
      }
    } catch (e: unknown) {
      const error = getErrorMessage(e);
      dispatch(setAdminPlatformErrorMessage(error));
    }
  };

  const handleClickChange = async () => {
    if (currentPlatformId && Object.keys(objEditPlatform).length > 0) {
      try {
        dispatch(setIsLoading(true));
        await editPlatformMainOptions(currentPlatformId, objEditPlatform);
        dispatch(setAdminPlatformSuccessMessage('Платформа успешно изменена.'));
        reset(getValues());
        setFileUpdated(false);
        setDefaultIsActive(isActivePlatform);
      } catch (e: unknown) {
        const error = getErrorMessage(e);
        dispatch(setAdminPlatformErrorMessage(error));
      } finally {
        dispatch(setIsLoading(false));
        setIsVisibleModal(false);
      }
    } else {
      dispatch(setAdminPlatformErrorMessage('Платформа не была изменена.'));
      setIsVisibleModal(false);
    }
  };

  const handleOnchangePrice = (fieldName: CreatePlatformFieldsName) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    /** обрабатываем значения в формате "1e1 | 000001 | .0" убирая e и 0 */
    const newVal = Number.isNaN(parseFloat(value)) ? +value : parseFloat(value);
    setValue(fieldName, newVal, { shouldDirty: true });
    trigger(fieldName);
  };

  const handleActivePlatform = () => {
    if (defaultIsActive === undefined) setDefaultIsActive(isActivePlatform);
    dispatch(toggleActivePlatform());
  };

  const switchChanged = defaultIsActive !== undefined && defaultIsActive !== isActivePlatform;
  const btnIsDisabled = !switchChanged && (!isValid || !isDirty) && !fileUpdated;
  const saveButtonStyle = cn(buttonStyle.invertButton, styles.saveBtn);
  const activityStyle = cn(styles.activity, {
    [styles.isActive]: isActivePlatform,
  });

  useEffect(
    () => () => {
      dispatch(setIsPlafromActive(false));
    },
    [dispatch],
  );

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className={styles.switcherBtnContainer}>
        {isAdmin ? (
          <SwitchBtn
            isToggled={isActivePlatform}
            setIsToggled={handleActivePlatform}
            id="switcher-btn-platform-1"
            unActiveText="Неактивна"
            activeText="Активна"
          />
        ) : (
          <span className={activityStyle}>{isActivePlatform ? 'Активна' : 'Неактивна'}</span>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.flexForm}>
        <div>
          <div className={styles.name}>
            <TextInput
              name={CreatePlatformFieldsName.name}
              id={MainOptionsFielsdId.nameId}
              register={register}
              label={CreatePlatformFields.name}
              error={errors[CreatePlatformFieldsName.name]}
            />
          </div>
          <div className={styles.link}>
            <TextInput
              name={CreatePlatformFieldsName.platfomLink}
              id={MainOptionsFielsdId.linkId}
              register={register}
              label={CreatePlatformFields.platfomLink}
              error={errors[CreatePlatformFieldsName.platfomLink]}
            />
          </div>
          <div className={styles.description}>
            <TextArea
              name={CreatePlatformFieldsName.description}
              id={MainOptionsFielsdId.descriptionId}
              register={register}
              label={CreatePlatformFields.description}
              error={errors[CreatePlatformFieldsName.description]?.message}
              maxLength={255}
              className={styles.field}
            />
          </div>
        </div>
        <div>
          <div className={styles.priceWrapper}>
            <div className={styles.priceLabel}>
              {CreatePlatformFields.price}
              <div className={styles.price}>
                <Controller
                  control={control}
                  name={CreatePlatformFieldsName.priceFrom}
                  render={({ field: { value, onChange, ...field } }) => (
                    <NumberInput
                      {...field}
                      size="m"
                      id={MainOptionsFielsdId.priceFromId}
                      register={register}
                      error={errors[CreatePlatformFieldsName.priceFrom]}
                      inputPrefix="от"
                      onChange={handleOnchangePrice(CreatePlatformFieldsName.priceFrom)}
                      value={getValues()[CreatePlatformFieldsName.priceFrom] || ''}
                      warningAbsolute
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={CreatePlatformFieldsName.priceTo}
                  render={({ field: { value, onChange, ...field } }) => (
                    <NumberInput
                      {...field}
                      size="m"
                      id={MainOptionsFielsdId.priceToid}
                      register={register}
                      error={errors[CreatePlatformFieldsName.priceTo]}
                      inputPrefix="до"
                      onChange={handleOnchangePrice(CreatePlatformFieldsName.priceTo)}
                      value={getValues()[CreatePlatformFieldsName.priceTo] || ''}
                      warningAbsolute
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.file}>
            <Controller
              control={control}
              name={CreatePlatformFieldsName.downloadFile}
              rules={{ required: 'Recipe picture is required' }}
              render={({ field: { value, onChange, ...field } }) => (
                <FileInput
                  {...field}
                  label="Логотип платформы"
                  value={value as File}
                  id={MainOptionsFielsdId.fileId}
                  onChange={onChange}
                  error={errors[CreatePlatformFieldsName.downloadFile]}
                  imageLink={imageLink}
                />
              )}
            />
          </div>
          <button className={saveButtonStyle} type="submit" disabled={btnIsDisabled}>
            Сохранить
          </button>
        </div>
      </form>
      {isVisibleModal && (
        <Modal
          setIsActiveModal={(prev) => setIsVisibleModal(prev)}
          submitButtonText="Изменить"
          handleClick={handleClickChange}
        >
          <p>Изменить платформу?</p>
        </Modal>
      )}
    </>
  );
};
