import { MAX_SOLUTION_FILE_SIZE_BYTES } from 'modules/Admin/data/createSolutionImageScheme';
import { Dispatch, SetStateAction } from 'react';

export const MAX_PLATFORM_LOGO_SIZE = 500000;

export const setFile = (
  fileList: FileList,
  onChange: (file: File | null) => void,
  setFileTypeError: Dispatch<SetStateAction<boolean>>,
  isSolution: boolean = false,
) => {
  if (fileList && fileList.length > 0) {
    const file = fileList[0];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (
      fileExtension === 'jpeg' ||
      fileExtension === 'jpg' ||
      fileExtension === 'png' ||
      (isSolution && (fileExtension === 'gif' || fileExtension === 'webp'))
    ) {
      onChange(file);
      setFileTypeError(false);
    } else {
      onChange(file);
      setFileTypeError(true);
    }
    if (isSolution && file.size > MAX_SOLUTION_FILE_SIZE_BYTES) {
      onChange(file);
      setFileTypeError(true);
    }
    if (!isSolution && file.size > MAX_PLATFORM_LOGO_SIZE) {
      onChange(file);
      setFileTypeError(true);
    }
  }
};
