import { deleteSolutionFromFavorites, addSolutionInFavorites } from 'api';

import { SolutionCardItem } from 'components/SolutionCard/SolutionCard';

import { useParams } from 'react-router-dom';

import Slider from 'react-slick';

import { SliderArrow, SliderArrowsDirection } from 'components/SliderArrow/SliderArrow';

import {
  getUser,
  useAppSelector,
  useAppDispatch,
  getPlatform,
  getPlatformSolutions,
  setPlatformDetailsAction,
} from 'store';

import { useWindowSize } from 'hooks';

import { useEffect, useState } from 'react';

import { countSlidesPerPage, CustomSettingsResponsive } from 'helpers/countSlidesPerPage/countSlidesPerPage';

import { sliderSettings } from './sliderSettings';

const customSettingResponsive: CustomSettingsResponsive = {
  breakpoint: 1200,
  slidesToShow: 4,
  responsive: {
    breakpoint: 950,
    slidesToShow: 3,
    responsive: {
      breakpoint: 850,
      slidesToShow: 2,
      responsive: {
        breakpoint: 400,
        slidesToShow: 1,
        responsive: null,
      },
    },
  },
};

export const PlatformSolutionList = () => {
  const dispatch = useAppDispatch();
  const { platformId } = useParams();
  const { id } = useAppSelector(getUser);
  const { platform } = useAppSelector(getPlatform);
  const solutions = useAppSelector(getPlatformSolutions);
  const [slidesPerPage, setSlidesPerPage] = useState(1);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width) {
      setSlidesPerPage(countSlidesPerPage(width, solutions.length, customSettingResponsive));
    }
  }, [width, solutions.length]);

  const reloadCardList = () => {
    if (platformId && id) {
      dispatch(setPlatformDetailsAction({ id, platformId }));
    } else if (platformId) {
      dispatch(setPlatformDetailsAction({ platformId }));
    }
  };

  const toggleFavorite = (isFavorite: boolean, cardId: string) => {
    if (!id) {
      return;
    }

    if (isFavorite) {
      deleteSolutionFromFavorites(cardId).then(() => reloadCardList());
    } else {
      addSolutionInFavorites(cardId).then(() => reloadCardList());
    }
  };

  return (
    <Slider
      {...sliderSettings}
      slidesToShow={slidesPerPage}
      nextArrow={<SliderArrow direction={SliderArrowsDirection.right} />}
      prevArrow={<SliderArrow direction={SliderArrowsDirection.left} />}
    >
      {solutions.map((item) => (
        <SolutionCardItem
          name={item.name}
          key={`review${item.id}`}
          id={item.id}
          description={item.description}
          isFavorite={item.isFavorites}
          priceMin={item.priceMin}
          platformName={platform.name}
          platformLogoLink={platform.file?.url || ''}
          toggleFavorite={toggleFavorite}
        />
      ))}
    </Slider>
  );
};
