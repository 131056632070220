export const handleKeyDown = (evt: KeyboardEvent) => {
  if (evt.target instanceof HTMLInputElement && evt.target.name.includes('price')) {
    if (
      !/[0-9]/.test(evt.key) &&
      (evt.key !== 'Backspace' || evt.keyCode !== 8) &&
      evt.key !== 'ArrowLeft' &&
      evt.key !== 'ArrowRight' &&
      evt.key !== 'ArrowUp' &&
      evt.key !== 'ArrowDown' &&
      evt.key !== 'Delete' &&
      evt.key !== 'Tab'
    ) {
      evt.preventDefault();
    }
  }
};
