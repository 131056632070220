import { setModalConfirmEmail, useAppDispatch } from 'store';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ROUTE } from 'router';
import { useState } from 'react';
import { Input } from 'sharedComponents';

import { FacebookIcon, GoogleIcon, VKIcon, ErrorIcon } from '../assets';
import styles from '../styles.module.scss';
import buttonStyles from '../UI/buttonStyles/button.styles.module.scss';
import { schema } from '../data/registrationScheme';
import { RegisterUserType } from '../types';
import { userRegistration } from '../api';

export const RegistrationForm = () => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<RegisterUserType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [registrationError, setRegistrationError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // Function for watching all fields event types (insert, change, input), fixes disabled btn bug
  watch();

  const onSubmit: SubmitHandler<RegisterUserType> = (data) => {
    setIsLoading(true);

    userRegistration(data)
      .then(() => {
        const obj = {
          showModal: true,
          email: data.email,
        };
        dispatch(setModalConfirmEmail(obj));
        navigate(ROUTE.SIGN_IN);
        setIsLoading(false);
      })
      .catch((error) => {
        setRegistrationError(error.response.data.message);
        setIsLoading(false);
      });
  };

  const isDisabled = !!Object.keys(errors).length || isLoading || !isValid;

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <h3>Регистрация</h3>
      <div className={styles.inputGroupWrapper}>
        <Input register={register} id="email" iconStart placeholder="Email" error={errors.email?.message} />
        <Input register={register} id="firstName" iconStart placeholder="Имя" error={errors.firstName?.message} />
        <Input register={register} id="lastName" iconStart placeholder="Фамилия" error={errors.lastName?.message} />
        <Input
          register={register}
          id="password"
          placeholder="Пароль"
          type="password"
          error={errors.password?.message}
          value={password}
          onInput={({ currentTarget }) => {
            setPassword(currentTarget.value);
          }}
        />
        <Input
          register={register}
          id="passwordConfirm"
          placeholder="Подтвердите пароль"
          type="password"
          error={errors.passwordConfirm?.message}
          value={passwordConfirm}
          onInput={({ currentTarget }) => {
            setPasswordConfirm(currentTarget.value);
          }}
        />
      </div>
      <button className={`${buttonStyles.button} ${buttonStyles.invertButton}`} type="submit" disabled={isDisabled}>
        Зарегистрироваться
      </button>
      {registrationError && (
        <p className={styles.error}>
          <ErrorIcon />
          {registrationError}
        </p>
      )}
      <p className={styles.sideCenter}>или</p>
      <div className={styles.socialWrapper}>
        <button className={buttonStyles.button} type="button">
          <GoogleIcon /> Google
        </button>
        <button className={buttonStyles.button} type="button">
          <FacebookIcon />
          Facebook
        </button>
        <button className={buttonStyles.button} type="button">
          <VKIcon /> VK
        </button>
      </div>
      <p className={styles.text}>
        Нажимая “Зарегистрироваться”, Вы соглашаетесь с условиями{' '}
        <NavLink to="/" className={styles.license}>
          лицензионного договора, политикой конфиденциальности
        </NavLink>
        и предоставляете согласие на обработку персональных данных
      </p>
      <p className={styles.enter}>
        Уже есть аккаунт? <Link to={ROUTE.SIGN_IN}>Войти</Link>
      </p>
    </form>
  );
};
