import { WarningIcon } from 'modules/Auth/assets';
import cn from 'classnames';

import styles from './styles.module.scss';

interface WarningProps {
  message?: string;
  absolute?: boolean;
}

export const Warning = ({ message = 'Неизвестная ошибка', absolute = false }: WarningProps) => {
  const warningStyles = cn(styles.warningText, {
    [styles.absolute]: absolute,
  });

  return (
    <span className={warningStyles}>
      <WarningIcon />
      {message}
    </span>
  );
};
