import { useEffect } from 'react';
import {
  useAppDispatch,
  filtersSelector,
  useAppSelector,
  setPlatformsAction,
  clearPlatformsPageTopFiltersError,
  clearPlatformsPageFiltersAndPrices,
} from 'store';

import { useNavigate } from 'react-router';
import { getTopFiltersAction } from 'store/actions/filters';
import { Loading, ErrorNotification } from 'sharedComponents';
import { updateActiveFiltersCheckbox, updateActiveFiltersRadio } from 'helpers';
import { useSearchParams } from 'react-router-dom';
import { SortType } from 'modules/Admin/config';
import { ROUTE } from 'router';
import { INPUTTYPE } from 'mocks';

import styles from './styles.module.scss';

import { Filters } from '../Filters/Filters';
import { PriceFilter } from '../PriceFilter/PriceFilter';

interface IFiltrationBar {
  sortOption: SortType;
}

export const FiltrationBar = ({ sortOption }: IFiltrationBar) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const { topPriorityFilters, topFiltersError, isTopFiltersLoading, activeFilters } = useAppSelector(filtersSelector);

  useEffect(() => {
    dispatch(getTopFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPlatformsAction({ amount: page }));
  }, [activeFilters, sortOption, page, dispatch]);

  const handlePageChange = (pageNumber: number) => {
    setSearchParams(`page=${pageNumber}`);
    document.body.scrollIntoView({ behavior: 'smooth' });
  };

  const getObjFilter = (filterName: string) => topPriorityFilters.find((item) => item.id === filterName);

  const handleFormChange = (event: any) => {
    const filterId = event.target.id;
    const filterName = event.target.name;
    const objFilter = getObjFilter(filterName);

    if (objFilter) {
      handlePageChange(1);
      if (objFilter?.inputType === INPUTTYPE.CHECKBOX) {
        const obj = { activeFilters, filterId, page: 'platformsPage', dispatch };
        updateActiveFiltersCheckbox(obj);
      } else {
        const obj = { objFilter, activeFilters, filterId, page: 'platformsPage', dispatch };
        updateActiveFiltersRadio(obj);
      }
    }
  };

  const handleFormReset = () => {
    handlePageChange(1);
    dispatch(clearPlatformsPageFiltersAndPrices());
  };

  const clearMessage = () => {
    dispatch(clearPlatformsPageTopFiltersError());
  };

  return isTopFiltersLoading ? (
    <Loading />
  ) : (
    <div className={styles.wrapper}>
      <form onChange={handleFormChange}>
        <PriceFilter page="platformsPage" onPageChange={handlePageChange} />
        {topPriorityFilters.map((filtersData) => (
          <Filters
            activeFilters={activeFilters}
            objFilter={getObjFilter(filtersData.id)}
            onClickAny={handleFormChange}
            filtersData={filtersData}
            key={filtersData.id}
          />
        ))}
      </form>
      <button className="button buttonSecondary" type="button" onClick={handleFormReset}>
        Очистить все
      </button>
      <button
        className="button buttonSecondary"
        type="button"
        onClick={() => {
          navigate(ROUTE.FILTERS);
          window.scrollTo(0, 0);
        }}
      >
        Все фильтры
      </button>
      {topFiltersError && <ErrorNotification message={topFiltersError} clearMessage={clearMessage} />}
    </div>
  );
};
