import { createSlice } from '@reduxjs/toolkit';
import { errorHandler } from 'helpers/errorHandler';
import { deleteSolutionVideoAction, updateSolutionVideoDataAction } from 'store/actions';
import { createSolutionVideoDataAction } from 'store/actions/createSolutionVideoDataAction';
import { setSolutionVideoDataByIdAction } from 'store/actions/setSolutionVideoDataByIdAction';
import { SolutionVideoType } from 'store/types/solutionVideo';

export interface SolutionVideoState {
  videoData: SolutionVideoType;
  solutionsVideoSuccessMessage: string;
  solutionsVideoErrorMessage: string;
  isSolutionAdded: boolean;
  isLinkAdded: boolean;
  isDescriptionAdded: boolean;
  isSolutionVideoLoading: boolean;
}

const initialState: SolutionVideoState = {
  videoData: {
    id: '',
    url: '',
    description: '',
  },
  solutionsVideoSuccessMessage: '',
  solutionsVideoErrorMessage: '',
  isSolutionAdded: false,
  isLinkAdded: false,
  isDescriptionAdded: false,
  isSolutionVideoLoading: false,
};

const solutionVideoSlice = createSlice({
  name: 'solutionVideo',
  initialState,
  reducers: {
    setSolutionVideoLink: (state, { payload }) => {
      state.videoData.url = payload;
      state.isLinkAdded = true;
    },
    setSolutionVideoDesc: (state, { payload }) => {
      state.videoData.description = payload;
      state.isDescriptionAdded = true;
    },
    editSolutionVideoLink: (state) => {
      state.isLinkAdded = false;
    },
    editSolutionVideoDesc: (state) => {
      state.isDescriptionAdded = false;
    },
    resetAddedState: (state) => {
      state.isSolutionAdded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSolutionVideoDataByIdAction.pending, (state) => {
        state.isSolutionVideoLoading = true;
        state.solutionsVideoErrorMessage = '';
        state.solutionsVideoSuccessMessage = '';
      })
      .addCase(setSolutionVideoDataByIdAction.fulfilled, (state, { payload }) => {
        state.isSolutionVideoLoading = false;
        if (payload.id) {
          state.videoData.url = payload.url;
          state.videoData.description = payload.description;
          state.isLinkAdded = true;
          state.isDescriptionAdded = true;
          state.isSolutionAdded = true;
          return;
        }
        state.videoData = {
          id: '',
          url: '',
          description: '',
        };
      })
      .addCase(setSolutionVideoDataByIdAction.rejected, (state, { error }) => {
        state.isSolutionVideoLoading = false;
        state.solutionsVideoErrorMessage = errorHandler(error, 'Ошибка получения данных');
        state.isLinkAdded = false;
        state.isDescriptionAdded = false;
      })
      .addCase(updateSolutionVideoDataAction.pending, (state) => {
        state.isSolutionVideoLoading = true;
        state.solutionsVideoErrorMessage = '';
        state.solutionsVideoSuccessMessage = '';
      })
      .addCase(updateSolutionVideoDataAction.fulfilled, (state) => {
        state.isSolutionVideoLoading = false;
        state.solutionsVideoSuccessMessage = 'Данные успешно обновлены';
      })
      .addCase(updateSolutionVideoDataAction.rejected, (state, { payload }) => {
        state.isSolutionVideoLoading = false;
        state.solutionsVideoSuccessMessage = '';
        state.solutionsVideoErrorMessage = payload as string;
        state.isLinkAdded = false;
        state.isDescriptionAdded = false;
      })
      .addCase(createSolutionVideoDataAction.pending, (state) => {
        state.isSolutionVideoLoading = true;
        state.solutionsVideoErrorMessage = '';
        state.solutionsVideoSuccessMessage = '';
      })
      .addCase(createSolutionVideoDataAction.fulfilled, (state) => {
        state.isSolutionVideoLoading = false;
        state.solutionsVideoSuccessMessage = 'Данные успешно сохранены';
        state.isSolutionAdded = true;
        state.isLinkAdded = true;
        state.isDescriptionAdded = true;
      })
      .addCase(createSolutionVideoDataAction.rejected, (state) => {
        state.isSolutionVideoLoading = false;
        state.solutionsVideoErrorMessage = 'Ошибка сохранения данных';
        state.isLinkAdded = false;
        state.isDescriptionAdded = false;
      })
      .addCase(deleteSolutionVideoAction.pending, (state) => {
        state.isSolutionVideoLoading = true;
        state.solutionsVideoErrorMessage = '';
        state.solutionsVideoSuccessMessage = '';
      })
      .addCase(deleteSolutionVideoAction.fulfilled, (state) => {
        state.isSolutionVideoLoading = false;
        state.solutionsVideoSuccessMessage = 'Данные успешно удалены';
        state.solutionsVideoErrorMessage = '';
        state.isSolutionAdded = false;
        state.isLinkAdded = false;
        state.isDescriptionAdded = false;
        state.videoData.url = '';
        state.videoData.description = '';
      })
      .addCase(deleteSolutionVideoAction.rejected, (state) => {
        state.isSolutionVideoLoading = false;
        state.solutionsVideoErrorMessage = 'Ошибка удаления данных';
        state.solutionsVideoSuccessMessage = '';
      });
  },
});

export const {
  reducer: solutionVideoReducer,
  actions: {
    setSolutionVideoLink,
    setSolutionVideoDesc,
    editSolutionVideoLink,
    editSolutionVideoDesc,
    resetAddedState,
  },
} = solutionVideoSlice;
