import { DescriptionIcon } from 'assets';

import { INPUTTYPE } from 'mocks';

import styles from './styles.module.scss';

interface ICheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
  text: string;
  id: string;
  icon?: boolean;
  name: string;
  isChecked?: boolean;
  isSolutionFilterChecked?: boolean;
}

export const Checkbox = ({ text, id, icon, name, isChecked, ...restProps }: ICheckbox) => (
  <li>
    <label htmlFor={id} className={styles.label}>
      <input
        className={styles.input}
        id={id}
        name={name}
        type={INPUTTYPE.CHECKBOX}
        checked={isChecked}
        readOnly
        {...restProps}
      />
      <span className={styles.span}>{text}</span>
      {icon && <DescriptionIcon />}
    </label>
  </li>
);
