import { getFavoritePlatforms } from 'api/getFavoritePlatforms';
import { PlatformList } from 'components';
import { useEffect, useState } from 'react';
import { PlatfromFavoriteCard } from 'store';
import { Loading } from 'sharedComponents';

import styles from '../../../../components/PlatformList/styles.module.scss';

export const ProfileFavoritePlatformsPage = () => {
  const [favoritePlatforms, setFavoritePlatforms] = useState<PlatfromFavoriteCard[]>([]);
  const [isLoading, setLoading] = useState(false);
  const reloadCardList = () =>
    getFavoritePlatforms()
      .then((data) => {
        setFavoritePlatforms(data || []);
      })
      .finally(() => setLoading(false));

  useEffect(() => {
    const getCards = async () => {
      await setLoading(true);
      reloadCardList();
    };
    getCards();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      {favoritePlatforms.length ? (
        <PlatformList platforms={favoritePlatforms} onlyFavorites reloadCardList={reloadCardList} />
      ) : (
        <div className={styles.emptyPlatformList}>Пусто</div>
      )}
    </div>
  );
};
