export enum UsersFiltration {
  ROLE = 'Роль',
  DATE = 'Дата',
  STATUS = 'Статус',
}

export enum UserStatus {
  ACTIVE = 'Активен',
  INACTIVE = 'Неактивен',
}
