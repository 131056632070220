import { createSlice } from '@reduxjs/toolkit';
import { errorHandler } from 'helpers/errorHandler';
import { toggle } from 'helpers/toggle';
import { SortType } from 'modules/Admin/config/sort';
import {
  deleteAllPlatformsFromHistoryAction,
  deleteSelectedPlatformsFromHistoryAction,
  setPlatformsAction,
  setViewedPlatformsAction,
} from 'store/actions';
import { PlatformsState } from 'store/types';

const initialState: PlatformsState = {
  platforms: {
    numberOfPages: '1',
    numberOfPlatformsPerPage: 24,
    rows: [],
    countRows: 0,
    pageNumber: 0,
    priceMin: 0,
    priceMax: 0,
  },
  favoritePlatforms: [],
  viewedPlatforms: [],
  platformsForDeletingFromViewed: [],
  isSelectMode: false,
  loadingState: 'idle',
  loadingMessage: '',
  isDeletingFromViewed: false,
  deleteFromHistorySuccess: '',
  deleteFromHistoryError: '',
  error: null,
  isLoaderVisible: true,
  sortKey: 'name',
  sortDirection: SortType.ACSENDING,
  pricesLimit: {
    priceMinLimit: 0,
    priceMaxLimit: 0,
  },
};

const platformsSlice = createSlice({
  name: 'platforms',
  initialState,
  reducers: {
    setSortKey: (state, action) => {
      state.sortKey = action.payload;
    },
    setPLatformsPageSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    setLoadingMessage: (state, { payload }) => {
      state.loadingMessage = payload;
    },
    togglePlatformForDeletingFromViewed: (state, { payload: platformId }) => {
      state.platformsForDeletingFromViewed = toggle(state.platformsForDeletingFromViewed, platformId);
    },
    toggleSelectMode: (state) => {
      state.isSelectMode = !state.isSelectMode;
    },
    clearPlatformsForDeletingFromViewed: (state) => {
      state.isSelectMode = false;
      state.platformsForDeletingFromViewed = [];
    },
    clearDeleteFromHistoryMessages: (state) => {
      state.deleteFromHistorySuccess = '';
      state.deleteFromHistoryError = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setPlatformsAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.isLoaderVisible = true;
        state.loadingMessage = '';
      })
      .addCase(setPlatformsAction.fulfilled, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.platforms.numberOfPages = payload.numberOfPages;
        state.platforms.rows = payload.rows;
        state.platforms.countRows = payload.countRows;
        state.pricesLimit.priceMinLimit = payload.priceMin;
        state.pricesLimit.priceMaxLimit = payload.priceMax;
        if (payload.rows.length === 0) {
          state.loadingMessage = 'Нет платформ';
        }
      })
      .addCase(setPlatformsAction.rejected, (state, { error, meta }) => {
        state.loadingState = meta.requestStatus;
        state.error = error;
        state.isLoaderVisible = false;
        state.loadingMessage = 'Ошибка запроса';
        state.platforms.rows = [];
      })
      .addCase(setViewedPlatformsAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.isLoaderVisible = true;
        state.loadingMessage = '';
      })
      .addCase(setViewedPlatformsAction.fulfilled, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.viewedPlatforms = payload;
        if (payload.length === 0) {
          state.loadingMessage = 'Пусто';
        }
      })
      .addCase(setViewedPlatformsAction.rejected, (state, { error, meta }) => {
        state.loadingState = meta.requestStatus;
        state.error = error;
        state.loadingMessage = error.message || 'Ошибка запроса';
        state.isLoaderVisible = false;
      })
      .addCase(deleteSelectedPlatformsFromHistoryAction.pending, (state) => {
        state.isDeletingFromViewed = true;
        state.loadingMessage = '';
      })
      .addCase(deleteSelectedPlatformsFromHistoryAction.fulfilled, (state) => {
        state.deleteFromHistorySuccess = 'Удаление выполнено успешно';
        state.isDeletingFromViewed = false;
      })
      .addCase(deleteSelectedPlatformsFromHistoryAction.rejected, (state, { payload }) => {
        if (payload) {
          state.deleteFromHistoryError = payload;
          state.isDeletingFromViewed = false;
        }
      })
      .addCase(deleteAllPlatformsFromHistoryAction.pending, (state) => {
        state.isDeletingFromViewed = true;
      })
      .addCase(deleteAllPlatformsFromHistoryAction.fulfilled, (state) => {
        state.deleteFromHistorySuccess = 'История просмотра платформ успешно очищена';
        state.isDeletingFromViewed = false;
      })
      .addCase(deleteAllPlatformsFromHistoryAction.rejected, (state, { error }) => {
        state.deleteFromHistoryError = errorHandler(error) || 'История просмотра платформ не была очищена';
        state.isDeletingFromViewed = false;
      });
  },
});

export const {
  reducer: platformsReducer,
  actions: {
    setLoadingMessage,
    setPLatformsPageSortDirection,
    togglePlatformForDeletingFromViewed,
    toggleSelectMode,
    clearPlatformsForDeletingFromViewed,
    clearDeleteFromHistoryMessages,
  },
} = platformsSlice;
