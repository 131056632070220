import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateSolutionFilters } from 'modules/Admin/api';

interface IUpdateSolutionFilters {
  updateData: string[];
  solutionId: string;
}

export const updateSolutionFiltersByIdAction = createAsyncThunk<
  Promise<any>,
  IUpdateSolutionFilters,
  { rejectValue: string }
>('solutionFilters/UpdateSolutionFilters', async ({ updateData, solutionId }) => {
  updateSolutionFilters(updateData, solutionId);
});
