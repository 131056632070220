import { SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  deleteFilterPlatformAdminAction,
  setAdminPlatformsFiltersAction,
  setDeleteFormOpen,
  useAppDispatch,
  useAppSelector,
  getAdminPlatformsFilters,
  getDeleteFilterMessage,
  getIsDeleteFormOpen,
  getIsFilterLoaderVisible,
  getAdminFiltersLoadingMessage,
  success,
  clearNotifications,
  clearErrorMessage,
} from 'store';
import { Loading, LoadingMessage, Modal, SuccessNotification } from 'sharedComponents';

import styles from './styles.module.scss';

import { FiltersGroup } from '../FiltersGroup/FiltersGroup';

export const FiltersList = () => {
  const [activeId, setActiveId] = useState('');
  const isDeleteModalOpen = useAppSelector(getIsDeleteFormOpen);
  const deleteFilterMessage = useAppSelector(getDeleteFilterMessage);
  const loadingMessage = useAppSelector(getAdminFiltersLoadingMessage);
  const isLoaderVisible = useAppSelector(getIsFilterLoaderVisible);
  const dispatch = useAppDispatch();
  const adminFilters = useAppSelector(getAdminPlatformsFilters);
  const successNotification = useAppSelector(success);

  useEffect(() => {
    dispatch(setAdminPlatformsFiltersAction());
  }, [dispatch]);

  const handleFilterDelete = () => dispatch(deleteFilterPlatformAdminAction({ id: activeId }));

  const handleOpenDeleteForm = (isOpen: SetStateAction<boolean>) => {
    dispatch(setDeleteFormOpen(isOpen));
  };

  const clearMessages = useCallback(() => {
    dispatch(clearNotifications());
    dispatch(clearErrorMessage());
  }, [dispatch]);

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.title}>Наименование фильтра</p>
        <p className={styles.title}>Категория</p>
        {adminFilters.map(({ id, filterGroupName, filtersForPlatforms }) => (
          <FiltersGroup
            key={id}
            filterGroupName={filterGroupName}
            filters={filtersForPlatforms}
            setActiveId={setActiveId}
            setIsModalOpen={handleOpenDeleteForm}
          />
        ))}
      </div>
      {isLoaderVisible && <Loading delay={300} />}
      {!!loadingMessage && <LoadingMessage message={loadingMessage} />}
      {isDeleteModalOpen && (
        <Modal setIsActiveModal={handleOpenDeleteForm} handleClick={handleFilterDelete}>
          <>
            {!!deleteFilterMessage && <LoadingMessage message={deleteFilterMessage} />}
            <p>Восстановить фильтр после удаления будет невозможно. Вы уверены, что хотите удалить фильтр?</p>
          </>
        </Modal>
      )}
      {successNotification && <SuccessNotification message={successNotification} clearMessage={clearMessages} />}
    </>
  );
};
