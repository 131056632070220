import { getUser, useAppSelector } from 'store';

import styles from './styles.module.scss';

export const UserCircle = () => {
  const { firstName } = useAppSelector(getUser);
  return (
    <div className={styles.profileName}>
      <span>{firstName && firstName[0]}</span>
    </div>
  );
};
