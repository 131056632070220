import { FC } from 'react';
import { CharacteristicItemFilterSolution } from 'components/CharacteristicItemFilterSolution/CharacteristicItemFilterSolutions';
import { SolutionFilterListCharacteristic } from 'store/types/solution';

import styles from '../CharacteristicListItem/styles.module.scss';

type CharacteristicProps = Omit<SolutionFilterListCharacteristic, 'id'>;

export const CharacteristicsListItemSolution: FC<CharacteristicProps> = ({ filterGroupName, filtersForSolutions }) => (
  <div className={styles.filterWrapper}>
    <div className={styles.filterTitle}>{filterGroupName}</div>
    <div className={styles.characteristicsListItem}>
      {filtersForSolutions?.map(({ filterName, filterItemsForSolutions, id }) => (
        <CharacteristicItemFilterSolution
          key={id}
          filterName={filterName}
          filterItemsForSolutions={filterItemsForSolutions}
        />
      ))}
    </div>
  </div>
);
