import { baseURL } from 'shared';

export const endpoints = {
  signIn: 'auth/login',
  signUp: `${baseURL}/auth/registration`,
  refreshToken: `${baseURL}/auth/refresh`,
  sendEmail: `${baseURL}/email/refresh-password-request`,
  resetPassword: `${baseURL}/email/refresh-password-answer-code`,
  modalData: `${baseURL}/platforms`,
  logout: `${baseURL}/auth/logout`,
  confirmEmail: `${baseURL}/users/confirm-email`,
  updateEmailSendOldEmail: `${baseURL}/email/update-email-address-request`,
  updateEmailSendNewEmail: `${baseURL}/email/update-email-address`,
};
