import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { createSolutionVideo } from 'modules/Admin/api/createSolutionVideo';
import { SolutionVideoType } from 'store/types/solutionVideo';

export const createSolutionVideoDataAction = createAsyncThunk<void, SolutionVideoType, { rejectValue: string }>(
  'video/CreateSolutionVideo',
  async (videoData, { rejectWithValue }) => {
    try {
      return await createSolutionVideo(videoData);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      return rejectWithValue(error.response?.data?.message || 'Ошибка добавления данных');
    }
  },
);
