import { isAxiosError, isAxiosResponseError } from 'modules/Admin/types';

export const errorHandler = (e: unknown, errorMessage: string = 'Что-то пошло не так') => {
  if (isAxiosResponseError(e)) {
    const { error, message } = e.data;
    return `Ошибка: ${error || ''}, описание: ${message || ''}`;
  }
  if (isAxiosError(e)) {
    const { message } = e;
    return `Ошибка: ${message || ''}`;
  }
  return errorMessage;
};
