import React, { useEffect } from 'react';
import {
  UpdateEmail,
  clearUpdateEmailMessages,
  closeUpdateEmailModal,
  getRole,
  getUpdateEmail,
  setUpdateEmailError,
  updateEmailSendNewEmailAction,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ErrorNotification, Input } from 'sharedComponents';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'modules/Auth/data/newEmailScheme';
import { ModalEmail } from 'modules/Profile/components';
import cn from 'classnames';
import { buttonStyles } from 'modules/Auth/UI';
import { ROUTE } from 'router';
import { logoIcon } from 'assets';
import { ROLES } from 'shared';

import styles from './styles.module.scss';

export const NewEmailPage = () => {
  const dispatch = useAppDispatch();
  const { newEmail, showModalNewEmail, errorMessage } = useAppSelector(getUpdateEmail);
  const role = useAppSelector(getRole);
  const backLink = role === ROLES.USER ? `${ROUTE.PROFILE}/${ROUTE.PROFILE_SETTINGS}` : ROUTE.SIGN_IN;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<UpdateEmail>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    document.body.style.overflow = showModalNewEmail ? 'hidden' : 'auto';
  }, [showModalNewEmail]);

  const onSubmit = (body: UpdateEmail) => {
    if (token) dispatch(updateEmailSendNewEmailAction({ body, token }));
    else dispatch(setUpdateEmailError('Ошибка отправки ссылки на новый email'));
  };

  const closeModal = () => {
    dispatch(closeUpdateEmailModal());
  };

  const clearMessages = () => {
    dispatch(clearUpdateEmailMessages());
  };

  const buttonClasses = cn(buttonStyles.button, buttonStyles.invertButton, styles.btn);

  const isSubmitDisabled = !isValid || !isDirty;

  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        {showModalNewEmail && (
          <ModalEmail textStart="Ссылка для подтверждения нового email" closeModal={closeModal} email={newEmail} />
        )}
        <div className={styles.navbar}>
          <Link to={ROUTE.HOME}>
            <img src={logoIcon} className={styles.logo} alt="Townsend logo" />
          </Link>
          <p className={styles.cancel}>
            <Link to={backLink}>Отмена</Link>
          </p>
        </div>
        <div className={styles.wrapper}>
          <h3 className={styles.title}>Введите адрес новой электронной почты</h3>
          <form className={styles.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.main}>
              <div className={`${styles.wrap} ${styles['wrap-order']}`}>
                <p className={styles.text}>
                  Email<span className={styles.star}>*</span>
                </p>
              </div>
              <div className={styles.item}>
                <Input register={register} id="to" error={errors.to?.message} className={styles.input} />
              </div>
            </div>
            <button className={buttonClasses} type="submit" disabled={isSubmitDisabled}>
              Продолжить
            </button>
          </form>
        </div>
      </div>
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
    </div>
  );
};
