import { createSlice } from '@reduxjs/toolkit';
import { LocalStorage, ROLES } from 'shared';
import { logoutAction, logoutWithoutErrorAction, updateUserDateAction, signInAction } from 'store/actions';
import { IUser } from 'store/types';

const initialState: IUser = {
  id: null,
  email: null,
  password: null,
  firstName: null,
  lastName: null,
  telegram: null,
  phone: null,
  createAt: null,
  updateAt: null,
  role: null,
  isAuth: false,
  loadingState: 'idle',
  error: null,
  isShowFavoriteIcon: false,
  successNotification: '',
  errorNotification: '',
  objUpdate: {},
  updateIsValid: true,
  isLoading: false,
  errorUpdateToken: null,
};

const clearUserData = (state: IUser, { meta }: any) => {
  state.loadingState = meta.requestStatus;
  state.email = null;
  state.firstName = null;
  state.lastName = null;
  state.role = null;
  state.isAuth = false;
  state.isShowFavoriteIcon = false;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearErrorUpdateToken: (state) => {
      state.errorUpdateToken = null;
    },
    clearUserNotifications: (state) => {
      state.successNotification = '';
      state.errorNotification = '';
    },
    setObjUpdate: (state, { payload }) => {
      state.objUpdate = payload;
    },
    setUpdateIsValid: (state, { payload }) => {
      state.updateIsValid = payload;
    },
    clearUpdateData: (state) => {
      state.objUpdate = {};
      state.updateIsValid = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(signInAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.email = null;
        state.firstName = null;
        state.lastName = null;
        state.telegram = null;
        state.phone = null;
        state.id = null;
        state.role = null;
        state.isAuth = false;
        state.error = null;
        state.createAt = null;
        state.updateAt = null;
      })
      .addCase(signInAction.fulfilled, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        if (payload) {
          const { email, firstName, lastName, telegram, phone, id, role, createAt, updateAt } = payload;
          state.email = email;
          state.firstName = firstName;
          state.lastName = lastName;
          state.telegram = telegram;
          state.phone = phone;
          state.id = id;
          if (role) {
            state.role = {
              id: role.id,
              role: role.role as ROLES,
            };
          }
          state.createAt = createAt;
          state.updateAt = updateAt;
          state.isAuth = true;
          state.error = null;
          state.isShowFavoriteIcon = state.role?.role === ROLES.USER;
          state.isLoading = false;
        }
      })
      .addCase(signInAction.rejected, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.error = action.error;
      });
    builder
      .addCase(updateUserDateAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.isLoading = true;
      })
      .addCase(updateUserDateAction.fulfilled, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.successNotification = 'Успешное обновление данных';
      })
      .addCase(updateUserDateAction.rejected, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.errorNotification = 'Ошибка обновления данных';
        state.isLoading = false;
      });
    builder
      .addCase(logoutAction.pending, (state, { meta }) => {
        state.loadingState = meta.requestStatus;
        state.errorUpdateToken = localStorage.getItem(LocalStorage.LogoutMessage);
        localStorage.removeItem(LocalStorage.LogoutMessage);
      })
      .addCase(logoutAction.fulfilled, clearUserData)
      .addCase(logoutAction.rejected, clearUserData);
    builder
      .addCase(logoutWithoutErrorAction.pending, (state, { meta }) => {
        state.loadingState = meta.requestStatus;
      })
      .addCase(logoutWithoutErrorAction.fulfilled, clearUserData)
      .addCase(logoutWithoutErrorAction.rejected, clearUserData);
  },
});

export const {
  reducer: userReducer,
  actions: { clearErrorUpdateToken, clearUserNotifications, setObjUpdate, setUpdateIsValid, clearUpdateData },
} = userSlice;
