import { SolutionEventCreating } from 'store';

import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

interface ICreateSolutionEvent {
  solutionId: string;
  body: {
    text: string;
  };
}

export const createSolutionEvent = ({ solutionId, body }: ICreateSolutionEvent): Promise<SolutionEventCreating[]> =>
  authAxiosInstance.post(`${endpoints.solutions}/${solutionId}/event`, body).then(({ data }) => data);
