import {
  docFieldObjectRequiredValidation,
  docMessageOrReviewOrDescriptionValidation,
  docNameOrParametrOrCategoryOfFilterRequiredValidation,
  docNameOrParametrOrCategoryOfFilterValidation,
} from 'shared';
import * as Yup from 'yup';

import { CreateFilterFields } from '../types';

export const createPlatformFilterScheme = Yup.object().shape({
  [CreateFilterFields.category]: docFieldObjectRequiredValidation,
  [CreateFilterFields.filterName]: docNameOrParametrOrCategoryOfFilterRequiredValidation,
  [CreateFilterFields.descriptionLong]: docMessageOrReviewOrDescriptionValidation,
  [CreateFilterFields.parametr]: docNameOrParametrOrCategoryOfFilterValidation,
});
