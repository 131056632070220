import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreatePlatformReview,
  deletePlatformReview,
  getPlatformReview,
  patchPlatformReview,
  postPlatformReview,
} from 'modules/Admin/api';

interface IDeletePlatformReviewAction {
  platformId: string;
  reviewId: string;
}
interface IChangePlatformReviewAction {
  platformId: string;
  body: {
    date: string;
    text: string;
    author: string;
  };
  reviewId: string;
}

export const setPlatformReviewAction = createAsyncThunk<void, string>(
  'platformReviews/setPlatformReviewAction',
  (platformId) => getPlatformReview(platformId),
);

export const createPlatformReviewAction = createAsyncThunk<void, ICreatePlatformReview>(
  'platformReviews/createPlatformReviewAction',
  async (body, { dispatch }) => {
    await postPlatformReview(body);
    dispatch(setPlatformReviewAction(body.platformId));
  },
);

export const deletePlatformReviewAction = createAsyncThunk<void, IDeletePlatformReviewAction>(
  'platformReviews/deletePlatformReviewAction',
  async ({ reviewId, platformId }, { dispatch }) => {
    await deletePlatformReview(reviewId);
    dispatch(setPlatformReviewAction(platformId));
  },
);

export const changePlatformReviewAction = createAsyncThunk<void, IChangePlatformReviewAction>(
  'platformReviews/changePlatformReviewAction',
  async ({ reviewId, body, platformId }, { dispatch }) => {
    await patchPlatformReview({ reviewId, body });
    dispatch(setPlatformReviewAction(platformId));
  },
);
