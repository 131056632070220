import { authAxiosInstance } from 'modules/Auth/api';
import { endpoints } from 'api/endpoints';

interface IBodyOrderForm {
  solutionId: string;
  name: string;
  email: string;
  phone: string;
}

export const postOrderSolutionReady = (body: IBodyOrderForm) =>
  authAxiosInstance.post(endpoints.postOrderSolutionReady, body);

export const postOrderSolutionRevision = (body: IBodyOrderForm) =>
  authAxiosInstance.post(endpoints.postOrderSolutionRevision, body);
