import { TabsRoutes } from '../router/routes';

export enum AdminFilterPath {
  platforms = 'Платформы',
  filter = 'Справочник фильтров',
  createFilter = 'Создание платформы',
}

export enum FilterTabs {
  mainOptions = 'Основные настройки',
  filtersCategory = 'Категории фильтров',
}

export enum CreateFilterFieldsName {
  category = 'category',
  filterName = 'name',
  description = 'description',
  filterParametr = 'parametr',
}

export interface FiltersTabsList {
  id: number;
  tabName: FilterTabs;
  tabLink: TabsRoutes;
}

export enum CreateFilterFields {
  category = 'filterGroupId',
  filterName = 'filterName',
  descriptionLong = 'descriptionLong',
  parametr = 'filterItemName',
}

export enum PlatformsFilterFieldsNames {
  category = 'Категория',
  filterName = 'Наименование фильтра',
  description = 'Текст подсказки к фильтру',
  parametr = 'Параметры к фильтру',
}
