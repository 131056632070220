import * as Yup from 'yup';

import {
  URL_PATTERN,
  TEXT_PATTERN,
  EMAIL_PATTERN,
  USERNAME_PATTERN,
  REVIEWNAME_PATTERN,
  REVIEW_TEXT_PATTERN,
  DESCRIPTION_PATTERN,
  EVENT_PATTERN,
  SEARCH_USERS_PAGE_PATTERN,
  USERS_PAGE_DATE_PATTERN,
  IMAGE_NAME_PATTERN,
} from './regularExpressions';

export const passwordValidation = Yup.string()
  .matches(/^\S*$/, 'Пароль не может содержать символы пробела')
  .matches(/^[\x00-\x7F]*$/, 'Пароль не может содержать буквы кириллицы')
  .min(5, 'Число символов должно быть не менее 5')
  .matches(/[A-Z]/, 'Пароль должен содержать заглавную букву')
  .matches(/[a-z]/, 'Пароль должен содержать прописную букву')
  .matches(/\d/, 'Пароль должен содержать цифру')
  .matches(/[^a-zA-Z\d]/, 'Пароль должен содержать специальный символ')
  .required('Обязательное для заполнения поле');

export const textValidation = Yup.string()
  .matches(TEXT_PATTERN, 'Допускаются только буквы, цифры и "-"')
  .max(50, 'Число символов должно быть не более 50');

export const searchUsersPageValidation = Yup.string()
  .matches(EVENT_PATTERN, 'Название не может начинаться с пробела')
  .matches(SEARCH_USERS_PAGE_PATTERN, 'Введен недопустимый символ')
  .max(50, 'Число символов должно быть не более 50');

export const usersPageDateValidation = Yup.string().matches(USERS_PAGE_DATE_PATTERN, 'Ошибка');

export const textDescriptionValidation = Yup.string().matches(REVIEW_TEXT_PATTERN, 'Введен недопустимый символ');

export const textDescriptionValidationRequired = Yup.string()
  .matches(REVIEW_TEXT_PATTERN, 'Введен недопустимый символ')
  .required('Обязательное для заполнения поле');

export const emailValidation = Yup.string()
  .matches(EMAIL_PATTERN, 'Некорректный email')
  .min(4, 'Число символов должно быть не менее 4')
  .required('Обязательное для заполнения поле');

export const nameValidation = Yup.string()
  .matches(USERNAME_PATTERN, 'Некорректное имя')
  .min(2, 'Число символов должно быть не менее 2')
  .max(25, 'Число символов должно быть не более 25')
  .required('Обязательное для заполнения поле');

export const phoneValidation = Yup.string()
  .matches(/^\+\d{1}\s\d{3}\s\d{3}\s\d{4}$/, 'Некорректный формат номера телефона')
  .min(8, 'Номер телефона должен содержать не менее 8 цифр')
  .max(17, 'Номер телефона должен содержать не более 17 цифр')
  .required('Обязательное для заполнения поле');

export const reviewValidation = Yup.string()
  .matches(REVIEW_TEXT_PATTERN, 'Введите отзыв')
  .matches(EVENT_PATTERN, 'Название не может начинаться с пробела')
  .max(254, 'Число символов должно быть не более 255')
  .required('Обязательное для заполнения поле');

export const addEventValidation = Yup.string()
  .matches(EVENT_PATTERN, 'Название не может начинаться с пробела')
  .max(255, 'Число символов должно быть не более 255');

export const changeEventValidation = Yup.string()
  .matches(EVENT_PATTERN, 'Название не может начинаться с пробела')
  .required('Обязательное для заполнения поле')
  .max(255, 'Число символов должно быть не более 255');

export const namReviewValidation = Yup.string()
  .matches(REVIEWNAME_PATTERN, 'Некорректное имя')
  .min(2, 'Число символов должно быть не менее 2')
  .max(25, 'Число символов должно быть не более 25')
  .required('Обязательное для заполнения поле');

export const categoryNameValidation = Yup.string()
  .matches(DESCRIPTION_PATTERN, 'Введен недопустимый символ')
  .max(35, 'Число символов должно быть не более 35')
  .required('Обязательное для заполнения поле');

export const URLValidation = Yup.string().matches(URL_PATTERN, {
  excludeEmptyString: true,
  message: 'Введите корректный url-адрес',
});

export const videoDescriptionValidation = Yup.string()
  .matches(DESCRIPTION_PATTERN, 'Введен недопустимый символ')
  .min(2, 'Число символов должно быть не менее 2')
  .max(255, 'Число символов должно быть не более 255')
  .required('Обязательное для заполнения поле');

export const solutionPriceValidation = Yup.number()
  .integer('Стоимость должна быть целым числом')
  .positive('Стоимость должна быть положительной')
  .typeError('Обязательное для заполнения поле');

export const imageNameValidation = Yup.string()
  .matches(IMAGE_NAME_PATTERN, 'Введен недопустимый символ')
  .min(3, 'Число символов должно быть не менее 3')
  .max(50, 'Число символов должно быть не более 50')
  .required('Обязательное для заполнения поле');
