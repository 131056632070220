import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReview, IReviewsDetailsState } from 'modules/Admin/types/reviews';
import {
  changePlatformReviewAction,
  createPlatformReviewAction,
  deletePlatformReviewAction,
  setPlatformReviewAction,
} from 'store/actions';

const initialState: IReviewsDetailsState = {
  isLoading: false,
  error: '',
  success: '',
  data: [],
};

const platformReviewsSlice = createSlice({
  name: 'platformReviews',
  initialState,
  reducers: {
    clearErrorMessageReviewPlatform: (state) => {
      state.error = '';
    },
    clearSuccessMessageReviewPlatform: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPlatformReviewAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setPlatformReviewAction.fulfilled, (state, { payload }: PayloadAction<IReview[] | void>) => {
      state.isLoading = false;
      if (payload) {
        state.data = payload;
      }
    });
    builder.addCase(setPlatformReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при получении отзыва';
    });

    builder.addCase(createPlatformReviewAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Отзыв успешно опубликован!';
    });
    builder.addCase(createPlatformReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при создании отзыва';
    });

    builder.addCase(deletePlatformReviewAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Отзыв удален';
    });
    builder.addCase(deletePlatformReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при удалении отзыва';
    });

    builder.addCase(changePlatformReviewAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Отзыв успешно изменен!';
    });
    builder.addCase(changePlatformReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при изменении отзыва';
    });
  },
});

export const platformReviewsReducer = platformReviewsSlice.reducer;
export const { clearErrorMessageReviewPlatform, clearSuccessMessageReviewPlatform } = platformReviewsSlice.actions;
