import { useMatches } from 'react-router-dom';

import { useEffect, useState } from 'react';

import { Crumb } from './Crumb';
import styles from './styles.module.scss';

interface ICrumb {
  path: string;
  name: string;
}

interface CrumbData {
  crumb: {
    path: string;
    name: string;
  };
}

interface PathInfo {
  handle: CrumbData;
}

interface BreadCrumbsProps {
  lastCrumb?: string | ICrumb;
}

// eslint-disable-next-line react/prop-types
export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ lastCrumb }) => {
  const matches = useMatches();
  const [crumbs, setCrumbs] = useState<ICrumb[]>([]);

  useEffect(() => {
    const newCrumbs: ICrumb[] = (matches as PathInfo[])
      .filter((match: PathInfo) => Boolean(match.handle && match.handle.crumb))
      .map((data: PathInfo) => data.handle.crumb);

    if (typeof lastCrumb === 'object' && 'path' in lastCrumb && 'name' in lastCrumb) {
      newCrumbs.push(lastCrumb as ICrumb);
    }

    setCrumbs(newCrumbs);
  }, [matches, lastCrumb]);

  return (
    <nav className={styles.breadcrumbsWrapper}>
      <ul className={styles.breadcrumbs}>
        {crumbs.map(({ path, name }, idx) => {
          const isLastCrumb = idx === crumbs.length - 1;
          return <Crumb path={path} name={name} isLastCrumb={isLastCrumb} key={idx} />;
        })}
      </ul>
    </nav>
  );
};
