export const FAQ_CONTENT = [
  {
    question: 'Что такое чат-бот?',
    answer:
      'Программа, которая выясняет потребности пользователей, а затем помогает удовлетворить их. Автоматическое общение с пользователем ведется с помощью текста или голоса,программа, которая выясняет потребности пользователей, а затем помогает удовлетворить их. Автоматическое общение с пользователем ведется с помощью текста или голоса',
    id: 0,
  },
  {
    question: 'Для чего нужны чат-боты?',
    answer:
      'Программа, которая выясняет потребности пользователей, а затем помогает удовлетворить их. Автоматическое общение с пользователем ведется с помощью текста или голоса',
    id: 1,
  },
  {
    question: 'Что такое автоматизация маркетинга?',
    answer:
      'Мультиканальная платформа для создания чат- ботов с искусственным интеллектом и голосовых ботов. программа, которая выясняет потребности пользователей, а затем помогает удовлетворить их. Автоматическое общение с пользователем ведется с помощью текста или голоса',
    id: 2,
  },
  {
    question: 'Чем полезен чат-бот для бизнеса?',
    answer: 'Мультиканальная платформа для создания чат- ботов с искусственным интеллектом и голосовых ботов.',
    id: 3,
  },

  {
    question: 'Какой функционал может быть у бота?',
    answer: 'Мультиканальная платформа для создания чат- ботов с искусственным интеллектом и голосовых ботов.',
    id: 4,
  },
  {
    question: 'Как внедрить чат-бота в мессенджер? ',
    answer: 'Мультиканальная платформа для создания чат- ботов с искусственным интеллектом и голосовых ботов.',
    id: 5,
  },
  {
    question: 'Как же это все произошло, ведь не мало времени прошло?',
    answer: 'Я не научился жить один и у меня на это пять причин...',
    id: 6,
  },
  {
    question: 'Какие преимущества есть у чат-ботов?',
    answer: 'Мультиканальная платформа для создания чат- ботов с искусственным интеллектом и голосовых ботов.',
    id: 7,
  },
];
