import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { ExitProfileIcon } from 'assets';
import { logoutWithoutErrorAction, useAppDispatch } from 'store';

import styles from './styles.module.scss';

import { INavBar } from '../../ProfileLayout/config/routes';

export const NavBar = ({ routes }: { routes: INavBar[] }) => {
  const dispatch = useAppDispatch();

  const onLogout = () => dispatch(logoutWithoutErrorAction());
  const location = useLocation();

  const getPath = (to: string) => to.split('/')[0];

  return (
    <nav className={styles.nav}>
      {routes.map(({ to, icon: Icon, text }) => (
        <NavLink
          to={to}
          className={cn(styles.link, { [styles.active]: location.pathname.includes(getPath(to)) })}
          key={text}
        >
          <Icon width="24" height="24" />
          {text}
        </NavLink>
      ))}
      <button className={styles.link} type="button" onClick={onLogout}>
        <ExitProfileIcon width="24" height="24" />
        Выйти
      </button>
    </nav>
  );
};
