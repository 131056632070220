import cn from 'classnames';
import { OrderForm } from 'components';
import { setSolutionOrderFormReadyAction, setSolutionOrderFormRevisionAction } from 'store';

import styles from './styles.module.scss';

import buttonStyle from '../../styles/button.style.module.scss';

interface IProps {
  title: string;
  description: string;
  btnTitle: string;
  picturePath: string;
  titleForm: string;
  onBtnClick: () => void;
  isFormReady?: boolean;
  isFormRevision?: boolean;
}

export const OrderCard = ({
  title,
  description,
  picturePath,
  btnTitle,
  titleForm,
  onBtnClick,
  isFormReady,
  isFormRevision,
}: IProps) => {
  const orderBtnStyle = cn(buttonStyle.invertButton, styles.orderBtn);

  return (
    <div className={styles.orderCardWrapper}>
      <h4 className={styles.orderCardTitle}>{title}</h4>
      <div className={styles.orderCardImgWrapper}>
        <img src={picturePath} alt={title} />
      </div>
      <div className={styles.orderCardDescription}>{description}</div>
      <button className={orderBtnStyle} onClick={onBtnClick} type="button">
        {btnTitle}
      </button>
      {isFormReady && (
        <OrderForm titleForm={titleForm} closeModal={onBtnClick} onSubmitFunction={setSolutionOrderFormReadyAction} />
      )}
      {isFormRevision && (
        <OrderForm
          titleForm={titleForm}
          closeModal={onBtnClick}
          onSubmitFunction={setSolutionOrderFormRevisionAction}
        />
      )}
    </div>
  );
};
