import { getRole, useAppSelector } from 'store';
import { ROLES } from 'shared';

import { dashboardListSuperAdmin, dashboardListModerator, dashboardListAdmin } from '../../config';
import { DashboardList } from '../../components';

export const DashboardPage = () => {
  const role = useAppSelector(getRole);

  const dashboardlist = {
    [ROLES.SUPERADMIN]: dashboardListSuperAdmin,
    [ROLES.ADMIN]: dashboardListAdmin,
    [ROLES.MODERATOR]: dashboardListModerator,
    [ROLES.USER]: [],
  };

  return <DashboardList dashboardsList={dashboardlist[role as ROLES]} />;
};
