import { createSlice } from '@reduxjs/toolkit';
import { setSolutionOrderFormReadyAction, setSolutionOrderFormRevisionAction } from 'store/actions';

const initialState = {
  isLoading: false,
  error: '',
  success: '',
  data: [],
};

const solutionOrderFormSlice = createSlice({
  name: 'solutionOrderForm',
  initialState,
  reducers: {
    clearErrorMessageOrderForm: (state) => {
      state.error = '';
    },
    clearSuccessMessageOrderForm: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSolutionOrderFormReadyAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setSolutionOrderFormReadyAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Заказ на готовое решение успешно отправлен';
    });

    builder.addCase(setSolutionOrderFormReadyAction.rejected, (state, { error }) => {
      state.isLoading = false;
      const errorMessage = error?.message || 'Ошибка при получении заказа на готовое решение';
      state.error = errorMessage;
    });

    builder.addCase(setSolutionOrderFormRevisionAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setSolutionOrderFormRevisionAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Заказ на доработку готового решения успешно отправлен';
    });

    builder.addCase(setSolutionOrderFormRevisionAction.rejected, (state, { error }) => {
      state.isLoading = false;
      const errorMessage = error?.message || 'Ошибка при получении заказа на доработку готового решения';
      state.error = errorMessage;
    });
  },
});

export const solutionOrderFormReducer = solutionOrderFormSlice.reducer;
export const { clearErrorMessageOrderForm, clearSuccessMessageOrderForm } = solutionOrderFormSlice.actions;
