import { useWindowSize } from 'hooks';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { ROUTE } from 'router';
import { phoneIcon, StarIcon } from 'assets';
import { BurgerMenu, Logo, MenuNav, Profile } from 'components';
import { useState, useEffect } from 'react';
import {
  isModalProfileOpen,
  getUser,
  toggleOpen,
  useAppDispatch,
  useAppSelector,
  setIsModalProfileOpen,
  getRole,
  logoutWithoutErrorAction,
  setPlatformsDoNotBelongToFilters,
  setSolutionsDoNotBelongToFilters,
} from 'store';
import cnBind from 'classnames/bind';
import { LocalStorage, ROLES } from 'shared';
import { scrollPosition, LANGUAGES } from 'helpers';
import { DEFAULT_OFFSET } from 'styles/shared/variables';
import { UserCircle } from 'sharedComponents';

import styles from './styles.module.scss';

const cx = cnBind.bind(styles);

export const Header = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const { width } = useWindowSize();
  const { isAuth, firstName, email } = useAppSelector(getUser);
  const isOpenProfile = useAppSelector(isModalProfileOpen);
  const role = useAppSelector(getRole);
  const headerClasses = cx('header', { hide: !isOpen });

  const defaultLang = localStorage.getItem(LocalStorage.Language);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLang || 'ru');

  useEffect(() => {
    const handleStorageChange = () => {
      const lang = localStorage.getItem(LocalStorage.Language);
      if (lang) setSelectedLanguage(lang);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = event.target.value;
    localStorage.setItem(LocalStorage.Language, lang);
    window.dispatchEvent(new Event('storage'));
  };

  const handleLogout = () => {
    dispatch(logoutWithoutErrorAction());
    dispatch(setIsModalProfileOpen(false));
    dispatch(setPlatformsDoNotBelongToFilters(false));
    dispatch(setSolutionsDoNotBelongToFilters(false));
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    dispatch(toggleOpen());
  };
  useEffect(() => {
    let lastScroll = 0;
    const handleOpen = debounce(() => {
      if (scrollPosition() > lastScroll && scrollPosition() > DEFAULT_OFFSET) {
        setIsOpen(false);
      } else if (scrollPosition() < lastScroll) {
        setIsOpen(true);
      }
      lastScroll = scrollPosition();
    }, 100);
    window.addEventListener('scroll', handleOpen);
    return () => window.removeEventListener('scroll', handleOpen);
  }, []);

  return (
    <header className={`${headerClasses} containerWrapper`}>
      {width && width <= 768 ? (
        <div className={styles.header__wrapper}>
          <Logo />
          <BurgerMenu />
        </div>
      ) : (
        <>
          <div className={styles.subheader}>
            <div className={styles.phoneWrapper}>
              <img src={phoneIcon} alt="Phone logo" />
              <p>+375(29) 874-59-80</p>
            </div>
            {!isAuth ? (
              <div className={styles.linkWrapper}>
                <div className={styles.dropdown}>
                  <select
                    className={styles.lang}
                    value={selectedLanguage || LANGUAGES.ru}
                    onChange={handleLanguageChange}
                  >
                    <option value="ru">{LANGUAGES.ru}</option>
                    <option value="en">{LANGUAGES.en}</option>
                  </select>
                </div>
                <Link to={ROUTE.SIGN_IN} className={styles.signIn} type="button">
                  Вход
                </Link>
                <Link to={ROUTE.SIGN_UP} className={styles.signUp} type="button">
                  Регистрация
                </Link>
              </div>
            ) : (
              <div className={styles.linkWrapper}>
                <div className={styles.dropdown}>
                  <select
                    className={styles.lang}
                    value={selectedLanguage || LANGUAGES.ru}
                    onChange={handleLanguageChange}
                  >
                    <option value="ru">{LANGUAGES.ru}</option>
                    <option value="en">{LANGUAGES.en}</option>
                  </select>
                </div>
                {role === ROLES.USER && (
                  <Link
                    to={`${ROUTE.PROFILE}/${ROUTE.PROFILE_FAVORITES}/${ROUTE.PROFILE_FAVORITES_PLATFORMS}`}
                    className={styles.signUp}
                    type="button"
                  >
                    <StarIcon className={styles.star} /> Избранное
                  </Link>
                )}

                <button type="button" className={styles.button} onClick={handleClick} id="toggleModalButton">
                  <UserCircle />
                  {firstName || email}
                </button>
                {isAuth && isOpenProfile && <Profile onLogout={handleLogout} />}
              </div>
            )}
          </div>
          <MenuNav />
        </>
      )}
    </header>
  );
};
