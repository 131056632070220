import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  clearConfirmEmailMessages,
  clearModalConfirmEmail,
  confirmEmailAction,
  getConfirmEmail,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ErrorNotification, Loading, SuccessNotification } from 'sharedComponents';
import { ModalEmail } from 'modules/Profile/components';

import { SignInForm } from 'modules/Auth';

import { ROUTE } from 'router';

import styles from '../styles.module.scss';

import { logoIcon } from '../../../assets';

export const SignInPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showModal, emailForConfirm, isLoading, errorMessage, successMessage } = useAppSelector(getConfirmEmail);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (token) {
      dispatch(confirmEmailAction(token));
      navigate('/sign-in');
    }
  }, [token, dispatch, navigate]);

  const clearMessages = () => {
    dispatch(clearConfirmEmailMessages());
  };

  const closeModal = () => {
    dispatch(clearModalConfirmEmail());
  };

  return (
    <main className={styles.main}>
      <Link to={ROUTE.HOME} className={styles.logo}>
        <img src={logoIcon} className={styles.logo} alt="Townsend logo" />
      </Link>
      <div className={styles.auth}>
        {isLoading ? <Loading /> : <SignInForm />}
        {showModal && (
          <ModalEmail textStart="Ссылка для подтверждения email" closeModal={closeModal} email={emailForConfirm} />
        )}
        {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
        {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
      </div>
    </main>
  );
};
