import { routes } from 'components/Profile/config/routes';
import { menuListAdmin, menuListModerator, menuListSuperAdmin } from 'modules/Admin/config';
import { ROLES } from 'shared';

export const currentRoutesProfile = {
  [ROLES.SUPERADMIN]: menuListSuperAdmin,
  [ROLES.ADMIN]: menuListAdmin,
  [ROLES.MODERATOR]: menuListModerator,
  [ROLES.USER]: routes,
};

export const currentRoutesNavAdmin = {
  [ROLES.SUPERADMIN]: menuListSuperAdmin,
  [ROLES.ADMIN]: menuListAdmin,
  [ROLES.MODERATOR]: menuListModerator,
  [ROLES.USER]: [],
};
