import React, { useCallback, useEffect } from 'react';

import {
  UsersPageFilters,
  UsersPageHeader,
  UsersPagePagination,
  UsersPageTableHeader,
  UsersPageTableLines,
} from 'modules/Admin/components';
import {
  clearUsersPageMessages,
  getRolesErrorMessage,
  getUsersPageErrorMessage,
  getUsersPageIsLoading,
  getUsersPageResponse,
  getUsersPageSuccessMessage,
  setRolesAction,
  setUsersAction,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { ErrorNotification, Loading, LoadingMessage, SuccessNotification } from 'sharedComponents';

import styles from './styles.module.scss';

export const UsersPage = () => {
  const dispatch = useAppDispatch();
  const { countRows } = useAppSelector(getUsersPageResponse);
  const isLoading = useAppSelector(getUsersPageIsLoading);
  const errorMessage = useAppSelector(getUsersPageErrorMessage);
  const successMessage = useAppSelector(getUsersPageSuccessMessage);
  const errorRoles = useAppSelector(getRolesErrorMessage);

  useEffect(() => {
    dispatch(setUsersAction());
    dispatch(setRolesAction());
  }, [dispatch]);

  const clearMessages = useCallback(() => {
    dispatch(clearUsersPageMessages());
  }, [dispatch]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.usersPage}>
      <UsersPageHeader />
      <UsersPageFilters />
      <div className={styles.table}>
        <UsersPageTableHeader />
        {countRows ? (
          <UsersPageTableLines />
        ) : (
          <div className={styles.loadingError}>
            <LoadingMessage message="Нет пользователей по выбранным критериям" />
          </div>
        )}
      </div>
      {!!countRows && <UsersPagePagination />}
      {(errorMessage || errorRoles) && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
      {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
    </div>
  );
};
