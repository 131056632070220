import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ChangePlatformsFilter, createPlatformsFilter } from 'modules/Admin/api';

export const createPlatformsFilterAction = createAsyncThunk<void, ChangePlatformsFilter, { rejectValue: string }>(
  'adminPlatformsFilter/createPlatformsFilterAction',
  async (filter, { rejectWithValue }) => {
    try {
      return await createPlatformsFilter(filter);
    } catch (e) {
      const error = e as AxiosError;
      const { response } = error.request;
      const { message } = JSON.parse(response);
      return rejectWithValue(message);
    }
  },
);
