import { ReviewCardResp } from 'store';

import Slider from 'react-slick';

import { Review } from 'components/Review/Review';

import { useEffect, useState } from 'react';

import { useWindowSize } from 'hooks';

import { countSlidesPerPage, CustomSettingsResponsive } from 'helpers/countSlidesPerPage/countSlidesPerPage';

import { sliderSettings } from './sliderSettings';

import { SliderArrow, SliderArrowsDirection } from '../SliderArrow/SliderArrow';

export interface SolutionReviewListProps {
  reviews: ReviewCardResp[];
}

const customSettingResponsive: CustomSettingsResponsive = {
  breakpoint: 1200,
  slidesToShow: 2,
  responsive: {
    breakpoint: 700,
    slidesToShow: 1,
    responsive: null,
  },
};

export const SolutionReviewList = ({ reviews }: SolutionReviewListProps): JSX.Element => {
  const [slidesPerPage, setSlidesPerPage] = useState(1);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width && reviews.length > 0) {
      const calculatedSlides = countSlidesPerPage(width, reviews.length, customSettingResponsive, 3);
      setSlidesPerPage(Number.isFinite(calculatedSlides) ? calculatedSlides : 1);
    }
  }, [width, reviews.length]);

  return (
    <Slider
      {...sliderSettings}
      slidesToShow={slidesPerPage}
      nextArrow={<SliderArrow direction={SliderArrowsDirection.right} />}
      prevArrow={<SliderArrow direction={SliderArrowsDirection.left} />}
    >
      {reviews.map((review) => (
        <Review key={review.id} {...review} />
      ))}
    </Slider>
  );
};
