import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterItem, ParametrFilter, ParametrFilterItem } from 'store';
import { isIncluded } from 'helpers/isInclude';
import uuid from 'react-uuid';

interface IParametrFilterSlice {
  currentFilter: ParametrFilter;
  formState: Record<string, string | boolean>;
  options: ParametrFilterItem[];
  isSolutionsFilterLoading: boolean;
  solutionsFilterLoadingMessage: string;
  solutionsFilterSuccessMessage: string;
  solutionsFilterErrorMessage: string;
}

const initialState: IParametrFilterSlice = {
  currentFilter: {} as ParametrFilter,
  formState: {},
  options: [],
  isSolutionsFilterLoading: false,
  solutionsFilterLoadingMessage: '',
  solutionsFilterSuccessMessage: '',
  solutionsFilterErrorMessage: '',
};

const adminPlatformsFilterSlice = createSlice({
  name: 'adminPlatformsFilter',
  initialState,
  reducers: {
    deleteParametrOption: (state, { payload: optionId }: PayloadAction<string>) => {
      const options = state.options.filter(({ id }) => id !== optionId);
      state.options = options;
    },
    addParametrOption: (state, { payload: filterItemName }: PayloadAction<string>) => {
      if (!state.currentFilter.filterItemsForSolutions) {
        state.currentFilter.filterItemsForSolutions = [];
      }

      const options = state.options.map(({ filterItemName: option }) => option);
      const isAlreadyExist = isIncluded(options, filterItemName);

      if (isAlreadyExist) {
        const errorText = 'Опция с данным значением уже существует';
        const errorTextSecondary = 'Опция с данным значением уже существует';
        state.solutionsFilterErrorMessage =
          state.solutionsFilterErrorMessage === errorText ? errorTextSecondary : errorText;
        return;
      }

      const id = uuid();
      const option: ParametrFilterItem = { id, filterItemName };
      state.options = [...state.options, option];
      state.currentFilter = {
        ...state.currentFilter,
        filterItemsForSolutions: state.options,
      };
    },
    setDefaultOptions: (state, { payload }: PayloadAction<FilterItem[]>) => {
      state.currentFilter.filterItemsForSolutions = payload;
      state.options = payload;
    },
    clearErrorMessage: (state) => {
      state.solutionsFilterErrorMessage = '';
    },
  },
});

export const {
  reducer: adminPlatformsFilterReducer,
  actions: { deleteParametrOption, addParametrOption, setDefaultOptions, clearErrorMessage },
} = adminPlatformsFilterSlice;
