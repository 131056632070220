import { INPUTTYPE } from 'mocks';

import { endpoints } from './endpoints';

import { authAxiosInstance } from '../../Auth/api/authAxiosInstance';

export interface ChangePlatformsFilter {
  inputType: keyof typeof INPUTTYPE;
  topPriority: boolean;
  filterName: string;
  descriptionLong?: string;
  filterGroupId: string;
  filterItemsName: string[];
}

export const createPlatformsFilter = (newFilter: ChangePlatformsFilter): Promise<void> =>
  authAxiosInstance.post(endpoints.filtersForPlatforms, newFilter).then((data) => data.data);
