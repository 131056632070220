import { createSlice } from '@reduxjs/toolkit';
import { formatDateToDateTimeEnd, formatDateToDateTimeStart } from 'helpers';
import { UsersFiltration } from 'modules/Admin/config';
import {
  blockUserAction,
  changeUserRoleAction,
  deleteUserAction,
  deleteUsersAction,
  setUsersAction,
  unblockUserAction,
} from 'store/actions';
import { UsersPageState } from 'store/types';

const initialState: UsersPageState = {
  optionsChecked: [
    {
      filter: UsersFiltration.ROLE,
      options: [],
    },
    {
      filter: UsersFiltration.STATUS,
      options: [],
    },
  ],
  filtration: {
    createdAt: {
      min: null,
      max: null,
    },
    search: null,
    orderByItems: [],
  },
  activeFilter: '',
  isAllUsersChecked: false,
  usersChecked: [],
  pageNumber: 1,
  numberOfUsersPerPage: 15,
  response: {
    numberOfPages: 0,
    pageNumber: 0,
    numberOfUsersPerPage: 0,
    countRows: 0,
    rows: [],
  },
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};

const setLoading = (state: UsersPageState) => {
  state.isLoading = true;
  state.errorMessage = '';
  state.successMessage = '';
};

const usersPageSlice = createSlice({
  name: 'usersPage',
  initialState,
  reducers: {
    // обновить выбранные опции фильтра
    setFilterOptionsChecked: (state, { payload }) => {
      state.optionsChecked = state.optionsChecked.map((item) => {
        if (item.filter === payload.filter) {
          return {
            ...item,
            options: item.options.includes(payload.value)
              ? item.options.filter((option) => option !== payload.value)
              : [...item.options, payload.value],
          };
        }
        return item;
      });
    },
    // очистить выбранные опции фильтра
    clearFilterOptionsChecked: (state, { payload }) => {
      state.optionsChecked = state.optionsChecked.map((item) => {
        if (item.filter === payload) {
          return { ...item, options: [] };
        }
        return item;
      });
    },
    setFiltrationDates: (state, { payload }) => {
      state.filtration.createdAt = {
        min: payload.dateStart ? formatDateToDateTimeStart(payload.dateStart) : null,
        max: payload.dateEnd ? formatDateToDateTimeEnd(payload.dateEnd) : null,
      };
    },
    clearFiltrationDates: (state) => {
      state.filtration.createdAt = {
        min: null,
        max: null,
      };
    },
    setFiltrationOrderBy: (state, { payload }) => {
      state.filtration.orderByItems[0] = {
        column: payload.column,
        orderBy: payload.orderBy,
      };
    },
    clearFiltrationOrderBy: (state) => {
      state.filtration.orderByItems = [];
    },
    setFiltrationSearch: (state, { payload }) => {
      state.filtration.search = payload;
    },
    setActiveFilter: (state, { payload }) => {
      state.activeFilter = payload;
    },
    // выбрать всех пользоваталей. обновить isAllUsersChecked и usersChecked
    setAllUsersChecked: (state) => {
      state.isAllUsersChecked = true;
      const allUsersId = state.response.rows.map((user) => user.id);
      const updateArr = [...state.usersChecked];
      allUsersId.forEach((value) => {
        if (!updateArr.includes(value)) updateArr.push(value);
      });
      state.usersChecked = updateArr;
    },
    clearAllUsersChecked: (state) => {
      state.isAllUsersChecked = false;
      state.usersChecked = [];
    },
    clearArrayUsersChecked: (state) => {
      state.usersChecked = [];
    },
    // выбрать нужного пользоваталя; если выбраны все, обновить isAllUsersChecked
    setUserChecked: (state, { payload }) => {
      const arr = [...state.usersChecked];
      state.usersChecked = arr.includes(payload) ? arr.filter((item) => item !== payload) : [...arr, payload];
      state.isAllUsersChecked = state.usersChecked.length === state.response.countRows;
    },
    setPageNumber: (state, { payload }) => {
      state.pageNumber = payload;
    },
    setNumberOfUsersPerPage: (state, { payload }) => {
      state.numberOfUsersPerPage = payload;
    },
    setUsersPageErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    clearUsersPageMessages: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setUsersAction.pending, setLoading)
      .addCase(setUsersAction.fulfilled, (state, { payload }) => {
        if (payload) {
          state.response = payload;
        }
        state.isLoading = false;
      })
      .addCase(setUsersAction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload || 'Ошибка при получении пользователей';
      })

      .addCase(deleteUserAction.pending, setLoading)
      .addCase(deleteUserAction.fulfilled, (state) => {
        state.usersChecked = [];
        state.successMessage = 'Пользователь успешно удалён';
      })
      .addCase(deleteUserAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при удалении пользователя';
      })

      .addCase(deleteUsersAction.pending, setLoading)
      .addCase(deleteUsersAction.fulfilled, (state) => {
        state.usersChecked = [];
        state.successMessage = 'Выбранные пользователи успешно удалены';
      })
      .addCase(deleteUsersAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при удалении выбранных пользователей';
      })

      .addCase(blockUserAction.pending, setLoading)
      .addCase(blockUserAction.fulfilled, (state) => {
        state.successMessage = 'Пользователь успешно заблокирован';
      })
      .addCase(blockUserAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при блокировке пользователя';
      })

      .addCase(unblockUserAction.pending, setLoading)
      .addCase(unblockUserAction.fulfilled, (state) => {
        state.successMessage = 'Пользователь успешно разблокирован';
      })
      .addCase(unblockUserAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при разблокировке пользователя';
      })

      .addCase(changeUserRoleAction.pending, setLoading)
      .addCase(changeUserRoleAction.fulfilled, (state) => {
        state.successMessage = 'Успешное изменение роли пользователя';
      })
      .addCase(changeUserRoleAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при изменении роли пользователя';
      });
  },
});

export const {
  reducer: usersPageReducer,
  actions: {
    setFilterOptionsChecked,
    clearFilterOptionsChecked,
    setFiltrationDates,
    clearFiltrationDates,
    setFiltrationOrderBy,
    clearFiltrationOrderBy,
    setFiltrationSearch,
    setActiveFilter,
    setAllUsersChecked,
    clearAllUsersChecked,
    clearArrayUsersChecked,
    setUserChecked,
    setPageNumber,
    setNumberOfUsersPerPage,
    setUsersPageErrorMessage,
    clearUsersPageMessages,
  },
} = usersPageSlice;
