import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export const updateSolutionFilters = (newFilters: string[], solutionId: string): Promise<void> => {
  const updateData = {
    idFilterItems: newFilters,
  };
  return authAxiosInstance
    .put(`${endpoints.solutions}/${solutionId}/${endpoints.solutionFilters}`, updateData)
    .then((data) => data.data);
};
