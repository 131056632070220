import { Outlet } from 'react-router-dom';
import { Tabs } from 'modules/Admin/components';

import styles from './styles.module.scss';
import { profileFavoriteTabsList } from './config';

export const ProfileFavoriteLayout = () => (
  <div className={styles.wrapper}>
    <nav className={styles.tabsWrapper}>
      <Tabs tabs={profileFavoriteTabsList} />
    </nav>
    <Outlet />
  </div>
);
