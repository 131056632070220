import { IUsersAmount } from 'modules/Admin/types';

export const options: IUsersAmount[] = [
  { value: '15', label: '15' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
];

// стили для <Select/>
export const styleSelectAmountUsers = {
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #ECECEC',
    borderRadius: '8px',
    cursor: 'pointer',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    background: 'none',
  }),
};
