import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  UpdateCurrentFilterPlatform,
  updateCurrentFilterPlatform,
} from 'modules/Admin/api/updateCurrentFilterPlatform';

type UpdatePlatformFilterArgs = {
  id: string;
  updateData: UpdateCurrentFilterPlatform;
};

export const updatePlatformsFilterAction = createAsyncThunk<
  Promise<any>,
  UpdatePlatformFilterArgs,
  { rejectValue: string }
>('filters/updatePlatformsFilter', async ({ updateData, id }, { rejectWithValue }) => {
  try {
    if (id) {
      const data: UpdateCurrentFilterPlatform = {
        filterGroupId: updateData.filterGroupId,
        inputType: updateData.inputType,
        topPriority: updateData.topPriority,
        filterName: updateData.filterName,
        descriptionLong: updateData.descriptionLong,
        filterItemsName: updateData.filterItemsName,
      };

      return await updateCurrentFilterPlatform(data, id);
    }

    return rejectWithValue('Отсутствуют данные для редактирования фильтра');
  } catch (e) {
    const error = e as AxiosError<{ message: string }>;
    return rejectWithValue(error.response?.data?.message || 'Ошибка сохранения фильтра');
  }
});
