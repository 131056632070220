import {
  Characteristic,
  PlatformFilterItem,
  PlatformFilter,
  IUsersPageOrderByItem,
  ISolutionOptionWithoutIsActive,
  ISolutionOption,
  IUsersPageRowRole,
  ISolutionOptionWithoutPlatform,
} from 'store';
import { rolesRuEng } from 'shared';

import { FiltersIdStatus, IInfoUsersPages } from '../types';

const processFilterItems = (filterItems: PlatformFilterItem[], filtersId: FiltersIdStatus): void => {
  filterItems.forEach((filterItem) => {
    filtersId[filterItem.id] = filterItem.selected ? filterItem.id : false;
  });
};

const processPlatformFilters = (platformFilters: PlatformFilter[], filtersId: FiltersIdStatus): void => {
  platformFilters.forEach((platformFilter) => {
    processFilterItems(platformFilter.filterItemsForPlatforms, filtersId);
  });
};

export const createActiveFilters = (allFilters: Characteristic[]): FiltersIdStatus => {
  const filtersId: FiltersIdStatus = {};

  allFilters.forEach((filter) => {
    processPlatformFilters(filter.filtersForPlatforms, filtersId);
  });

  return filtersId;
};

// возвращает массив чисел от start до end
export const getArrFromStartToEnd = (start: number, end: number) => {
  const arr = [];
  for (let i = start; i <= end; i++) arr.push(i);
  return arr;
};

// возвращает массив предудущих и следующих номеров страниц
export const getArrPaginationPageNumbers = (pageNumber: number, numberOfPages: number) => {
  let arrPages = getArrFromStartToEnd(pageNumber - 1, pageNumber + 2);
  if (numberOfPages < 4) arrPages = getArrFromStartToEnd(1, numberOfPages);
  else if (pageNumber === 1) arrPages = getArrFromStartToEnd(1, 4);
  else if (pageNumber === numberOfPages) arrPages = getArrFromStartToEnd(numberOfPages - 3, numberOfPages);
  else if (pageNumber === numberOfPages - 1) arrPages = getArrFromStartToEnd(pageNumber - 2, pageNumber + 1);
  return arrPages;
};

// возвращает количество и номера пользователей на странице
export const getAmountUsersShown = (obj: IInfoUsersPages) => {
  const { pageNumber, numberOfPages, numberOfUsersPerPage, countRows } = obj;
  const start = (pageNumber - 1) * numberOfUsersPerPage + 1;
  const end = pageNumber !== numberOfPages ? start + numberOfUsersPerPage - 1 : countRows;
  return `${start}-${end} из ${countRows}`;
};

export const changeSortType = (title: string, { column, orderBy }: IUsersPageOrderByItem) => {
  // если нажали по уже отсортированной колонке, сортируем её другим типом, иначе 'ASC'
  if (title === column) {
    switch (orderBy) {
      case 'ASC':
        return 'DESC';
      case 'DESC':
        return '';
      default:
        return 'ASC';
    }
  }
  return 'ASC';
};

interface IGetDifferencesObjSolutions {
  initialValue: ISolutionOptionWithoutIsActive;
  updatedValue: ISolutionOptionWithoutPlatform;
  isActive: boolean;
  isActiveSolution: boolean;
}

export const getDifferencesObjSolution = ({
  initialValue,
  updatedValue,
  isActive,
  isActiveSolution,
}: IGetDifferencesObjSolutions): Partial<ISolutionOption> => {
  const objDifference: any = {};
  const { platform, ...solution } = initialValue;
  Object.keys(solution).forEach((key) => {
    const field = key as keyof ISolutionOptionWithoutPlatform;
    if (solution[field] !== updatedValue[field]) {
      objDifference[field] = updatedValue[field];
    }
  });
  const body = isActive !== isActiveSolution ? { ...objDifference, isActive: isActiveSolution } : objDifference;
  return body;
};

export const getRoleRu = (role: IUsersPageRowRole) => {
  const ruRole = Object.keys(rolesRuEng).find((key) => rolesRuEng[key] === role.role);
  return ruRole || '';
};
