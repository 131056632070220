import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { clearUpdateData, getUser, updateUserDateAction, useAppDispatch, useAppSelector } from 'store';
import { Loading } from 'sharedComponents';

import { passwordScheme } from '../../data';
import styles from '../../styles/styles.module.scss';
import { ChangePasswordType } from '../../types';
import { Input } from '../../UI';

export const ChangePasswordForm = () => {
  const dispatch = useAppDispatch();
  const { isLoading, updateIsValid } = useAppSelector(getUser);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<ChangePasswordType>({
    mode: 'onChange',
    resolver: yupResolver(passwordScheme),
  });

  const password = watch('password');
  const passwordConfirm = watch('passwordConfirm');
  const isPasswordValid = password === passwordConfirm && password !== '';

  const onSubmit: SubmitHandler<ChangePasswordType> = async (data) => {
    const objPassword = isPasswordValid ? { newPassword: data.password } : {};
    dispatch(updateUserDateAction(objPassword));
    dispatch(clearUpdateData());
    reset();
  };

  return isLoading ? (
    <Loading />
  ) : (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputGroupWrapper}>
        <Input register={register} id="password" label="Пароль" type="password" error={errors.password?.message} />
        <Input
          register={register}
          id="passwordConfirm"
          label="Подтвердите пароль"
          type="password"
          error={errors.passwordConfirm?.message}
        />
      </div>
      <button type="submit" className={styles.button} disabled={!updateIsValid && !isPasswordValid}>
        Сохранить изменения
      </button>
    </form>
  );
};
