import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  IUsersPageRow,
  deleteUserAction,
  getUsersPageUsersChecked,
  setUserChecked,
  blockUserAction,
  unblockUserAction,
  useAppDispatch,
  useAppSelector,
  getRole,
} from 'store';
import { ROLES } from 'shared';

import cn from 'classnames';

import { Checkbox, Modal } from 'sharedComponents';

import { BasketIcon } from 'modules/Admin/assets';

import { SwitchBtn, UsersPageChangeRole } from 'modules/Admin/components';

import { getRoleRu } from 'modules/Admin/utils/helpers';
import { formatDateTimeToDate } from 'helpers';
import { ArrowDownIcon } from 'assets';

import styles from './styles.module.scss';

interface IUsersPageTableLine {
  user: IUsersPageRow;
}

export const UsersPageTableLine: FC<IUsersPageTableLine> = ({ user }) => {
  const dispatch = useAppDispatch();
  const { id, isActive, firstName, role, email, lastName, createdAt } = user;
  const usersChecked = useAppSelector(getUsersPageUsersChecked);
  const userRole = useAppSelector(getRole);
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [openRoleSelector, setOpenRoleSelector] = useState(false);

  const isChecked = usersChecked.includes(id);
  const isSuperAdmin = userRole === ROLES.SUPERADMIN;
  const roleRef = useRef<HTMLDivElement>(null);

  const handleSwitch = () => {
    const action = isActive ? blockUserAction(id) : unblockUserAction(id);
    dispatch(action);
  };
  const handleClickDelete = () => {
    dispatch(deleteUserAction(id));
  };
  const handleClickCheckbox = () => {
    dispatch(setUserChecked(id));
  };
  const handleClickBasket = () => {
    setOpenModalDelete(true);
  };

  const openSelector = () => {
    setOpenRoleSelector(true);
  };
  const closeSelector = useCallback((e: any) => {
    e.stopPropagation();
    setOpenRoleSelector(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (roleRef.current && !roleRef.current.contains(event.target as Node)) {
        closeSelector(event);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [closeSelector]);

  const containerStyle = cn(styles.usersPageTableLine, {
    [styles.isSuperAdmin]: isSuperAdmin,
  });

  return (
    <div className={containerStyle}>
      {isSuperAdmin ? (
        <>
          <Checkbox text="" id={`checkbox_${id}`} checked={isChecked} onChange={handleClickCheckbox} />
          <div className={styles.role} onClick={openSelector} ref={roleRef}>
            <ArrowDownIcon className={styles.iconOpenRoles} />
            <p className={styles.roleText}>{getRoleRu(role)}</p>
            {openRoleSelector && (
              <UsersPageChangeRole userId={id} roleRu={getRoleRu(role)} closeSelector={closeSelector} />
            )}
          </div>
        </>
      ) : (
        <div className={styles.role}>
          <p className={styles.roleText}>{getRoleRu(role)}</p>
        </div>
      )}
      <p className={styles.name}>{firstName}</p>
      <p className={styles.surname}>{lastName}</p>
      <p className={styles.email}>{email}</p>
      <p className={styles.date}>{formatDateTimeToDate(createdAt)}</p>
      <div className={styles.status}>
        <SwitchBtn
          isToggled={isActive}
          setIsToggled={handleSwitch}
          id={`switch_${id}`}
          unActiveText="Неактивен"
          activeText="Активен"
        />
      </div>
      {isSuperAdmin && (
        <button type="button" onClick={handleClickBasket} className={styles.btnDelete}>
          <BasketIcon />
        </button>
      )}
      {isOpenModalDelete && (
        <Modal setIsActiveModal={(prev) => setOpenModalDelete(prev)} handleClick={handleClickDelete}>
          <p>Удалить пользователя?</p>
        </Modal>
      )}
    </div>
  );
};
