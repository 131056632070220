import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeSolutionEvent } from 'modules/Admin/api';
import { setSolutionEventsAction } from 'store';

export interface IChangeSolutionEventAction {
  solutionId: string;
  eventId: string;
  data: { text: string };
}

export const changeSolutionEventAction = createAsyncThunk<void, IChangeSolutionEventAction>(
  'solutionEvents/changeSolutionEventAction',
  async ({ solutionId, eventId, data }, { dispatch }) => {
    await changeSolutionEvent({ solutionId, eventId, body: data });
    dispatch(setSolutionEventsAction(solutionId));
  },
);
