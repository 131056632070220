import { UserStatus, UsersFiltration } from 'modules/Admin/config';
import { rolesRuEng } from 'shared';
import { IRole, IUsersPageFiltrationInState, IUsersPageOptionsChecked, IUsersPageOrderByItem } from 'store';

interface IConversionToRoleIds {
  roles: IRole[];
  optionsChecked: IUsersPageOptionsChecked[];
}

// преобразование массива ролей в массив ids
export const conversionToRoleIds = ({ optionsChecked, roles }: IConversionToRoleIds) => {
  const objRolesOptions = optionsChecked.find((item) => item.filter === UsersFiltration.ROLE);
  const optionsRoles = objRolesOptions?.options || [];

  const checkedRolesEng = optionsRoles.map((item) => rolesRuEng[item]);
  const filteredRoles = roles.filter((role) => checkedRolesEng.includes(role.role));
  return filteredRoles.map((role) => role.id);
};

// преобразование массива статусов в isActive
export const conversionToIsActive = (optionsChecked: IUsersPageOptionsChecked[]) => {
  const objStatusOptions = optionsChecked.find((item) => item.filter === UsersFiltration.STATUS);
  const optionsStatus = objStatusOptions?.options || [];

  let isActive = null;
  if (optionsStatus.length === 1 && optionsStatus[0] === UserStatus.ACTIVE) isActive = true;
  if (optionsStatus.length === 1 && optionsStatus[0] === UserStatus.INACTIVE) isActive = false;
  return isActive;
};

// если сортировка пустая, сортируем по роли
export const addSortUsers = (filtration: IUsersPageFiltrationInState) => {
  const sort: IUsersPageOrderByItem[] = [{ column: 'role', orderBy: 'ASC' }];
  const changedFiltration = {
    ...filtration,
    orderByItems: !filtration.orderByItems.length ? sort : filtration.orderByItems,
  };
  return changedFiltration;
};
