import { docNameTaskOrEventRequiredValidation, docNameTaskOrEventValidation } from 'shared';
import * as Yup from 'yup';

export const addEventSheme = Yup.object().shape({
  event: docNameTaskOrEventValidation,
});

export const changeEventSheme = Yup.object().shape({
  event: docNameTaskOrEventRequiredValidation,
});
