import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPlatformCards } from 'api';
import { GetPlatformsResponse, RootState } from 'store/types';

interface ISetPlatformsAction {
  amount: string;
  numberOfPerPage?: number;
}

export const setPlatformsAction = createAsyncThunk<GetPlatformsResponse, ISetPlatformsAction, { state: RootState }>(
  'platforms/setPlatformsAction',
  async ({ amount, numberOfPerPage }, { getState }) => {
    const state = getState();
    const { sortDirection } = state.platforms;
    const { activeFilters, prices } = state.platfromsPageFilters;
    const numberOfPlatformsPerPage =
      numberOfPerPage !== undefined ? numberOfPerPage : state.platforms.platforms.numberOfPlatformsPerPage;

    const obj = {
      amount,
      count: numberOfPlatformsPerPage,
      type: sortDirection,
      idFilterItems: activeFilters,
      priceMin: prices.priceMin,
      priceMax: prices.priceMax,
    };
    const response = await getPlatformCards(obj);
    return response;
  },
);
