import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUsersPageResponse, RootState } from 'store';
import { AxiosError } from 'axios';
import { getUsers } from 'modules/Admin/api';
import { addSortUsers, conversionToIsActive, conversionToRoleIds } from 'helpers';

export const setUsersAction = createAsyncThunk<IUsersPageResponse, void, { rejectValue: string; state: RootState }>(
  'usersPage/setUsersAction',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const { roles } = state.roles;
      const { filtration, pageNumber, numberOfUsersPerPage, optionsChecked } = state.usersPage;

      const roleIds = conversionToRoleIds({ optionsChecked, roles });
      const isActive = conversionToIsActive(optionsChecked);
      const changedFiltration = addSortUsers(filtration);
      const fullFiltration = { ...changedFiltration, roleIds, isActive };
      const usersResponse = await getUsers({ fullFiltration, pageNumber, numberOfUsersPerPage });
      return usersResponse;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
