import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteUser } from 'modules/Admin/api';
import { RootState, setUsersAction } from 'store';

export const deleteUserAction = createAsyncThunk<void, string, { state: RootState }>(
  'usersPage/deleteUserAction',
  async (id: string, { dispatch }) => {
    await deleteUser(id);
    dispatch(setUsersAction());
  },
);
