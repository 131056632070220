import { BreadCrumbs, SolutionTasksList } from 'components';

import { girl, StarIcon } from 'assets';

import { useParams } from 'react-router';
import {
  getError,
  getSolution,
  getSolutionIsLoading,
  getSolutionsFiltersSelector,
  getUser,
  setErrorMessage,
  setSolutionsFiltersAction,
  SolutionCharacteristicFilter,
  SolutionCharacteristicItemFilter,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { useEffect, useRef, useState } from 'react';

import { setSolutionDetailsAction, setSolutionFavoriteAction } from 'store/actions/setSolutionAction';

import cn from 'classnames';

import formatPrice from 'helpers/formatPrice';

import { ErrorNotification, Loading } from 'sharedComponents';

import { Link } from 'react-router-dom';

import Slider from 'react-slick';

import { SliderArrow, SliderArrowsDirection } from 'components/SliderArrow/SliderArrow';

import { SolutionEventsList } from 'components/SolutionEventsList/SolutionEventsList';

import { SolutionReviewList } from 'components/SolutionReviewList/SolutionReviewList';

import { OrderCards } from 'components/OrderCards/OrderCards';

import { CharacteristicsListItemSolution } from 'components/CharacteristicsListItemSolution/CharacteristicsListItemSolution';

import styles from './styles.module.scss';

import { sliderSettings } from './sliderSettings';

import buttonStyle from '../../styles/button.style.module.scss';

export const SolutionDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { solutionId } = useParams();
  const { isAuth, id, isShowFavoriteIcon } = useAppSelector(getUser);
  const [isMainOrderBtnClicked, setIsMainOrderBtnClicked] = useState(false);
  const { solution, solutionTasks, platform, characteristics, solutionEvents, reviews, files, solutionVideo } =
    useAppSelector(getSolution);
  const error = useAppSelector(getError);
  const isLoading = useAppSelector(getSolutionIsLoading);

  // characteristics
  const solutionFilters: SolutionCharacteristicFilter[] = useAppSelector(getSolutionsFiltersSelector);

  const [, setCharacteristics] = useState<SolutionCharacteristicFilter[]>([]);
  useEffect(() => {
    if (solutionId) {
      dispatch(setSolutionsFiltersAction());
    }
  }, [dispatch, solutionId]);

  useEffect(() => {
    setCharacteristics(characteristics);
  }, [characteristics]);

  const allSolutionCharacteristics = solutionFilters.map((item) => {
    const requiredProperties = {
      id: item.id,
      filterName: item.filterName,
      filterItemsForSolutions: [] as SolutionCharacteristicItemFilter[],
    };
    return requiredProperties;
  });

  characteristics.forEach((element) => {
    allSolutionCharacteristics.forEach((item) => {
      if (element.filterName === item.filterName) {
        item.filterItemsForSolutions = element.filterItemsForSolutions;
      }
    });
  });

  const orderBtn = useRef<HTMLElement>(null);

  useEffect(() => {
    if (solutionId) {
      dispatch(setSolutionDetailsAction(solutionId));
    }
  }, [dispatch, solutionId, id]);

  const { name, isFavorites, description, priceMin, priceMax } = solution;
  const { name: platformName, urlWebsite } = platform;

  const handleFavorite = () => {
    if (id && solutionId) {
      const setSolutionFavoritesProps = { isFavorite: isFavorites, solutionId };
      dispatch(setSolutionFavoriteAction(setSolutionFavoritesProps));
    }
  };

  const handleMainOrderBtn = () => {
    setIsMainOrderBtnClicked(true);
    orderBtn.current?.scrollIntoView(true);
  };

  const clearMessage = () => {
    dispatch(setErrorMessage(''));
  };

  const startIconClass = !isFavorites ? styles.starEmpty : '';

  const orderMainInfoBtnStyle = cn(buttonStyle.invertButton, styles.orderMainInfoBtn);

  const orderBtnStyle = cn(buttonStyle.invertButton, styles.orderBtn);

  const formattedPrice = formatPrice(priceMin);
  const formattedPriceMax = formatPrice(priceMax);

  const platformLinkName = platformName
    ? platformName[0].toUpperCase() + platformName.slice(1)
    : 'Неизвестная платформа';

  const orderSolutionContent = isMainOrderBtnClicked ? (
    <OrderCards />
  ) : (
    <button className={orderBtnStyle} type="button" onClick={handleMainOrderBtn}>
      Заказать решение
    </button>
  );

  return isLoading ? (
    <Loading />
  ) : (
    <main className={styles.section}>
      <div className={styles.solution}>
        <BreadCrumbs lastCrumb={{ name, path: `solution/${id}` }} />
        <div className={styles.wrapper}>
          <section className={styles.mainInfo}>
            <div className={styles.mainInfoHeader}>
              <h2 className={styles.title}>{name}</h2>
              {isAuth && isShowFavoriteIcon && (
                <button className={styles.favoriteBtn} type="button" onClick={handleFavorite}>
                  <StarIcon className={startIconClass} />В избранное
                </button>
              )}
            </div>
            <div className={styles.mainInfoBody}>
              <div className={styles.mainInfoBodyText}>
                <div className={styles.priceWrapper}>
                  <p className={styles.price}>от {formattedPrice} руб.</p>
                  <p className={styles.price}>до {formattedPriceMax} руб.</p>
                </div>
                <p className={styles.text}>{description}</p>
              </div>
              <div className={styles.mainInfoBodyButton}>
                <button className={orderMainInfoBtnStyle} type="button" onClick={handleMainOrderBtn}>
                  Заказать решение
                </button>
              </div>
            </div>
          </section>
          <section className={styles.tasks}>
            <div className={styles.tasksImg}>
              <img src={girl} alt="girl with laptop" />
            </div>
            <div className={styles.tasksOptions}>
              <h3 className={styles.tasksTitle}>Задачи</h3>
              <SolutionTasksList tasks={solutionTasks} />
            </div>
          </section>
          <section className={styles.platform}>
            <div className={styles.platformTitle}>
              <h2>
                Чат-бот на базе платформы &nbsp;
                <Link to={urlWebsite} target="blank" className={styles.platformLink}>
                  {platformLinkName}
                </Link>
              </h2>
            </div>
            <div className={styles.platformInfo}>
              <CharacteristicsListItemSolution
                key={id}
                filterGroupName="Характеристики"
                filtersForSolutions={allSolutionCharacteristics}
              />
              <div className={styles.plaformSlider}>
                <Slider
                  {...sliderSettings}
                  nextArrow={<SliderArrow direction={SliderArrowsDirection.right} centeredBtns />}
                  prevArrow={<SliderArrow direction={SliderArrowsDirection.left} centeredBtns />}
                >
                  {files.map(({ orderNum, file, description: title }) => (
                    <div key={orderNum} className={styles.imgWrapper}>
                      <img className={styles.stepImg} src={file?.url} alt="solution step" />
                      <span className={styles.imgTitle}>{title}</span>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </section>
          <section className={styles.tasksEvents}>
            <h3 className={styles.tasksEventsTitle}>Мероприятия по выполнению задач</h3>
            <SolutionEventsList events={solutionEvents} />
          </section>
          {solutionVideo && (
            <section className={styles.solutionVideoWrapper}>
              <iframe
                className={styles.solutionVideo}
                src={solutionVideo.url}
                allowFullScreen
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              />
              <div>{solutionVideo.description}</div>
            </section>
          )}
          {reviews.length > 0 && (
            <section className={styles.solutionReview}>
              <h3 className={styles.reviewTitle}>Отзывы</h3>
              <SolutionReviewList reviews={reviews} />
            </section>
          )}
          <section className={styles.orderSolution} ref={orderBtn}>
            <div className={styles.bottomOrderButtonWrapper}>{orderSolutionContent}</div>
          </section>
        </div>
      </div>
      {error && <ErrorNotification message={error} clearMessage={clearMessage} className={styles.notific} />}
    </main>
  );
};
