import {
  docDateRequiredValidation,
  docMessageOrReviewOrDescriptionRequiredValidation,
  docNameAndSurnameRequiredValidation,
} from 'shared';
import * as Yup from 'yup';

export const reviewFormSchema = Yup.object().shape({
  date: docDateRequiredValidation,
  author: docNameAndSurnameRequiredValidation,
  text: docMessageOrReviewOrDescriptionRequiredValidation,
});
