import { authAxiosInstance } from 'modules/Auth/api';
import { SolutionFilter, SolutionFilterItemAdmin } from 'store';

import { endpoints } from './endpoints';

export const getFiltersForSolutions = (): Promise<SolutionFilter[]> =>
  authAxiosInstance.get(endpoints.filtersForSolutions).then(({ data }) => data);

export const getFiltersForSolutionsAdmin = (): Promise<SolutionFilterItemAdmin[]> =>
  authAxiosInstance.get(endpoints.filtersForSolutionsAdmin).then(({ data }) => data);
