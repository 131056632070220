// имя или фамилия с одним дефисом или без него
export const DOC_NAME_OR_SURNAME_PATTERN = /^[a-zа-яё]+(?:-[a-zа-яё]+)?$/iu;

// имя с одним дефисом или без него, или имя и фамилия с дифисами или без
export const DOC_NAME_AND_SURNAME_PATTERN =
  /^(?:[a-zа-яё]+(?:-[a-zа-яё]+)?(?: [a-zа-яё]+(?:-[a-zа-яё]+)?)?|[a-zа-яё]+(?:-[a-zа-яё]+)?(?: [a-zа-яё]+(?:-[a-zа-яё]+)?)?)$/iu;

// почта
export const DOC_EMAIL_PATTERN = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

// ссылка
export const DOC_URL_PATTERN =
  /^$|^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

// номер телефона в формате +7 999 999 9999
export const DOC_PHONE_PATTERN = /^\+\d{1}\s\d{3}\s\d{3}\s\d{4}$/;

// номер телефона в формате +7 999 999 9999 или +7 ___ ___ ___
export const DOC_PHONE_EMPTY_PATTERN = /^\+\d{1}\s\d{3}\s\d{3}\s\d{4}$|^\+7\s_{3}\s_{3}\s_{4}$|^$/;

// telegram
export const DOC_TELEGRAM_PATTERN = /^@[A-Za-z0-9_]+|^$/;

// дата в диапазоне: от 01.01.2000 до 31.12.2099
export const DOC_DATE_PATTERN = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(2000|20[0-9][0-9])$|^$/;

// дата в формате 2024-05-08T21:00:00.000Z
export const DOC_FULL_DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$|^$/;

// строка состоит не только из пробелов
export const DOC_STRING_NOT_OF_SPACES_PATTERN = /^(?!\s+$).*$/;

// название платформы/решения, или поиск по ним
export const DOC_NAME_OR_SEARCH_PLATFORM_OR_SOLUTION_PATTERN = /^[а-яА-Яa-zA-Z0-9\s.()«»'"‘“’”&\-&$#]*$/;

// сообщение, отзыв, описание
export const DOC_MESSAGE_OR_REVIEW_OR_DESCRIPTION_PATTERN = /^[а-яА-Яa-zA-Z0-9\s.,!?()/\\+«»'"‘“’”:;&$#№<>-]*$/;

// название задачи/мероприятия
export const DOC_NAME_TASK_OR_EVENT_PATTERN = /^[а-яА-Яa-zA-Z0-9\s.,!?()/\-+«»'"‘“’”:;&$#№]*$/;

// поиск на странице пользователей
export const DOC_SEARCH_USERS_PAGE_PATTERN = /^[а-яА-Яa-zA-Z0-9@ ]*$/;

// название, параметр или категория фильтра
export const DOC_NAME_OR_PARAMETR_OR_CATEGORY_OF_FILTER = /^[а-яА-Яa-zA-Z0-9\s\-():/&$#№]*$/;
