import { Navigate, Outlet } from 'react-router-dom';

import { IUser } from 'store/types';

import { ROUTE } from 'router';
import { ROLES } from 'shared';

interface IProps {
  redirectPage?: string;
  user?: IUser;
}

export const ProtectedAdmin = ({ redirectPage = ROUTE.SIGN_IN, user }: IProps) => {
  const allowedRoles = [ROLES.SUPERADMIN, ROLES.ADMIN];
  const userRole = user?.role?.role;

  if (user?.isAuth && allowedRoles.includes(userRole as ROLES)) {
    return <Outlet />;
  }

  return <Navigate to={redirectPage} replace />;
};

export const ProtectedUpperUser = ({ redirectPage = ROUTE.SIGN_IN, user }: IProps) => {
  const allowedRoles = [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.MODERATOR];

  if (user?.isAuth && allowedRoles.includes(user?.role?.role as ROLES)) {
    return <Outlet />;
  }

  return <Navigate to={redirectPage} replace />;
};
