import {
  docMessageOrReviewOrDescriptionValidation,
  docNameOrParametrOrCategoryOfFilterRequiredValidation,
  docNameOrParametrOrCategoryOfFilterValidation,
} from 'shared';
import * as Yup from 'yup';

import { SolutionsFilterFormFields } from '../components/SolutionFilterOptions/config';

export const solutionsFilterScheme = Yup.object().shape({
  [SolutionsFilterFormFields.filterName]: docNameOrParametrOrCategoryOfFilterRequiredValidation,
  [SolutionsFilterFormFields.descriptionLong]: docMessageOrReviewOrDescriptionValidation,
  [SolutionsFilterFormFields.filterItemName]: docNameOrParametrOrCategoryOfFilterValidation,
});
