import { DescriptionIcon } from 'assets';
import { SolutionFilter, getSolutionsFiltersSelector, useAppSelector } from 'store';

import { useToogle } from 'hooks';
import { useParams } from 'react-router';

import { PopUpDescription } from 'modules';

import { InputTypes } from 'modules/Admin/config';

import { CheckItem } from '../CheckItem/CheckItem';

import styles from '../Filters/styles.module.scss';

import { ToggleButton } from '../ToggleButton/ToggleButton';

const VISIBLE_ITEMS = 4; // количество видимых элементов в группе

interface FiltersGroupProps {
  activeFilters: string[];
  filtersData: SolutionFilter;
  onClickAny: (event: any) => void;
}

export const SolutionFilters = ({ activeFilters, filtersData, onClickAny }: FiltersGroupProps) => {
  const solutionsFilters = useAppSelector(getSolutionsFiltersSelector);
  const { id, inputType, filterName, descriptionLong, filterItemsForSolutions } = filtersData;
  const filterItemsCount = filterItemsForSolutions.length;
  const toggleButtonThreshold = filterItemsCount > VISIBLE_ITEMS;

  const { id: solutionId } = useParams<{ id: string }>();
  const [isOpen, toggleOpen] = useToogle(false);

  const getHasActiveRadio = (filterId: string) => {
    const objFilter = solutionsFilters.find((item) => item.id === filterId);
    const arrFilterItems = objFilter?.filterItemsForSolutions.map((item) => item.id) || [];
    const hasActive = arrFilterItems.some((item) => activeFilters.includes(item));
    return hasActive;
  };

  return (
    <div className={styles.filterGroup}>
      <h6 className={styles.filterName}>
        {filterName}
        {descriptionLong && !solutionId && (
          <div className={styles.iconContainer}>
            <DescriptionIcon className={styles.icon} />
            <div className={styles.popUpBox}>
              <PopUpDescription name={filterName} text={descriptionLong} />
            </div>
          </div>
        )}
      </h6>
      <ul className={styles.filterOptions}>
        {filterItemsForSolutions.map((option, index) => {
          const checkItemThreshold = isOpen || index + 1 <= VISIBLE_ITEMS;
          const { id: optionId } = option;

          return (
            checkItemThreshold && (
              <CheckItem
                filterName={id}
                type={inputType as InputTypes.CHECKBOX | InputTypes.RADIO}
                filter={option}
                index={index}
                key={optionId}
                activeFilters={activeFilters}
                hasActiveRadio={getHasActiveRadio(id)}
                onClickAny={onClickAny}
              />
            )
          );
        })}
      </ul>
      {toggleButtonThreshold && (
        <ToggleButton moreItem={filterItemsCount - VISIBLE_ITEMS} isOpen={isOpen} onClick={toggleOpen} />
      )}
    </div>
  );
};
