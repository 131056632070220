import { useCallback } from 'react';
import { ChangeNameForm, ChangePasswordForm } from 'modules/Profile/components';
import { clearUserNotifications, getUser, useAppDispatch, useAppSelector } from 'store';
import { ErrorNotification, SuccessNotification } from 'sharedComponents';

import styles from './styles.module.scss';

export const ProfileSettingsPage = () => {
  const dispatch = useAppDispatch();
  const { successNotification, errorNotification } = useAppSelector(getUser);

  const clearMessages = useCallback(() => {
    dispatch(clearUserNotifications());
  }, [dispatch]);

  return (
    <div className={styles.settings}>
      <div className={styles.container}>
        <h5 className={styles.settings__title}>Личные данные</h5>
        <ChangeNameForm />
        <h5 className={styles.settings__title}>Пароль</h5>
        <ChangePasswordForm />
        {successNotification && <SuccessNotification message={successNotification} clearMessage={clearMessages} />}
        {errorNotification && <ErrorNotification message={errorNotification} clearMessage={clearMessages} />}
      </div>
    </div>
  );
};
