import { createAsyncThunk } from '@reduxjs/toolkit';
import { addSolutionInFavorites, deleteSolutionFromFavorites, getSolutionDetails } from 'api';
import { addSolutionInViewed } from 'api/addSolutionInViewed';
import { AxiosError } from 'axios';
import { ROLES } from 'shared';
import { RootState } from 'store';
import { SolutionDetailsResponse } from 'store/types/solution';

export interface SolutionFavoriteProps {
  solutionId: string;
  isFavorite: boolean;
}

export const setSolutionDetailsAction = createAsyncThunk<
  SolutionDetailsResponse,
  string,
  { rejectValue: string; state: RootState }
>('solution/setSolutionDetailsAction', async (solutionId: string, { rejectWithValue, getState }) => {
  try {
    const state = getState();
    const isUser = state.user.role?.role === ROLES.USER;
    if (isUser) addSolutionInViewed(solutionId);

    const solutionDetails = await getSolutionDetails(solutionId);
    return solutionDetails;
  } catch (e) {
    const error = e as AxiosError;
    return rejectWithValue(error.message);
  }
});

export const setSolutionFavoriteAction = createAsyncThunk<boolean, SolutionFavoriteProps, { rejectValue: string }>(
  'solution/setSolutionFavoriteAction',
  async ({ solutionId, isFavorite }, { rejectWithValue }) => {
    try {
      const toggleAction = isFavorite ? deleteSolutionFromFavorites : addSolutionInFavorites;
      await toggleAction(solutionId);
      const solutionDetails = await getSolutionDetails(solutionId);
      return solutionDetails.solution.isFavorites;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
