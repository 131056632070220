import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { createSolutionImage } from 'modules/Admin/api/createSolutionImage';
import { ICreateSolutionImageAction } from 'store/types/solutionImage';

export const createSolutionImageAction = createAsyncThunk<void, ICreateSolutionImageAction, { rejectValue: string }>(
  'image/CreateSolutionImage',
  async (imageData, { rejectWithValue }) => {
    try {
      return await createSolutionImage(imageData);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      return rejectWithValue(error.response?.data?.message || 'Ошибка добавления данных');
    }
  },
);
