import {
  useAppSelector,
  useAppDispatch,
  getSolutions,
  getIsSolutionsLoaderVisible,
  getSolutionsTotalPages,
  getSolutionsLoadingMessage,
  setSolutionSortDirection,
  getSortSolutionDirection,
} from 'store';
import { Pagination, Sort, BreadCrumbs, SolutionsList } from 'components';
import { SolutionsFiltrationBar } from 'modules';
import { useEffect } from 'react';
import { setSolutionsAction } from 'store/actions';
import { useSearchParams } from 'react-router-dom';
import { Loading, LoadingMessage } from 'sharedComponents';

import { SortingType } from 'modules/Admin/types/sortingType';

import styles from '../PlatformsPage/styles.module.scss';

export const SolutionsPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page') || '1';

  const solutions = useAppSelector(getSolutions);
  const totalPages = +useAppSelector(getSolutionsTotalPages);
  const sortOption = useAppSelector(getSortSolutionDirection);

  const isLoaderVisible = useAppSelector(getIsSolutionsLoaderVisible);
  const loadingMessage = useAppSelector(getSolutionsLoadingMessage);

  const reloadCardList = () => {
    const obj = { amount: currentPage };
    dispatch(setSolutionsAction(obj));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchParams(`page=${pageNumber}`);
    document.body.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSortChange = (newSortOption: SortingType) => {
    handlePageChange(1);
    dispatch(setSolutionSortDirection(newSortOption));
  };

  useEffect(() => {
    // проверка на то, что значение текущей страницы является числом  больше или равно 1 (при вводе номера страницы вручную в URL)
    if (Number.isNaN(+currentPage) || +currentPage < 1) {
      setSearchParams(`page=1`);
    } // eslint-disable-next-line
  }, [currentPage]);

  // проверка на то, что значение текущей страницы в пределах от 1 до максимального в соответствии с пагинацией (при вводе номера страницы вручную в URL)
  useEffect(() => {
    if (totalPages !== 1 && +currentPage > totalPages) {
      setSearchParams(`page=1`);
    } // eslint-disable-next-line
  }, [totalPages]);

  return (
    <section className={styles.section}>
      <div className={styles.contentWrapper}>
        <div className={styles.wrapper}>
          <BreadCrumbs />
          <h4>Решения</h4>
          <p>
            Решения - это готовый сценарий, реализованный с помощью одной или нескольких Платформ. Наличие готовых
            решений упрощает и ускоряет процесс выбора Платформ для дальнейшего взаимодействия.
          </p>
          <Sort onSortChange={handleSortChange} />
          <div className={styles.content}>
            <SolutionsFiltrationBar sortOption={sortOption} />
            <div className={styles.cardsWrapper}>
              {isLoaderVisible ? (
                <Loading />
              ) : (
                <>
                  <LoadingMessage message={loadingMessage} />
                  <SolutionsList key={sortOption} solutions={solutions} reloadCardList={reloadCardList} />
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={Number(currentPage)}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
