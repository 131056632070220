import { FilterGroup, IFilters, AdminPlatformFilter } from 'store/types';

import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export const getAllFilters = (): Promise<FilterGroup[]> =>
  authAxiosInstance.get(endpoints.filters).then(({ data }) => data);

export const getTopFilters = (): Promise<IFilters[]> =>
  authAxiosInstance.get(endpoints.topFilters).then(({ data }) => data);

export const getCurrentFilterForPlatform = (id: string): Promise<AdminPlatformFilter> =>
  authAxiosInstance.get(`${endpoints.filters}/${id}`).then(({ data }) => data);
