import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorNotification } from 'sharedComponents';

import { clearErrorUpdateToken, getUser, logoutAction, useAppDispatch, useAppSelector } from 'store';
import { Footer, Header } from 'components';
import { LocalStorage } from 'shared';

import styles from './styles.module.scss';

export const MainLayout = () => {
  const dispatch = useAppDispatch();
  const { errorUpdateToken } = useAppSelector(getUser);

  // если в LocalStorage.LogoutMessage записалась ошибка, вызывается logoutAction() для выхода из аккаунта, записи ошибки в slice, очистки localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const message = localStorage.getItem(LocalStorage.LogoutMessage);
      if (message) dispatch(logoutAction());
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [dispatch]);

  const clearMessage = () => {
    dispatch(clearErrorUpdateToken());
  };

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <Header />
        <div className={styles.outlet}>
          <Outlet />
        </div>
      </div>
      <Footer />
      {errorUpdateToken && <ErrorNotification message={errorUpdateToken} clearMessage={clearMessage} />}
    </div>
  );
};
