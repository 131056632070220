import { Fragment } from 'react';
import { IFiltersForPlatforms } from 'modules/Admin/types';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'sharedComponents';
import { INPUTTYPE } from 'mocks';
import { Radio } from 'components';

import styles from './styles.module.scss';

export const FilterGroup = ({ filtersForPlatforms, activeFilters, onChangeRadio }: IFiltersForPlatforms) => {
  const { register } = useFormContext();

  const hasActiveFilters = (filterItems: any) => filterItems.some((item: any) => !!activeFilters[item.id]);

  return (
    <div className={styles.filtersForPlatformsWrapper}>
      {filtersForPlatforms.map((filterForPlatform) => (
        <div key={filterForPlatform.id}>
          <div className={styles.checkboxGroup}>
            <div className={styles.filterName}>{filterForPlatform.filterName}</div>
            <div>
              {filterForPlatform.filterItemsForPlatforms.map((filterGroupItem, index) =>
                filterForPlatform.inputType === INPUTTYPE.CHECKBOX ? (
                  <Checkbox
                    key={filterGroupItem.id}
                    {...register(filterGroupItem.id)}
                    name={filterGroupItem.id}
                    text={filterGroupItem.filterItemName}
                    id={filterGroupItem.id}
                    value={filterGroupItem.id}
                  />
                ) : (
                  <Fragment key={filterGroupItem.id}>
                    {index === 0 && (
                      <Radio
                        text="Любое"
                        value=""
                        id={`default_${filterForPlatform.id}`}
                        isChecked={!hasActiveFilters(filterForPlatform.filterItemsForPlatforms)}
                        name={filterForPlatform.filterName}
                        onChange={() => onChangeRadio(filterForPlatform.filterItemsForPlatforms)}
                      />
                    )}
                    <Radio
                      isChecked={!!activeFilters[filterGroupItem.id]}
                      name={filterForPlatform.filterName}
                      text={filterGroupItem.filterItemName}
                      id={filterGroupItem.id}
                      value={filterGroupItem.id}
                      onChange={() => onChangeRadio(filterForPlatform.filterItemsForPlatforms, filterGroupItem.id)}
                    />
                  </Fragment>
                ),
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
