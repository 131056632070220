import { endpoints } from 'api/endpoints';
import { authAxiosInstance } from 'modules/Auth/api';
import { SolutionVideoType, UpdateSolutionVideoType } from 'store/types/solutionVideo';

export const updateSolutionVideoData = (videoData: SolutionVideoType): Promise<unknown> => {
  const { url, description, id } = videoData;
  const updatedVideoData: UpdateSolutionVideoType = {
    url,
    description,
  };
  return authAxiosInstance
    .patch(`${endpoints.solutions}/${id}/${endpoints.video}`, updatedVideoData)
    .then((data) => data.data);
};
