import { RootState } from 'store/types/types';

export const getUsersPageOptionsChecked = (state: RootState) => state.usersPage.optionsChecked;

export const getUsersPageFiltration = (state: RootState) => state.usersPage.filtration;

export const getUsersPageActiveFilter = (state: RootState) => state.usersPage.activeFilter;

export const getUsersPageIsAllUsersChecked = (state: RootState) => state.usersPage.isAllUsersChecked;

export const getUsersPageUsersChecked = (state: RootState) => state.usersPage.usersChecked;

export const getUsersPagePageNumber = (state: RootState) => state.usersPage.pageNumber;

export const getUsersPageNumberOfUsersPerPage = (state: RootState) => state.usersPage.numberOfUsersPerPage;

export const getUsersPageResponse = (state: RootState) => state.usersPage.response;

export const getUsersPageIsLoading = (state: RootState) => state.usersPage.isLoading;

export const getUsersPageErrorMessage = (state: RootState) => state.usersPage.errorMessage;

export const getUsersPageSuccessMessage = (state: RootState) => state.usersPage.successMessage;
