import { baseURL } from 'shared';

export const endpoints = {
  createPlatform: `${baseURL}/platforms`,
  platforms: 'platforms',
  filtersForPlatformsAdmin: 'filter-for-platforms/admin',
  deleteFilterForPlatformsAdmin: 'filter-for-platforms',
  viewedPlatforms: 'platforms/viewed',
  viewedSolutions: 'solutions/viewed',
  solutions: 'solutions',
  filtersForSolutions: 'filter-for-solutions',
  filtersForSolutionsAdmin: 'filter-for-solutions/admin',
  filtersForPlatforms: 'filter-for-platforms',
  video: 'video',
  photo: 'photo',
  groupFiltersForPlatform: 'filter-for-platforms/category',
  categoriesFiltersForPlatforms: 'filter-for-platforms/category',
  users: 'users',
  usersRole: 'users/role',
  roles: 'roles',
  task: 'task',
  solutionReview: 'solution-review',
  solutionFilters: 'filters',
  platformReview: 'platform-review',
};
