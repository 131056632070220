import { createSlice } from '@reduxjs/toolkit';
import { deleteSolutionImageByUrlAction, updateSolutionImageAction } from 'store/actions';
import { createSolutionImageAction } from 'store/actions/createSolutionImageAction';
import { setSolutionImageDataByIdAction } from 'store/actions/setSolutionImageDataByIdAction';

export interface ISolutionImageLine {
  orderNum: number;
  name: string;
  description: string;
  file: {
    id: string;
    url: string;
  } | null;
}

export interface SolutionImageState {
  solutionImageLines: ISolutionImageLine[];
  id: string;
  solutionId: string;
  name: string;
  description: string;
  isSolutionImageLoading: boolean;
  solutionImageSuccessMessage: string;
  solutionImageErrorMessage: string;
  solutionImageDeleteId: string;
  solutionImageDeleteName: string;
}

const initialState: SolutionImageState = {
  solutionImageLines: [],
  solutionId: '',
  id: '',
  name: '',
  description: '',
  isSolutionImageLoading: false,
  solutionImageSuccessMessage: '',
  solutionImageErrorMessage: '',
  solutionImageDeleteId: '',
  solutionImageDeleteName: '',
};

const solutionImageSlice = createSlice({
  name: 'solutionImage',
  initialState,
  reducers: {
    setSolutionImageDescription: (state, { payload }) => {
      state.description = payload;
    },
    setFileInfo: (state, { payload }) => {
      state.id = payload.slice(0, payload.indexOf('.'));
      state.name = payload;
    },
    setSolutionIdForImage: (state, { payload }) => {
      state.solutionId = payload;
    },
    setSolutionImageIdToDelete: (state, { payload }) => {
      state.solutionImageDeleteId = payload;
    },
    setSolutionImageNameToDelete: (state, { payload }) => {
      state.solutionImageDeleteName = payload;
    },
    resetMessages: (state) => {
      state.solutionImageSuccessMessage = '';
      state.solutionImageErrorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSolutionImageAction.pending, (state) => {
        state.isSolutionImageLoading = true;
        state.solutionImageSuccessMessage = '';
        state.solutionImageErrorMessage = '';
      })
      .addCase(createSolutionImageAction.fulfilled, (state) => {
        state.isSolutionImageLoading = false;
        state.solutionImageSuccessMessage = 'Данные успешно сохранены';
        state.solutionImageErrorMessage = '';
      })
      .addCase(createSolutionImageAction.rejected, (state) => {
        state.isSolutionImageLoading = false;
        state.solutionImageSuccessMessage = '';
        state.solutionImageErrorMessage = 'Ошибка сохранения данных';
      })
      .addCase(setSolutionImageDataByIdAction.pending, (state) => {
        state.isSolutionImageLoading = true;
      })
      .addCase(setSolutionImageDataByIdAction.fulfilled, (state, { payload }) => {
        state.isSolutionImageLoading = false;
        state.solutionImageLines = payload;
      })
      .addCase(setSolutionImageDataByIdAction.rejected, (state) => {
        state.isSolutionImageLoading = false;
        state.solutionImageSuccessMessage = '';
        state.solutionImageErrorMessage = 'Ошибка получения данных';
      })
      .addCase(deleteSolutionImageByUrlAction.pending, (state) => {
        state.isSolutionImageLoading = true;
        state.solutionImageSuccessMessage = '';
        state.solutionImageErrorMessage = '';
      })
      .addCase(deleteSolutionImageByUrlAction.fulfilled, (state) => {
        state.isSolutionImageLoading = false;
        state.solutionImageSuccessMessage = 'Данные успешно удалены';
        state.solutionImageErrorMessage = '';
      })
      .addCase(deleteSolutionImageByUrlAction.rejected, (state) => {
        state.isSolutionImageLoading = false;
        state.solutionImageErrorMessage = 'Ошибка удаления данных';
        state.solutionImageSuccessMessage = '';
      })
      .addCase(updateSolutionImageAction.pending, (state) => {
        state.isSolutionImageLoading = true;
        state.solutionImageSuccessMessage = '';
        state.solutionImageErrorMessage = '';
      })
      .addCase(updateSolutionImageAction.fulfilled, (state) => {
        state.isSolutionImageLoading = false;
        state.solutionImageSuccessMessage = 'Данные успешно обновлены';
        state.solutionImageErrorMessage = '';
      })
      .addCase(updateSolutionImageAction.rejected, (state) => {
        state.isSolutionImageLoading = false;
        state.solutionImageErrorMessage = 'Ошибка обновления данных';
        state.solutionImageSuccessMessage = '';
      });
  },
});

export const {
  reducer: solutionImageReducer,
  actions: {
    setSolutionImageDescription,
    setFileInfo,
    setSolutionIdForImage,
    setSolutionImageIdToDelete,
    setSolutionImageNameToDelete,
    resetMessages,
  },
} = solutionImageSlice;
