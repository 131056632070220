/* eslint-disable import/export */
import { createSlice } from '@reduxjs/toolkit';

interface IModalSolutionsState {
  categoriesMessage: string | null;
}

const initialState: IModalSolutionsState = {
  categoriesMessage: '',
};

const modalSolutionsSlice = createSlice({
  name: 'modalSolutions',
  initialState,
  reducers: {
    clearNotificationsSuccess: (state, { payload }) => {
      state.categoriesMessage = payload;
    },
    clearNotificationsError: (state, { payload }) => {
      state.categoriesMessage = payload;
    },
    clearNotifications: (state) => {
      state.categoriesMessage = null;
    },
  },
});

export const {
  reducer: modalSolutionsReducer,
  actions: { clearNotificationsSuccess, clearNotificationsError, clearNotifications },
} = modalSolutionsSlice;
