import { Tabs } from 'modules/Admin/components';
import { Outlet, useParams } from 'react-router';
import {
  clearSolutionOptionMessages,
  getSolutionOptionErrorMessage,
  getSolutionOptionSuccessMessage,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ErrorNotification, SuccessNotification } from 'sharedComponents';

import styles from './styles.module.scss';
import { solutionSettingsTabsList } from './config';

export const SolutionSettingsLayout = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const disabledTabs = id ? [] : solutionSettingsTabsList.map(({ id: tabId }) => tabId - 1);

  const errorMessage = useAppSelector(getSolutionOptionErrorMessage);
  const successMessage = useAppSelector(getSolutionOptionSuccessMessage);

  const clearMessages = () => {
    dispatch(clearSolutionOptionMessages());
  };

  return (
    <>
      <div className={styles.tabsWrapper}>
        <Tabs tabs={solutionSettingsTabsList} disabledTabs={disabledTabs} />
      </div>
      <Outlet />
      {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
    </>
  );
};
