import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export interface ICreateSolutionReview {
  solutionId: string;
  date: string;
  text: string;
  author: string;
}

export interface IPatchSolutionReview {
  reviewId: string;
  body: {
    date: string;
    text: string;
    author: string;
  };
}

export const postSolutionReview = (body: ICreateSolutionReview): Promise<string> =>
  authAxiosInstance.post(endpoints.solutionReview, body);

export const getSolutionReview = (solutionId: string) =>
  authAxiosInstance
    .get(endpoints.solutionReview, {
      params: {
        id: solutionId,
      },
    })
    .then(({ data }) => data);

export const deleteSolutionReview = (reviewId: string) =>
  authAxiosInstance.delete(`${endpoints.solutionReview}/${reviewId}`);

export const patchSolutionReview = ({ reviewId, body }: IPatchSolutionReview) =>
  authAxiosInstance.patch(`${endpoints.solutionReview}/${reviewId}`, body);
