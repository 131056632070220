import { ROUTE } from 'router';

export enum ProfileFavoriteTabs {
  platforms = 'Платформы',
  solutions = 'Решения',
}

export interface TabsList {
  id: number;
  tabName: ProfileFavoriteTabs;
  tabLink: ROUTE;
}

export const profileFavoriteTabsList: TabsList[] = [
  {
    id: 1,
    tabName: ProfileFavoriteTabs.platforms,
    tabLink: ROUTE.PROFILE_FAVORITES_PLATFORMS,
  },
  {
    id: 2,
    tabName: ProfileFavoriteTabs.solutions,
    tabLink: ROUTE.PROFILE_FAVORITES_SOLUTIONS,
  },
];
