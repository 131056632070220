import { authAxiosInstance } from 'modules/Auth/api';
import { SolutionCard } from 'store';

import { endpoints } from './endpoints';

import { SortingType } from '../types';

interface IGetAdminSolutions {
  name?: string;
  type: SortingType;
  doNotBelongToFilters: boolean;
}

export const getAdminSolutions = ({
  name,
  type = 'ASC',
  doNotBelongToFilters,
}: IGetAdminSolutions): Promise<SolutionCard[]> => {
  const query = {
    name: name || null,
    type,
    doNotBelongToFilters,
  };

  return authAxiosInstance
    .get(endpoints.solutions, {
      params: query,
    })
    .then(({ data }) => data);
};
