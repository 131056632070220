import { UseFormRegister, FieldError } from 'react-hook-form';
import cn from 'classnames';
import { ChangeEvent, KeyboardEvent, LegacyRef, forwardRef, useState } from 'react';
import { Warning } from 'sharedComponents';

import styles from './styles.module.scss';

export interface INumberInputProps {
  id: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  label?: string;
  value?: string | number;
  defaultValue?: string | number;
  error?: FieldError;
  inputPrefix?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'm' | '';
  warningAbsolute?: boolean;
  isDisabled?: boolean;
}

export const NumberInput = forwardRef(
  (
    {
      id,
      name,
      placeholder,
      label,
      register,
      defaultValue,
      error,
      inputPrefix = '',
      onChange,
      value,
      size = '',
      warningAbsolute,
      isDisabled,
      ...other
    }: INumberInputProps,
    ref: LegacyRef<HTMLInputElement> | undefined,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = () => {
      if (!isDisabled) setIsFocused(true);
    };

    const handleInputBlur = () => {
      if (!isDisabled) setIsFocused(false);
    };

    const handleKeyDown = (evt: KeyboardEvent) => {
      if (!/[0-9]/.test(evt.key) && (evt.key !== 'Backspace' || evt.keyCode !== 8)) {
        evt.preventDefault();
      }
    };

    const customInputStyle = cn(styles.customInput, {
      [styles.inputError]: error,
      [styles.inputActive]: isFocused,
      [styles.inputDisabled]: isDisabled,
      [styles[size]]: size,
    });

    const prefixStyle = cn(styles.prefix, {
      [styles.prefixHide]: !inputPrefix,
      [styles.prefixDisabled]: isDisabled,
    });
    const inputStyle = cn(styles.input, {
      [styles.inputWithPrefix]: inputPrefix,
    });
    return (
      <label htmlFor={id} className={styles.label}>
        {label && <span>{label}</span>}

        <div className={customInputStyle}>
          {inputPrefix && <span className={prefixStyle}>{inputPrefix}</span>}
          <input
            id={id}
            placeholder={placeholder}
            {...register(name)}
            className={inputStyle}
            defaultValue={defaultValue}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            type="number"
            ref={ref}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            value={value}
            disabled={isDisabled}
            {...other}
          />
        </div>

        {error?.message && <Warning message={error.message} absolute={warningAbsolute} />}
      </label>
    );
  },
);
