import { Outlet, useParams } from 'react-router-dom';
import { Tabs } from 'modules/Admin/components/Tabs/Tabs';
import {
  clearAdminPlatformMessages,
  getAdminPlatformErrorMessage,
  getAdminPlatformSuccessMessage,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ErrorNotification, SuccessNotification } from 'sharedComponents';

import { platformsTabsList } from './platforms';
import styles from './styles.module.scss';

export const AdminPlatform = () => {
  const dispatch = useAppDispatch();
  const { platformId } = useParams();

  const disabledTabs = platformId ? [] : [0, 1, 2];

  const errorMessage = useAppSelector(getAdminPlatformErrorMessage);
  const successMessage = useAppSelector(getAdminPlatformSuccessMessage);

  const clearMessages = () => {
    dispatch(clearAdminPlatformMessages());
  };

  return (
    <div className={styles.platformsWrapper}>
      <Tabs tabs={platformsTabsList} linkId={platformId} disabledTabs={disabledTabs} />
      <Outlet />
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
      {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
    </div>
  );
};
