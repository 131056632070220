import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { createSolutionsFilter, updateSolutionsFilter } from 'modules/Admin/api';

import { SolutionFilter } from 'store';

export const updateSolutionsFilterAction = createAsyncThunk<Promise<any>, SolutionFilter, { rejectValue: string }>(
  'filters/updateSolutionsFilter',
  async (filter, { rejectWithValue }) => {
    try {
      if (filter.id) {
        return await updateSolutionsFilter(filter);
      }

      return await createSolutionsFilter(filter).then((data) => data);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      return rejectWithValue(error.response?.data?.message || 'Ошибка сохранения фильтра');
    }
  },
);
