import { createSlice } from '@reduxjs/toolkit';
import { SortType } from 'modules/Admin/config/sort';
import {
  deleteAllSolutionsFromHistoryAction,
  deleteSelectedSolutionsFromHistoryAction,
  setSolutionsAction,
  setViewedSolutionsAction,
} from 'store/actions';
import { setSolutionsFavouritesAction } from 'store/actions/setFavoriteSolutionsAction';
import { SolutionsState } from 'store/types';
import { toggle } from 'helpers/toggle';
import { errorHandler } from 'helpers';

const initialState: SolutionsState = {
  solutions: {
    numberOfPages: '1',
    numberOfSolutionsPerPage: 24,
    rows: [],
    countRows: 0,
    pageNumber: 0,
    priceMin: 0,
    priceMax: 0,
  },
  favoriteSolutions: [],
  viewedSolutions: [],
  solutionsForDeletingFromViewed: [],
  isSelectMode: false,
  loadingState: 'idle',
  loadingMessage: '',
  isDeletingFromViewed: false,
  deleteFromHistorySuccess: '',
  deleteFromHistoryError: '',
  error: null,
  isLoaderVisible: true,
  sortSolutionKey: 'name',
  sortSolutionDirection: SortType.ACSENDING,
  pricesLimit: {
    priceMinLimit: 0,
    priceMaxLimit: 0,
  },
};

const solutionsSlice = createSlice({
  name: 'solutions',
  initialState,
  reducers: {
    setSolutionSortDirection: (state, { payload }) => {
      state.sortSolutionDirection = payload;
    },
    setSolutionsLoadingMessage: (state, { payload }) => {
      state.loadingMessage = payload;
    },
    toggleSolutionForDeletingFromViewed: (state, { payload: solutionId }) => {
      state.solutionsForDeletingFromViewed = toggle(state.solutionsForDeletingFromViewed, solutionId);
    },
    toggleSelectModeSolutions: (state) => {
      state.isSelectMode = !state.isSelectMode;
    },
    clearSolutionsForDeletingFromViewed: (state) => {
      state.isSelectMode = false;
      state.solutionsForDeletingFromViewed = [];
    },
    clearDeleteFromHistoryMessagesSolutions: (state) => {
      state.deleteFromHistorySuccess = '';
      state.deleteFromHistoryError = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSolutionsAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.isLoaderVisible = true;
        state.loadingMessage = '';
      })
      .addCase(setSolutionsAction.fulfilled, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.solutions.numberOfPages = payload.numberOfPages;
        state.solutions.numberOfSolutionsPerPage = payload.numberOfSolutionsPerPage;
        state.solutions.rows = payload.rows;
        state.solutions.countRows = payload.countRows;
        state.pricesLimit.priceMinLimit = payload.priceMin;
        state.pricesLimit.priceMaxLimit = payload.priceMax;
        if (payload.rows.length === 0) {
          state.loadingMessage = 'Нет решений';
        }
      })
      .addCase(setSolutionsAction.rejected, (state, { error, meta }) => {
        state.loadingState = meta.requestStatus;
        state.error = error;
        state.isLoaderVisible = false;
        state.loadingMessage = `${error.message}`;
      })
      .addCase(setViewedSolutionsAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.isLoaderVisible = true;
        state.loadingMessage = '';
      })
      .addCase(setViewedSolutionsAction.fulfilled, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.viewedSolutions = payload;
        if (payload.length === 0) {
          state.loadingMessage = 'Пусто';
        }
      })
      .addCase(setViewedSolutionsAction.rejected, (state, { error, meta }) => {
        state.loadingState = meta.requestStatus;
        state.error = error;
        state.loadingMessage = error.message || 'Ошибка запроса';
        state.isLoaderVisible = false;
      })
      .addCase(setSolutionsFavouritesAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        state.isLoaderVisible = true;
        state.loadingMessage = '';
      })
      .addCase(setSolutionsFavouritesAction.fulfilled, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.favoriteSolutions = payload;
      })
      .addCase(setSolutionsFavouritesAction.rejected, (state, { meta, payload, error }) => {
        state.loadingState = meta.requestStatus;
        state.error = error;
        state.loadingMessage = (payload as string) || 'Ошибка запроса';
        state.isLoaderVisible = false;
      })

      .addCase(deleteSelectedSolutionsFromHistoryAction.pending, (state) => {
        state.isDeletingFromViewed = true;
        state.loadingMessage = '';
      })
      .addCase(deleteSelectedSolutionsFromHistoryAction.fulfilled, (state) => {
        state.deleteFromHistorySuccess = 'Удаление выполнено успешно';
        state.isDeletingFromViewed = false;
      })
      .addCase(deleteSelectedSolutionsFromHistoryAction.rejected, (state, { payload }) => {
        if (payload) {
          state.deleteFromHistoryError = payload;
          state.isDeletingFromViewed = false;
        }
      })
      .addCase(deleteAllSolutionsFromHistoryAction.pending, (state) => {
        state.isDeletingFromViewed = true;
      })
      .addCase(deleteAllSolutionsFromHistoryAction.fulfilled, (state) => {
        state.deleteFromHistorySuccess = 'История просмотра решений успешно очищена';
        state.isDeletingFromViewed = false;
      })
      .addCase(deleteAllSolutionsFromHistoryAction.rejected, (state, { error }) => {
        state.deleteFromHistoryError = errorHandler(error) || 'История просмотра решений не была очищена';
        state.isDeletingFromViewed = false;
      });
  },
});

export const {
  reducer: solutionsReducer,
  actions: {
    setSolutionSortDirection,
    setSolutionsLoadingMessage,
    toggleSolutionForDeletingFromViewed,
    toggleSelectModeSolutions,
    clearSolutionsForDeletingFromViewed,
    clearDeleteFromHistoryMessagesSolutions,
  },
} = solutionsSlice;
