import { ChangeEvent, useEffect, useState } from 'react';
import { Input, Warning } from 'sharedComponents';
import { useForm } from 'react-hook-form';
import {
  FiltrationFormType,
  useAppDispatch,
  useAppSelector,
  getSolutionsPricesLimit,
  getPlatfromsPricesLimit,
  getPlatfromsPagePrices,
  getSolutionsPagePrices,
} from 'store';

import styles from './styles.module.scss';
import { updateCards, updatePricesSlice } from './config';

interface IPriceFilter {
  page: 'solutionsPage' | 'platformsPage';
  onPageChange?: (pageNumber: number) => void;
}

export const PriceFilter = ({ page, onPageChange }: IPriceFilter) => {
  const dispatch = useAppDispatch();

  const objFuncsGetPricesLimit = {
    solutionsPage: getSolutionsPricesLimit,
    platformsPage: getPlatfromsPricesLimit,
  };
  const objFuncsGetPrices = {
    solutionsPage: getSolutionsPagePrices,
    platformsPage: getPlatfromsPagePrices,
  };

  const { priceMinLimit, priceMaxLimit } = useAppSelector(objFuncsGetPricesLimit[page]);
  const defaultPrices = useAppSelector(objFuncsGetPrices[page]);

  const {
    register,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm<FiltrationFormType>({
    mode: 'onChange',
  });

  const [priceError, setPriceError] = useState('');
  const priceMin = watch('priceMin');
  const priceMax = watch('priceMax');

  const handleOnChangePrice = (fieldName: 'priceMin' | 'priceMax') => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    /** обрабатываем значения в формате "1e1 | 000001 | .0" убирая e и 0 */
    const newVal = Number.isNaN(parseFloat(value)) ? value : parseFloat(value);
    setValue(fieldName, `${newVal}`);
  };

  useEffect(() => {
    if (isDirty) {
      setPriceError('');
      updatePricesSlice({ page, priceMin, priceMax, dispatch });

      if (+priceMin < 0 || +priceMax < 0) {
        setPriceError('Значение не может быть отрицательным');
        return;
      }
      if (priceMax && +priceMin > +priceMax) {
        setPriceError('Минимальное значение больше максимального');
        return;
      }
      updateCards({ page, dispatch });
      if (onPageChange) onPageChange(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, priceMin, priceMax, page, dispatch]);

  useEffect(() => {
    if (defaultPrices.priceMin === '' && defaultPrices.priceMax === '') {
      setValue('priceMin', '');
      setValue('priceMax', '');
    }
  }, [defaultPrices, setValue]);

  return (
    <div>
      <p>Стоимость, ₽</p>
      <div className={styles.priceWrapper}>
        <Input
          id="priceMin"
          name="priceMin"
          register={register}
          Icon={<span className={styles.placeholder}>от</span>}
          iconStart
          onInput={handleOnChangePrice('priceMin')}
          placeholder={priceMinLimit}
        />
        <Input
          id="priceMax"
          name="priceMax"
          register={register}
          Icon={<span className={styles.placeholder}>до</span>}
          iconStart
          onInput={handleOnChangePrice('priceMax')}
          placeholder={priceMaxLimit}
        />
      </div>
      {priceError && <Warning message={priceError} />}
    </div>
  );
};
