import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteSolutionTask, getSolutionTask, patchSolutionTask, postSolutionTask } from 'modules/Admin/api';

interface ICreateSolutionTasksAction {
  solutionId: string;
  data: { text: string };
}
interface IDeleteSolutionTasksAction {
  solutionId: string;
  data: { text: string }[];
  taskId: string;
}
interface IChangeSolutionTasksAction {
  solutionId: string;
  taskId: string;
  data: { text: string };
}

export const setSolutionTasksAction = createAsyncThunk<void, string>(
  'solutionTasks/setSolutionTasksAction',
  (solutionId) => getSolutionTask(solutionId),
);

export const createSolutionTasksAction = createAsyncThunk<void, ICreateSolutionTasksAction>(
  'solutionTasks/createSolutionTasksAction',
  async ({ solutionId, data }, { dispatch }) => {
    await postSolutionTask({ solutionId, body: data });
    dispatch(setSolutionTasksAction(solutionId));
  },
);

export const deleteSolutionTasksAction = createAsyncThunk<void, IDeleteSolutionTasksAction>(
  'solutionTasks/deleteSolutionTasksAction',
  async ({ solutionId, taskId }, { dispatch }) => {
    await deleteSolutionTask(solutionId, taskId);
    dispatch(setSolutionTasksAction(solutionId));
  },
);

export const changeSolutionTasksAction = createAsyncThunk<void, IChangeSolutionTasksAction>(
  'solutionTasks/updateSolutionTasksAction',
  async ({ solutionId, data, taskId }, { dispatch }) => {
    await patchSolutionTask({ solutionId, taskId, body: data });
    dispatch(setSolutionTasksAction(solutionId));
  },
);
