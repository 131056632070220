import * as Yup from 'yup';
import {
  docFileValidation,
  docNamePlatformOrSolutionRequiredValidation,
  docURLRequiredValidation,
  docMessageOrReviewOrDescriptionRequiredValidation,
  docPriceRequiredValidation,
} from 'shared';

import { CreatePlatformFieldsName } from '../types/platformsTypes';

export const createPlatformScheme = Yup.object().shape({
  [CreatePlatformFieldsName.name]: docNamePlatformOrSolutionRequiredValidation,
  [CreatePlatformFieldsName.platfomLink]: docURLRequiredValidation,
  [CreatePlatformFieldsName.description]: docMessageOrReviewOrDescriptionRequiredValidation,
  [CreatePlatformFieldsName.downloadFile]: docFileValidation,
  [CreatePlatformFieldsName.priceFrom]: docPriceRequiredValidation,
  [CreatePlatformFieldsName.priceTo]: docPriceRequiredValidation,
});
