import { createAsyncThunk } from '@reduxjs/toolkit';

import { deleteCategoriesFiltersForPlatforms } from 'modules/Admin/api';

import { setAdminPlatformsCategoriesFiltersAction } from './setAdminPlatformsCategoriesFiltersAction';

interface IDeleteCategoriesForPlatforms {
  id: string;
  deleteFilters: boolean;
}

export const deleteCategoriesForPlatformsAction = createAsyncThunk<void, IDeleteCategoriesForPlatforms>(
  'adminPlatformsFiltersCategory/deleteCategoriesFiltersForPlatforms',
  async ({ id, deleteFilters }, { dispatch }) => {
    await deleteCategoriesFiltersForPlatforms(id, deleteFilters);
    dispatch(setAdminPlatformsCategoriesFiltersAction());
  },
);
