import { createSlice } from '@reduxjs/toolkit';
import { getMainOptionsAdminAction } from 'store/actions/platformMainOptions';

export interface IAdminPlatform {
  isLoading: boolean;
  errorMessage: string;
  successMessage: string;
  isActive: boolean;
}

const initialState: IAdminPlatform = {
  isLoading: false,
  errorMessage: '',
  successMessage: '',
  isActive: false,
};

const createPlatformSlice = createSlice({
  name: 'createPlatform',
  initialState,
  reducers: {
    toggleActivePlatform: (state) => {
      state.isActive = !state.isActive;
    },
    setIsPlafromActive: (state, { payload }) => {
      state.isActive = payload;
    },
    setAdminPlatformErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    setAdminPlatformSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    clearAdminPlatformMessages: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMainOptionsAdminAction.rejected, (state, { payload }) => {
      state.errorMessage = payload || '';
    });
  },
});

export const {
  reducer: adminPlatformReducer,
  actions: {
    toggleActivePlatform,
    setIsPlafromActive,
    setAdminPlatformErrorMessage,
    setAdminPlatformSuccessMessage,
    clearAdminPlatformMessages,
    setIsLoading,
  },
} = createPlatformSlice;
