import { yupResolver } from '@hookform/resolvers/yup';
import { isMultipleChoice } from 'helpers';
import { PlusIcon } from 'modules/Admin/assets';
import { FilterOptionItem } from 'modules/Admin/components';
import { solutionsFilterScheme } from 'modules/Admin/data/solutionsFilterScheme';
import { ISolutionFilterForm } from 'modules/Admin/types';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { Input, TextArea, Checkbox, Modal, ErrorNotification } from 'sharedComponents';
import {
  addOption,
  changeCurrentSolutionsFilter,
  clearCurrentSolutionsFilter,
  getCurrentSolutionsFilterSelector,
  getSolutionsFilterOptionsSelector,
  isSaveModalOpenSelector,
  getSolutionsFilterErrorSelector,
  setIsSaveModalOpen,
  setSolutionsFilterByIdAction,
  updateSolutionsFilterAction,
  useAppDispatch,
  useAppSelector,
  clearMessages,
  isSolutionFilterOptionPageRedirectSelector,
  cancelRedirect,
} from 'store';

import { SolutionsFilterFormFields } from './config';
import styles from './styles.module.scss';

interface EditModeProps {
  isEditMode?: boolean;
}

// eslint-disable-next-line react/prop-types
export const SolutionFilterOptions: React.FC<EditModeProps> = ({ isEditMode }) => {
  const navigate = useNavigate();
  const options = useAppSelector(getSolutionsFilterOptionsSelector);
  const isSaveModalOpen = useAppSelector(isSaveModalOpenSelector);
  const errorMessage = useAppSelector(getSolutionsFilterErrorSelector);

  const [value, setValue] = useState(false);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const currentFilter = useAppSelector(getCurrentSolutionsFilterSelector);
  const { filterName, inputType, descriptionLong, id: currentId } = currentFilter;
  const multiplicity = isMultipleChoice(inputType);
  const { id: filterId } = useParams();
  const dispatch = useAppDispatch();
  const isRedirect = useAppSelector(isSolutionFilterOptionPageRedirectSelector);

  const initialState = useMemo(
    () => ({
      filterName,
      ...(descriptionLong && { descriptionLong }),
      multiplicity,
    }),
    [filterName, descriptionLong, multiplicity],
  );

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    resetField,
    formState: { errors, isValid, isDirty },
  } = useForm<ISolutionFilterForm>({
    mode: 'onChange',
    defaultValues: useMemo(() => initialState, [initialState]),
    resolver: yupResolver(solutionsFilterScheme),
  });

  const isDisabled = !isDirty || !isValid || !options.length;

  const handleAddOption = () => {
    if (errors.filterItemName) return;

    const { filterItemName } = getValues();
    if (filterItemName) {
      dispatch(addOption(filterItemName));
      setValue(true);
    }
    resetField('filterItemName');
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };

  const handleSaveChanges = () => dispatch(updateSolutionsFilterAction(currentFilter));

  const onSubmit = () => {
    if (!isEditMode) {
      handleSaveChanges();
    } else {
      dispatch(setIsSaveModalOpen(true));
    }
  };

  const handleSaveModalOpen = (isOpen: boolean) => {
    dispatch(setIsSaveModalOpen(isOpen));
  };

  const handleQuit = () => {
    setCancelModalOpen(false);
    navigate(-1);
  };

  useEffect(() => {
    if (isRedirect) {
      navigate(-1);
    }
  }, [isRedirect, navigate]);

  useEffect(() => {
    dispatch(clearMessages());

    if (filterId) {
      dispatch(setSolutionsFilterByIdAction(filterId));
    }
    return () => {
      dispatch(clearCurrentSolutionsFilter());
      dispatch(cancelRedirect());
    };
    // eslint-disable-next-line
  }, [dispatch, filterId]);

  useEffect(() => {
    reset(initialState);
    // eslint-disable-next-line
  }, [currentId]);

  const handleKeyDownForm = (event: React.KeyboardEvent<HTMLFormElement>) => {
    // Предотвращает отправку формы
    if (event.key === 'Enter') event.preventDefault();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          dispatch(changeCurrentSolutionsFilter(getValues()));
        }}
        onKeyDown={handleKeyDownForm}
        className={styles.form}
      >
        <div className={styles.wrapper}>
          <div className={styles.itemWrapper}>
            <Input
              id={SolutionsFilterFormFields.filterName}
              name={SolutionsFilterFormFields.filterName}
              label="Наименование фильтра"
              register={register}
              error={errors[SolutionsFilterFormFields.filterName]?.message}
            />

            <TextArea
              id={SolutionsFilterFormFields.descriptionLong}
              name={SolutionsFilterFormFields.descriptionLong}
              label="Текст подсказки к фильтру"
              maxLength={255}
              defaultValue={initialState[SolutionsFilterFormFields.descriptionLong]}
              register={register}
              error={errors[SolutionsFilterFormFields.descriptionLong]?.message}
              className={styles.textArea}
            />
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.optionsInputWrapper}>
              <p>Параметры к фильтру</p>
              <div className={styles.options}>
                <Input
                  id={SolutionsFilterFormFields.filterItemName}
                  register={register}
                  error={errors[SolutionsFilterFormFields.filterItemName]?.message}
                  funcClickEnter={handleAddOption}
                />
                <div className={styles.plusIconWrapper} onClick={handleAddOption}>
                  <PlusIcon />
                </div>
              </div>
              <div className={styles.optionsWrapper}>
                {options.map(({ filterItemName, id }) => (
                  <FilterOptionItem
                    filterItemName={filterItemName}
                    id={id}
                    key={id}
                    setButtonActive={() => setValue(true)}
                  />
                ))}
              </div>
            </div>
            <ul className={styles.checkboxWrapper}>
              <Checkbox
                id={SolutionsFilterFormFields.multiplicity}
                text="Множественность выбора"
                {...register(SolutionsFilterFormFields.multiplicity)}
              />
            </ul>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <button className=" button buttonWithoutBorder" type="button" onClick={handleQuit}>
            Назад
          </button>
          <button className="button buttonSecondary" type="submit" disabled={value ? !options.length : isDisabled}>
            Сохранить
          </button>
        </div>
      </form>
      {isEditMode && (
        <>
          {isCancelModalOpen && (
            <Modal setIsActiveModal={setCancelModalOpen} submitButtonText="Выйти" handleClick={handleCancelModalOpen}>
              <p>Восстановить изменения будет невозможно. Вы уверены, что хотите выйти?</p>
            </Modal>
          )}
          {isSaveModalOpen && (
            <Modal setIsActiveModal={handleSaveModalOpen} handleClick={handleSaveChanges} submitButtonText="Сохранить">
              <p>Отменить изменения будет невозможно. Вы уверены, что хотите сохранить изменения?</p>
            </Modal>
          )}
        </>
      )}
      {errorMessage && <ErrorNotification message={errorMessage} className={styles.notific} />}
    </>
  );
};
