import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorHandler } from 'helpers';

import { updatePlatformsFilterAction } from 'store/actions/updateCurrentFilterPlatform';

import { AdminPlatformsFiltersSlice } from '../types';
import {
  createPlatformsFilterAction,
  deleteFilterPlatformAdminAction,
  setAdminPlatformsFiltersAction,
  setCurrentPlatformFilterAction,
} from '../actions';

const initialState: AdminPlatformsFiltersSlice = {
  filters: [
    {
      id: '',
      filterGroupName: '',
      filtersForPlatforms: [],
    },
  ],
  filtersGroupOptions: [],
  loadingState: 'idle',
  error: null,
  success: null,
  isLoaderVisible: false,
  currentFilter: null,
  loadingMessage: '',
  deleteFilterMessage: '',
  isDeleteFormOpen: false,
  reloadCurrentFilter: null,
};

const adminPlatformsFiltersSlice = createSlice({
  name: 'adminPlatformsFilters',
  initialState,
  reducers: {
    setDeleteFormOpen: (state, { payload }) => {
      state.isDeleteFormOpen = payload;
    },
    setCurrentFilter: (state, { payload }: PayloadAction<string>) => {
      state.filters.forEach((group) => {
        const { filterGroupName, id } = group;
        group.filtersForPlatforms.forEach((filter) => {
          if (filter.id === payload) {
            state.currentFilter = { ...filter, filterGroupName, filterGroupId: id };
          }
        });
      });
    },

    updateCurrentFilter: (state, { payload }) => {
      state.currentFilter = { ...state.currentFilter, ...payload };
    },

    clearCurrentFilter: (state) => {
      state.currentFilter = null;
      state.reloadCurrentFilter = null;
    },

    setFiltersGroupOptions: (state) => {
      state.filtersGroupOptions = state.filters.map(({ filterGroupName, id }) => ({ filterGroupName, id }));
    },

    setSuccessFilter: (state, { payload }) => {
      state.success = payload;
    },

    clearNotifications: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setAdminPlatformsFiltersAction.pending, (state, action) => {
        state.loadingState = action.meta.requestStatus;
        if (state.filters.length === 0) {
          state.isLoaderVisible = true;
        }
        state.loadingMessage = '';
      })
      .addCase(setAdminPlatformsFiltersAction.fulfilled, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.filters = payload;
        if (payload.length === 0) {
          state.loadingMessage = 'Фильтры не найдены';
        }
      })
      .addCase(setAdminPlatformsFiltersAction.rejected, (state, { error, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        const errorMessage = error?.message || 'Что-то пошло не так. Ошибка запроса';
        state.loadingMessage = errorMessage;
        state.error = error;
      })
      .addCase(deleteFilterPlatformAdminAction.fulfilled, (state) => {
        state.isDeleteFormOpen = false;
      })
      .addCase(deleteFilterPlatformAdminAction.rejected, (state, { error: e }) => {
        state.isDeleteFormOpen = true;
        state.deleteFilterMessage = errorHandler(e);
      })
      .addCase(updatePlatformsFilterAction.fulfilled, (state) => {
        state.isLoaderVisible = false;
        state.success = 'Фильтр успешно изменен!';
        state.error = null;
      })
      .addCase(updatePlatformsFilterAction.rejected, (state, { error, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.error = error.message || 'Что-то пошло не так. Ошибка запроса при редактировании фильтра.';
        state.success = null;
      })
      .addCase(createPlatformsFilterAction.fulfilled, (state) => {
        state.isLoaderVisible = false;
        state.success = 'Фильтр успешно создан!';
        state.error = null;
      })
      .addCase(createPlatformsFilterAction.rejected, (state, { payload, meta }) => {
        state.loadingState = meta.requestStatus;
        state.isLoaderVisible = false;
        state.error = payload!;
        state.success = null;
      })
      .addCase(setCurrentPlatformFilterAction.pending, (state) => {
        state.isLoaderVisible = true;
        state.error = '';
        state.success = '';
      })
      .addCase(setCurrentPlatformFilterAction.fulfilled, (state, { payload }) => {
        state.isLoaderVisible = false;
        state.reloadCurrentFilter = payload;
      })
      .addCase(setCurrentPlatformFilterAction.rejected, (state) => {
        state.isLoaderVisible = false;
        state.error = 'Что-то пошло не так. Ошибка получения данных о фильтре';
      });
  },
});

export const {
  reducer: adminPlatformsFiltersReducer,
  actions: {
    setDeleteFormOpen,
    setFiltersGroupOptions,
    setCurrentFilter,
    clearCurrentFilter,
    updateCurrentFilter,
    setSuccessFilter,
    clearNotifications,
  },
} = adminPlatformsFiltersSlice;
