const columns = [
  {
    title: 'Роль',
    class: 'role',
  },
  {
    title: 'Имя',
    class: 'name',
  },
  {
    title: 'Фамилия',
    class: 'surname',
  },
  {
    title: 'Почта',
    class: 'email',
  },
  {
    title: 'Дата',
    class: 'date',
  },
  {
    title: 'Статус',
    class: 'status',
  },
];

export default columns;
