import { AdminCategoriesList } from 'modules/Admin/components';
import { EditingModes } from 'shared/config/modes';
import { AdminCategoriesEdit } from 'modules/Admin/components/AdminCategoriesEdit/AdminCategoriesEdit';
import {
  clearActiveCategory,
  clearErrorMessageCategoryPlatform,
  clearSuccessMessageCategoryPlatform,
  getEditingCategoryMode,
  getPlatformCategoryErrorMessage,
  getPlatformCategorySuccessMessage,
  setEditingCategoryMode,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ErrorNotification, SuccessNotification } from 'sharedComponents';
import { useCallback, useEffect } from 'react';

import styles from './styles.module.scss';

export const PlatformFilterСategories = () => {
  const editingMode = useAppSelector(getEditingCategoryMode);
  const dispatch = useAppDispatch();
  const successMessage = useAppSelector(getPlatformCategorySuccessMessage);
  const errorMessage = useAppSelector(getPlatformCategoryErrorMessage);

  const handleSetCreateMode = () => {
    dispatch(clearActiveCategory());
    dispatch(setEditingCategoryMode(EditingModes.CREATE));
  };

  const clearMessages = useCallback(() => {
    dispatch(clearErrorMessageCategoryPlatform());
    dispatch(clearSuccessMessageCategoryPlatform());
  }, [dispatch]);

  const isEditMode = editingMode === EditingModes.EDIT;

  useEffect(
    () => () => {
      dispatch(setEditingCategoryMode(null));
      dispatch(clearActiveCategory());
    },
    [dispatch],
  );

  return (
    <div className={styles.wrapper}>
      {editingMode ? (
        <AdminCategoriesEdit isEditMode={isEditMode} />
      ) : (
        <div className={styles.buttonWrapper}>
          <button type="button" className="button buttonSecondary" onClick={() => handleSetCreateMode()}>
            Добавить категорию
          </button>
        </div>
      )}
      <AdminCategoriesList />
      {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessages} />}
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
    </div>
  );
};
