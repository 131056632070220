import { FC } from 'react';
import { SolutionFilterCharacteristic } from 'store';

import styles from '../CharacteristicItemFilter/styles.module.scss';

type CharacteristicItemProps = Omit<SolutionFilterCharacteristic, 'id'>;

export const CharacteristicItemFilterSolution: FC<CharacteristicItemProps> = ({
  filterName,
  filterItemsForSolutions,
}) => {
  const filtersList: string = filterItemsForSolutions?.length
    ? filterItemsForSolutions.reduce((acc, filter) => {
        acc += `${acc ? ', ' : ''}${filter.filterItemName}`;
        return acc;
      }, '')
    : 'Нет';
  return (
    <div className={styles.filterItemWrapper}>
      <div className={styles.name}>
        <div className={styles.filterGroupName}>{filterName}</div>
        <div className={styles.delimeterWrapper}>
          <div className={styles.delimeter} />
        </div>
      </div>
      <div className={styles.filters}>
        <span className={styles.filterName}>{filtersList}</span>
      </div>
    </div>
  );
};
