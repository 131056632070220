import {
  useAppSelector,
  useAppDispatch,
  getPlatforms,
  getIsLoaderVisible,
  getTotalPages,
  getLoadingMessage,
  getPlatformsPageSortDirection,
  setPLatformsPageSortDirection,
} from 'store';
import { PlatformList, Pagination, Sort, BreadCrumbs } from 'components';
import { FiltrationBar } from 'modules';
import { useEffect } from 'react';
import { setPlatformsAction } from 'store/actions';
import { useSearchParams } from 'react-router-dom';
import { Loading, LoadingMessage } from 'sharedComponents';

import { SortType } from 'modules/Admin/config';

import styles from './styles.module.scss';

export const PlatformsPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page') || '1';

  const platforms = useAppSelector(getPlatforms);
  const totalPages = +useAppSelector(getTotalPages);
  const sortOption = useAppSelector(getPlatformsPageSortDirection);

  const isLoaderVisible = useAppSelector(getIsLoaderVisible);
  const loadingMessage = useAppSelector(getLoadingMessage);

  const handlePageChange = (pageNumber: number) => {
    setSearchParams(`page=${pageNumber}`);
    document.body.scrollIntoView({ behavior: 'smooth' });
  };

  const reloadCardList = () => {
    const obj = { amount: currentPage };
    dispatch(setPlatformsAction(obj));
  };

  const handleSortChange = (newSortOption: SortType) => {
    handlePageChange(1);
    dispatch(setPLatformsPageSortDirection(newSortOption));
  };

  useEffect(() => {
    // проверка на то, что значение текущей страницы является числом  больше или равно 1 (при вводе номера страницы вручную в URL)
    if (Number.isNaN(+currentPage) || +currentPage < 1) {
      setSearchParams(`page=1`);
    } // eslint-disable-next-line
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // проверка на то, что значение текущей страницы в пределах от 1 до максимального в соответствии с пагинацией (при вводе номера страницы вручную в URL)
  useEffect(() => {
    if (totalPages !== 1 && +currentPage > totalPages) {
      setSearchParams(`page=1`);
    } // eslint-disable-next-line
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  return (
    <section className={styles.section}>
      <div className={styles.contentWrapper}>
        <div className={styles.wrapper}>
          <BreadCrumbs />
          <h4>Платформы</h4>
          <p>
            Платформа это конструктор чат-ботов, который помогает бизнесу автоматизировать процесс коммуникации с
            клиентами по продуманному сценарию. С помощью чат-бота можно отправлять новости, напоминания или обновления,
            обрабатывать заказы, обеспечивать поддержку клиентов круглосуточно и многое другое.
          </p>
          <Sort onSortChange={handleSortChange} />
          <div className={styles.content}>
            <FiltrationBar sortOption={sortOption} />
            <div className={styles.cardsWrapper}>
              {isLoaderVisible ? (
                <Loading />
              ) : (
                <>
                  <LoadingMessage message={loadingMessage} />
                  <PlatformList platforms={platforms} reloadCardList={reloadCardList} />
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={Number(currentPage)}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
