import { createSlice } from '@reduxjs/toolkit';
import { errorHandler } from 'helpers/errorHandler';
import {
  deleteSolutionsFilterAction,
  setSolutionsFiltersAction,
  setSolutionsFiltersAdminAction,
  setSolutonFiltersByIdAction,
  updateSolutionFiltersByIdAction,
} from 'store/actions';

import type { SolutionFilter, SolutionFilterItemAdmin } from '../types';

interface ISolutionsFiltersSlice {
  activeFilters: string[];
  radioFilter: string;
  solutionsFilters: SolutionFilter[];
  solutionFiltersAdmin: SolutionFilterItemAdmin[];
  topPriorityFilters: SolutionFilter[];
  isShowSolutionsFiltersLoader: boolean;
  isDeleteModalOpen: boolean;
  solutionsFiltersMessage: string;
  deleteSolutionsFilterMessage: string;
  solutionSuccessFiltersMessage: string;
  solutionErrorFiltersMessage: string;
  allSolutonFilters: SolutionFilter[];
}

const initialState: ISolutionsFiltersSlice = {
  activeFilters: [],
  radioFilter: '',
  allSolutonFilters: [],
  solutionFiltersAdmin: [],
  topPriorityFilters: [],
  solutionsFilters: [],
  isShowSolutionsFiltersLoader: false,
  isDeleteModalOpen: false,
  solutionsFiltersMessage: '',
  deleteSolutionsFilterMessage: '',
  solutionSuccessFiltersMessage: '',
  solutionErrorFiltersMessage: '',
};

const solutionsFiltersSlice = createSlice({
  name: 'solutionsFilters',
  initialState,
  reducers: {
    setIsDeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalOpen = payload;
    },
    changeActiveFilters: (state, { payload }) => {
      state.activeFilters = payload;
    },
    clearActiveFilters: (state) => {
      state.activeFilters = [];
    },
    addFilterToSolutionFilters: (state, { payload }) => {
      state.activeFilters = payload;
    },
    deleteFilterFromSolutionFilters: (state, { payload }) => {
      state.activeFilters = payload;
    },
    resetActiveFilters: (state) => {
      state.activeFilters = [];
    },
    setRadioFilter: (state, { payload }) => {
      state.radioFilter = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSolutionsFiltersAction.pending, (state) => {
        if (!state.solutionsFilters.length) {
          state.isShowSolutionsFiltersLoader = true;
        }
        state.solutionsFiltersMessage = '';
      })
      .addCase(setSolutionsFiltersAction.fulfilled, (state, { payload }) => {
        const filters = payload || [];

        state.isShowSolutionsFiltersLoader = false;
        state.solutionsFilters = filters;

        if (!state.solutionsFilters.length) {
          state.solutionsFiltersMessage = 'Для данного раздела фильтры отсутствуют';

          return;
        }
        state.solutionsFiltersMessage = '';
      })
      .addCase(setSolutionsFiltersAction.rejected, (state, { error }) => {
        state.isShowSolutionsFiltersLoader = false;
        state.solutionsFiltersMessage = errorHandler(error, 'Ошибка получения фильтров');
      })
      .addCase(setSolutionsFiltersAdminAction.pending, (state) => {
        if (!state.solutionFiltersAdmin.length) {
          state.isShowSolutionsFiltersLoader = true;
        }
        state.solutionsFiltersMessage = '';
      })
      .addCase(setSolutionsFiltersAdminAction.fulfilled, (state, { payload }) => {
        const solutionsFiltersAdmin = payload || [];
        state.isShowSolutionsFiltersLoader = false;

        state.solutionFiltersAdmin = solutionsFiltersAdmin;
        state.solutionsFiltersMessage = '';
      })
      .addCase(setSolutionsFiltersAdminAction.rejected, (state, { error }) => {
        state.isShowSolutionsFiltersLoader = false;
        state.solutionsFiltersMessage = errorHandler(error, 'Ошибка получения фильтров');
      })
      .addCase(deleteSolutionsFilterAction.fulfilled, (state) => {
        state.isDeleteModalOpen = false;
        state.deleteSolutionsFilterMessage = '';
      })
      .addCase(deleteSolutionsFilterAction.rejected, (state, { error }) => {
        state.deleteSolutionsFilterMessage = errorHandler(error, 'Ошибка удаления фильтра');
      })
      .addCase(setSolutonFiltersByIdAction.pending, (state) => {
        state.isShowSolutionsFiltersLoader = true;
        state.solutionSuccessFiltersMessage = '';
        state.solutionErrorFiltersMessage = '';
      })
      .addCase(setSolutonFiltersByIdAction.fulfilled, (state, { payload }) => {
        state.isShowSolutionsFiltersLoader = false;
        state.allSolutonFilters = payload;

        if (state.allSolutonFilters) {
          const setFilters = state.allSolutonFilters.flatMap((item) =>
            item.filterItemsForSolutions.map((filterItem) => filterItem.id),
          );
          state.activeFilters = setFilters;
          return;
        }
        state.activeFilters = [];
      })
      .addCase(setSolutonFiltersByIdAction.rejected, (state) => {
        state.isShowSolutionsFiltersLoader = false;
        state.solutionSuccessFiltersMessage = '';
        state.solutionErrorFiltersMessage = 'Ошибка получения фильтров';
      })
      .addCase(updateSolutionFiltersByIdAction.pending, (state) => {
        state.isShowSolutionsFiltersLoader = true;
        state.solutionSuccessFiltersMessage = '';
        state.solutionErrorFiltersMessage = '';
      })
      .addCase(updateSolutionFiltersByIdAction.fulfilled, (state) => {
        state.isShowSolutionsFiltersLoader = false;
        state.solutionSuccessFiltersMessage = 'Фильтры успешно сохранены';
        state.solutionErrorFiltersMessage = '';
      })
      .addCase(updateSolutionFiltersByIdAction.rejected, (state) => {
        state.isShowSolutionsFiltersLoader = false;
        state.solutionSuccessFiltersMessage = '';
        state.solutionErrorFiltersMessage = 'Ошибка сохранения фильтров';
      });
  },
});

export const {
  reducer: solutionsFiltersReducer,
  actions: {
    setIsDeleteModalOpen,
    changeActiveFilters,
    clearActiveFilters,
    addFilterToSolutionFilters,
    deleteFilterFromSolutionFilters,
    resetActiveFilters,
  },
} = solutionsFiltersSlice;
