import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { close } from 'assets';
import { ErrorNotification, Input, SuccessNotification } from 'sharedComponents';
import InputMask from 'react-input-mask';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'modules/Auth/data/orderFormScheme';
import { useForm } from 'react-hook-form';
import { WarningIcon } from 'modules/Auth/assets';
import { buttonStyles } from 'modules/Auth/UI';
import cn from 'classnames';
import { UserModalDataType } from 'api';
import FIELDS from 'components/OrderForm/config';
import { useParams } from 'react-router';
import {
  RootState,
  clearErrorMessageOrderForm,
  clearSuccessMessageOrderForm,
  getSolutionOrderFormErrorMessage,
  getSolutionOrderFormSuccessMessage,
  getUserEmail,
  getUserFirstName,
  isLoadingOrderForm,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { scrollAuto, scrollHidden } from 'helpers/scrollHiddenAndAuto';

import styles from '../ModalSolution/styles.module.scss';

export const OrderForm = ({
  closeModal,
  titleForm,
  onSubmitFunction,
}: {
  closeModal: () => void;
  titleForm: string;
  onSubmitFunction: ({
    solutionId,
    data,
  }: {
    solutionId: string;
    data: UserModalDataType;
  }) => ThunkAction<void, RootState, unknown, AnyAction>;
}) => {
  const { solutionId } = useParams();
  const [phoneInputFilled, setPhoneInputFilled] = useState(false);
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(getSolutionOrderFormErrorMessage);
  const successMessage = useAppSelector(getSolutionOrderFormSuccessMessage);
  const isLoading = useAppSelector(isLoadingOrderForm);
  const userFirstName = useAppSelector(getUserFirstName);
  const userEmail = useAppSelector(getUserEmail);

  const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = event.target.value.replace(/\D/g, '');
    setPhoneInputFilled(phoneNumber.length > 0);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<UserModalDataType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: UserModalDataType) => {
    dispatch(onSubmitFunction({ solutionId: solutionId as string, data }));
  };

  const { name, email, phone } = errors;

  const buttonClasses = cn(buttonStyles.button, buttonStyles.invertButton, styles.btn);
  const isSubmitDisabled = !isValid || !isDirty || isLoading;

  const clearMessages = useCallback(() => {
    dispatch(clearErrorMessageOrderForm());
    dispatch(clearSuccessMessageOrderForm());
  }, [dispatch]);

  const handleCloseModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    clearMessages();
    closeModal();
  };

  const handleClickOutside = (event: React.FormEvent<HTMLFormElement>) => {
    if (!(event.target as HTMLFormElement).closest(`.${styles.content}`) && (!isDirty || successMessage)) {
      clearMessages();
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside as unknown as EventListener);
    scrollHidden();
    if (successMessage) {
      scrollAuto();
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside as unknown as EventListener);
      scrollAuto();
    };
  });

  return (
    <div>
      {successMessage ? (
        <SuccessNotification message={successMessage} />
      ) : (
        <div className={styles.modal}>
          {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
          <div className={styles.content}>
            <button type="button" onClick={handleCloseModal}>
              <img className={styles.picture} src={close} alt="close" />
            </button>
            <h3 className={styles.title}>
              Заполните форму, чтобы оформить заказ <br />
              {titleForm}
            </h3>
            <form className={styles.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.main}>
                <div className={`${styles.wrap} ${styles['wrap-order']}`}>
                  <p className={styles.text}>
                    Имя<span className={styles.star}>*</span>
                  </p>
                  <p className={styles.text}>
                    Email<span className={styles.star}>*</span>
                  </p>
                  <p className={styles.text}>
                    Номер телефона<span className={styles.star}>*</span>
                  </p>
                </div>
                <div className={styles.item}>
                  <Input
                    register={register}
                    id={FIELDS.NAME}
                    error={name?.[FIELDS.MESSAGE]}
                    className={styles.input}
                    defaultValue={userFirstName ?? ''}
                  />
                  <Input
                    register={register}
                    id={FIELDS.EMAIL}
                    error={email?.[FIELDS.MESSAGE]}
                    className={styles.input}
                    defaultValue={userEmail ?? ''}
                  />
                  <div className={styles.container}>
                    <InputMask
                      mask="+7 999 999 9999"
                      maskChar="_"
                      {...register('phone')}
                      id={FIELDS.PHONE}
                      placeholder="+7 ___ ___ ____"
                      className={errors.phone ? `${styles.inputMask} ${styles.errorPhone}` : styles.inputMask}
                      onBlur={handlePhoneInputChange}
                    />
                    {errors.phone && phoneInputFilled && (
                      <span className={styles.error}>
                        <WarningIcon /> {phone?.[FIELDS.MESSAGE]}
                      </span>
                    )}
                  </div>
                  <button className={buttonClasses} type="submit" disabled={isSubmitDisabled}>
                    Отправить
                  </button>
                  <p className={styles.agreement}>
                    Нажимая кнопку, Вы соглашаетесь с условиями&nbsp;
                    <a href="https://example.com" className={styles.license}>
                      лицензионного договора, политикой конфиденциальности&nbsp;
                    </a>
                    и предоставляете согласие на обработку персональных данных
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
