import React, { useState } from 'react';
import { changeUserRoleAction, getRolesState, useAppDispatch, useAppSelector } from 'store';

import { Radio } from 'components';
import { ArrowUpIcon } from 'assets';
import { rolesRuEng } from 'shared';

import { rolesChangeRu } from './config';
import styles from './styles.module.scss';

interface IUsersPageChangeRole {
  userId: string;
  roleRu: string;
  closeSelector: (e: any) => void;
}

export const UsersPageChangeRole = ({ userId, roleRu, closeSelector }: IUsersPageChangeRole) => {
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector(getRolesState);
  const [newRoleRu, setNewRoleRu] = useState(roleRu);

  const handleClickApply = () => {
    const roleObj = roles.find((item) => item.role === rolesRuEng[newRoleRu]);
    const roleId = roleObj?.id;
    if (roleId) dispatch(changeUserRoleAction({ userId, roleId }));
  };

  return (
    <div className={styles.usersPageChangeRole}>
      <div className={styles.iconClose} onClick={closeSelector}>
        <ArrowUpIcon />
      </div>
      <div className={styles.content}>
        <div className={styles.options}>
          {rolesChangeRu.map((item) => (
            <Radio
              name="role"
              key={item}
              text={item}
              onChange={() => setNewRoleRu(item)}
              defaultChecked={item === roleRu}
            />
          ))}
        </div>
        <button
          className="button buttonSecondary"
          type="submit"
          onClick={handleClickApply}
          disabled={roleRu === newRoleRu}
        >
          Применить
        </button>
      </div>
    </div>
  );
};
