import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAllPlatformsFromHistory } from 'modules';

import { setViewedPlatformsAction } from './setViewedPlatformsAction';

export const deleteAllPlatformsFromHistoryAction = createAsyncThunk<Promise<void>>(
  'adminPlatforms/deleteAllPlatformsFromHistoryAction',
  async (_, { dispatch }) => {
    await deleteAllPlatformsFromHistory();
    dispatch(setViewedPlatformsAction());
  },
);
