import { createSlice } from '@reduxjs/toolkit';
import { EditingModes } from 'shared/config/modes';
import { errorHandler } from 'helpers/errorHandler';
import { AdminPlatformsCategoriesFiltersResponse } from 'store';

import { createCategoriesForPlatformsAction } from 'store/actions/createCategoriesForPlatformsAction';

import { deleteCategoriesForPlatformsAction } from '../actions/deleteCategoriesForPlatformsAction';
import { setAdminPlatformsCategoriesFiltersAction } from '../actions/setAdminPlatformsCategoriesFiltersAction';
import { editCategoriesForPlatformsAction } from '../actions/editCategoriesForPlatformsAction';

interface IPlatformsFiltersCategorySlice {
  isShowCategoryLoader: boolean;
  editingMode: EditingModes | null;
  activeCategoryId: string | null;
  isSaveModalOpen: boolean;
  activeCategory: AdminPlatformsCategoriesFiltersResponse | null;
  solutionsFilterLoadingMessage: string;
  categoriesForFiltersPlatforms: AdminPlatformsCategoriesFiltersResponse[];
  categoryLoadingMessage: string;
  isCategoriesPlatformIsDeleteModalOpen: boolean;
  editCategorySuccessMessage: string;
  editCategoryErrorMessage: string;
  deleteCategoryMessage: string | null;
  createCategorySuccessMessage: string;
  createCategoryErrorMessage: string;
  categoriesMessage: string;
  deletPlatformCategory: AdminPlatformsCategoriesFiltersResponse[];
  errorMessage: string;
  successMessage: string;
}

const initialState: IPlatformsFiltersCategorySlice = {
  solutionsFilterLoadingMessage: '',
  activeCategoryId: null,
  editingMode: null,
  isSaveModalOpen: false,
  categoriesForFiltersPlatforms: [],
  activeCategory: null,
  categoryLoadingMessage: '',
  isCategoriesPlatformIsDeleteModalOpen: false,
  editCategorySuccessMessage: '',
  editCategoryErrorMessage: '',
  deleteCategoryMessage: '',
  createCategoryErrorMessage: '',
  createCategorySuccessMessage: '',
  isShowCategoryLoader: false,
  categoriesMessage: '',
  deletPlatformCategory: [],
  errorMessage: '',
  successMessage: '',
};

const adminPlatformsFiltersCategorySlice = createSlice({
  name: 'adminPlatformsFilter',
  initialState,
  reducers: {
    setIsDeleteCategoryModalOpen: (state, { payload }) => {
      state.isCategoriesPlatformIsDeleteModalOpen = payload;
    },
    setSaveModalOpen: (state, { payload }) => {
      state.isSaveModalOpen = payload;
    },
    setEditingCategoryMode: (state, { payload }) => {
      state.editingMode = payload;
    },
    clearErrorMessageCategoryPlatform: (state) => {
      state.errorMessage = '';
    },
    clearSuccessMessageCategoryPlatform: (state) => {
      state.successMessage = '';
    },
    setActiveCategory: (state, { payload: categoryId }) => {
      state.activeCategoryId = categoryId;
      state.activeCategory = state.categoriesForFiltersPlatforms.find(({ id }) => id === categoryId) || null;
    },
    updateActiveCategoryName: (state, { payload: categoryName }) => {
      if (categoryName) {
        state.activeCategory = { id: state.activeCategoryId || '', filterGroupName: categoryName, isDefault: false };
      }
    },
    clearActiveCategory: (state) => {
      state.activeCategoryId = null;
      state.activeCategory = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(setAdminPlatformsCategoriesFiltersAction.pending, (state) => {
      if (!state.categoriesForFiltersPlatforms.length) {
        state.isShowCategoryLoader = true;
      }
      state.categoriesMessage = '';
    });
    builder
      .addCase(setAdminPlatformsCategoriesFiltersAction.fulfilled, (state, { payload }) => {
        const categories = payload || [];

        state.isShowCategoryLoader = false;
        state.categoriesForFiltersPlatforms = categories;

        if (!state.categoriesForFiltersPlatforms.length) {
          state.categoriesMessage = 'Для данного раздела категории отсутствуют';
        }
        state.categoriesMessage = '';
      })
      .addCase(setAdminPlatformsCategoriesFiltersAction.rejected, (state, { error }) => {
        state.isShowCategoryLoader = false;
        state.errorMessage = errorHandler(error, 'Ошибка получения категорий');
      })
      .addCase(createCategoriesForPlatformsAction.fulfilled, (state) => {
        state.isShowCategoryLoader = false;
        state.successMessage = 'Категория успешно создана';
      })
      .addCase(createCategoriesForPlatformsAction.rejected, (state, { payload }) => {
        state.isShowCategoryLoader = false;
        state.errorMessage = payload!;
      })
      .addCase(deleteCategoriesForPlatformsAction.fulfilled, (state) => {
        state.isShowCategoryLoader = false;
        state.successMessage = 'Категория успешно удалена';
        state.isCategoriesPlatformIsDeleteModalOpen = false;
      })
      .addCase(deleteCategoriesForPlatformsAction.rejected, (state, { error }) => {
        state.isShowCategoryLoader = false;
        state.errorMessage = errorHandler(error, 'Ошибка удаления категории');
      })
      .addCase(editCategoriesForPlatformsAction.fulfilled, (state) => {
        state.isShowCategoryLoader = false;
        state.successMessage = 'Изменения успешно сохранены';
      })
      .addCase(editCategoriesForPlatformsAction.rejected, (state, { error }) => {
        state.isShowCategoryLoader = false;
        state.errorMessage = errorHandler(error, 'Изменения не сохранены');
      });
  },
});

export const {
  reducer: adminPlatformsFiltersCategoryReducer,
  actions: {
    setIsDeleteCategoryModalOpen,
    setEditingCategoryMode,
    setActiveCategory,
    clearActiveCategory,
    setSaveModalOpen,
    clearErrorMessageCategoryPlatform,
    clearSuccessMessageCategoryPlatform,
    updateActiveCategoryName,
  },
} = adminPlatformsFiltersCategorySlice;
