import { close } from 'assets';
import { buttonStyles } from 'modules/Auth/UI';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'modules/Auth/data/modalSchema';
import { useState, MouseEvent, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { Portal } from 'components/Portal/Portal';
import { TargetPortal } from 'shared';
import { ErrorNotification, Input, TextArea } from 'sharedComponents';
import { findSolutionRequest, UserModalDataType } from 'api';
import cn from 'classnames';
import { clearNotifications } from 'store/features/modalSolutionsSlice';
import { getUserEmail, getUserFirstName, useAppDispatch, useAppSelector } from 'store';

import { WarningIcon } from 'modules/Auth/assets';

import styles from './styles.module.scss';

interface ModalProps {
  closeModal: () => void;
}

export const ModalSolution = ({ closeModal }: ModalProps) => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState(false);
  const [serverError, setServerError] = useState<string>('');
  const [phoneInputFilled, setPhoneInputFilled] = useState(false);
  const userFirstName = useAppSelector(getUserFirstName);
  const userEmail = useAppSelector(getUserEmail);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleCloseModal = (event: MouseEvent) => {
    event.stopPropagation();
    setShowMessage(false);
    closeModal();
  };

  const clear = () => dispatch(clearNotifications());

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UserModalDataType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = event.target.value.replace(/\D/g, '');
    setPhoneInputFilled(phoneNumber.length > 0);
  };

  const onSubmit = async (data: UserModalDataType) => {
    setIsLoading(true);
    try {
      if (isValid) {
        await findSolutionRequest(data);
        setIsLoading(false);
        setShowMessage(true);
      } else {
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        setServerError(error.response.data.message);
      } else {
        setServerError('Неизвестная ошибка');
      }
    }
  };

  const buttonClasses = cn(buttonStyles.button, buttonStyles.invertButton, styles.btn);
  const isSubmitDisabled = !isValid || !!Object.keys(errors).length || isLoading;

  return (
    <Portal target={TargetPortal.MODAL}>
      {showMessage ? (
        <div className={styles.modal} ref={modalRef} onClick={handleCloseModal}>
          <div className={styles.modalWrapper} onClick={(e) => e.stopPropagation()}>
            <div className={styles.message}>
              <button type="button" onClick={handleCloseModal}>
                <img className={styles.picture} src={close} alt="close" />
              </button>
              <p className={styles.titleMes}>Спасибо!</p>
              <p className={styles.subtitle}>
                Ваше сообщение
                <br />
                успешно отправлено
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.modal} ref={modalRef} onClick={handleCloseModal}>
          <div className={styles.modalWrapper} onClick={(e) => e.stopPropagation()}>
            <div className={styles.content}>
              <button type="button" onClick={handleCloseModal}>
                <img className={styles.picture} src={close} alt="close" />
              </button>
              <h3 className={styles.title}>
                Оставьте свои контактные данные и мы подберем <br />
                подходящее для Вас решение
              </h3>
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className={styles.main}>
                  <div className={styles.wrap}>
                    <p className={styles.text}>
                      Имя<span className={styles.star}>*</span>
                    </p>
                    <p className={styles.text}>
                      Email<span className={styles.star}>*</span>
                    </p>
                    <p className={styles.text}>
                      Номер телефона<span className={styles.star}>*</span>
                    </p>
                    <p className={styles.text}>
                      Сообщение <span className={styles.star}>*</span>
                    </p>
                  </div>
                  <div className={styles.item}>
                    <Input
                      register={register}
                      id="name"
                      error={errors.name?.message}
                      className={styles.input}
                      defaultValue={userFirstName ?? ''}
                    />
                    <Input
                      register={register}
                      id="email"
                      error={errors.email?.message}
                      className={styles.input}
                      defaultValue={userEmail ?? ''}
                    />
                    <div className={styles.container}>
                      <InputMask
                        mask="+7 999 999 9999"
                        maskChar="_"
                        {...register('phone')}
                        id="phone"
                        placeholder="+7 ___ ___ ____"
                        className={errors.phone ? `${styles.inputMask} ${styles.errorPhone}` : styles.inputMask}
                        onBlur={handlePhoneInputChange}
                      />
                      {errors.phone && phoneInputFilled && (
                        <span className={styles.error}>
                          <WarningIcon /> {errors.phone.message}
                        </span>
                      )}
                    </div>
                    <TextArea
                      name="message"
                      id="message"
                      placeholder="Подробно опишите, какие задачи вы хотели бы решить (до 255 символов)"
                      register={register}
                      error={errors.message?.message}
                      maxLength={255}
                      className={styles.field}
                    />
                    {serverError && <ErrorNotification message={serverError} clearMessage={clear} />}

                    <button className={buttonClasses} type="submit" disabled={isSubmitDisabled}>
                      Отправить
                    </button>
                    <p className={styles.agreement}>
                      Нажимая кнопку, Вы соглашаетесь с условиями&nbsp;
                      <a href="https://example.com" className={styles.license}>
                        лицензионного договора, политикой конфиденциальности&nbsp;
                      </a>
                      и предоставляете согласие на обработку персональных данных
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};
