import React, { useState } from 'react';
import { deleteUsersAction, getRole, getUsersPageUsersChecked, useAppDispatch, useAppSelector } from 'store';

import { UsersPageFilter } from 'modules/Admin/components';

import { Modal } from 'sharedComponents';

import styles from './styles.module.scss';
import filters from './config';

export const UsersPageFilters = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getRole);
  const usersChecked = useAppSelector(getUsersPageUsersChecked);
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);

  const handleDeleteUsers = () => {
    dispatch(deleteUsersAction());
  };

  const handleClickBtnDelete = () => {
    setOpenModalDelete(true);
  };

  return (
    <div className={styles.usersPageFilters}>
      <UsersPageFilter title="Роль" options={filters.roles} />
      <UsersPageFilter title="Дата" options={filters.dates} />
      <UsersPageFilter title="Статус" options={filters.statuses} />
      {!!usersChecked.length && userRole === 'SuperAdmin' && (
        <button
          className={`button buttonSecondary ${styles.btnDeleteSeveral}`}
          type="submit"
          onClick={handleClickBtnDelete}
        >
          Удалить выбранных
        </button>
      )}
      {isOpenModalDelete && (
        <Modal setIsActiveModal={(prev) => setOpenModalDelete(prev)} handleClick={handleDeleteUsers}>
          <p>Удалить выбранных пользователей?</p>
        </Modal>
      )}
    </div>
  );
};
