import React from 'react';
import {
  clearAllUsersChecked,
  getRole,
  getUsersPageIsAllUsersChecked,
  setAllUsersChecked,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ROLES } from 'shared';
import cn from 'classnames';

import { UsersPageTableHeaderColumn } from 'modules/Admin/components';

import { Checkbox } from 'sharedComponents';

import styles from './styles.module.scss';
import columns from './config';

export const UsersPageTableHeader = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getRole);
  const isAllUsersChecked = useAppSelector(getUsersPageIsAllUsersChecked);

  const handleClickAllUsers = () => {
    if (!isAllUsersChecked) dispatch(setAllUsersChecked());
    else dispatch(clearAllUsersChecked());
  };

  const usersPageTableHeaderStyle = cn(styles.usersPageTableHeader, {
    [styles.usersPageTableWithoutDelete]: userRole !== ROLES.SUPERADMIN,
  });

  return (
    <div className={usersPageTableHeaderStyle}>
      {userRole === ROLES.SUPERADMIN && (
        <Checkbox text="" id="tableHeader" checked={isAllUsersChecked} onChange={handleClickAllUsers} />
      )}
      {columns.map((item) => (
        <UsersPageTableHeaderColumn title={item.title} customClass={item.class} key={item.title} />
      ))}
    </div>
  );
};
