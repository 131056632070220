import { createAsyncThunk } from '@reduxjs/toolkit';
import { unblockUser } from 'modules/Admin/api';
import { RootState, setUsersAction } from 'store';

export const unblockUserAction = createAsyncThunk<void, string, { state: RootState }>(
  'usersPage/unblockUserAction',
  async (id: string, { dispatch }) => {
    await unblockUser(id);
    dispatch(setUsersAction());
  },
);
