import { addSolutionInFavorites, deleteSolutionFromFavorites } from 'api';
import { SolutionCardItem } from 'components';
import { SolutionCard, SolutionViewedCard, getUser, useAppSelector } from 'store';

import styles from '../PlatformList/styles.module.scss';

interface SolutionsListProps {
  solutions: SolutionCard[] | SolutionViewedCard[];
  reloadCardList: () => void;
}

export const SolutionsList = ({ solutions, reloadCardList }: SolutionsListProps) => {
  const { id: userId } = useAppSelector(getUser);

  const toggleFavorite = (isFavorite: boolean, cardId: string) => {
    if (userId) {
      if (isFavorite) {
        deleteSolutionFromFavorites(cardId).then(() => reloadCardList());
      } else {
        addSolutionInFavorites(cardId).then(() => reloadCardList());
      }
    }
  };

  return (
    <div className={styles.platformList}>
      {solutions.map((solution) => {
        const { description, name, id, priceMin, platform, isFavorites } = solution;

        return (
          <SolutionCardItem
            isFavorite={isFavorites}
            description={description}
            name={name}
            key={id}
            id={id}
            platformName={platform.name}
            platformLogoLink={platform.file?.url || ''}
            priceMin={+priceMin}
            toggleFavorite={toggleFavorite}
          />
        );
      })}
    </div>
  );
};
