import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteSolutionImage } from 'modules/Admin/api';

export interface IDeleteSolutionImageAction {
  fileId: string;
  solutionId: string;
}

export const deleteSolutionImageByUrlAction = createAsyncThunk<void, IDeleteSolutionImageAction>(
  'solutionImage/deleteSolutionImageAction',
  async ({ fileId, solutionId }) => {
    await deleteSolutionImage(fileId, solutionId);
  },
);
