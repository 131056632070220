import { createSlice } from '@reduxjs/toolkit';
import { confirmEmailAction } from 'store/actions';

interface ConfirmEmailState {
  showModal: boolean;
  emailForConfirm: string;
  isLoading: boolean;
  errorMessage: string;
  successMessage: string;
}

const initialState: ConfirmEmailState = {
  showModal: false,
  emailForConfirm: '',
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};

const confirmEmailSlice = createSlice({
  name: 'confirmEmail',
  initialState,
  reducers: {
    clearConfirmEmailMessages: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
    setModalConfirmEmail: (state, { payload }) => {
      state.showModal = payload.showModal;
      state.emailForConfirm = payload.email;
    },
    clearModalConfirmEmail: (state) => {
      state.showModal = false;
      state.emailForConfirm = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(confirmEmailAction.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
        state.successMessage = '';
      })
      .addCase(confirmEmailAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.successMessage = payload;
      })
      .addCase(confirmEmailAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка подтверждения email';
      });
  },
});

export const {
  reducer: confirmEmailReducer,
  actions: { clearConfirmEmailMessages, setModalConfirmEmail, clearModalConfirmEmail },
} = confirmEmailSlice;
