import { createAsyncThunk } from '@reduxjs/toolkit';
import { SolutionEvents } from 'store';
import { AxiosError } from 'axios';
import { getSolutionEvents } from 'modules/Admin/api';

export const setSolutionEventsAction = createAsyncThunk<SolutionEvents[], string, { rejectValue: string }>(
  'solutionEvents/setSolutionEventsAction',
  async (solutionId: string, { rejectWithValue }) => {
    try {
      const solutionEvents = await getSolutionEvents(solutionId);
      return solutionEvents;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
