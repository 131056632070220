import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFiltersForSolutions, getFiltersForSolutionsAdmin } from 'modules/Admin/api';
import { SolutionFilter, SolutionFilterItemAdmin } from 'store';

export const setSolutionsFiltersAction = createAsyncThunk<SolutionFilter[]>(
  'filters/solutionsFilters',
  getFiltersForSolutions,
);

export const setSolutionsFiltersAdminAction = createAsyncThunk<SolutionFilterItemAdmin[]>(
  'filters/solutionsFiltersAdmin',
  getFiltersForSolutionsAdmin,
);
