import Select from 'react-select';
import { SortType } from 'modules/Admin/config';

import styles from './styles.module.scss';

interface SortProps {
  onSortChange: (newSortOption: SortType) => void;
}

const options = [
  { value: 'ASC', label: 'A - Z' },
  { value: 'DESC', label: 'Z - A' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #ECECEC',
    borderRadius: '8px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    background: 'none',
  }),
};

export const Sort = ({ onSortChange }: SortProps) => (
  <div className={styles.sort}>
    <Select
      options={options}
      onChange={(selectedOption) => onSortChange(selectedOption?.value as SortType)}
      placeholder="A - Z"
      styles={customStyles}
      isSearchable={false}
    />
  </div>
);
