import { PlatformsTabsList } from 'modules/Admin/types/platformsTypes';
import { NavLink, generatePath } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import {
  SolutionsFiltersTabsList,
  SolutionsTabsList,
  SolutionSettingsTabsList,
  FiltersTabsList,
} from 'modules/Admin/types';
import cn from 'classnames';
import { TabsList } from 'modules/Profile/ProfileLayout/ProfileFavoriteLayout/config';
import { HistoryTabsList } from 'modules/Profile/ProfileLayout/ProfileHistoryLayout/config';

import styles from './styles.module.scss';

const createlinkStyle = ({ isActive }: { isActive: boolean }) => `${styles.tabLink} ${isActive ? styles.active : ''}`;

type TabsType =
  | PlatformsTabsList
  | SolutionsTabsList
  | SolutionsFiltersTabsList
  | SolutionSettingsTabsList
  | FiltersTabsList
  | TabsList
  | HistoryTabsList;

export interface TabsProps {
  tabs: TabsType[];
  linkId?: string;
  setActiveTab?: Dispatch<SetStateAction<number | null>> | ((id: number) => void);
  disabledTabs?: number[];
}

export const Tabs = ({ tabs, linkId, setActiveTab, disabledTabs = [] }: TabsProps) => {
  const disabledLinkStyle = cn(styles.tabLink, styles.disabled);

  return (
    <div className={styles.tabs}>
      <ul className={styles.linksWrapper}>
        {tabs.map(({ id, tabName, tabLink }: any, currentTabIdx: number) => {
          const isLinkDisabled = disabledTabs?.includes(currentTabIdx);

          const link = linkId ? generatePath(tabLink, { id: linkId }) : tabLink;

          const linkLayout = isLinkDisabled ? (
            <div key={id} className={disabledLinkStyle}>
              {tabName}
            </div>
          ) : (
            <NavLink
              to={link}
              end
              key={id}
              className={createlinkStyle}
              onClick={() => {
                if (setActiveTab) setActiveTab(id);
              }}
            >
              {tabName}
            </NavLink>
          );
          return linkLayout;
        })}
      </ul>
    </div>
  );
};
