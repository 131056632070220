import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserModalDataType, postOrderSolutionReady, postOrderSolutionRevision } from 'api';

interface ICreateOrderForm {
  solutionId: string;
  data: UserModalDataType;
}

export const setSolutionOrderFormReadyAction = createAsyncThunk<void, ICreateOrderForm>(
  'solutionOrderForm/setSolutionOrderFormReadyAction',
  async ({ solutionId, data }) => {
    const { name, email, phone } = data;
    const body = { solutionId, name, email, phone };
    await postOrderSolutionReady(body);
  },
);

export const setSolutionOrderFormRevisionAction = createAsyncThunk<void, ICreateOrderForm>(
  'solutionOrderForm/setSolutionOrderFormRevisionAction',
  async ({ solutionId, data }) => {
    const { name, email, phone } = data;
    const body = { solutionId, name, email, phone };
    await postOrderSolutionRevision(body);
  },
);
