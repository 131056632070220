import {
  docFieldRequiredValidation,
  docPriceRequiredValidation,
  docNamePlatformOrSolutionRequiredValidation,
  docURLValidation,
  docMessageOrReviewOrDescriptionRequiredValidation,
} from 'shared';
import * as Yup from 'yup';

export const createSolutionScheme = (hasPlatformId: boolean) =>
  Yup.object().shape({
    name: docNamePlatformOrSolutionRequiredValidation,
    description: docMessageOrReviewOrDescriptionRequiredValidation,
    priceMin: docPriceRequiredValidation,
    priceMax: docPriceRequiredValidation,
    eventPlan: docURLValidation,
    platformId: Yup.string().when([], {
      is: () => !hasPlatformId,
      then: docFieldRequiredValidation,
      otherwise: Yup.string().notRequired(),
    }),
  });
