import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getInputTypeByMultiple } from 'helpers';
import { errorHandler } from 'helpers/errorHandler';
import { isIncluded } from 'helpers/isInclude';
import { ISolutionFilterForm } from 'modules/Admin/types';
import uuid from 'react-uuid';
import { SolutionFilter, SolutionFilterItem } from 'store';
import { setSolutionsFilterByIdAction, updateSolutionsFilterAction } from 'store/actions';

interface ISolutionsFilterSlice {
  currentFilter: SolutionFilter;
  solutionsFilterOptions: SolutionFilterItem[];
  isSolutionsFilterLoading: boolean;
  isSaveModalOpen: boolean;
  isRedirect: boolean;
  solutionsFilterSuccessMessage: string;
  solutionsFilterErrorMessage: string;
}

const initialState: ISolutionsFilterSlice = {
  currentFilter: {} as SolutionFilter,
  solutionsFilterOptions: [],
  isRedirect: false,
  isSolutionsFilterLoading: false,
  isSaveModalOpen: false,
  solutionsFilterSuccessMessage: '',
  solutionsFilterErrorMessage: '',
};

const solutionsFilterSlice = createSlice({
  name: 'solutionsFilter',
  initialState,
  reducers: {
    deleteOption: (state, { payload: filterId }: PayloadAction<string>) => {
      const options = state.solutionsFilterOptions.filter(({ id }) => id !== filterId);
      state.solutionsFilterOptions = options;
      const filterItemsForSolutions = options;
      state.currentFilter = {
        ...state.currentFilter,
        filterItemsForSolutions,
      };
    },

    addOption: (state, { payload: filterItemName }: PayloadAction<string>) => {
      const options = state.currentFilter.filterItemsForSolutions.map(({ filterItemName: option }) => option);
      const isAlreadyExist = isIncluded(options, filterItemName);

      if (isAlreadyExist) {
        const errorText = 'Опция с данным значением уже существует';
        const errorTextSecondary = 'Опция с данным значением уже существует';
        state.solutionsFilterErrorMessage =
          state.solutionsFilterErrorMessage === errorText ? errorTextSecondary : errorText;
        return;
      }

      const id = uuid();
      const option: SolutionFilterItem = { id, filterItemName };
      state.solutionsFilterOptions = [...state.solutionsFilterOptions, option];
      state.currentFilter = {
        ...state.currentFilter,
        filterItemsForSolutions: state.solutionsFilterOptions,
      };
    },

    clearCurrentSolutionsFilter: (state) => {
      state.currentFilter = {} as SolutionFilter;
      state.solutionsFilterOptions = [];
    },

    changeCurrentSolutionsFilter: (state, { payload }: PayloadAction<ISolutionFilterForm>) => {
      const { filterName, descriptionLong, multiplicity } = payload;
      const inputType = getInputTypeByMultiple(multiplicity);
      const filterItemsForSolutions = state.solutionsFilterOptions;

      state.currentFilter = {
        ...state.currentFilter,
        filterName,
        descriptionLong,
        inputType,
        filterItemsForSolutions,
      };
    },

    setIsSaveModalOpen: (state, { payload: isActive }: PayloadAction<boolean>) => {
      state.isSaveModalOpen = isActive;
    },

    clearMessages: (state) => {
      state.solutionsFilterSuccessMessage = '';
      state.solutionsFilterErrorMessage = '';
    },

    cancelRedirect: (state) => {
      state.isRedirect = false;
    },

    setSolutionsFilterSuccessMessage: (state, { payload }) => {
      state.solutionsFilterSuccessMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSolutionsFilterAction.pending, (state) => {
        state.isSolutionsFilterLoading = true;
        state.solutionsFilterSuccessMessage = '';
        state.solutionsFilterErrorMessage = '';
      })
      .addCase(updateSolutionsFilterAction.fulfilled, (state) => {
        state.isSolutionsFilterLoading = false;
        state.solutionsFilterSuccessMessage = 'Фильтр успешно сохранен';
        state.isSaveModalOpen = false;
        state.isRedirect = true;
      })
      .addCase(updateSolutionsFilterAction.rejected, (state, { payload: errorMessage }) => {
        state.isSolutionsFilterLoading = false;
        state.solutionsFilterSuccessMessage = '';
        state.solutionsFilterErrorMessage = errorMessage as string;
        state.isSaveModalOpen = false;
      })
      .addCase(setSolutionsFilterByIdAction.pending, (state) => {
        state.isSolutionsFilterLoading = true;
        state.solutionsFilterErrorMessage = '';
      })
      .addCase(setSolutionsFilterByIdAction.fulfilled, (state, { payload: filter }) => {
        state.isSolutionsFilterLoading = false;
        state.currentFilter = filter;
        state.solutionsFilterOptions = state.currentFilter.filterItemsForSolutions;
      })
      .addCase(setSolutionsFilterByIdAction.rejected, (state, { error }) => {
        state.isSolutionsFilterLoading = false;
        state.solutionsFilterErrorMessage = errorHandler(error, 'Ошибка получения фильтра');
      });
  },
});

export const {
  reducer: solutionsFilterReducer,
  actions: {
    deleteOption,
    addOption,
    setIsSaveModalOpen,
    changeCurrentSolutionsFilter,
    clearCurrentSolutionsFilter,
    clearMessages,
    setSolutionsFilterSuccessMessage,
    cancelRedirect,
  },
} = solutionsFilterSlice;
