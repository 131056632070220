import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISolutionOptionWithoutPlatformName } from 'store/types';
import { createSolution } from 'modules/Admin/api';

export const createSolutionOptionAction = createAsyncThunk<string, ISolutionOptionWithoutPlatformName>(
  'solutionOption/createSolutionOptionAction',
  async (obj) => {
    const { id, ...body } = obj;
    const { id: solutionId } = await createSolution(body);
    return solutionId;
  },
);
