import { BasketIcon, PencilIcon } from 'modules/Admin/assets';

import React, { FC, useState } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { changeEventSheme } from 'modules/Admin/data/createEventSheme';

import { EventsOptions } from 'modules/Admin/types';

import cn from 'classnames';

import styles from './styles.module.scss';

import buttonStyle from '../../../../styles/button.style.module.scss';

import { TextInput } from '../TextInput/TextInput';

interface EventLineProps {
  number: number;
  text: string;
  id: string;
  handleClickDelete: (id: string) => void;
  handleClickChange: (id: string, text: string) => void;
}

export const SolutionEventLine: FC<EventLineProps> = ({ number, text, id, handleClickDelete, handleClickChange }) => {
  const [isDisabledField, setDisabledField] = useState<boolean>(true);

  const {
    setValue,
    trigger,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<EventsOptions>({
    mode: 'onChange',
    defaultValues: { event: text },
    resolver: yupResolver(changeEventSheme),
  });

  const buttonsContainerStyle = cn(styles.eventButtons, {
    [styles.eventButtonsDisabled]: isDisabledField,
  });

  const saveButtonStyle = cn(buttonStyle.invertButton, styles.addEventButton);

  const isDisabledBtnSave = !isValid || !isDirty;

  const onSubmit = (obj: EventsOptions) => {
    handleClickChange(id, obj.event);
  };

  const clickCancelBtn = () => {
    setDisabledField(true);
    setValue('event', text);
    trigger('event');
  };

  const clickPencil = () => {
    setDisabledField(false);
    // reset() для того, чтобы после после повторного нажатия на pencil сработала валидация кнопки
    reset();
    setValue('event', text);
  };

  const clickTrash = () => {
    handleClickDelete(id);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.eventForm}>
      <p className={styles.eventNumber}>Мероприятие {number}</p>
      <div className={styles.eventFlex}>
        <div className={styles.inputContainer}>
          <TextInput id={id} name="event" register={register} error={errors?.event} isDisabled={isDisabledField} />
        </div>
        <button type="button" onClick={clickPencil}>
          <PencilIcon />
        </button>
        <button type="button" onClick={clickTrash}>
          <BasketIcon />
        </button>
      </div>
      <div className={buttonsContainerStyle}>
        <button className="button buttonWithoutBorder" type="button" onClick={clickCancelBtn}>
          Отмена
        </button>
        <button className={saveButtonStyle} type="submit" disabled={isDisabledBtnSave}>
          Сохранить изменения
        </button>
      </div>
    </form>
  );
};
