import { UseFormRegister, FieldError } from 'react-hook-form';
import cn from 'classnames';
import { Warning } from 'sharedComponents';

import styles from './styles.module.scss';

export interface ITextInputProps {
  id: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  label?: string;
  value?: string;
  defaultValue?: string;
  error?: FieldError;
  isDisabled?: boolean;
  inputIcon?: string;
}

export const TextInput = ({
  id,
  name,
  placeholder,
  label,
  register,
  defaultValue,
  error,
  isDisabled = false,
  inputIcon,
}: ITextInputProps) => {
  const inputStyle = cn(
    styles.input,
    {
      [styles.inputError]: error,
      [styles.inputDisabled]: isDisabled,
    },
    inputIcon && styles.inputWithIcon,
  );
  return (
    <label htmlFor={id} className={styles.label}>
      {label && <span>{label}</span>}
      {inputIcon && <img className={styles.inputIcon} src={inputIcon} alt="" />}
      <input
        id={id}
        placeholder={placeholder}
        {...register(name)}
        className={inputStyle}
        defaultValue={defaultValue}
        disabled={isDisabled}
        type="text"
      />
      {error?.message && <Warning message={error.message} absolute />}
    </label>
  );
};
