import { createSlice } from '@reduxjs/toolkit';
import {
  changeSolutionTasksAction,
  createSolutionTasksAction,
  deleteSolutionTasksAction,
  setSolutionTasksAction,
} from 'store/actions/solutionTasksAction';
import { SolutionTasksState } from 'store/types/solutionTasks';

const initialState: SolutionTasksState = {
  isLoading: false,
  error: '',
  success: '',
  data: [],
};

const solutionTasksSlice = createSlice({
  name: 'solutionTasks',
  initialState,
  reducers: {
    cleanErrorMessage: (state) => {
      state.error = '';
    },
    cleanSuccessMessage: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSolutionTasksAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setSolutionTasksAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload !== undefined && payload !== null) {
        state.data = payload;
      }
    });
    builder.addCase(setSolutionTasksAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при получении задачи';
    });

    builder.addCase(createSolutionTasksAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Задача успешно создана';
    });
    builder.addCase(createSolutionTasksAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при создании задачи';
    });

    builder.addCase(deleteSolutionTasksAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Задача удалена';
    });
    builder.addCase(deleteSolutionTasksAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при удалении задачи';
    });

    builder.addCase(changeSolutionTasksAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Задача успешно изменена';
    });
    builder.addCase(changeSolutionTasksAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при изменении задачи';
    });
  },
});

export const { cleanErrorMessage, cleanSuccessMessage } = solutionTasksSlice.actions;
export const solutionTasksReducer = solutionTasksSlice.reducer;
