import { useEffect, useState } from 'react';

import { ArrowDownIcon, ArrowLeft, ArrowUpIcon, Clear, DescriptionIcon } from 'assets';

import {
  clearPlatformsPageAllFiltersError,
  clearPlatformsPageFiltersAndPrices,
  filtersSelector,
  getTotalPlatformsCount,
  setPlatformsAction,
  useAppDispatch,
  useAppSelector,
  IFilters,
} from 'store';
import { updateActiveFiltersCheckbox, updateActiveFiltersRadio } from 'helpers';

import { useNavigate } from 'react-router';

import { BreadCrumbs } from 'components';
import { INPUTTYPE } from 'mocks';

import styles from './styles.module.scss';

import { SyntheticEvent } from '../types';
import { ROUTE } from '../../../router/routes';
import { getAllFiltersAction } from '../../../store/actions/filters';
import { ErrorNotification, Loading } from '../../../sharedComponents';
import { PriceFilter } from '../PriceFilter/PriceFilter';
import { Filters } from '../Filters/Filters';

export const AllFilters = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { allFilters, allFiltersError, isAllFiltersLoading, activeFilters } = useAppSelector(filtersSelector);
  const platformsCount = useAppSelector(getTotalPlatformsCount);

  const [closedFilters, setClosedFilters] = useState<Array<string>>([]);
  const isLoading = isAllFiltersLoading;

  useEffect(() => {
    dispatch(getAllFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPlatformsAction({ amount: '1', numberOfPerPage: 0 }));
  }, [activeFilters, dispatch]);

  const getObjFilter = (filterName: string) => {
    let objFilter: IFilters | undefined;
    allFilters.some((item) => {
      objFilter = item.filtersForPlatforms.find((item2) => item2.id === filterName);
      return objFilter !== undefined;
    });
    return objFilter;
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
    const filterId = event.target.id;
    const filterName = event.target.name;
    const objFilter = getObjFilter(filterName);

    if (objFilter) {
      if (objFilter?.inputType === INPUTTYPE.CHECKBOX) {
        const obj = { activeFilters, filterId, page: 'platformsPage', dispatch };
        updateActiveFiltersCheckbox(obj);
      } else {
        const obj = { objFilter, activeFilters, filterId, page: 'platformsPage', dispatch };
        updateActiveFiltersRadio(obj);
      }
    }
  };

  const handleClearFilters = () => {
    dispatch(clearPlatformsPageFiltersAndPrices());
    navigate(ROUTE.PLATFORMS);
  };

  const clearMessage = () => {
    dispatch(clearPlatformsPageAllFiltersError());
  };

  const isClosed = (id: string): boolean => closedFilters.includes(id);

  const toggleOpen = ({ currentTarget: { id } }: SyntheticEvent<HTMLButtonElement>) =>
    isClosed(id)
      ? setClosedFilters(closedFilters.filter((item) => item !== id))
      : setClosedFilters([...closedFilters, id]);

  return isLoading ? (
    <Loading />
  ) : (
    <section className={styles.section}>
      <div className={styles.allFilters}>
        <BreadCrumbs lastCrumb={{ name: 'Все фильтры', path: 'platforms/filters' }} />
        <h4>Все фильтры</h4>
        <form onChange={handleFormChange}>
          <div className={styles.wrapper}>
            {allFilters.map((filterGroup, i) => (
              <div key={filterGroup.id} className={styles.filter}>
                {i === 0 && <PriceFilter page="platformsPage" />}
                <div className={styles.filterGroup} key={filterGroup.id}>
                  <h6 className={styles.groupName}>{filterGroup.filterGroupName}</h6>
                  {filterGroup.filtersForPlatforms.map((filter, index) => (
                    <div key={`Input ${index}`}>
                      <div className={styles.groupItem}>
                        {filter.inputType !== 'text' && (
                          <button type="button" className={styles.filterName} id={filter.id} onClick={toggleOpen}>
                            {isClosed(filter.id) ? <ArrowDownIcon /> : <ArrowUpIcon />}
                            <span className={styles.filterNameText}>{filter.filterName}</span>
                            {filter.descriptionShort && <DescriptionIcon />}
                          </button>
                        )}
                        {!isClosed(filter.id) && (
                          <div className={styles.filterOptions}>
                            <Filters
                              activeFilters={activeFilters}
                              objFilter={getObjFilter(filter.id)}
                              onClickAny={handleFormChange}
                              filtersData={filter}
                              showTitle={false}
                              showFullList
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {allFiltersError && <ErrorNotification message={allFiltersError} clearMessage={clearMessage} />}
          </div>
          <div className={styles.buttonWrapper}>
            <button
              type="button"
              onClick={() => {
                navigate(ROUTE.PLATFORMS);
                window.scrollTo(0, 0);
              }}
            >
              <ArrowLeft />
              &nbsp; Вернуться к платформам
            </button>
            <button
              className="button buttonSecondary"
              type="button"
              onClick={() => {
                navigate(ROUTE.PLATFORMS);
                window.scrollTo(0, 0);
              }}
            >
              Показать результат ({platformsCount})
            </button>
            <button type="button" onClick={handleClearFilters}>
              Сбросить фильтры &nbsp;
              <Clear />
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
