import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSolutionDetailsAction, setSolutionFavoriteAction } from 'store/actions/setSolutionAction';

import { SolutionDetailsResponse, SolutionDetailsState } from 'store/types';

const initialState: SolutionDetailsState = {
  solutionDetails: {
    solution: {
      id: '',
      name: '',
      description: '',
      isActive: false,
      type: '',
      priceMin: 0,
      priceMax: 0,
      eventPlan: '',
      platformId: '',
      isFavorites: false,
    },
    platform: {
      id: '',
      isActive: false,
      name: '',
      description: '',
      urlWebsite: '',
      priceMin: '0',
      priceMax: '0',
      numberOfSolution: 0,
      file: null,
      adminId: '',
    },
    solutionTasks: [],
    solutionEvents: [],
    files: [],
    reviews: [],
    characteristics: [],
  },

  isLoading: false,
  loadingStatus: '',
  error: null,
};

const solutionSlice = createSlice({
  name: 'solution',
  initialState,
  reducers: {
    setErrorMessage: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSolutionDetailsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        setSolutionDetailsAction.fulfilled,
        (state, { payload }: PayloadAction<SolutionDetailsResponse | undefined>) => {
          if (payload) {
            state.solutionDetails = payload;
          }
          state.error = '';
          state.isLoading = false;
        },
      )
      .addCase(setSolutionDetailsAction.rejected, (state, { meta, payload }) => {
        state.loadingStatus = meta.requestStatus;
        state.isLoading = false;
        state.error = payload || 'Что-то пошло не так';
      })
      .addCase(setSolutionFavoriteAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setSolutionFavoriteAction.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
        state.solutionDetails.solution.isFavorites = payload;
        state.error = '';
        state.isLoading = false;
      })
      .addCase(setSolutionFavoriteAction.rejected, (state, { meta, payload }) => {
        state.loadingStatus = meta.requestStatus;
        state.isLoading = false;
        state.error = payload || 'Что-то пошло не так';
      });
  },
});

export const {
  reducer: solutionReducer,
  actions: { setErrorMessage },
} = solutionSlice;
