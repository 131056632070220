import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateEmailSendOldEmail, updateEmailSendNewEmail } from 'modules/Admin/api';

import { logoutAction } from './logoutAction';

export interface UpdateEmail {
  to: string;
}

interface IUpdateEmailSendNewEmail {
  body: UpdateEmail;
  token: string;
}

export const updateEmailSendOldEmailAction = createAsyncThunk<void>(
  'updateEmail/updateEmailSendOldEmailAction',
  async () => {
    await updateEmailSendOldEmail();
  },
);

export const updateEmailSendNewEmailAction = createAsyncThunk<string, IUpdateEmailSendNewEmail>(
  'updateEmail/updateEmailSendNewEmailAction',
  async ({ body, token }: IUpdateEmailSendNewEmail, { dispatch }) => {
    await updateEmailSendNewEmail(body, token);
    await dispatch(logoutAction());
    return body.to;
  },
);
