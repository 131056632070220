import { authAxiosInstance } from 'modules/Auth/api';
import { ICreateSolutionImageAction, IPostSolutionImage } from 'store/types/solutionImage';

import { endpoints } from './endpoints';

export const createSolutionImage = (imageData: ICreateSolutionImageAction): Promise<void> => {
  const { extra, solutionId, files } = imageData;
  const CreateImageData: IPostSolutionImage = {
    extra,
    files,
  };
  return authAxiosInstance
    .postForm(`${endpoints.solutions}/${solutionId}/${endpoints.photo}`, CreateImageData)
    .then((data) => data.data);
};
