import { useEffect } from 'react';
import {
  useAppDispatch,
  useAppSelector,
  setSolutionsAction,
  setSolutionsFiltersAction,
  clearSolutionsPageFiltersAndPrices,
  getSolutionsPageActiveFiltersSelector,
  getSolutionsPageSolutionsFiltersSelector,
  getSolutionsPageErrorMessage,
  getSolutionsPageIsLoading,
  clearSolutionsPageErrorMessage,
} from 'store';

import { updateActiveFiltersCheckbox, updateActiveFiltersRadio } from 'helpers';

import { Loading, ErrorNotification } from 'sharedComponents';
import { useSearchParams } from 'react-router-dom';
import { SortType } from 'modules/Admin/config';
import { INPUTTYPE } from 'mocks';

import styles from '../FiltrationBar/styles.module.scss';

import { SolutionFilters } from '../SolutionsFilters/SolutionsFilters';
import { PriceFilter } from '../PriceFilter/PriceFilter';

interface ISolutionsFiltrationBar {
  sortOption: SortType;
}

export const SolutionsFiltrationBar = ({ sortOption }: ISolutionsFiltrationBar) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const solutionsFilters = useAppSelector(getSolutionsPageSolutionsFiltersSelector);
  const activeFilters = useAppSelector(getSolutionsPageActiveFiltersSelector);
  const isLoading = useAppSelector(getSolutionsPageIsLoading);
  const errorMessage = useAppSelector(getSolutionsPageErrorMessage);

  useEffect(() => {
    dispatch(setSolutionsFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setSolutionsAction({ amount: page }));
  }, [activeFilters, sortOption, page, dispatch]);

  const handlePageChange = (pageNumber: number) => {
    setSearchParams(`page=${pageNumber}`);
    document.body.scrollIntoView({ behavior: 'smooth' });
  };

  const handleFormChange = (event: any) => {
    const filterId = event.target.id;
    const filterName = event.target.name;
    const objFilter = solutionsFilters.find((item) => item.id === filterName);

    if (objFilter) {
      handlePageChange(1);
      if (objFilter?.inputType === INPUTTYPE.CHECKBOX) {
        const obj = { activeFilters, filterId, page: 'solutionsPage', dispatch };
        updateActiveFiltersCheckbox(obj);
      } else {
        const obj = { objFilter, activeFilters, filterId, page: 'solutionsPage', dispatch };
        updateActiveFiltersRadio(obj);
      }
    }
  };

  const handleFormReset = () => {
    handlePageChange(1);
    dispatch(clearSolutionsPageFiltersAndPrices());
  };

  const clearMessage = () => {
    dispatch(clearSolutionsPageErrorMessage());
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.wrapper}>
      <form onChange={handleFormChange}>
        <PriceFilter page="solutionsPage" onPageChange={handlePageChange} />
        {solutionsFilters.map((filtersData) => (
          <SolutionFilters
            activeFilters={activeFilters}
            filtersData={filtersData}
            onClickAny={handleFormChange}
            key={filtersData.id}
          />
        ))}
      </form>
      <button className="button buttonSecondary" type="button" onClick={handleFormReset}>
        Очистить все
      </button>
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessage} />}
    </div>
  );
};
