import { RootState } from 'store';

export const getVideo = (state: RootState) => state.solutionVideo;

export const getLoadingVideoState = (state: RootState) => state.solutionVideo.isSolutionVideoLoading;

export const getSolutionVideoState = (state: RootState) => state.solutionVideo.videoData;

export const getIsSolutionAddedState = (state: RootState) => state.solutionVideo.isSolutionAdded;

export const getSolutionVideoLinkAddedState = (state: RootState) => state.solutionVideo.isLinkAdded;

export const getSolutionVideoDescAddedState = (state: RootState) => state.solutionVideo.isDescriptionAdded;

export const getSolutionVideoErrorMessage = (state: RootState) => state.solutionVideo.solutionsVideoErrorMessage;

export const getSolutionVideoSuccessMessage = (state: RootState) => state.solutionVideo.solutionsVideoSuccessMessage;
