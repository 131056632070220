import { INPUTTYPE } from 'mocks';

import cn from 'classnames';

import styles from './styles.module.scss';

interface IRadio extends React.InputHTMLAttributes<HTMLInputElement> {
  isChecked?: boolean;
  text: string;
  name: string;
  customClass?: string;
  isSolutionFilterChecked?: boolean;
}

export const Radio = ({ isChecked, text, id, name, customClass = '', ...restProps }: IRadio) => (
  <li>
    <label htmlFor={id} className={styles.label}>
      <input
        className={cn(styles.input, styles[customClass])}
        id={id}
        name={name}
        defaultChecked={isChecked}
        type={INPUTTYPE.RADIO}
        readOnly
        {...restProps}
      />
      <span className={styles.span}>{text}</span>
    </label>
  </li>
);
