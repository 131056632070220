import { AdminPlatformsCategoriesFiltersResponse } from 'store';
import { BasketIcon, PencilIcon } from 'modules/Admin/assets';

import styles from './styles.module.scss';

interface AdminCharacteristicsProps {
  category: AdminPlatformsCategoriesFiltersResponse;
  handleDeleteClick: (id: string) => void;
  handleEditClick: (id: string) => void;
}

export const AdminCategories = ({ category, handleDeleteClick, handleEditClick }: AdminCharacteristicsProps) => {
  const { filterGroupName, id: categoryId, isDefault } = category;

  return isDefault ? (
    <span className={styles.filterName}>{filterGroupName}</span>
  ) : (
    <>
      <span className={styles.filterName}>{filterGroupName}</span>
      <button type="button" onClick={() => handleEditClick(categoryId)}>
        <PencilIcon />
      </button>
      <button type="button" onClick={() => handleDeleteClick(categoryId)}>
        <BasketIcon />
      </button>
    </>
  );
};
