// Перед @ должны быть 1 и более символов, букв или цифр (не допускаются символы: (<>()[]\.,;:)
// Обязательное наличие @
// Доменное имя (между @ и .) допускается писать только из латинких букв и цифр, а также их комбинаций через .
// Обязательное наличие . перед указанием доменной зоны.
// Доменная зона должна состоять минимум из двух букв латинского алфавита
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Имя может состоять из букв латинского и русского алфавита
export const USERNAME_PATTERN = /^([a-z]+|[а-яё]+)$/iu;

export const TEXT_PATTERN = /^([a-z0-9-\s*]+|[а-яё0-9-\s*]+){0,15}$/iu;

export const REVIEW_TEXT_PATTERN = /^[a-zA-Zа-яА-ЯёЁ0-9,.!?()'\s-]*$/;

export const REVIEWNAME_PATTERN = /^([a-z\s]+|[а-яё\s]+)$/iu;

export const DESCRIPTION_PATTERN = /^[a-zA-Zа-яА-ЯёЁ0-9,.!?()'\s-]*$/;

// Строка не может начинаться с пробела
export const EVENT_PATTERN = /^\S|^$/;

export const SEARCH_USERS_PAGE_PATTERN = /^[А-Яа-яA-Za-z0-9@ ]*$/;

export const USERS_PAGE_DATE_PATTERN = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$|^$/;

export const URL_PATTERN =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const IMAGE_NAME_PATTERN = /^(?! )[\wа-яА-ЯёЁ,.!?()'\s-]+$/;
