import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  useAppDispatch,
  useAppSelector,
  getSolutionsFiltersSelector,
  setSolutionsFiltersAction,
  getSolutionActiveFilters,
  setSolutonFiltersByIdAction,
  updateSolutionFiltersByIdAction,
  getSolutionFiltersErrorMessage,
  getSolutionFiltersSuccessMessage,
  clearActiveFilters,
  isShowSolutionsFiltersLoaderSelector,
} from 'store';

import cn from 'classnames';

import { SolutionFilters } from 'modules/Filtration/SolutionsFilters/SolutionsFilters';
import { updateActiveFiltersCheckbox, updateActiveFiltersRadio } from 'helpers';
import { ErrorNotification, Loading, Modal, SuccessNotification } from 'sharedComponents';
import { cancelBtnIcon } from 'assets';

import { INPUTTYPE } from 'mocks';

import styles from './style.module.scss';

import buttonStyle from '../../../../styles/button.style.module.scss';

export const SettingSolutionFiltersPage = () => {
  const dispatch = useAppDispatch();
  const { id: currentSolutionId } = useParams<{ id: string }>();
  const solutionsFilters = useAppSelector(getSolutionsFiltersSelector);
  const activeFilters = useAppSelector(getSolutionActiveFilters);
  const errorMessage = useAppSelector(getSolutionFiltersErrorMessage);
  const successMessage = useAppSelector(getSolutionFiltersSuccessMessage);
  const isLoading = useAppSelector(isShowSolutionsFiltersLoaderSelector);
  const saveButtonStyle = cn(buttonStyle.invertButton, styles.saveBtn);

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    dispatch(setSolutionsFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (currentSolutionId) {
      dispatch(setSolutonFiltersByIdAction(currentSolutionId));
    }
  }, [currentSolutionId, dispatch]);

  useEffect(() => {
    setHasChanges(false);
  }, [solutionsFilters]);

  const clickSave = () => {
    setIsModal(true);
  };

  const handleClickSend = () => {
    const solutionUpdateData = {
      updateData: activeFilters,
      solutionId: currentSolutionId!,
    };
    dispatch(updateSolutionFiltersByIdAction(solutionUpdateData));
    setHasChanges(false);
    setIsModal(false);
  };

  const handleFormChange = (event: any) => {
    const filterId = event.target.id;
    const filterName = event.target.name;
    const objFilter = solutionsFilters.find((item) => item.id === filterName);

    if (objFilter) {
      if (objFilter?.inputType === INPUTTYPE.CHECKBOX) {
        updateActiveFiltersCheckbox({ activeFilters, filterId, page: 'solutionSettings', dispatch });
      } else {
        updateActiveFiltersRadio({ objFilter, activeFilters, filterId, page: 'solutionSettings', dispatch });
      }
    }
    setHasChanges(true);
  };

  const handleReset = () => {
    dispatch(clearActiveFilters());
    setHasChanges(true);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.wrapper}>
      <form onChange={handleFormChange} className={styles.filtersContainer}>
        {solutionsFilters.map((filtersData) => (
          <SolutionFilters
            activeFilters={activeFilters}
            filtersData={filtersData}
            onClickAny={handleFormChange}
            key={filtersData.id}
          />
        ))}
        <div className={styles.btnContainer}>
          <button className={styles.btnBox} onClick={handleReset} type="button">
            Сбросить фильтры
            <img className={styles.cancelBtnIcon} src={cancelBtnIcon} alt={`Изображение ${cancelBtnIcon}`} />
          </button>
          <button className={saveButtonStyle} type="button" onClick={clickSave} disabled={!hasChanges}>
            Сохранить
          </button>
        </div>
      </form>
      {errorMessage && <ErrorNotification message={errorMessage} />}
      {successMessage && <SuccessNotification message={successMessage} />}
      {isModal && (
        <Modal setIsActiveModal={(prev) => setIsModal(prev)} submitButtonText="Сохранить" handleClick={handleClickSend}>
          <p>Вы уверены, что хотите сохранить изменения?</p>
        </Modal>
      )}
    </div>
  );
};
