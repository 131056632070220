import { CheckedTaskIcon } from 'assets';

import styles from './styles.module.scss';

export interface SolutionTaskItemProps {
  taskText: string;
}

const SolutionTaskItem = ({ taskText }: SolutionTaskItemProps): JSX.Element => (
  <li className={styles.taskWrapper}>
    <CheckedTaskIcon className={styles.taskIcon} />
    <span className={styles.taskText}>{taskText}</span>
  </li>
);

export { SolutionTaskItem };
