import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteSolutionEvent } from 'modules/Admin/api';
import { setSolutionEventsAction } from 'store';

export interface IDeleteSolutionEventAction {
  solutionId: string;
  eventId: string;
}

export const deleteSolutionEventAction = createAsyncThunk<void, IDeleteSolutionEventAction>(
  'solutionEvents/deleteSolutionEventAction',
  async ({ solutionId, eventId }, { dispatch }) => {
    await deleteSolutionEvent(solutionId, eventId);
    dispatch(setSolutionEventsAction(solutionId));
  },
);
