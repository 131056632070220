// Словарь соответсвия хостов на стендах - фронт: бек
const PORTS_MAP = {
  // release
  'node.twnsnd.online:32043': 'node.twnsnd.online:31033',

  // dev
  'nodedev.devlaba.online': 'nodedev.devlaba.online',

  // qa
  'nodeqa.devlaba.online': 'nodeqa.devlaba.online',
} as const;

// Дефолтный хост для запросов с localhost
// Или если фронтовый стенд переехал на другой хост, которого нет в словаре
const DEFAULT_HOST = 'nodedev.devlaba.online';

const getHost = () => {
  const {
    location: { host },
  } = window;

  return PORTS_MAP[host as keyof typeof PORTS_MAP] || DEFAULT_HOST;
};

export const baseURL = `https://${getHost()}/api` as const;
