import { AdminPlatformRoutes, ROUTE } from 'modules/Admin/router/routes';
import { useNavigate } from 'react-router';
import { clearNotifications, success, useAppDispatch, useAppSelector } from 'store';

import styles from './styles.module.scss';

import { FiltersList } from '../../components';

export const FiltersDirectoryPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getIsSuccess = useAppSelector(success);

  const handleClick = () => {
    if (getIsSuccess) dispatch(clearNotifications());
    const newPath = `${ROUTE.ADMIN}/${ROUTE.PLATFORMS}/${AdminPlatformRoutes.createFilter}`;
    navigate(newPath);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <button type="button" className="button buttonSecondary" onClick={handleClick}>
          Добавить фильтр
        </button>
      </div>
      <FiltersList />
    </div>
  );
};
