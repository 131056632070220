import { FileInput } from 'modules/Admin/components/FileInput/FileInput';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorNotification, Loading, Modal, SuccessNotification, TextArea } from 'sharedComponents';
import cn from 'classnames';

import { yupResolver } from '@hookform/resolvers/yup';
import { createSolutionImageScheme } from 'modules/Admin/data/createSolutionImageScheme';

import { SolutionImageLine } from 'modules/Admin/components/SolutionImageLine/SolutionImageLine';

import {
  getImage,
  getImageName,
  getImageLoadingState,
  getSolutionImageErrorMessage,
  getSolutionImageLines,
  getSolutionImageSuccessMessage,
  createSolutionImageAction,
  setFileInfo,
  setSolutionIdForImage,
  setSolutionImageDataByIdAction,
  setSolutionImageDescription,
  useAppDispatch,
  useAppSelector,
  deleteSolutionImageByUrlAction,
  resetMessages,
  setSolutionImageNameToDelete,
  setSolutionImageIdToDelete,
  getSolutionImageDeleteId,
  getSolutionImageDeleteName,
} from 'store';

import { useParams } from 'react-router';

import styles from './styles.module.scss';

import buttonStyles from '../../../../styles/button.style.module.scss';

interface ImageOptionsFields {
  files: null | File;
  description: string;
}

interface ImageDeleteData {
  fileId: string;
  solutionId: string;
}

export const SettingSolutionImagesPage = () => {
  const dispatch = useAppDispatch();
  const [imageLink, setImageLink] = useState<File | null>(null);
  const [solutionImageOption] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const isLoading = useAppSelector(getImageLoadingState);
  const imageData = useAppSelector(getImage);
  const imageName = useAppSelector(getImageName);
  const successMessage = useAppSelector(getSolutionImageSuccessMessage);
  const errorMessage = useAppSelector(getSolutionImageErrorMessage);
  const solutionImageLines = useAppSelector(getSolutionImageLines);
  const solutionImageDeleteId = useAppSelector(getSolutionImageDeleteId);
  const solutionImageDeleteName = useAppSelector(getSolutionImageDeleteName);
  const { id: currentSolutionId } = useParams<{ id: string }>();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<ImageOptionsFields>({
    mode: 'onChange',
    defaultValues: {
      files: null,
      description: '',
    },
    resolver: yupResolver(createSolutionImageScheme),
  });

  const saveButtonStyle = cn(buttonStyles.invertButton, styles.saveBtn);
  const isDisabled = !isValid || !isDirty;

  const onSubmit = (data: ImageOptionsFields) => {
    if (currentSolutionId) {
      const newData = {
        ...data,
        solutionId: currentSolutionId!,
        extra: JSON.stringify([
          {
            name: imageName,
            description: data.description,
          },
        ]),
      };
      dispatch(createSolutionImageAction(newData)).then(() =>
        dispatch(setSolutionImageDataByIdAction(currentSolutionId)),
      );
      reset();
      setImageLink(null);
    }
  };

  const handleDeleteSolutionImage = async () => {
    const deleteData: ImageDeleteData = {
      fileId: solutionImageDeleteId,
      solutionId: currentSolutionId!,
    };
    await dispatch(deleteSolutionImageByUrlAction(deleteData));
    await dispatch(setSolutionImageDataByIdAction(currentSolutionId!));
    setModalOpen(false);
  };

  const handleDeleteImageButton = (id: string, name: string) => {
    dispatch(setSolutionImageIdToDelete(id));
    dispatch(setSolutionImageNameToDelete(name));
    setModalOpen(true);
  };

  useEffect(() => {
    if (currentSolutionId) {
      dispatch(setSolutionIdForImage(currentSolutionId));
      dispatch(setSolutionImageDataByIdAction(currentSolutionId));
      dispatch(resetMessages());
    }
  }, [currentSolutionId, dispatch]);

  useEffect(() => {
    if (getValues().description) {
      dispatch(setSolutionImageDescription(getValues().description));
    }
  }, [getValues, dispatch]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {solutionImageLines.length < 10 && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.addImageBlock}>
            <div className={styles.file}>
              <Controller
                control={control}
                name="files"
                rules={{ required: 'Picture is required' }}
                render={({ field: { value, onChange, ...field } }) => (
                  <FileInput
                    {...field}
                    label="Добавить новое изображение"
                    value={value as File}
                    id="solution-image-option-file"
                    onChange={(file: File | null) => {
                      onChange(file);
                      dispatch(setFileInfo(file?.name));
                      setImageLink(file);
                    }}
                    error={errors.files}
                    imageLink={imageLink?.name!}
                    isSolutionImage={solutionImageOption}
                    isSolution
                  />
                )}
              />
            </div>
            <TextArea
              id="solution-image-option-textarea"
              label="Краткое описание изображения"
              name="description"
              register={register}
              error={errors.description?.message}
              maxLength={100}
              defaultValue={imageData.description}
            />
            <div className={styles.buttonsBlock}>
              <button
                className="button buttonWithoutBorder"
                type="button"
                onClick={() => {
                  reset();
                  setImageLink(null);
                }}
              >
                Отмена
              </button>
              <button className={saveButtonStyle} type="submit" disabled={isDisabled}>
                Сохранить
              </button>
            </div>
          </div>
        </form>
      )}
      {solutionImageLines.length === 10 && <p className={styles.cardsLimit}>Достигнут лимит количества изображений.</p>}
      {solutionImageLines && (
        <div className={styles.imageLines}>
          {solutionImageLines.map((line) => (
            <div key={line.orderNum}>
              <SolutionImageLine
                handleDelete={() => handleDeleteImageButton(line.file?.id || '', line.name)}
                description={line.description}
                file={line.file}
              />
            </div>
          ))}
        </div>
      )}
      {isModalOpen && (
        <Modal setIsActiveModal={setModalOpen} handleClick={handleDeleteSolutionImage}>
          <p>Вы уверены, что хотите удалить изображение &apos;{solutionImageDeleteName}&apos;?</p>
        </Modal>
      )}
      {errorMessage && <ErrorNotification message={errorMessage} />}
      {successMessage && <SuccessNotification message={successMessage} />}
    </>
  );
};
