import { createAsyncThunk } from '@reduxjs/toolkit';
import { deletePlatformFromHistory } from 'modules/Profile/api';
import { clearDeleteFromHistoryMessages, clearPlatformsForDeletingFromViewed } from 'store/features';

import { setViewedPlatformsAction } from './setViewedPlatformsAction';

export const deleteSelectedPlatformsFromHistoryAction = createAsyncThunk<
  PromiseSettledResult<void>[],
  string[],
  { rejectValue: string }
>(
  'adminPlatforms/deleteSelectedPlatformsFromHistoryAction',
  async (selectedPlatforms, { dispatch, rejectWithValue }) => {
    const requests = selectedPlatforms.map((id) => deletePlatformFromHistory(id));
    const responses = await Promise.allSettled(requests);
    const errors = responses.filter((response) => response.status === 'rejected');
    dispatch(clearDeleteFromHistoryMessages());

    if (errors.length > 0) {
      return rejectWithValue('Удаление не выполнено');
    }

    await dispatch(setViewedPlatformsAction());
    dispatch(clearPlatformsForDeletingFromViewed());
    return responses;
  },
);
