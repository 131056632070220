import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateSolutionReview,
  deleteSolutionReview,
  getSolutionReview,
  patchSolutionReview,
  postSolutionReview,
} from 'modules/Admin/api/solutionReviews';

interface IDeleteSolutionReviewAction {
  solutionId: string;
  reviewId: string;
}
interface IChangeSolutionReviewAction {
  solutionId: string;
  body: {
    date: string;
    text: string;
    author: string;
  };
  reviewId: string;
}

export const setSolutionReviewAction = createAsyncThunk<void, string>(
  'solutionReviews/setSolutionReviewAction',
  (solutionId) => getSolutionReview(solutionId),
);

export const createSolutionReviewAction = createAsyncThunk<void, ICreateSolutionReview>(
  'solutionReviews/createSolutionReviewAction',
  async (body, { dispatch }) => {
    await postSolutionReview(body);
    dispatch(setSolutionReviewAction(body.solutionId));
  },
);

export const deleteSolutionReviewAction = createAsyncThunk<void, IDeleteSolutionReviewAction>(
  'solutionReviews/deleteSolutionReviewAction',
  async ({ reviewId, solutionId }, { dispatch }) => {
    await deleteSolutionReview(reviewId);
    dispatch(setSolutionReviewAction(solutionId));
  },
);

export const changeSolutionReviewAction = createAsyncThunk<void, IChangeSolutionReviewAction>(
  'solutionReviews/changeSolutionReviewAction',
  async ({ reviewId, body, solutionId }, { dispatch }) => {
    await patchSolutionReview({ reviewId, body });
    dispatch(setSolutionReviewAction(solutionId));
  },
);
