import { docNameTaskOrEventRequiredValidation, docNameTaskOrEventValidation } from 'shared';
import * as Yup from 'yup';

export const addTaskSheme = Yup.object().shape({
  newTask: docNameTaskOrEventValidation,
});

export const changeTaskSheme = Yup.object().shape({
  task: docNameTaskOrEventRequiredValidation,
});
