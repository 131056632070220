import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { getSolutionOption } from 'modules/Admin/api';
import { ISolutionOption } from 'store/types';

export const setSolutionOptionAction = createAsyncThunk<ISolutionOption, string, { rejectValue: string }>(
  'solutionOption/setSolutionOptionAction',
  async (solutionId, { rejectWithValue }) => {
    try {
      const response = await getSolutionOption(solutionId);
      return response;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
