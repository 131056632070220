import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { SolutionSelect, SwitchBtn } from 'modules/Admin/components';
import { TextInput } from 'modules/Admin/components/TextInput/TextInput';
import { NumberInput } from 'modules/Admin/components/NumberInput/NumberInput';

import { Loading, Modal, TextArea } from 'sharedComponents';
import { ROUTE, TabsRoutes } from 'modules/Admin/router/routes';
import { createSolutionScheme } from 'modules/Admin/data/createSolutionSheme';

import cn from 'classnames';

import {
  getSolutionOptionIsLoading,
  getSolutionState,
  setSolutionOptionAction,
  createSolutionOptionAction,
  useAppDispatch,
  useAppSelector,
  ISolutionOptionWithoutIsActive,
  getAdminPlatforms,
  setAdminPlatformsAction,
  getIsAdminLoaderVisible,
  clearSolutionOption,
  setSolutionOptionErrorMessage,
  getSolutionOptionIsRedirect,
  setSolutionOptionIsRedirect,
  updateSolutionOptionAction,
  getSolutionOptionIsNeedUpdateInit,
  setSolutionOptionIsNeedUpdate,
  ISolutionOption,
  clearSolutionOptionMessages,
  getRole,
} from 'store';
import { CreateSolutionBlocks } from 'modules/Admin/types';
import { getDifferencesObjSolution } from 'modules/Admin/utils/helpers';
import { ROLES } from 'shared';

import styles from './styles.module.scss';

import buttonStyle from '../../../../styles/button.style.module.scss';

export const SolutionMainOptionPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id: solutionId } = useParams();

  const solutionState = useAppSelector(getSolutionState);
  const { isActive, ...initialValue } = solutionState;

  const isLoading = useAppSelector(getSolutionOptionIsLoading);
  const isRedirect = useAppSelector(getSolutionOptionIsRedirect);
  const isNeedUpdateInit = useAppSelector(getSolutionOptionIsNeedUpdateInit);

  const adminPlatforms = useAppSelector(getAdminPlatforms);
  const allPlatforms = adminPlatforms.map((el) => ({ id: el.id, value: el.id, label: el.name }));
  const isLoadingPlatforms = useAppSelector(getIsAdminLoaderVisible);

  const [isActiveSolution, setActiveSolution] = useState<boolean>(isActive);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [objDifference, setObjDifference] = useState<Partial<ISolutionOption>>({});
  const [selectedPlatformName, setSelectedPlatformName] = useState<string>('');

  const userRole = useAppSelector(getRole);
  const isAdmin = userRole === ROLES.SUPERADMIN || userRole === ROLES.ADMIN;

  const {
    control,
    trigger,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<ISolutionOptionWithoutIsActive>({
    mode: 'onChange',
    defaultValues: initialValue,
    resolver: yupResolver(createSolutionScheme(!!initialValue.platform.id)),
  });

  // очистка старых уведомлений
  useEffect(() => {
    dispatch(clearSolutionOptionMessages());
  }, [dispatch]);

  // получение значений решения
  useEffect(() => {
    if (solutionId) dispatch(setSolutionOptionAction(solutionId));
    else dispatch(clearSolutionOption());
  }, [solutionId, dispatch]);

  // установление isActive
  useEffect(() => {
    setActiveSolution(isActive);
  }, [isActive]);

  // заполнение полей
  useEffect(() => {
    const { platform, ...solutionValue } = initialValue;
    if (isNeedUpdateInit) {
      Object.keys(solutionValue).forEach((key) => {
        const field = key as keyof typeof solutionValue;
        setValue(field, solutionValue[field]);
      });
      if (!solutionValue.eventPlan) setValue('eventPlan', '');
      dispatch(setSolutionOptionIsNeedUpdate(false));
    }
  }, [initialValue, isNeedUpdateInit, setValue, dispatch]);

  // получение всех платформ
  useEffect(() => {
    if (!adminPlatforms.length) dispatch(setAdminPlatformsAction({ type: 'ASC', doNotBelongToFilters: false }));
  }, [adminPlatforms, dispatch]);

  // переход на страницу фильтров
  useEffect(() => {
    if (isRedirect) {
      const id = solutionId ?? initialValue.id;
      const editSolutionOptionsPath = `${ROUTE.ADMIN}/${ROUTE.SOLUTIONS}/${id}/${TabsRoutes.filtersOptions}`;
      if (id) navigate(editSolutionOptionsPath);
      dispatch(setSolutionOptionIsRedirect(false));
    }
  }, [isRedirect, dispatch, navigate, solutionId, initialValue]);

  useEffect(() => {
    if (initialValue.platform.id && adminPlatforms.length) {
      const selectedPlatform = adminPlatforms.find((platformItem) => platformItem.id === initialValue.platform.id);
      const platformName = selectedPlatform ? selectedPlatform.name : '';
      setSelectedPlatformName(platformName);
      setValue('platformName', platformName);
    }
  }, [initialValue.platform.id, adminPlatforms, setValue]);

  const onSubmit = (obj: ISolutionOptionWithoutIsActive) => {
    if (obj.priceMax < obj.priceMin) {
      dispatch(setSolutionOptionErrorMessage('Значение второй цены должно быть больше или равно первой'));
      return;
    }

    if (!initialValue.id) {
      // создание решения
      const { platformName, platform, ...rest } = obj;
      const solutionOptions = {
        ...rest,
        eventPlan: obj.eventPlan || null,
        isActive: isActiveSolution,
      };
      dispatch(createSolutionOptionAction(solutionOptions));
    } else {
      // редактирование решения: открыть модалку
      const { platform, ...rest } = obj;
      const updatedValue = {
        ...rest,
        eventPlan: obj.eventPlan || null,
      };
      const objDiff = getDifferencesObjSolution({ initialValue, updatedValue, isActive, isActiveSolution });
      if (Object.keys(objDiff).length) {
        setObjDifference(objDiff);
        setIsVisibleModal(true);
      } else dispatch(setSolutionOptionErrorMessage('Решение не было изменено'));
    }
  };

  // запрос на редактирование решения
  const handleClickChange = () => {
    if (solutionId) {
      const body = objDifference;
      dispatch(updateSolutionOptionAction({ id: solutionId, body }));
      setIsVisibleModal(false);
      reset(initialValue);
    }
  };

  const handleActiveSolution = () => {
    setActiveSolution((prev) => !prev);
  };

  const handleOnchangePrice = (fieldName: 'priceMin' | 'priceMax') => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    /** обрабатываем значения в формате "1e1 | 000001 | .0" убирая e и 0 */
    const newVal = Number.isNaN(parseFloat(value)) ? +value : parseFloat(value);
    setValue(fieldName, newVal, { shouldDirty: true });
    trigger(fieldName);
  };

  const getDefaultPrice = (field: 'priceMin' | 'priceMax') => (solutionId ? initialValue[field] : '');

  const switchChanged = isActiveSolution !== isActive;
  const btnIsDisabled = !switchChanged && (!isValid || !isDirty);
  const saveButtonStyle = cn(buttonStyle.invertButton, styles.saveBtn);
  const activityStyle = cn(styles.activity, {
    [styles.isActive]: isActiveSolution,
  });

  return isLoading || isLoadingPlatforms || isNeedUpdateInit ? (
    <Loading />
  ) : (
    <>
      <div className={styles.switcherBtnContainer}>
        {isAdmin ? (
          <SwitchBtn
            isToggled={isActiveSolution}
            setIsToggled={handleActiveSolution}
            id="switcher-btn-solution"
            unActiveText="Неактивна"
            activeText="Активна"
          />
        ) : (
          <span className={activityStyle}>{isActiveSolution ? 'Активна' : 'Неактивна'}</span>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.name}>
          <TextInput
            name="name"
            id="solutionName"
            register={register}
            label={CreateSolutionBlocks.name}
            error={errors?.name}
          />
        </div>
        {initialValue.platform.id ? (
          <div>
            <TextInput
              name="platformName"
              id="solutionPlatformName"
              register={register}
              label={CreateSolutionBlocks.platformId}
              error={errors.platformId}
              isDisabled={!!selectedPlatformName}
            />
          </div>
        ) : (
          <div className={styles.platform}>
            <SolutionSelect
              name="platformId"
              id="solutionPlatformId"
              register={register}
              label={CreateSolutionBlocks.platformId}
              error={errors?.platformId}
              options={allPlatforms}
            />
          </div>
        )}
        <div className={styles.description}>
          <TextArea
            name="description"
            id="solutionDescription"
            register={register}
            label={CreateSolutionBlocks.description}
            error={errors?.description?.message}
            maxLength={255}
          />
        </div>
        <div className={styles.price}>
          <p className={styles.priceLabel}>{CreateSolutionBlocks.price}</p>
          <div className={styles.priceFlex}>
            <Controller
              control={control}
              name="priceMin"
              render={({ field: { value, onChange, ...field } }) => (
                <NumberInput
                  {...field}
                  size="m"
                  id="solutionPriceMin"
                  register={register}
                  error={errors?.priceMin}
                  inputPrefix="от"
                  onChange={handleOnchangePrice('priceMin')}
                  defaultValue={getDefaultPrice('priceMin')}
                  warningAbsolute
                />
              )}
            />
            <Controller
              control={control}
              name="priceMax"
              render={({ field: { value, onChange, ...field } }) => (
                <NumberInput
                  {...field}
                  size="m"
                  id="solutionPriceMax"
                  register={register}
                  error={errors?.priceMax}
                  inputPrefix="до"
                  onChange={handleOnchangePrice('priceMax')}
                  defaultValue={getDefaultPrice('priceMax')}
                  warningAbsolute
                />
              )}
            />
          </div>
        </div>
        <div className={styles.link}>
          <TextInput
            name="eventPlan"
            id="linkEventPlan"
            register={register}
            label={CreateSolutionBlocks.eventPlan}
            error={errors?.eventPlan}
          />
        </div>
        <button className={saveButtonStyle} type="submit" disabled={btnIsDisabled}>
          Сохранить
        </button>
      </form>
      {isVisibleModal && (
        <Modal
          setIsActiveModal={(prev) => setIsVisibleModal(prev)}
          submitButtonText="Изменить"
          handleClick={handleClickChange}
        >
          <p>Изменить решение?</p>
        </Modal>
      )}
    </>
  );
};
