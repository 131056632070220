import React from 'react';
import {
  getUsersPageNumberOfUsersPerPage,
  setNumberOfUsersPerPage,
  setFiltrationSearch,
  setUsersAction,
  useAppDispatch,
  useAppSelector,
  getUsersPageFiltration,
  setPageNumber,
} from 'store';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from 'sharedComponents';
import { searchUsersPageScheme } from 'modules/Admin/data';
import { IFilterSearch } from 'modules/Admin/types';
import { SearchIcon } from 'modules/Admin/assets';

import styles from './styles.module.scss';
import { options, styleSelectAmountUsers } from './config';

export const UsersPageHeader = () => {
  const dispatch = useAppDispatch();
  const usersAmount = useAppSelector(getUsersPageNumberOfUsersPerPage);
  const { search: defaultSearch } = useAppSelector(getUsersPageFiltration);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<IFilterSearch>({
    mode: 'onChange',
    defaultValues: { text: defaultSearch || '' },
    resolver: yupResolver(searchUsersPageScheme),
  });

  const onSubmit = ({ text }: IFilterSearch) => {
    const data = text || null;
    dispatch(setFiltrationSearch(data));
    dispatch(setPageNumber(1));
    dispatch(setUsersAction());
  };

  const setUsersAmount = (value: string) => {
    dispatch(setNumberOfUsersPerPage(+value));
    dispatch(setPageNumber(1));
    dispatch(setUsersAction());
  };

  const isDisabledBtnSave = !isValid;

  // отображение количества пользователей в <Select/> из State
  const formateAmountToOption = (amount: number) => options.find((item) => +item.value === amount);

  return (
    <div className={styles.usersPageHeader}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formSearch}>
        <Input register={register} id="text" Icon={<SearchIcon />} iconStart error={errors.text?.message} />
        <button className="button buttonSecondary" type="submit" disabled={isDisabledBtnSave}>
          Поиск
        </button>
      </form>
      <div className={styles.selectAmountUsers}>
        <Select
          options={options}
          defaultValue={formateAmountToOption(usersAmount)}
          onChange={(option) => setUsersAmount(option?.value || '')}
          styles={styleSelectAmountUsers}
          isSearchable={false}
        />
      </div>
    </div>
  );
};
