import React, { useEffect } from 'react';
import {
  getUsersPageIsAllUsersChecked,
  getUsersPageResponse,
  setAllUsersChecked,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { UsersPageTableLine } from 'modules/Admin/components';

import styles from './styles.module.scss';

export const UsersPageTableLines = () => {
  const dispatch = useAppDispatch();
  const { rows } = useAppSelector(getUsersPageResponse);
  const isAllUsersChecked = useAppSelector(getUsersPageIsAllUsersChecked);

  useEffect(() => {
    if (isAllUsersChecked) dispatch(setAllUsersChecked());
  }, [rows, isAllUsersChecked, dispatch]);

  return (
    <div className={styles.usersPageTableLines}>
      {rows.map((item) => (
        <UsersPageTableLine user={item} key={item.id} />
      ))}
    </div>
  );
};
