import { FavoriteIcon, CheckedIcon, NotCheckedIcon } from 'assets';

import { useState, useRef, MouseEvent } from 'react';

import { Link, generatePath } from 'react-router-dom';

import { getUser, toggleSolutionForDeletingFromViewed, useAppDispatch, useAppSelector } from 'store';

import { CustomLogo } from 'components/CustomLogo/CustomLogo';

import { ROUTE } from 'router';
import cn from 'classnames';

import styles from './styles.module.scss';

export interface SolutionCardProps {
  platformName: string;
  platformLogoLink: string;
  name: string;
  description: string;
  priceMin: number;
  isSelected?: boolean;
  isSelectMode?: boolean;
  isFavorite: boolean;
  id: string;
  toggleFavorite: (isFavorite: boolean, cardId: string) => void;
}

const SolutionCardItem = ({
  platformLogoLink,
  platformName,
  name,
  description,
  priceMin,
  isSelected = false,
  isSelectMode = false,
  isFavorite = false,
  id,
  toggleFavorite,
}: SolutionCardProps) => {
  const { isShowFavoriteIcon } = useAppSelector(getUser);
  const [imageLoadError, setImageLoadError] = useState(false);

  const textRef = useRef<HTMLParagraphElement>(null);

  const dispatch = useAppDispatch();

  const pathToSolution = generatePath(ROUTE.SOLUTION_DETAILS, { solutionId: id });

  const handleSelectSolution = () => {
    if (isSelectMode) {
      dispatch(toggleSolutionForDeletingFromViewed(id));
    }
  };

  const handleFavorite = (e: MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    toggleFavorite(isFavorite, id);
  };
  const imageError = !platformLogoLink || imageLoadError;

  const handleImageLoadError = () => {
    setImageLoadError(true);
  };

  const cardStyle = cn(styles.card, {
    [styles.checkedCard]: isSelected,
  });
  const selectIcon = isSelected ? <CheckedIcon /> : <NotCheckedIcon />;

  return (
    <div className={cardStyle} onClick={handleSelectSolution}>
      <div className={styles.wrap}>
        <div className={styles.list}>
          <div className={styles.title}>{name}</div>
          {isShowFavoriteIcon && (
            <button className={styles.flag} type="button">
              <FavoriteIcon className={isFavorite ? styles.favorite : styles.notFavorite} onClick={handleFavorite} />
            </button>
          )}
        </div>
        <div className={styles.iconWrapper}>
          {imageError ? (
            <CustomLogo title={name} small />
          ) : (
            <img
              className={styles.icon}
              src={platformLogoLink}
              onError={handleImageLoadError}
              alt={`logo card ${name}`}
            />
          )}

          <span className={styles.iconText}>{platformName}</span>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.descriptionWrapper}>
          <p ref={textRef} className={styles.text}>
            {description.length > 135 ? `${description.slice(0, 135)}...` : description}
          </p>
          <div className={styles.price}>Стоимость от {priceMin} ₽</div>
        </div>
        <div className={styles.linkWrapper}>
          <Link className={styles.link} to={pathToSolution}>
            Подробнее
          </Link>
        </div>
        {isSelectMode && (
          <button type="button" className={styles.selectIconWrapper}>
            {selectIcon}
          </button>
        )}
      </div>
    </div>
  );
};

export { SolutionCardItem };
