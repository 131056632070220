import { docPasswordRequiredValidation } from 'shared';
import * as Yup from 'yup';

export const passwordScheme = Yup.object().shape({
  password: Yup.string().test('is-valid-password', (value, context) => {
    if (value === '') return true;
    try {
      docPasswordRequiredValidation.validateSync(value);
      return true;
    } catch (err: any) {
      return context.createError({ message: err.errors[0] });
    }
  }),
  passwordConfirm: Yup.string()
    .test('is-valid-password', (value, context) => {
      if (value === '') return true;
      try {
        docPasswordRequiredValidation.validateSync(value);
        return true;
      } catch (err: any) {
        return context.createError({ message: err.errors[0] });
      }
    })
    // eslint-disable-next-line func-names
    .test('passwords-match', 'Пароли должны совпадать', function (value: string | undefined) {
      if (value === '') return true;
      return value === this.parent.password;
    }),
});
