import {
  AppDispatch,
  setPlatformsAction,
  setPlatformsPagePrices,
  setSolutionsAction,
  setSolutionsPagePrices,
} from 'store';

type PageTypes = 'solutionsPage' | 'platformsPage';

interface IUpdatePricesSlice {
  page: PageTypes;
  priceMin: string;
  priceMax: string;
  dispatch: AppDispatch;
}

interface IUpdateCards {
  page: PageTypes;
  dispatch: AppDispatch;
}

export const updatePricesSlice = ({ page, priceMin, priceMax, dispatch }: IUpdatePricesSlice) => {
  const actions = {
    solutionsPage: () => dispatch(setSolutionsPagePrices({ priceMin, priceMax })),
    platformsPage: () => dispatch(setPlatformsPagePrices({ priceMin, priceMax })),
  };
  const action = actions[page];
  if (action) action();
};

export const updateCards = ({ page, dispatch }: IUpdateCards) => {
  const actions = {
    solutionsPage: () => dispatch(setSolutionsAction({ amount: '1' })),
    platformsPage: () => dispatch(setPlatformsAction({ amount: '1' })),
  };
  const action = actions[page];
  if (action) action();
};
