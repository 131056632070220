import { Controller, useForm } from 'react-hook-form';
import { IReview } from 'modules/Admin/types/reviews';
import { yupResolver } from '@hookform/resolvers/yup';
import { reviewFormSchema } from 'modules/Auth/data/reviewSchema';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';
import { calendar } from 'modules/Admin/assets';
import { useCallback, useEffect, useState } from 'react';
import warning from 'pages/Auth/assets/icons/warning.svg';
import { ErrorNotification, Input, Modal, SuccessNotification, TextArea } from 'sharedComponents';

import {
  changeSolutionReviewAction,
  clearErrorMessageReview,
  clearSuccessMessageReview,
  createSolutionReviewAction,
  deleteSolutionReviewAction,
  getReviewErrorMessage,
  getReviewSuccessMessage,
  getSolutionReviews,
  setSolutionReviewAction,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { useParams } from 'react-router';
import cn from 'classnames';
import { format } from 'date-fns';
import { buttonStyles } from 'modules/Auth/UI';

import styles from '../../components/AdminReviews/styles.module.scss';

export const SettingSolutionReviewsPage = () => {
  const { id: solutionId } = useParams();
  const [error] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const reviews = useAppSelector(getSolutionReviews);
  const errorMessage = useAppSelector(getReviewErrorMessage);
  const successMessage = useAppSelector(getReviewSuccessMessage);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedReview, setSelectedReview] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enteredText, setEnteredText] = useState('');

  const {
    register,
    formState: { errors, isDirty, isValid },
    setValue,
    clearErrors,
    handleSubmit,
    reset,
    control,
  } = useForm<IReview>({
    mode: 'onChange',
    resolver: yupResolver(reviewFormSchema),
  });

  const isDisabled = isLoading || !isValid || !isDirty;
  const itemButtonStyle = cn(buttonStyles.invertButton, styles.btn);

  const handleCalendarClick = () => {
    setCalendarOpen(!isCalendarOpen);
    if (!isCalendarOpen) {
      setSelectedDate(new Date());
    }
  };

  const handleDateChange = (date: Date) => {
    handleCalendarClick();
    setSelectedDate(date);
    setValue('date', date.toISOString(), { shouldDirty: true, shouldValidate: true });
    clearErrors('date');
  };

  const resetForm = useCallback(() => {
    setSelectedDate(null);
    reset({
      date: format(new Date(), 'yyyy-MM-dd'),
      author: '',
      text: '',
    });
  }, [reset]);

  const onSubmitNewReview = (obj: IReview) => {
    if (solutionId) {
      const formattedDateValue = obj.date ? format(new Date(obj.date), 'yyyy-MM-dd') : '';
      const body = {
        solutionId,
        text: obj.text,
        author: obj.author,
        date: formattedDateValue,
      };
      dispatch(createSolutionReviewAction(body));
      resetForm();
      setEnteredText({} as string);
    }
  };

  useEffect(() => {
    if (solutionId) {
      dispatch(setSolutionReviewAction(solutionId));
    }
  }, [solutionId, dispatch]);

  const onSubmitEditionReview = (body: IReview) => {
    if (isEditing && selectedReview) {
      const formattedDateValue = body.date ? format(new Date(body.date), 'yyyy-MM-dd') : '';

      const updatedBody = {
        ...body,
        date: formattedDateValue,
      };
      dispatch(
        changeSolutionReviewAction({
          solutionId: String(solutionId),
          reviewId: String(selectedReview),
          body: updatedBody,
        }),
      );
      setSelectedReview(null);
      setIsEditing(false);
      resetForm();
      setEnteredText('');
    }
  };

  useEffect(() => {
    if (isEditing && reviews && reviews.length > 0) {
      const reviewToEdit = reviews.find((review) => review.id === selectedReview);
      if (reviewToEdit) {
        const { id, date, author, text } = reviewToEdit;
        setSelectedReview(id);
        setSelectedDate(new Date(date));
        reset({
          date: format(new Date(date), 'yyyy-MM-dd'),
          author,
          text,
        });
      }
    }
  }, [reviews, selectedReview, isEditing, reset, setSelectedReview, setSelectedDate]);

  const clearMessage = useCallback(() => {
    dispatch(clearErrorMessageReview());
    dispatch(clearSuccessMessageReview());
  }, [dispatch]);

  const handleClickButtonDeleteReview = (id: string) => {
    setSelectedReview(id);
    setIsModalOpen(true);
  };

  const handleClickDeleteReview = () => {
    if (selectedReview) {
      dispatch(deleteSolutionReviewAction({ solutionId: String(solutionId), reviewId: selectedReview }));
      setIsModalOpen(false);
      resetForm();
      setIsEditing(false);
      setEnteredText('');
    }
  };

  const handleClickEditButton = (reviewId: string, text: string) => {
    setSelectedReview(reviewId);
    setIsEditing(true);
    setEnteredText(text);
  };

  const habdleClickCancelEdition = () => {
    setIsEditing(!isEditing);
    setSelectedReview(null);
    resetForm();
    setEnteredText('');
  };

  return (
    <div className={styles.admin}>
      {error && <div className={styles.error}>{error}</div>}
      <form
        className={styles.form}
        onSubmit={isEditing ? handleSubmit(onSubmitEditionReview) : handleSubmit(onSubmitNewReview)}
        autoComplete="off"
      >
        <label htmlFor="date" className={styles.label}>
          Дата отзыва
          <div className={styles.wrap}>
            <Controller
              control={control}
              name="date"
              render={() => (
                <>
                  <DatePicker
                    id="date"
                    selected={selectedDate}
                    placeholderText="__.__.____"
                    className={errors.date ? styles.warningDate : styles.date}
                    onChange={(date) => date instanceof Date && handleDateChange(date)}
                    open={isCalendarOpen}
                    dateFormat="dd.MM.yyyy"
                    locale={ru}
                    required
                    disabled
                  />
                  <img src={calendar} alt="calendar" className={styles.calendar} onClick={handleCalendarClick} />
                </>
              )}
            />
          </div>
        </label>
        {errors.date && (
          <div className={styles.error}>
            <span className={styles.warning}>
              <img src={warning} alt="warning" />
            </span>
            {errors.date.message}
          </div>
        )}
        <div className={styles.nameWrapper}>
          <Input
            id="author"
            register={register}
            label="Имя пользователя"
            error={errors.author?.message}
            className={styles.name}
          />
        </div>
        <TextArea
          name="text"
          id="text"
          register={register}
          label="Отзыв"
          error={errors.text?.message}
          maxLength={255}
          className={styles.field}
          defaultValue={enteredText}
        />
        <button type="submit" className={itemButtonStyle} disabled={isDisabled}>
          {!isEditing ? 'Опубликовать' : 'Изменить'}
        </button>
        {isEditing && (
          <button type="button" className={styles.btn} onClick={habdleClickCancelEdition}>
            Отменить
          </button>
        )}
        {successMessage && <SuccessNotification message={successMessage} clearMessage={clearMessage} />}
        {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessage} />}
      </form>
      <div className={styles.reviews}>
        <ul className={styles.list}>
          {reviews.map(({ id, author, text, date }) => (
            <li key={id}>
              <div className={cn(styles.item, { [styles.editing]: id === selectedReview })}>
                <div className={styles.container}>
                  <p className={styles.nameTxt}>{author}</p>
                </div>
                <p className={styles.rvTxt}>{text}</p>
                <div className={styles.container}>
                  <p className={styles.txt}>{date && format(new Date(date), 'dd.MM.yyyy')}</p>
                  <div className={styles.container}>
                    <button className={styles.btnTxt} type="button" onClick={() => handleClickEditButton(id, text)}>
                      Редактировать
                    </button>
                    <button className={styles.btnTxt} type="button" onClick={() => handleClickButtonDeleteReview(id)}>
                      Удалить
                    </button>
                  </div>
                </div>
              </div>
              {isModalOpen && (
                <Modal setIsActiveModal={setIsModalOpen} handleClick={handleClickDeleteReview}>
                  <p>Вы уверены, что хотите удалить отзыв?</p>
                </Modal>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
