import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSolutionEvent } from 'modules/Admin/api';
import { setSolutionEventsAction } from 'store';

export interface ICreateSolutionEventAction {
  solutionId: string;
  text: string;
}

export const createSolutionEventAction = createAsyncThunk<void, ICreateSolutionEventAction>(
  'solutionEvents/createSolutionEventAction',
  async ({ solutionId, text }, { dispatch }) => {
    await createSolutionEvent({ solutionId, body: { text } });
    dispatch(setSolutionEventsAction(solutionId));
  },
);
