import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  setSolutionEventsAction,
  createSolutionEventAction,
  deleteSolutionEventAction,
  changeSolutionEventAction,
} from 'store/actions';
import { SolutionEvents, SolutionEventsDetailsState } from 'store/types';

const initialState: SolutionEventsDetailsState = {
  solutionEvents: [],
  isLoading: false,
  errorMessage: '',
  successMessage: '',
};

const setLoading = (state: SolutionEventsDetailsState) => {
  state.isLoading = true;
  state.errorMessage = '';
  state.successMessage = '';
};

const solutionEventsSlice = createSlice({
  name: 'solutionEvents',
  initialState,
  reducers: {
    clearEventsMessages: (state) => {
      state.errorMessage = '';
      state.successMessage = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setSolutionEventsAction.pending, setLoading)
      .addCase(setSolutionEventsAction.fulfilled, (state, { payload }: PayloadAction<SolutionEvents[] | undefined>) => {
        if (payload) {
          state.solutionEvents = payload;
        }
        state.isLoading = false;
      })
      .addCase(setSolutionEventsAction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMessage = payload || 'Ошибка при получении мероприятий';
      })

      .addCase(createSolutionEventAction.pending, setLoading)
      .addCase(createSolutionEventAction.fulfilled, (state) => {
        state.successMessage = 'Мероприятие успешно добавлено';
      })
      .addCase(createSolutionEventAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при добавлении мероприятия';
      })

      .addCase(deleteSolutionEventAction.pending, setLoading)
      .addCase(deleteSolutionEventAction.fulfilled, (state) => {
        state.successMessage = 'Мероприятие успешно удалено';
      })
      .addCase(deleteSolutionEventAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при удалении мероприятия';
      })

      .addCase(changeSolutionEventAction.pending, setLoading)
      .addCase(changeSolutionEventAction.fulfilled, (state) => {
        state.successMessage = 'Мероприятие успешно изменено';
      })
      .addCase(changeSolutionEventAction.rejected, (state) => {
        state.isLoading = false;
        state.errorMessage = 'Ошибка при изменении мероприятия';
      });
  },
});

export const {
  reducer: solutionEventsReducer,
  actions: { clearEventsMessages },
} = solutionEventsSlice;
