import { authAxiosInstance } from 'modules/Auth/api';

import { endpoints } from './endpoints';

export const deleteCategoriesFiltersForPlatforms = (id: string, deleteFilters: boolean): Promise<void> =>
  authAxiosInstance.delete(`${endpoints.categoriesFiltersForPlatforms}/${id}`, {
    params: {
      deleteFilters,
    },
  });
