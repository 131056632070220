import { endpoints } from 'api/endpoints';
import { authAxiosInstance } from 'modules/Auth/api';
import { ISolutionOption, ISolutionOptionUpdate } from 'store';

interface IUpdateSolutionOption {
  id: string;
  body: Partial<ISolutionOptionUpdate>;
}

export const updateSolutionOption = ({ id, body }: IUpdateSolutionOption): Promise<ISolutionOption> =>
  authAxiosInstance.patch(`${endpoints.solutions}/${id}`, body);
