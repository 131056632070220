import { Outlet, useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { filtersTabsList } from './filters';

import { Tabs } from '../Tabs/Tabs';

export const AdminFilter = () => {
  const { filterId } = useParams();

  const disabledTabs = filterId ? [] : [0, 1];

  return (
    <div className={styles.platformsWrapper}>
      <Tabs tabs={filtersTabsList} linkId={filterId} disabledTabs={disabledTabs} />
      <Outlet />
    </div>
  );
};
