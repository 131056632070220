import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getAdminLoadingMessage,
  getAdminPlatforms,
  getAdminSortDirection,
  getAdminSortKey,
  getIsAdminLoaderVisible,
  getPlatformsDoNotBelongToFilters,
  getRole,
  useAppDispatch,
  useAppSelector,
} from 'store';
import cn from 'classnames';
import { setAdminPlatformsAction } from 'store/actions';
import { Loading, Modal, LoadingMessage, ErrorNotification } from 'sharedComponents';
import { ROLES } from 'shared';
import { errorHandler } from 'helpers';

import styles from './styles.module.scss';

import { PlatformLine } from '../PlatformLine/PlatformLine';

import { deletePlatform, updatePlatformData } from '../../api';

export const PlatformsList = () => {
  const platforms = useAppSelector(getAdminPlatforms);
  const sortDirection = useAppSelector(getAdminSortDirection);
  const sortKey = useAppSelector(getAdminSortKey);
  const doNotBelongToFilters = useAppSelector(getPlatformsDoNotBelongToFilters);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeId, setActiveId] = useState<string>('');
  const role = useAppSelector(getRole);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const dispatch = useAppDispatch();
  const isLoaderVisible = useAppSelector(getIsAdminLoaderVisible);
  const loadingMessage = useAppSelector(getAdminLoadingMessage);

  const isAdmin = role === ROLES.ADMIN || role === ROLES.SUPERADMIN;

  const reloadCardList = useCallback(
    () => dispatch(setAdminPlatformsAction({ type: sortDirection, name: sortKey, doNotBelongToFilters })),
    [sortDirection, sortKey, doNotBelongToFilters, dispatch],
  );

  const wrapperStyles = cn(styles.wrapper, { [styles.wrapperSecondary]: isAdmin });

  const handleToggle = useCallback(
    async (isActive: boolean, platformId: string) => {
      try {
        await updatePlatformData({ isActive }, platformId);
        reloadCardList();
      } catch (e) {
        const message = errorHandler(e);
        setErrorMessage(message);
      }
    },
    [reloadCardList],
  );

  const handleDelete = useCallback(async () => {
    try {
      await deletePlatform(activeId);
      reloadCardList();
    } catch (e) {
      const message = errorHandler(e);
      setErrorMessage(message);
    } finally {
      setIsModalOpen(false);
    }
  }, [activeId]); // eslint-disable-line  react-hooks/exhaustive-deps

  useEffect(() => {
    reloadCardList();
  }, [reloadCardList]);

  const PlatformRows = useMemo(
    () =>
      platforms.map((platform) => (
        <PlatformLine
          key={platform.id}
          platform={platform}
          handleToggle={handleToggle}
          setActiveId={setActiveId}
          setIsModalOpen={setIsModalOpen}
          setErrorMessage={setErrorMessage}
          isAdmin={isAdmin}
        />
      )),
    [platforms, handleToggle, isAdmin],
  );

  return (
    <>
      <div className={wrapperStyles}>
        <p className={styles.title}>Название платформы</p>
        <p className={styles.title}>Количество решений</p>
        {PlatformRows}
      </div>
      {isLoaderVisible && platforms.length === 0 && <Loading delay={300} />}
      <LoadingMessage message={loadingMessage} />
      {errorMessage && <ErrorNotification message={errorMessage} clearMessage={() => setErrorMessage('')} />}
      {isModalOpen && (
        <Modal setIsActiveModal={setIsModalOpen} handleClick={handleDelete}>
          <p>Вы уверены, что хотите удалить платформу?</p>
        </Modal>
      )}
    </>
  );
};
