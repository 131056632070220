import { createSlice } from '@reduxjs/toolkit';

import { getAllFiltersAction, getTopFiltersAction } from '../actions/filters';

import type { FilterGroup, IFilters } from '../types';

export type FiltrationFormType = { priceMin: string; priceMax: string };

interface IPlatfromsPageFilter {
  activeFilters: [];
  topPriorityFilters: IFilters[];
  allFilters: FilterGroup[];
  prices: FiltrationFormType;
  isAllFiltersLoading: boolean;
  allFiltersError: string;
  isTopFiltersLoading: boolean;
  topFiltersError: string;
}

const initialState: IPlatfromsPageFilter = {
  activeFilters: [],
  topPriorityFilters: [],
  allFilters: [],
  prices: { priceMin: '', priceMax: '' },
  isAllFiltersLoading: false,
  allFiltersError: '',
  isTopFiltersLoading: false,
  topFiltersError: '',
};

const platformsPageFiltersSlice = createSlice({
  name: 'platfromsPageFilters',
  initialState,
  reducers: {
    changePlatformsPageFilters: (state, { payload }) => {
      state.activeFilters = payload;
    },
    setPlatformsPagePrices: (state, { payload }) => {
      state.prices = payload;
    },
    clearPlatformsPageFiltersAndPrices: (state) => {
      state.activeFilters = [];
      state.prices = {
        priceMin: '',
        priceMax: '',
      };
    },
    clearPlatformsPageAllFiltersError: (state) => {
      state.allFiltersError = '';
    },
    clearPlatformsPageTopFiltersError: (state) => {
      state.topFiltersError = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFiltersAction.pending, (state) => {
      state.isAllFiltersLoading = true;
    });
    builder.addCase(getAllFiltersAction.fulfilled, (state, action) => {
      state.isAllFiltersLoading = false;
      state.allFilters = action.payload;
    });
    builder.addCase(getAllFiltersAction.rejected, (state) => {
      state.isAllFiltersLoading = false;
      state.allFiltersError = 'Ошибка загрузки фильтров';
    });
    builder.addCase(getTopFiltersAction.pending, (state) => {
      state.isTopFiltersLoading = true;
    });
    builder.addCase(getTopFiltersAction.fulfilled, (state, action) => {
      state.isTopFiltersLoading = false;
      state.topPriorityFilters = action.payload;
    });
    builder.addCase(getTopFiltersAction.rejected, (state) => {
      state.isTopFiltersLoading = false;
      state.topFiltersError = 'Ошибка загрузки фильтров';
    });
  },
});

export const {
  reducer: platfromsPageFiltersReducer,
  actions: {
    changePlatformsPageFilters,
    setPlatformsPagePrices,
    clearPlatformsPageFiltersAndPrices,
    clearPlatformsPageAllFiltersError,
    clearPlatformsPageTopFiltersError,
  },
} = platformsPageFiltersSlice;
