import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateSolutionOption } from 'modules/Admin/api';
import { ISolutionOption } from 'store/types';

import { setSolutionOptionAction } from './setSolutionOptionAction';

interface IUpdateSolutionOptionAction {
  id: string;
  body: Partial<ISolutionOption>;
}

export const updateSolutionOptionAction = createAsyncThunk<void, IUpdateSolutionOptionAction>(
  'solutionOption/updateSolutionOptionAction',
  async ({ id, body }, { dispatch }) => {
    await updateSolutionOption({ id, body });
    dispatch(setSolutionOptionAction(id));
  },
);
