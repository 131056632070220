import { UseFormRegister, FieldError } from 'react-hook-form';
import cn from 'classnames';
import { Warning } from 'sharedComponents';
import { IAddSolutionType } from 'store';

import styles from './styles.module.scss';

export interface ISolutionSelectProps {
  id: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  label?: string;
  options: IAddSolutionType[];
  defaultValue?: string;
  error?: FieldError;
}

export const SolutionSelect = ({
  id,
  name,
  placeholder,
  label,
  options,
  register,
  defaultValue,
  error,
}: ISolutionSelectProps) => {
  const selectStyle = cn(styles.select, {
    [styles.selectError]: error,
  });
  return (
    <label htmlFor={id} className={styles.label}>
      {label && <span>{label}</span>}
      <select id={id} defaultValue={defaultValue} placeholder={placeholder} {...register(name)} className={selectStyle}>
        <option value="">Выберите значение</option>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error?.message && <Warning message={error.message} absolute />}
    </label>
  );
};
