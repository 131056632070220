import { authAxiosInstance } from 'modules/Auth/api';
import { IUsersPageFiltration, IUsersPageResponse } from 'store';

import { endpoints } from './endpoints';

interface ICreateSolutionEvent {
  fullFiltration: IUsersPageFiltration;
  pageNumber: number;
  numberOfUsersPerPage: number;
}

export const getUsers = ({
  fullFiltration,
  pageNumber,
  numberOfUsersPerPage,
}: ICreateSolutionEvent): Promise<IUsersPageResponse> =>
  authAxiosInstance
    .post(`${endpoints.users}/filtration`, fullFiltration, {
      params: { pageNumber, numberOfUsersPerPage },
    })
    .then(({ data }) => data);
