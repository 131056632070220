import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReview, IReviewsDetailsState } from 'modules/Admin/types/reviews';
import {
  changeSolutionReviewAction,
  createSolutionReviewAction,
  deleteSolutionReviewAction,
  setSolutionReviewAction,
} from 'store/actions';

const initialState: IReviewsDetailsState = {
  isLoading: false,
  error: '',
  success: '',
  data: [],
};

const solutionReviewsSlice = createSlice({
  name: 'solutionReviews',
  initialState,
  reducers: {
    clearErrorMessageReview: (state) => {
      state.error = '';
    },
    clearSuccessMessageReview: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSolutionReviewAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setSolutionReviewAction.fulfilled, (state, { payload }: PayloadAction<IReview[] | void>) => {
      state.isLoading = false;
      if (payload) {
        state.data = payload;
      }
    });
    builder.addCase(setSolutionReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при получении отзыва';
    });

    builder.addCase(createSolutionReviewAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Отзыв успешно опубликован!';
    });
    builder.addCase(createSolutionReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при создании отзыва';
    });

    builder.addCase(deleteSolutionReviewAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Отзыв удален';
    });
    builder.addCase(deleteSolutionReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при удалении отзыва';
    });

    builder.addCase(changeSolutionReviewAction.fulfilled, (state) => {
      state.isLoading = false;
      state.success = 'Отзыв успешно изменен!';
    });
    builder.addCase(changeSolutionReviewAction.rejected, (state) => {
      state.isLoading = false;
      state.error = 'Ошибка при изменении отзыва';
    });
  },
});

export const solutionReviewsReducer = solutionReviewsSlice.reducer;
export const { clearErrorMessageReview, clearSuccessMessageReview } = solutionReviewsSlice.actions;
