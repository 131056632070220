import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateFilterFields, PlatformsFilterFieldsNames } from 'modules/Admin/types';
import { INPUTTYPE } from 'mocks';
import {
  useAppDispatch,
  setCurrentFilter,
  useAppSelector,
  clearCurrentFilter,
  addParametrOption,
  createPlatformsFilterAction,
  setAdminPlatformsCategoriesFiltersAction,
  getPlatformsFilterOptionsSelector,
  getPlatformsFilterCategoriesSelector,
  clearNotifications,
  error,
  setDefaultOptions,
  getPlatformsFilterErrorMessage,
  clearErrorMessage,
  success,
  getCurrentPlatformReloadFilter,
  setCurrentPlatformFilterAction,
} from 'store';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Checkbox, ErrorNotification, Input, Loading, Modal, TextArea, Warning } from 'sharedComponents';

import { PlusOption } from 'modules/Admin/assets';
import { FilterOptionItem } from 'components';

import { createPlatformFilterScheme } from 'modules/Admin/data';

import Select from 'react-select';

import { updatePlatformsFilterAction } from 'store/actions/updateCurrentFilterPlatform';

import { UpdateCurrentFilterPlatform } from 'modules/Admin/api/updateCurrentFilterPlatform';

import { ChangePlatformsFilter } from 'modules/Admin/api';

import styles from './styles.module.scss';

export interface IParametrFilterForm {
  filterGroupId: Option;
  filterName: string;
  descriptionLong: string;
  filterItemName: string;
  multiplicity: boolean;
  topPriority: boolean;
}

type Option = {
  label: string;
  value: string;
};

export const PlatformFilterOptions = () => {
  const { filterId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const reloadCurrentFilter = useAppSelector(getCurrentPlatformReloadFilter);
  const options = useAppSelector(getPlatformsFilterOptionsSelector);
  const categories = useAppSelector(getPlatformsFilterCategoriesSelector);
  const errorMessage = useAppSelector(getPlatformsFilterErrorMessage);
  const successNotification = useAppSelector(success);
  const errorNotification = useAppSelector(error);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [optionsChanged, setOptionsChanged] = useState(false);

  const sel = categories
    .filter(({ isDefault }) => isDefault === false)
    .map(({ filterGroupName, id }) => ({ label: filterGroupName, value: id }));

  const currentFilterGroup = {
    label: reloadCurrentFilter?.filterGroup.filterGroupName!,
    value: reloadCurrentFilter?.filterGroup.id!,
  };
  const initState: Omit<IParametrFilterForm, 'filterItemName'> = {
    filterGroupId: currentFilterGroup,
    filterName: reloadCurrentFilter?.filterName!,
    descriptionLong: reloadCurrentFilter?.descriptionLong!,
    multiplicity: reloadCurrentFilter?.inputType === 'checkbox'!,
    topPriority: reloadCurrentFilter?.topPriority!,
  };

  const {
    resetField,
    getValues,
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<IParametrFilterForm>({
    mode: 'onBlur',
    defaultValues: initState,
    resolver: yupResolver(createPlatformFilterScheme),
  });

  const isDisabled = !isDirty || !isValid || !initState.filterGroupId.value;

  const isCheckedMultiplicity = reloadCurrentFilter! && reloadCurrentFilter?.inputType === 'checkbox';
  const isCheckedTopPriority = reloadCurrentFilter! && reloadCurrentFilter.topPriority;

  const clearFilter = useCallback(() => dispatch(clearCurrentFilter()), [dispatch]);
  const clearMessages = useCallback(() => {
    dispatch(clearNotifications());
    dispatch(clearErrorMessage());
  }, [dispatch]);

  const handleAddOption = () => {
    if (errors.filterItemName) return;

    const { filterItemName } = getValues();
    if (filterItemName) {
      dispatch(addParametrOption(filterItemName));
      setOptionsChanged(true);
    }

    resetField('filterItemName');
  };

  const handleOnSubmit = async () => {
    if (isVisibleModal) {
      const { filterName, descriptionLong, topPriority, multiplicity, filterGroupId } = getValues();
      const newFilter: ChangePlatformsFilter = {
        filterName,
        ...(descriptionLong && { descriptionLong }),
        topPriority,
        filterGroupId: filterGroupId.value,
        inputType: (multiplicity ? INPUTTYPE.CHECKBOX : INPUTTYPE.RADIO) as keyof typeof INPUTTYPE,
        filterItemsName: options.map(({ filterItemName }) => filterItemName),
      };

      if (filterId) {
        const updateData: UpdateCurrentFilterPlatform = {
          ...newFilter,
          filterGroupId: newFilter.filterGroupId,
        };
        await dispatch(updatePlatformsFilterAction({ updateData, id: filterId }));
      } else {
        await dispatch(createPlatformsFilterAction(newFilter));
      }
    }
  };

  useEffect(() => {
    if (successNotification) navigate(-1);
  }, [successNotification, navigate]);

  useEffect((): any => {
    if (filterId) {
      dispatch(setAdminPlatformsCategoriesFiltersAction());
      dispatch(setCurrentFilter(filterId));
      dispatch(setCurrentPlatformFilterAction(filterId));
    }

    return clearFilter;
  }, [dispatch, clearFilter, filterId]);

  useEffect(() => {
    dispatch(setDefaultOptions(reloadCurrentFilter?.filterItemsForPlatforms ?? []));
  }, [reloadCurrentFilter, dispatch]);

  if (!reloadCurrentFilter && filterId) {
    return <Loading />;
  }

  const handleKeyDownForm = (event: React.KeyboardEvent<HTMLFormElement>) => {
    // Предотвращает отправку формы
    if (event.key === 'Enter') event.preventDefault();
  };

  const handleClickSaveFilter = () => {
    setIsVisibleModal(true);
  };

  const handleClickConfirmSaveFilter = () => {
    handleOnSubmit();
    setIsVisibleModal(false);
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(() => {
        handleOnSubmit();
      })}
      onKeyDown={handleKeyDownForm}
    >
      {currentFilterGroup.label ? (
        <div>
          <Input
            name="platformCategory"
            id={currentFilterGroup.label}
            register={register}
            defaultValue={currentFilterGroup.label}
            label={PlatformsFilterFieldsNames.category}
            isDisabled
          />
        </div>
      ) : (
        <div className={styles.category}>
          <div className={styles.span}>Категория</div>
          <Controller
            control={control}
            defaultValue={currentFilterGroup}
            name={CreateFilterFields.category}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                isMulti={false}
                onChange={(selValue) => onChange(selValue as Option)}
                classNamePrefix="customSelect"
                options={sel}
                placeholder="Выберите категорию"
                styles={{
                  control: (base) => ({ ...base, borderColor: errors.filterGroupId?.message ? '#FFAA00' : ' ' }),
                }}
              />
            )}
          />
          {errors.filterGroupId?.message && <Warning message={errors.filterGroupId?.message} />}
        </div>
      )}
      <div className={styles.nameDescription}>
        <Input
          name={CreateFilterFields.filterName}
          id={CreateFilterFields.filterName}
          defaultValue={reloadCurrentFilter?.filterName}
          register={register}
          label={PlatformsFilterFieldsNames.filterName}
          error={errors[CreateFilterFields.filterName]?.message}
        />
        <div className={styles.description}>
          <TextArea
            name={CreateFilterFields.descriptionLong}
            id={CreateFilterFields.descriptionLong}
            defaultValue={reloadCurrentFilter?.descriptionLong}
            register={register}
            maxLength={255}
            label={PlatformsFilterFieldsNames.description}
            error={errors[CreateFilterFields.descriptionLong]?.message}
          />
        </div>
      </div>
      <div className={styles.parametr}>
        <div>
          <div className={styles.span}>{PlatformsFilterFieldsNames.parametr}</div>
          <div className={styles.parametrWrapper}>
            <Input
              name={CreateFilterFields.parametr}
              id={CreateFilterFields.parametr}
              register={register}
              error={errors[CreateFilterFields.parametr]?.message}
              funcClickEnter={handleAddOption}
            />
            <div className={styles.plusWrapper} onClick={handleAddOption}>
              <PlusOption className={styles.plus} />
            </div>
          </div>
        </div>
        <div className={styles.optionWrapper}>
          {options.map(({ filterItemName, id }) => (
            <FilterOptionItem
              filterItemName={filterItemName}
              id={id}
              key={id}
              changeOptions={() => setOptionsChanged(true)}
            />
          ))}
        </div>
      </div>
      <ul className={styles.check}>
        <Checkbox
          id="multiplicity"
          text="Множественность выбора"
          defaultChecked={isCheckedMultiplicity}
          {...register('multiplicity')}
        />
        <Checkbox
          id="topPriority"
          text="Отображать на главной странице"
          defaultChecked={isCheckedTopPriority}
          {...register('topPriority')}
        />
      </ul>
      <div className={styles.saveBtn}>
        <button className="button buttonWithoutBorder" onClick={() => navigate(-1)} type="button">
          Назад
        </button>
        <button
          className="button buttonSecondary"
          type="button"
          disabled={optionsChanged ? !options.length : isDisabled}
          onClick={handleClickSaveFilter}
        >
          Сохранить
        </button>
        {errorNotification && <ErrorNotification message={errorNotification as string} clearMessage={clearMessages} />}
        {errorMessage && <ErrorNotification message={errorMessage} clearMessage={clearMessages} />}
        {isVisibleModal && (
          <Modal
            setIsActiveModal={(prev) => setIsVisibleModal(prev)}
            handleClick={handleClickConfirmSaveFilter}
            submitButtonText="Сохранить"
          >
            <p>Подтвердить сохранение фильтра?</p>
          </Modal>
        )}
      </div>
    </form>
  );
};
