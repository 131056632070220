import React, { useEffect, useState } from 'react';
import {
  clearArrayUsersChecked,
  setActiveFilter,
  setFiltrationDates,
  setPageNumber,
  setUsersAction,
  useAppDispatch,
} from 'store';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFilterDates } from 'modules/Admin/types';
import { usersPageDatesScheme } from 'modules/Admin/data';
import { Warning } from 'sharedComponents';
import { calendar } from 'modules/Admin/assets';
import { ru } from 'date-fns/locale';

import { FieldName } from './config';
import styles from './styles.module.scss';

export const UsersPageFilterDates = () => {
  const dispatch = useAppDispatch();
  const [isDisabledBtn, setDisabledBtn] = useState(true);

  const [isCalendarStartOpen, setCalendarStartOpen] = useState(false);
  const [isCalendarEndOpen, setCalendarEndOpen] = useState(false);
  const [fullDateStart, setFullDateStart] = useState<Date | null>(null);
  const [fullDateEnd, setFullDateEnd] = useState<Date | null>(null);

  const {
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<IFilterDates>({
    mode: 'onBlur',
    resolver: yupResolver(usersPageDatesScheme),
  });

  const dateStart = watch('dateStart');
  const dateEnd = watch('dateEnd');
  useEffect(() => {
    trigger();
    setDisabledBtn(!isValid);
    if (!dateStart && !dateEnd) setDisabledBtn(true);
  }, [isValid, dateStart, dateEnd, trigger]);

  const handleClickApply = () => {
    dispatch(setFiltrationDates(getValues()));
    dispatch(setActiveFilter(''));
    dispatch(setPageNumber(1));
    dispatch(clearArrayUsersChecked());
    dispatch(setUsersAction());
  };

  const handleCalendarClick = (field: FieldName) => {
    if (field === 'dateStart') {
      setCalendarEndOpen(false);
      setCalendarStartOpen((prev) => !prev);
    } else {
      setCalendarStartOpen(false);
      setCalendarEndOpen((prev) => !prev);
    }
  };

  const handleDateSelect = (date: Date, field: FieldName) => {
    const formattedDate = format(new Date(date.toISOString()), 'dd.MM.yyyy');
    setValue(field, formattedDate);
    if (field === 'dateStart') {
      setFullDateStart(date);
      setCalendarStartOpen(false);
    } else {
      setFullDateEnd(date);
      setCalendarEndOpen(false);
    }
  };

  const handleClickClear = () => {
    setValue('dateStart', '');
    setValue('dateEnd', '');
    setFullDateStart(null);
    setFullDateEnd(null);
    setDisabledBtn(true);
  };

  return (
    <div className={styles.usersPageFilterDates}>
      <form className={styles.inputs} autoComplete="off">
        <div className={styles.inputWithIcon}>
          <Controller
            control={control}
            name="dateStart"
            render={() => (
              <>
                <DatePicker
                  id="dateStart"
                  selected={fullDateStart}
                  placeholderText="__.__.____"
                  onChange={() => {}}
                  open={isCalendarStartOpen}
                  onClickOutside={() => setCalendarStartOpen(false)}
                  onSelect={(date) => handleDateSelect(date, 'dateStart')}
                  dateFormat="dd.MM.yyyy"
                  minDate={new Date(2000, 0, 1)}
                  maxDate={fullDateEnd || new Date(2099, 11, 31)}
                  locale={ru}
                  disabled
                />
                <img
                  src={calendar}
                  alt="calendar"
                  className={styles.calendar}
                  onClick={() => handleCalendarClick('dateStart')}
                />
              </>
            )}
          />
          {errors.dateStart && <Warning message={errors.dateStart.message} absolute />}
        </div>
        <div className={styles.line} />
        <div className={styles.inputWithIcon}>
          <Controller
            control={control}
            name="dateEnd"
            render={() => (
              <>
                <DatePicker
                  id="dateEnd"
                  selected={fullDateEnd}
                  placeholderText="__.__.____"
                  onChange={() => {}}
                  open={isCalendarEndOpen}
                  onClickOutside={() => setCalendarEndOpen(false)}
                  onSelect={(date) => handleDateSelect(date, 'dateEnd')}
                  dateFormat="dd.MM.yyyy"
                  minDate={fullDateStart || new Date(2000, 0, 1)}
                  maxDate={new Date(2099, 11, 31)}
                  locale={ru}
                  disabled
                />
                <img
                  src={calendar}
                  alt="calendar"
                  className={styles.calendar}
                  onClick={() => handleCalendarClick('dateEnd')}
                />
              </>
            )}
          />
        </div>
      </form>
      <div className={styles.buttons}>
        <button className={`button buttonWithoutBorder ${styles.btnClear}`} type="button" onClick={handleClickClear}>
          Очистить
        </button>
        <button className="button buttonSecondary" type="submit" onClick={handleClickApply} disabled={isDisabledBtn}>
          Применить
        </button>
      </div>
    </div>
  );
};
