import React, { FC, useEffect, useRef } from 'react';
import {
  clearArrayUsersChecked,
  clearFilterOptionsChecked,
  getUsersPageOptionsChecked,
  setActiveFilter,
  setFilterOptionsChecked,
  setPageNumber,
  setUsersAction,
  useAppDispatch,
  useAppSelector,
} from 'store';

import { Checkbox } from 'sharedComponents';

import styles from './styles.module.scss';

interface IUsersPageFilterOptions {
  title: string;
  options: string[];
}

export const UsersPageFilterOptions: FC<IUsersPageFilterOptions> = ({ title, options }) => {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const optionsChecked = useAppSelector(getUsersPageOptionsChecked);
  const objChecked = optionsChecked.find((item) => item.filter === title);

  const handleClickOption = (value: string) => {
    dispatch(setFilterOptionsChecked({ filter: title, value }));
  };

  const handleClickApply = () => {
    dispatch(setActiveFilter(''));
    dispatch(setPageNumber(1));
    dispatch(clearArrayUsersChecked());
    dispatch(setUsersAction());
  };

  // закрытие окна если клик вне его области
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        dispatch(clearFilterOptionsChecked(title));
        dispatch(setActiveFilter(''));
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dispatch, title]);

  return (
    <div className={styles.usersPageFilterOptionsContainer} ref={modalRef}>
      <div className={styles.options}>
        {options.map((value) => (
          <Checkbox
            key={value}
            text={value}
            id={value}
            onClick={() => handleClickOption(value)}
            defaultChecked={objChecked?.options.includes(value)}
          />
        ))}
      </div>
      <button
        className="button buttonSecondary"
        type="submit"
        onClick={handleClickApply}
        disabled={!objChecked?.options.length}
      >
        Применить
      </button>
    </div>
  );
};
